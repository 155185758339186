import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Tooltip } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import BlockUI from "../../layouts/bockUi/BlockUI";
import config from "../../env.json";
import {
  contactDetails,
  deleteContactApi,
} from "../../apis/contacts/contactsApi";
import { useSelector, useDispatch } from "react-redux";
import {
  setChatDetails,
  setChatShowChat,
  setChatType,
  setRefreshChatComponent,
  setRefreshChatList,
  setNumberAssigned,
} from "../../app/slice/chatSlice";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { setCallModalDetails } from "../../app/slice/callModalSlice";
import { useTranslation } from "react-i18next";
import LogoutModal from "../logoutModal/LogoutModal";
import { setActiveTab, setTitle } from "../../app/slice/sideBarSlice";
import { setShowSuccessSnackBar } from "../../app/slice/personalisationSlice";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { Keyboard } from "@capacitor/keyboard";
import { useNavigate } from "react-router-dom";
import Avatar from "../Avatar/Avatar";
import "./ContactDetialsModal.css";
import EditContactGAM from "./EditContactGAM";
export default function ContactDetialsModalGAM(props) {
  const {
    showDetails,
    contact,
    handleCloseParent,
    fetchDetails,
    showCallMakerModal,
    showAddContactModal,
    showChatIcon,
    funcForEdited,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(showDetails);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [blockUI, setBlockUI] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [post, setPost] = useState<any>();
  const [deleteModal, setDeletModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [disableIcons, setDisableIcons] = useState(true);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const { chatId } = useSelector((state: any) => state.chat);
  const Chattype = useSelector((state: any) => state.chat.type);
  const chatPermId = useSelector((state: any) => state.sideBar.chatPermId);
  const navigate = useNavigate();

  useEffect(() => {
    calcHeightWidth();
  }, [mobileStyle, modalHeight, modalWidth]);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        calcHeightWidth();
      });

      Keyboard.addListener("keyboardDidHide", () => {
        calcHeightWidth();
      });
    }
  }, []);

  const calcHeightWidth = () => {
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  const getNumberFromItem = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return getFormatedPhoneNumber(item.AcctPh_Nbr);
      } else
        return getFormatedPhoneNumber(
          "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr
        );
    } else return "";
  };
  const getContactDetails = async (contact) => {
    if (!fetchDetails) {
      setPost(contact);
      setName(contact.AcctFull_Nm);
      setEmail(contact.Email_Adr);
      setTitle(contact.Title_Cd);
      setHospitalName(contact.HospFull_Nm);
      setPhone(getNumberFromItem(contact));
      dispatch(
        setCallModalDetails({
          AcctFull_Nm: contact.AcctFull_Nm,
          AcctFull_Nbr: getNumberFrom(contact),
        })
      );
    } else {
      setBlockUI(true);
      contactDetails(
        { Id: contact.Id, Is_Prescriber: contact.Is_Prescriber == "Y" },
        (res) => {
          if (res) {
            if (!res.message) {
              setDisableIcons(false);
              let data = res;
              dispatch(
                setCallModalDetails({
                  AcctFull_Nm: data.AcctFull_Nm,
                  AcctFull_Nbr: getNumberFrom(data),
                })
              );
              console.log("postdata", data);
              setPost(data);
              setName(data.AcctFull_Nm ? data.AcctFull_Nm : "");
              setEmail(data.Email_Adr);
              setTitle(data.Title_Cd);
              setHospitalName(data.HospFull_Nm);
              getNumberFromItem(data);
              setPhone(getNumberFromItem(data));
              if (data.Prescribers) {
              }
            } else {
              setDisableIcons(true);
            }
          }
          setBlockUI(false);
        }
      );
    }
  };
  const getNumberFrom = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  };

  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    return () => {
      window.removeEventListener("orientationchange", calcHeightWidth);
    };
  }, []);

  useEffect(() => {
    getContactDetails(contact);
    setShow(showDetails);
    calcHeightWidth();
  }, [showDetails]);

  const deleteContact = (e: any) => {
    if (e === true) {
      setShowDelete(true);
      deleteContactApi(contact.Id, (res) => {
        if (res) {
          if (chatId == contact.Id && Chattype == "ASSPRS") {
            dispatch(setRefreshChatComponent(false));
            dispatch(setShowSuccessSnackBar(true));
          }
          dispatch(setRefreshChatList(true));
        }
        handleCloseParent();
      });
    }
  };
  const updateContactAfterEdit = (contact) => {
    console.log("setpost", contact);
    setPost(contact);
    getContactDetails(contact);
  };
  const setChat = (post: any) => {
    dispatch(
      setChatDetails({
        chatId: post.Id,
        chatIsPrescriber: post.Is_Prescriber == "Y" ? true : false,
        showChat: true,
        chatName: post.AcctFull_Nm,
      })
    );
    dispatch(setNumberAssigned(true));
    dispatch(setChatType(post.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS"));
    navigate("/chat");
    dispatch(setActiveTab(chatPermId));
  };
  return (
    <div>
      showEditModal &&
      <EditContactGAM
        show={showEditModal}
        handleCloseParent={(e, contact: any) => {
          setShowEditModal(false);
          if (!window.location.href.includes("/Chat")) {
            dispatch(setChatShowChat(false));
          }
          if (e) {
            funcForEdited && funcForEdited(contact);
            updateContactAfterEdit(contact);
          }
        }}
        onChangefunction={(e) => {
          console.log(e);
          setShow(e);
        }}
        contact={post}
      />
      <div className="customParentDivModal">
        <Modal
          open={show}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <Box>
              <div
                id="modalOuter"
                className="modal-box"
                style={
                  mobileStyle
                    ? { height: modalHeight + "px", width: modalWidth + "px" }
                    : {}
                }
              >
                <div className="modal-header d-flex">
                  <h1 className="font-createcontact">{t("Contact Details")}</h1>
                  <span
                    onClick={() => {
                      handleCloseParent(post, showDelete);
                    }}
                    className="close"
                  >
                    ×
                  </span>
                </div>

                <div className="modal-body">
                  <div className="name-avatar-div">
                    <div className="contact-avatar-div">
                      <Avatar
                        imageType={
                          contact.Is_Prescriber === "Y"
                            ? "prescriber"
                            : "prescriberassociates"
                        }
                        avatarHeight="3.5rem"
                        avatarWidth="3.5rem"
                        presOrAssoc={name}
                      />
                    </div>

                    <label
                      className="font-md-title"
                      style={{ wordBreak: "break-word" }}
                    >
                      {name}
                    </label>

                    <div className="buttons-div contactDetailsButton">
                      {showChatIcon === true ? (
                        <button
                          disabled={disableIcons}
                          onClick={() => {
                            phone.length > 0
                              ? setChat(post)
                              : showAddContactModal(post, true);
                          }}
                        >
                          <Tooltip title={t("Message")} arrow>
                            <MessageIcon />
                          </Tooltip>{" "}
                        </button>
                      ) : null}

                      <Tooltip title={t("Call")} arrow>
                        <button
                          disabled={disableIcons}
                          onClick={() => {
                            phone.length > 0
                              ? showCallMakerModal(post)
                              : showAddContactModal(post, false);
                          }}
                        >
                          <CallIcon />
                        </button>
                      </Tooltip>

                      <Tooltip title={t("Edit")} arrow>
                        <button
                          disabled={disableIcons}
                          onClick={() => {
                            setShowEditModal(true);
                            setShow(false);
                          }}
                        >
                          <ModeEditOutlineOutlinedIcon />
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="name-contact-details-div">
                    <label className="font-md-title margin-top">
                      {t("Information")}
                    </label>
                    <hr />
                    <div className="contacts-detials-info">
                      <label className="font-md">{t("Contact Name")}</label>
                      <label
                        className="font-md-normal marginCtn"
                        style={{ wordBreak: "break-word" }}
                      >
                        {" "}
                        {name}
                      </label>

                      <label className="font-md">{t("Title")}</label>
                      <label className="font-md-normal marginCtn">
                        {" "}
                        {title}
                      </label>

                      <label className="font-md">{t("Phone Number")}</label>

                      {phone.length > 0 ? (
                        <label className="font-md-normal marginCtn">
                          {" "}
                          {getFormatedPhoneNumber(phone)}
                        </label>
                      ) : (
                        <br />
                      )}
                      <label className="font-md">{t("Email address")}</label>
                      {email?.length > 0 ? (
                        <label className="font-md-normal marginCtn">
                          {email}
                        </label>
                      ) : (
                        <br />
                      )}

                      <label className="font-md">{t("Account name")}</label>

                      {hospitalName.length > 0 ? (
                        <label className="font-md-normal marginCtn">
                          {hospitalName}
                        </label>
                      ) : (
                        <br />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
      <LogoutModal
        show={deleteModal}
        yorn={deleteContact}
        modalText={t("Are you sure you want to delete ?")}
        handleCloseParent={(e) => {
          console.log(e);
          setDeletModal(false);
        }}
      />
      {blockUI ? <BlockUI show={true} /> : null}
    </div>
  );
}
