import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import GenericButton from "../../layouts/button/Button";
import Fade from '@mui/material/Fade';
import DropdownInupt from "../shared/Dropdown";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import config from "../../env.json";
import './EmailDetailsModal.css'
import './../../App.css';
import axios from "axios";
import { useGoogleLogin } from '@react-oauth/google';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};
//{ "id": "0", "name": "Outlook" }
const emailProvider = [{ "id": "1", "name": "Google" }]
export default function EmailDetailsModal({ email, show, handleClose }) {

  const { t } = useTranslation();
  const [mobileStyle, setMobileStyle] = useState(false);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [emailProviderType, setEmailProviderType] = useState("");
  const [loginProviderType, setLoginProviderType] = useState("");
  const [emailUserName, setEmailUserName] = useState<any>("");
  const [emailType, setEmailType] = useState<any>([]);
  useEffect(() => {
    setEmailType(emailProvider)
  })

  function getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  async function handleGoogleLogin ()  {
    var oAuthUrl    =   'https://accounts.google.com/o/oauth2/auth?';
    var scope       =   'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar  https://www.googleapis.com/auth/gmail.compose  https://www.googleapis.com/auth/gmail.send';
    var clientId    =   config.REACT_APP_GOOGLE_CLIENT_ID;
    var redirect    =   'http://localhost:3000'
    // var LOGOUT      =   'http://accounts.google.com/Logout';
    var type        =   'code';
    var _url        =   oAuthUrl + 'scope=' + scope + '&client_id=' + clientId + '&redirect_uri=' + redirect + '&response_type=' + type + '&prompt=select_account&access_type=offline';
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      let browser = InAppBrowser.create(_url, '_blank', 'location=no');
      browser.on('loadstart').subscribe(async event => {
        if(event.url.startsWith(redirect)) {
          browser.close();
          let code = getParameterByName('code', event.url);
          if(code && code != "") {
            callGoogleTokenAPI(code)
          }
        }
      })
    } else {
      googleLoginWeb()
    }
  }


  const googleLoginWeb = useGoogleLogin({
    flow: 'auth-code',
    scope: 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar  https://www.googleapis.com/auth/gmail.compose  https://www.googleapis.com/auth/gmail.send',
    onSuccess: async (codeResponse) => {
        console.log(codeResponse);
        callGoogleTokenAPI(codeResponse.code)
    },
    onError: errorResponse => console.log(errorResponse),
  });


  async function handleGoogleLogout ()  {
    var logout      =   'http://accounts.google.com/Logout';
    
    if(config.REACT_APP_IS_CROSS_PLATFORM) {
      let browser = InAppBrowser.create(logout, '_blank', 'location=no');
      browser.on('loadstart').subscribe(async event => {
        browser.close();
      })
    } else {
      var winObj         =   window.open(logout, "windowname1", 'width=800, height=600')!; 
      var pollTimer   =   window.setInterval(function() { 
        try {

          winObj.close();
          window.clearInterval(pollTimer);
        } catch(e) {
        }
    }, 500);
      
    }
    callGoogleTokenRemoveAPI()
    setEmailUserName("")
  }
  
  

  async function callGoogleTokenAPI(code) {
    axios
        .post(
          config.REACT_APP_CALENDAR_API_BASE+"/google/token",
          {
            code: code,
            syncType: 'calendar'
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          setEmailUserName(res.data.data.email)
          setLoginProviderType("Google")
          console.log(res);
        })
        .catch((err)=>{
          console.log(err)
        })
  }

  async function callGoogleTokenRemoveAPI() {
    axios
        .delete(
          config.REACT_APP_CALENDAR_API_BASE+"/google/token",
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
            data: {
              syncType: 'calendar'
            }
          }
        )
        .then((res) => {
          setLoginProviderType("")
          console.log(res);
        })
        .catch((err)=>{
          console.log(err)
        })
  }

  useEffect(() => {
    // console.log(axios.CancelToken.source())
    calcHeightWidth();
    window.addEventListener("resize", (e) => {
      calcHeightWidth();
    });
    setEmailUserName(email)
    if(email != "") {
      setLoginProviderType("Google")
    } else {
      setLoginProviderType("")
    }
    
  }, []);
  const calcHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    console.log("gc modal toppad", divpaddingTop);
    console.log("gc modal bottompad", divpaddingbottom);
    console.log("gc modal leftpad", divpaddingleft);
    console.log("gc modal rightpad", divpaddingright);
    console.log(
      "gc modal height",
      window.innerHeight - divpaddingTop - divpaddingbottom
    );
    console.log(
      "gc modal width",
      window.innerWidth - divpaddingleft - divpaddingright
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  return (
    <div>
      <Modal
        open={show}
        //onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div className='modal-box'
              style={
                mobileStyle
                  ? {
                    height:
                      window.innerWidth > 768
                        ? "calc(75.7458%)"
                        : "calc(65.75%)",
                    width: modalWidth + "px",
                  }
                  : {}
              }>
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t("Email Details")}</h1>
                <span onClick={() => { handleClose(emailUserName) }} className="close">×</span>
              </div>

              <div className="modal-body addEmailDerails"
                style={mobileStyle ? { height: modalHeight - 99.7 + "px" } : {}}>
                <div className="form-fields-div">
                {emailProvider.length == 1 ? <TextField className="roundborder inputEnable"
                    value={emailProvider[0].name}
                    label={t("Email Provider")}
                    placeholder={emailProvider[0].name}
                    color="secondary"
                    autoComplete="off"
                    disabled
                  />:
                  <DropdownInupt
                    notdisabledAfter={true}
                    //refreshData={refreshTemplate}
                    label="Email Provider"
                    data={emailType}
                    keepPlaceHolderAtStart={true}
                    placeHolder={t("Select a Email Provider")}
                    name="name"
                    // disableColored={disableTemplate}
                    disableLater={false}
                    func={(e) => {
                      console.log(e);
                      setEmailProviderType(e.name);
                      //   console.log(disabled,e.TemplateId==0,optedOut,assoPrescName)
                      //   setIsForm(e.FormId!==null);
                      //   setTemplateText(e.TemplateBody);
                      //   setOriginalTemplateText(e.TemplateBody);
                      //   setTemplateValue(e);
                      //   setTemplateId(e.TemplateId);
                      //   setDisabled(false);
                      
                      //   if(!formType && (assoPrescName.length <=0 || othersName.length <= 0 || othersPhone.length <= 0)){
                      //     setDisabled(true)
                      //   }
                      //   // else if(othersName.length<=0)
                      //   // {
                      //   //   setDisabled(true)
                      //   // }

                      //   // if(setAssoPrescName)
                      //   setAssets(e.AssetDetails?e.AssetDetails:[]);
                      //   setSelectForm(e)
                      //   setFormValues(e.FieldDetails)           
                    }}
                  />}
                  { emailUserName != "" ? 
                  <TextField className="roundborder inputEnable"
                    value={emailUserName}
                    label={t("Email")}
                    placeholder={t("Email")}
                    color="secondary"
                    focused
                    autoComplete="off"
                    disabled
                  />:
                  null

                  }
                  

                  {emailProvider.length === 1 && loginProviderType == '' ? (
                    <div className='modal-inputs'>
                      <div className='button-wrapper'>
                          <button className="create-btn"
                            onClick={() => { 
                              if(emailProvider[0].name === 'Google') {
                                handleGoogleLogin()
                              }
                             }}>
                            Login with {emailProvider[0].name}
                          </button>
                      </div>
                    </div>
                  ) : null}

                  {emailProvider.length === 1 && loginProviderType != '' ? (
                    <div className='modal-inputs'>
                      <div className='button-wrapper'>
                          <button className="create-btn"
                            onClick={() => { 
                              if(emailProvider[0].name === 'Google') {
                                handleGoogleLogout()
                              }
                             }}>
                            Logout from {emailProvider[0].name}
                          </button>
                      </div>
                    </div>
                  ) : null}

                  {emailProviderType  && loginProviderType == '' ? (
                    <div className='modal-inputs'>
                      <div className='button-wrapper'>
                        {emailProviderType == 'Google' ? (
                          <button className="create-btn"
                            onClick={handleGoogleLogin}>
                            {t('Login with Google')}
                          </button>
                        ) : (
                          <button className="create-btn"
                            onClick={() => { setLoginProviderType("Outlook") }}>
                            {t('Login with Outlook')}
                          </button>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
