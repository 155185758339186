import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./Video.css";
import VideoModal from "../../components/video/VideoModal";
import Tooltip from "@mui/material/Tooltip";

import { getAllVideoHistory } from "../../apis/video/video";
import moment from "moment";
import { formatDateIntoTodayYesterdayFormat } from "../../utils/Date";
import {
  setVideoCallDetails,
  setVideoCallShowFloater,
  setVideoCallRoom,
  setVideoCall,
  setVideoCallCallStarted,
  setVideoCallEnded,
  setVideoCallChats,
  setDisconnectedParticipants,
} from "../../app/slice/videoCallSlice";
import {
  setVideoCallFileSharedData,
  setSharedFilePermissionModal,
} from "../../app/slice/sharefileModalSlice";
import { useSelector, useDispatch } from "react-redux";
import config from "../../env.json";
import VideoCall from "../../components/video/videoCall/VideoCall";
import axios from "axios";
import { connect, LocalDataTrack } from "twilio-video";
import { Videocam } from "@mui/icons-material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Spinner from "../../layouts/spinner/Spinner";

import { useTranslation } from "react-i18next";
import Button from "../../layouts/button/Button";
import { setFullScreenMode } from "../../app/slice/videoCallsliceCentral";
import VideoLogs from "../../components/video/VideoLogs";
import InfiniteScroll from "react-infinite-scroll-component";
import VideoSpinner from "../../layouts/spinner/VideoSpinner";
import SharePopup from "../../components/SharePopup/SharePopup";

export default function Video() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const videoCallCallStarted = useSelector(
    (state: any) => state.floaterVideoCall.videoCallCallStarted
  );
  const fullScreenMode = useSelector(
    (state: any) => state.videoCallCentral.fullScreenMode
  );
  const pageTitle = useSelector((state: any) => state.sideBar.title);
  const [histData, setHistData] = useState([]) as any;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [ongoing, setOngoing] = useState(false);
  const [videoLogs, setVideologs] = useState(false);
  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(0);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [spin, setSpin] = useState(false);
  const [activeVideoTab, setActiveVideoTab] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const videoCallRecordingStarted = useSelector(
    (state: any) => state.floaterVideoCall.videoCallRecordingStarted
  );
  const videoCallRecordingStartedTime = useSelector(
    (state: any) => state.floaterVideoCall.videoCallRecordingStartedTime
  );
  const videoCallFileDetail = useSelector(
    (state: any) => state.floaterVideoCall.videoFileDetails
  );
  const showSharedModal = useSelector(
    (state: any) => state.shared.showSharedFilePopup
  );
  const personalizationDateFormat = useSelector(
    (state: any) => state.personalisation.personalizationDateFormat
  );
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [sharePopupDetails, setSharePopupDetails] = useState({});
  let currdate = new Date();

  const getData = (e) => {
    console.log("eroor");
    let pageNum = 1;
    if (e == "upcoming") {
      pageNum = 1;
      setPage(pageNum);
    } else {
      pageNum = page > 1 ? page : pageNum;
    }
    setSpin(true);

    getAllVideoHistory({ Page: pageNum }, (res: any) => {
      let pageNum = page + 1;
      setPage(pageNum);
      setHistData([...histData, ...res]);
      if (res.length < 20) setHasMore(false);
      else setHasMore(true);
      setSpin(false);

      setHistData(res);
      console.log("gc data is here", res);
    });
  };

  const openInNewTab = async (item: any) => {
    localStorage.setItem("VideoOff", "false");
    localStorage.setItem("Mute", "false");
    dispatch(setVideoCallChats([]));
    dispatch(setDisconnectedParticipants([]));
    let temp = item.WebHook_Url.split("?")[1].split("&");
    let randomToken = temp[0].split("=")[1];
    let name = temp[1].split("=")[1];
    let otp = temp[2].split("=")[1];

    axios
      .get(config.REACT_APP_VIDEO_API_BASE + "/meeting/GetAuthTokenFromZing", {
        params: {
          RandomToken: randomToken,
          OTP: otp,
          RoomId: item.Room_Id,
          UserName: name,
        },
      })
      .then((res) => {
        console.log(res);

        dispatch(
          setVideoCallDetails({
            videoCallName: item.Room_Nm,
            videoCallToken: res.data.data,
            videoCallId: item.Room_Id,
            videoCallUrl: item.WebHook_Url,
          })
        );
        connect(res.data.data, {
          name: item.Room_Id,
          audio: true,
          video: true,
          networkQuality: {
            local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
          dominantSpeaker: true,
        }).then((room: any) => {
          getChat(item.Room_Id);
          room.localParticipant.setNetworkQualityConfiguration({
            local: 2,
            remote: 1,
          });
          room.localParticipant.publishTrack(new LocalDataTrack());
          dispatch(setVideoCallShowFloater(false));
          dispatch(setVideoCallRoom(room));
          dispatch(setVideoCall(item.Trn_Call_Id));
          dispatch(setVideoCallEnded(false));
          dispatch(setVideoCallCallStarted(true));
          setOngoing(true);
        });
      });
  };

  const getChat = (roomId) => {
    axios
      .get(
        config.REACT_APP_VIDEO_API_BASE + "/meeting/GetChat?RoomId=" + roomId
      )
      .then((res) => {
        console.log(res);

        if (typeof res.data != "string")
          dispatch(setVideoCallChats(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getHeight = () => {
    let viewportHeight = window.innerHeight;
    let doc: any = document;
    if (doc.getElementsByClassName("videoListScroll")[0])
      return (
        viewportHeight -
        doc.getElementsByClassName("videoListScroll")[0].offsetTop -
        5
      );
    else return "100%";
  };
  useEffect(() => {
    getHeight();
  }, []);
  useEffect(() => {
    console.log("video");
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
    getData("");

    let doc: any = document;
    let div: any = document.querySelector("#main");
    console.log("gc MobHeader" + doc.getElementById("MobHeader").offsetHeight);
    console.log("gc Header" + doc.getElementById("headerVal").offsetHeight);
    console.log("gc footer" + doc.getElementById("footer").offsetHeight);
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingright);
    setInfiniteScrollHeight(
      window.innerHeight -
        (doc.getElementById("MobHeader").offsetHeight +
          document.getElementById("headerVal")?.offsetHeight +
          doc.getElementById("footer").offsetHeight) -
        divpaddingTop -
        divpaddingbottom
    );

    handletabs();
    if (showSharedModal) {
      setShowSharePopup(true);
      setSharePopupDetails(videoCallFileDetail);
    }
  }, []);

  const handletabs = () => {
    let doc: any = document;
    if (!sessionStorage.getItem("activeVideoTab")) {
      setActiveVideoTab("upcoming");
      setVideologs(false);
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "#734BD1";
        doc.getElementById("upcoming").style.fontWeight = "bold";
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "grey";
        doc.getElementById("videolog").style.removeProperty("fontWeight");
      }
    } else if (sessionStorage.getItem("activeVideoTab") === "upcoming") {
      setActiveVideoTab("upcoming");
      setVideologs(false);
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "#734BD1";
        doc.getElementById("upcoming").style.fontWeight = "bold";
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "grey";
        doc.getElementById("videolog").style.removeProperty("fontWeight");
      }
    } else if (sessionStorage.getItem("activeVideoTab") === "ongoingMeeting") {
      setActiveVideoTab("ongoingMeeting");
      setVideologs(false);
      if (videoCallCallStarted) {
        doc.getElementById("ongoingMeeting").style.color = "#734BD1";
        doc.getElementById("ongoingMeeting").style.fontWeight = "bold";
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "grey";
        doc.getElementById("videolog").style.removeProperty("fontWeight");
      }
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "grey";
        doc.getElementById("upcoming").style.removeProperty("fontWeight");
      }
    } else {
      setActiveVideoTab("past-video");
      setVideologs(true);
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "grey";
        doc.getElementById("upcoming").style.removeProperty("fontWeight");
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.fontWeight = "bold";
        doc.getElementById("videolog").style.color = "#734BD1";
      }
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "initial");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [pageTitle]);
  const handleNewVideo = () => {
    setModalShow(true);
  };

  useEffect(() => {
    console.log(videoCallCallStarted);
    if (videoCallCallStarted) {
      setOngoing(true);
    } else {
      setOngoing(false);
    }
    handletabs();
  }, [videoCallCallStarted]);
  const getaddChange = (timestring1) => {
    var timestring2 = moment();
    var startdate = moment(timestring1);
    var expected_end = moment(timestring2).subtract(2, "hours");
    var returned_end = moment(startdate).add(2, "hours");
    returned_end.isSameOrAfter(expected_end);

    console.log(timestring1);
    console.log(timestring2);
    console.log(returned_end.isSameOrAfter(expected_end));

    if (!returned_end.isSameOrAfter(expected_end)) {
      return false;
    } else {
      return true;
    }

    // return (false)
  };
  const handleActive = (e) => {
    let doc: any = document;

    if (e == "past-video") {
      setOngoing(false);
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "#734BD1";
        doc.getElementById("videolog").style.fontWeight = "bold";
      }
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "grey";
        doc.getElementById("upcoming").style.removeProperty("fontWeight");
      }
      if (videoCallCallStarted) {
        doc.getElementById("ongoingMeeting").style.color = "grey";
        doc.getElementById("ongoingMeeting").style.removeProperty("fontWeight");
      }
    } else if (e == "upcoming") {
      getData(e);
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "#734BD1";
        doc.getElementById("upcoming").style.fontWeight = "bold";
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "grey";
        doc.getElementById("videolog").style.removeProperty("fontWeight");
      }

      if (videoCallCallStarted) {
        doc.getElementById("ongoingMeeting").style.color = "grey";
        doc.getElementById("ongoingMeeting").style.removeProperty("fontWeight");
      }
    } else {
      if (doc.getElementById("ongoingMeeting")) {
        doc.getElementById("ongoingMeeting").style.color = "#734BD1";
        doc.getElementById("ongoingMeeting").style.fontWeight = "bold";
      }
      if (doc.getElementById("videolog")) {
        doc.getElementById("videolog").style.color = "grey";
        doc.getElementById("videolog").style.removeProperty("fontWeight");
      }
      if (doc.getElementById("upcoming")) {
        doc.getElementById("upcoming").style.color = "grey";
        doc.getElementById("upcoming").style.removeProperty("fontWeight");
      }
    }
  };
  const getStartBtnByMeetingTime = (meetingTime: any) => {
    const now = new Date();
    const meeting = new Date(meetingTime);
    const difference = meeting.getTime() - now.getTime();
    const hours = difference / (1000 * 60 * 60);
    let hoursduration = parseInt(config.VIDEO_MEETING_START_END_BUFFER_HOURS);
    if (hours > hoursduration) {
      return true;
    }
    return false;
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
    dispatch(setSharedFilePermissionModal(false));
  };

  return (
    <div className="calllog-container videoContainer">
      {showSharedModal ? (
        <SharePopup
          contentDetails={sharePopupDetails}
          closeSharePopup={closeSharePopup}
        />
      ) : // <SharePopup2/>
      null}
      <div className="calllog-header  videoHeader notMobile">
        <div>
          <button
            className={
              +!ongoing ? "activeMeeting meetingHeading" : "meetingHeading"
            }
            id="upcoming"
            onClick={() => {
              setOngoing(false);
              setVideologs(false);
              handleActive("upcoming");
              setPage(1);
              sessionStorage.setItem("activeVideoTab", "upcoming");
            }}
          >
            {t("Upcoming meetings")}
          </button>
          &nbsp;&nbsp;
          <button
            className={
              +!ongoing ? "activeMeeting meetingHeading" : "meetingHeading"
            }
            id="videolog"
            onClick={() => {
              setVideologs(true);
              handleActive("past-video");
              sessionStorage.setItem("activeVideoTab", "past-video");
            }}
          >
            {t("Past meeting")}
          </button>
          {videoCallCallStarted ? (
            <button
              className={
                ongoing ? "activeMeeting meetingHeading " : "meetingHeading"
              }
              id="ongoingMeeting"
              onClick={() => {
                setOngoing(true);
                handleActive("ongoingMeeting");
                sessionStorage.setItem("activeVideoTab", "ongoingMeeting");
              }}
            >
              {t("Ongoing call")}
              {videoCallRecordingStarted && videoCallRecordingStartedTime ? (
                <div
                  id="recordTimer"
                  style={{
                    position: "absolute",
                    background: "red",
                    zIndex: "10",
                    right: "26rem",
                  }}
                >
                  {videoCallRecordingStartedTime.hours}:
                  {videoCallRecordingStartedTime.minutes}:
                  {videoCallRecordingStartedTime.seconds}
                </div>
              ) : null}
            </button>
          ) : null}
        </div>
        <div className="video-btn" onClick={handleNewVideo}>
          <i className="material-icons i-2p5">{t("video_call")}</i>
          <h4 className="font-video-meeting">{t("New Video Meeting")}</h4>
        </div>
      </div>
      {!ongoing && (
        <div className="video-mobile-header onlyMobile">
          <button
            className={
              videoLogs
                ? "header-title inactive-tab"
                : "header-title active-tab"
            }
            id="upcoming"
            onClick={() => {
              setOngoing(false);
              setVideologs(false);
              sessionStorage.setItem("activeVideoTab", "upcoming");

              handleActive("upcoming");
            }}
          >
            {t("Upcoming meeting")}
          </button>
          <button
            className={
              videoLogs
                ? "header-title active-tab"
                : "header-title inactive-tab"
            }
            id="videolog"
            onClick={() => {
              setVideologs(true);
              sessionStorage.setItem("activeVideoTab", "past-video");

              handleActive("past-video");
            }}
          >
            {t("Past meeting")}
          </button>
        </div>
      )}

      {ongoing ? (
        <VideoCall />
      ) : videoLogs ? (
        <VideoLogs />
      ) : (
        <div className="videolist-body">
          <div className="videolist-title call-log-sub-header notMobile">
            <div className="video-call-log-list-item">{t("Meeting Name")}</div>
            <div className="video-call-log-list-item">{t("Meeting Date")}</div>
            <div className="video-call-log-list-item">{t("Meeting Time")}</div>
            <div className="video-call-log-list-item">{t("Action")}</div>
          </div>

          <div
            className="videoListScroll"
            style={{ height: infiniteScrollHeight }}
          >
            {/* Desktop */}
            <InfiniteScroll
              dataLength={histData.length} //This is important field to render the next data
              next={() => {
                getData("");
              }}
              hasMore={hasMore}
              loader={<Spinner />}
              height={700}
              endMessage={
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    fontWeight: "lighter",
                  }}
                  className="content-spacing"
                >
                  {histData.length == 0 ? (
                    <span>{t("No Upcoming records found")}</span>
                  ) : (
                    <span></span>
                  )}
                </p>
              }
            >
              <ul className="notMobile">
                {spin ? <Spinner /> : null}

                {histData.map((item: any) => {
                  return (
                    <li>
                      <div className="calllogListItem-div">
                        <div className="avatar-div video-calllogListItem-content">
                          <div
                            className="video-calllogListItem-content"
                            style={{
                              fontSize: "1.098rem",
                              fontWeight: "bold",
                            }}
                          >
                            {item.Room_Nm}
                          </div>
                        </div>

                        <div
                          className="video-calllogListItem-content"
                          style={{ fontSize: "1.098rem" }}
                        >
                          {t(
                            formatDateIntoTodayYesterdayFormat(
                              item["Start_DtTm"],
                              personalizationDateFormat
                            )
                          )}
                        </div>
                        <div
                          className="video-calllogListItem-content"
                          style={{ fontVariant: "", fontSize: "1.098rem" }}
                        >
                          {moment(item["Start_DtTm"]).format("hh:mm A")}
                        </div>
                        {
                          <button
                            disabled={
                              videoCallCallStarted
                                ? true
                                : getStartBtnByMeetingTime(item.Start_DtTm)
                            }
                            id="v-join"
                            type="button"
                            className="video-join"
                            onClick={() => openInNewTab(item)}
                          >
                            {t("Start")}{" "}
                          </button>
                        }
                      </div>
                    </li>
                  );
                })}
                {/* ); */}
              </ul>

              {/* Mobile view */}
              <ul className="meeting-list onlyMobile">
                {spin ? <Spinner /> : null}

                {/* {CalllogList.map((item) => { */}
                {/* return ( */}
                {histData.map((item: any) => {
                  return (
                    <li>
                      <div className="meeting-list-item">
                        <div className="meeting-date">
                          {moment(item["Start_DtTm"]).format("Do MMM dddd")}
                        </div>

                        <div className="meeting-name">{item.Room_Nm}</div>

                        <div className="meeting-time">
                          {moment(item.Start_DtTm).format("h:mm A")}
                        </div>

                        <div className="meeting-host">
                          <span>{"Created by: "}</span>
                          {item.RequestedBy.split(",")[0].length > 15
                            ? item.RequestedBy.split(",")[0].substring(0, 15) +
                              "..."
                            : item.RequestedBy.split(",")[0].substring(0, 15)}
                        </div>

                        <button
                          disabled={
                            videoCallCallStarted
                              ? true
                              : getStartBtnByMeetingTime(item.Start_DtTm)
                          }
                          id="v-join"
                          type="button"
                          className="meeting-icon video-join"
                          onClick={() => openInNewTab(item)}
                        >
                          {" "}
                          {t("Start")}{" "}
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </InfiniteScroll>
            <div
              className="floating onlyMobile"
              style={{
                paddingBottom: paddingValBottom,
                paddingRight: paddingValRight,
              }}
            >
              <div className="video-btn" onClick={handleNewVideo}>
                <i
                  className="material-icons i-2p5"
                  style={{ fontSize: "2rem" }}
                >
                  {t("video_call")}
                </i>
                <h4 className="font-video-meeting">{t("New Video Meeting")}</h4>
              </div>
            </div>
          </div>
        </div>
      )}

      <VideoModal
        show={modalShow}
        handleCloseParent={(e) => {
          if (e) {
            getData("upcoming");
          }
          setModalShow(false);
        }}
      />
    </div>
  );
}
