import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "../../components/shared/Dropdown.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultiselectDropdown(props) {
  const theme = useTheme();
  const [value, setValue] = useState([props.selectedData]);
  const [checkValues, setCheckValues] = useState<String[]>([]);
  const handleChange = (event: any) => {
    if (!props.disable) {
      setValue(event.target.value);
      setCheckValues(event.target.value.map((x) => x[props.id]));
      props.func(event.target.value);
    }
  };

  useEffect(() => {
    
   setValue(props.selectedData.length>0?props.selectedData:[])
   setCheckValues(props.selectedData.map(x=>x[props.id]))
   
  }, [props])
  

  return (
    <div className="genericDropdownDiv">
      <InputLabel className="genericDropdown_label">{props.label}</InputLabel>
      <Select
        onChange={handleChange}
        className="genericDropdown_select generic_form_select"
        placeholder="Cordastin"
        value={value}
        input={<OutlinedInput />}
        renderValue={(selected: any) => {
          console.log(selected);
          return selected.map((x: any) => x[props.name]).join(",");
        }}
        MenuProps={MenuProps}
        multiple
      >
        {props.data.map((name: any) => (
          <MenuItem
            value={name}
            className="brand_name_dropdown multi-select-itemlist"
          >
            {checkValues.indexOf(name[props.id]) >= 0 ? (
              <Checkbox checked={true} />
            ) : (
              <Checkbox checked={false} />
            )}
            {name[props.name]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
