import { Box, Modal } from "@mui/material";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import htmlToDraft from "html-to-draftjs";
import "./MobileDraftMail.css";
import { EditorState, ContentState } from "draft-js";
import Button from "../../layouts/button/Button";
import { updateMail } from "../../apis/Email/Email";
import { convertToHTML } from "draft-convert";
import { setComposeEmailView } from "../../app/slice/emailSubMenuSlice";

export default function AlignItemsList({ email, handleClose }) {
  const dispatch = useDispatch();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const editor = useRef(null);
  const [toEmailId, setToEmailId] = useState<any>();
  const [ccEmailId, setCcEmailId] = useState<any>();
  const [bccEmailId, setBccEmailId] = useState<any>();
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);
  const [emailId, setEmailId] = useState<any>();
  const config = {
    readonly: false,
  };

  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );
  const [emailSubject, setEmailSubject] = useState<any>();
  useEffect(() => {
    const contentBlock = htmlToDraft(email?.Email_Bdy);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      )
    );

    console.log(email);
    email?.Id.map((e) => {
      setEmailId(e);
    });
    if (email) {
      setToEmailId(email?.EmailTo_Email);
      setCcEmailId(email?.EmailCC_Email);
      setEmailSubject(email?.Subject);
    }
  }, [email]);

  const sendMail = () => {
    let html = convertToHTML(editorState.getCurrentContent());
    let updatedEmailBody = html;
    console.log(emailId);
    let data = {
      EmailId: emailId,
      ContactId: 1000,
      ContactType: "PRES",
      EmailStatus: "SENT",
      EmailBody: updatedEmailBody,
      EmailSubject: emailSubject,
      ContentType: "HTML",
      ToEmail: toEmailId,
      CCEmail: ccEmailId,
    };
    console.log(data);
    updateMail(data, (resp) => {
      console.log(resp);
      if (resp === 200) {
        dispatch(setComposeEmailView(false));
      }
    });
  };

  return (
    <div className="modal">
      <Modal open={email}>
        <Box
          sx={{
            bgcolor: "background.paper",
            height: "100vh",
            width: "100%",
            borderBlockEndStyle: 25,
            borderBlockStartStyle: 25,
          }}
        >
          <div className="modal-header d-flex ">
            <span>
              <ArrowBackIcon
                onClick={() => {
                  handleClose();
                }}
              />
            </span>
          </div>
          <div className="jodit">
            <div className="form-field">
              <label>From:</label>
              <input type="email" value={email?.EmailFrom_Email} />
            </div>
            <div className="form-field ">
              <label>To:</label>
              <input
                type="email"
                value={toEmailId}
                onChange={(e) => {
                  setToEmailId(e.target.value);
                }}
              />
              <p
                style={{ margin: "0px" }}
                onClick={() => {
                  setCC(!cc);
                }}
              >
                CC
              </p>
              <p
                onClick={() => {
                  setBCC(!bcc);
                }}
              >
                BCC
              </p>
            </div>
            {cc ? (
              <div className="form-field" style={{ display: "flex" }}>
                <label>CC:</label>
                <input
                  type="email"
                  value={ccEmailId}
                  onChange={(e) => {
                    setCcEmailId(e.target.value);
                  }}
                />
              </div>
            ) : null}

            {bcc ? (
              <div className="form-field">
                <label>BCC:</label>
                <input
                  type="email"
                  value={bccEmailId}
                  onChange={(e) => {
                    setBccEmailId(e.target.value);
                  }}
                />
              </div>
            ) : null}
            <div className="form-field">
              <label>Subject:</label>
              <input
                type="text"
                value={emailSubject}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                }}
              />
            </div>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(e) => {
                setEditorState(e);
              }}
              toolbarClassName="toolbar-class"
            />

            <div className="send-btn-wrraper">
              <Button
                type="button"
                onClick={() => {
                  sendMail();
                }}
                label="Send"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

function getSentEmailList(arg0: boolean): any {
  throw new Error("Function not implemented.");
}
