import { createSlice,PayloadAction } from '@reduxjs/toolkit';


interface loginState {
    chatId: string;
    chatIsPrescriber:any;
    chatUserAccount:any,
    chatName:string,
    showChat:boolean,
    refreshChatList:boolean,
    type:any,
    refreshChatComponent:boolean,
    chatNumber:string,
    pageSelected: string,
    numberAssigned: boolean,
    repRole: string,
    channelMedia: boolean,
    channelType: string,
    EmployeePermissionCountry:boolean
  }
export const initialState: loginState = {
    chatId:'',
    chatIsPrescriber:'',
    chatUserAccount:{},
    chatName:'',
    showChat:false,
    refreshChatList:false,
    type:null,
    refreshChatComponent:false,
    chatNumber:'',
    pageSelected: '',
    numberAssigned: true,
    repRole:"",
    channelMedia:false,
    channelType: 'SMS',
    EmployeePermissionCountry:false


};
export const chat = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
      setChatDetails: (state,action: PayloadAction<any>) => { 
        state.chatId=action.payload.chatId;
        state.chatName=action.payload.chatName;
        //state.chatUserAccount=action.payload.chatUserAccount;
        state.chatIsPrescriber=action.payload.chatIsPrescriber;
        state.showChat=action.payload.showChat
      },
      setChatShowChat: (state,action: PayloadAction<any>) => { 
        state.showChat=action.payload
      },
      setuserAccount:(state,action: PayloadAction<any>) => { 
        state.chatUserAccount=action.payload;
      },
      setRefreshChatList: (state,action: PayloadAction<any>) => { 
        state.refreshChatList=action.payload
      },
      setChatType: (state,action: PayloadAction<any>) => { 
        state.type=action.payload
      },
      setRefreshChatComponent:(state,action: PayloadAction<any>) => { 
        state.refreshChatComponent=action.payload
      },
      setChatNumber:(state,action: PayloadAction<any>) => { 
        state.chatNumber=action.payload
      },
      setPage:(state,action: PayloadAction<any>) => { 
        state.pageSelected=action.payload
      },
      setNumberAssigned:(state,action: PayloadAction<any>) => { 
        state.numberAssigned=action.payload
      },
      setChatDetailsWOShowChat:(state,action: PayloadAction<any>) => { 
        state.chatId=action.payload.chatId;
        state.chatName=action.payload.chatName;
        state.chatIsPrescriber=action.payload.chatIsPrescriber;
      },
      setUserRole:(state,action: PayloadAction<any>) => { 
        state.repRole=action.payload
      },
      setShowChannelMedia:(state,action: PayloadAction<any>) => { 
        state.channelMedia=action.payload
      },
      setShowChannelTypeMedia:(state,action: PayloadAction<any>) => { 
        state.channelType=action.payload
      }
    }
});

// this is for dispatch
export const { setChatDetails,setRefreshChatList,setuserAccount,setChatShowChat,setChatType,setRefreshChatComponent,setChatNumber, setPage, setNumberAssigned, setChatDetailsWOShowChat, setUserRole, setShowChannelMedia, setShowChannelTypeMedia } = chat.actions;

// this is for configureStore
export default chat.reducer;