import { useState, useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDebounce } from "usehooks-ts";
import config from "../../env.json";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import { sendMessageApi } from "../../apis/chat/messageApi";
import { parsePhoneNumber } from "react-phone-number-input";
import { Tooltip } from "@mui/material";
import {
  addNewContact,
  checkNumberExist,
  contactDetails,
  editContact,
} from "../../apis/contacts/contactsApi";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
} from "../../app/slice/personalisationSlice";
import "./QR.css";
import GenericButton from "../../layouts/button/Button";
import {
  setChatDetails,
  setRefreshChatComponent,
  setRefreshChatList,
  setChatType,
} from "../../app/slice/chatSlice";
import { useTranslation } from "react-i18next";
import LogoutModal from "../logoutModal/LogoutModal";
import { getAOCStatus, getSRFQuantity } from "../../apis/srfApi/srfApi";
import {
  setAOCStatus,
  setRepQuantity,
  setPresQuantity,
} from "../../app/slice/srfSlice";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import { setMobileProfileSidebarView } from "../../app/slice/loginSlice";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primcolor
    ? document.documentElement.style.getPropertyValue("--primary-color")
    : "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor
      ? document.documentElement.style.getPropertyValue("--primary-color")
      : "var(--primary-color)",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function NewChatModal({ show, handleCloseParent }) {
  const dispatch = useDispatch();
  const messageChannelType = useSelector(
    (state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType
  );
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState({ Id: "" });
  const [post, setPost] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [formType, setFormType] = useState(true);

  const [templateValue, setTemplateValue] = useState<any>({});
  const [campaignId, setCampaignId] = useState(0);
  const [templateId, setTemplateId] = useState(0);
  const [templateText, setTemplateText] = useState("");
  const [originalTemplateText, setOriginalTemplateText] = useState("");
  const [isWhatsapp, setIsWhatsapp] = useState(true);
  const [disableCampaignInput, setDisableCampaignInput] = useState(true);
  const [disableWaveInput, setDisableWaveInput] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [othersData, setOthersData] = useState([]);
  const [othersDatahidden, setOthersDataHidden] = useState(true);
  const [selectedOthers, setSelecteedOthers] = useState<any>({});
  const debouncedValue = useDebounce<string>(othersName, 350);
  const [assets, setAssets] = useState([]);
  const [disableBrandInput, setDisableBrandInput] = useState(true);
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [associated, setAssociated] = useState<any>({});
  const [optedOut, setOptedOut] = useState(false);
  const [formsValues, setFormValues] = useState<any>([]);
  const refForm = useRef<any>(null);
  const [disableTemplate, setDisableTemplate] = useState<any>(true);
  const [initialPhone, setInitialPhone] = useState<any>(true);
  const [showOnce, setShowOnce] = useState(false);
  const [overrideModal, setOverrideModal] = useState(false);
  const [formDataGlobal, setFormDataGlobal] = useState<any>(null);
  const [repQuantity, setRepQuantityLocal] = useState<any>([]);
  const [presQauntity, setPresQauantityLocal] = useState<any>([]);
  const [aoc, setAoc] = useState<any>([]);
  const [tempProductId, setTempProductId] = useState<any>(0);
  const [repQuantityError, setRepQuantityError] = useState(false);
  const [presQuantityError, setPresQuantityError] = useState(false);
  const [aocError, setAocError] = useState(false);
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState("");
  const [discolor, setDiscolor] = useState(true);
  const [asscPresInputDisabled,setAsscPresInputDisabled]= useState(true);
  const [contactloading,setContactloading]= useState(false);
  const [notFound,setNotFound]=useState(false);
  const userQR=useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);

  useEffect(() => {
    // console.log(axios.CancelToken.source());
    setCountryList();
    getSRFQuantity((res: any) => {
      console.log(res);
      if (res) {
        setRepQuantityLocal(res.employeeQnt ? res.employeeQnt : []);
        setPresQauantityLocal(res.prescriberQnt ? res.prescriberQnt : []);
        dispatch(setRepQuantity(res.employeeQnt ? res.employeeQnt : []));
        dispatch(setPresQuantity(res.prescriberQnt ? res.prescriberQnt : []));
      }
    });
    getAOCStatus((res: any) => {
      if (res) {
        setAoc(res);
        dispatch(setAOCStatus(res));
      }
    });
  }, []);
  let countryCode = useSelector((state:any)=> state.personalisation.EmployeePersonalizationPhone_Allow_CountryCd)  
  const setCountryList = () => {
    let userCountryList:Array<any>= countryCode?.split(",")?.map((code: string) =>code?.toLowerCase()) 
    setCountries(userCountryList)
  };
  const getFormStatus = (value: any) => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    let pres = presQauntity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let pq = pres.length > 0 ? pres[0].quantity : 0;
    let n = aoc.filter(
      (x) =>
        x.product_id == tempProductId &&
        x.prescriber_id == assoPresc.Id &&
        x.aoc_status == "pending"
    );
    let nc = n.length > 0 ? n[0].aoc_status : "unknown";

    if (value > rq && rq != 0) {
      setRepQuantityError(true);
    } else {
      setRepQuantityError(false);
    }
    if (value > pq && pq != 0) {
      setPresQuantityError(true);
    } else {
      setPresQuantityError(false);
    }
    if (nc == "pending") setAocError(true);
    else setAocError(false);

    if ((value > rq && rq != 0) || (value > pq && pq != 0) || nc == "pending")
      setDisabled(true);
    else setDisabled(false);
  };
  const getRepLimit = () => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };
  const getPresLimit = () => {
    let rep = presQauntity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == assoPresc.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };

  const getContactsData = (value) => {
    if (value.length >= 2) {
      setContactloading(true);
      axios
        .get(
          config.REACT_APP_CONTACTS_API_BASE +
            "/contact?ContactType=A&Page=" +
            0 +
            "&PageSize=" +
            pageSize +
            "&Search=" +
            encodeURIComponent(value),
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.data.length) {
            setContactloading(false);
            setNotFound(false);
            setOthersData(
              res.data.data.filter((x: any) => x.Is_Prescriber == "N")
            );
            setPost(res.data.data.filter((x: any) => x.Is_Prescriber == "Y"));
          } else {
            setContactloading(false);
            setNotFound(true);
            setOthersPhone("");
            setDiscolor(true);
          }
        });
    } else {
      setOthersDataHidden(true);
      setOthersData([]);
      setPost([]);
      setHiddenPresList(true);
    }
  };
  const checkStopTyping = (e) => {
    var timeout = setTimeout(function () {}, 0);
    clearTimeout(timeout); /// clear timeout if user is typing

    timeout = setTimeout(
      function () {
        getContactsData(assoPrescName);
      },
      500 /// Time in milliseconds
    );
  };
  useEffect(() => {
    setOthersDataHidden(false);
    getContactsData(debouncedValue);
  }, [debouncedValue]);
  const setPrescriber = (x: any) => {
    console.log(x);
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setOthersPhone(getNumberFromItem(x));
    setHiddenPresList(true);
    setDisabled(false);
    brandInputdisable(x.AcctFull_Nm.length > 0, x.AcctPh_Nbr.length > 0, true);
    setOptedOut(x.All_Ph_Cont_Flags === "N, N, N");
    if (
      x.AcctFull_Nm !== "" ||
      x.AcctFull_Nm !== null ||
      x.AcctFull_Nm !== undefined
    ) {
      setDiscolor(false);
    }
  };
  const getNumberFromItem = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  };

  const handleOverrideMsg = (accType, accName) => {
    if (config.REACT_APP_PH_OVERRIDE_MSG) {
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
      let type = accType === "N" ? t("Others") : t("Prescriber");
      let name = accName;
      if (type) {
        msg = msg.replace("TYPE", type);
      } else {
        msg = msg.replace("TYPE -", "");
      }
      if (name) {
        msg = msg.replace("NAME", name);
      }
      setOverrideMessage(msg);
    }
  };

  const updateFormValue = (i, value) => {
    let formVal = [...formsValues];
    let item = {
      ...formVal[i],
      Value: value,
    };
    formVal[i] = item;
    setFormValues(formVal);
    updateTemplateText(formVal);
  };

  const falsifyShowOnce = () => {
    setShowOnce(false);
  };
  const updateTemplateText = (formData) => {
    var templateBody = originalTemplateText;
    formData.map((x: any, i: any) => {
      if ("Value" in x) {
        var replaceWord = "[" + x.FieldName + "]";
        templateBody = templateBody.replace(replaceWord, x.Value);
      }
    });
    setTemplateText(templateBody);
  };
  async function addContact() {
    let payload = {
      AcctPh_Nbr: othersPhone,
      Prescriber_Id: assoPresc.Id as any,
      AcctFull_Nm: othersName,
      IsPrescriber: false,
    };
    console.log(payload);
    axios
      .post(
        config.REACT_APP_CONTACTS_API_BASE + "/contact",
        {
          AcctPh_Nbr: othersPhone,
          Prescriber_Id: assoPresc.Id as any,
          AcctFull_Nm: othersName,
          IsPrescriber: false,
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        handleCloseParent(true);
      })
      .catch((err) => {
        handleCloseParent(false);
      });
  }
  const sendToPrescriber = (formData: any) => {
    console.log("wertyuiopasdfghjklasdfghjkl");
    formData.append("role_id", "0");
    formData.append("recipient_id", assoPresc.Id);
    formData.append("recipient_name", assoPresc["AcctFull_Nm"]);
    formData.append("physcian_id", assoPresc.Id);
    console.log("senddsad ::", templateText);
    console.log("formdata", formData);
    sendMessageApi(formData, (res) => {
      //console.log(res)
      dispatch(setChatType("PRESCR"));
      dispatch(
        setChatDetails({
          chatId: assoPresc.Id,
          chatIsPrescriber: true,
          showChat: true,
          chatName: assoPresc["AcctFull_Nm"],
        })
      );

      setLoading(false);
      reSetFormInputs(true);
      handleCloseParent(true);
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
      dispatch(setMobileProfileSidebarView(false));
      if (res) dispatch(setShowSuccessSnackBar(true));
      else dispatch(setShowSuccessSnackBar(false));
    });
  };
  const sendToOthers = (formData: any) => {};
  const sendMessageForPresOrOthers = (formData) => {
  console.log("::::::::::::::::", associated)
    
    if (formType) {
      //prescriber

      if (getNumberFromItem(assoPresc) !== othersPhone) {
        let phn: any = parsePhoneNumber(othersPhone);
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
        };
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res) {
            handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
            setFormDataGlobal(formData);
            setOverrideModal(true);
          } else {
            editContact(payload, (res: any) => {});
            sendToPrescriber(formData);
            console.log("called");
          }
        });
      } else {
        sendToPrescriber(formData);
        console.log("called");
      }
    } else {
      //others
      if (!selectedOthers.Id) {
        let phn: any = parsePhoneNumber(othersPhone);
        let payload = {
          AcctPh_Nbr: phn.nationalNumber,
          Prescriber_Id: [associated.Id],
          AcctFull_Nm: othersName,
          IntlCallPfx_Nbr: phn.countryCallingCode,
        };
        
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res) {
            
            handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
            setFormDataGlobal(formData);
            setOverrideModal(true);
          } else {
            
            addNewContact(payload, (res: any) => {
              console.log(res);
              if (res) {
                formData.append("recipient_id", res.Id);
                formData.append("recipient_name", othersName);
                formData.append("role_id", "1");
                formData.append(
                  "association_id",
                  res.Prescribers.filter((x) => x.Id == associated.Id)[0]
                    .Association_Id
                );
                formData.append("physcian_id", associated.Id);
                console.log("formdata", formData);
                sendMessageApi(formData, (resp: any) => {
                  console.log(resp);
                  if (resp) {
                    dispatch(setChatType("ASSPRS"));
                    
                    dispatch(
                      setChatDetails({
                        chatId: res.Id,
                        chatIsPrescriber: false,
                        showChat: true,
                        chatName: othersName,
                      })
                    );
                    setLoading(false);
                    reSetFormInputs(true);
                    handleCloseParent(true);
                    dispatch(setRefreshChatList(true));
                    dispatch(setRefreshChatComponent(true));
                    dispatch(setMobileProfileSidebarView(false));
                    dispatch(setShowSuccessSnackBar(true));
                  } else dispatch(setShowSuccessSnackBar(false));
                });
              } else {
              }
            });
          }
        });
      } else {
        let phn: any = parsePhoneNumber(othersPhone);
        console.log(phn);
        let payload = {
          AcctPh_Nbr: phn.nationalNumber,
          AcctFull_Nm: othersName,
          Prescriber_Id: [associated.Id],
          Id: othersData.map((x: any) => x.Id),
          IntlCallPfx_Nbr: phn.countryCallingCode,
        };
        formData.append("recipient_id", selectedOthers.Id);
        formData.append("recipient_name", othersName);
        formData.append("role_id", "1");
        formData.append("association_id", associated.Association_Id);
        formData.append("physcian_id", associated.Id);

        if (othersPhone != getNumberFromItem(selectedOthers)) {
          checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
            if (res) {
              handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
              setFormDataGlobal(formData);
              setOverrideModal(true);
            } else {
              addNewContact(payload, async (cont) => {
                if (cont) {
                  dispatch(setShowSuccessSnackBar(true));
                } else {
                  dispatch(setShowErrorSnackBar(true));
                }
              });
              console.log("formdata", formData);

              sendMessageApi(formData, (res) => {
                console.log(res);
                dispatch(setChatType("ASSPRS"));
                dispatch(
                  setChatDetails({
                    chatId: selectedOthers.Id,
                    chatIsPrescriber: false,
                    showChat: true,
                    chatName: othersName,
                  })
                );
                setLoading(false);
                reSetFormInputs(true);
                handleCloseParent(true);
                dispatch(setRefreshChatList(true));
                dispatch(setRefreshChatComponent(true));
                if (res) dispatch(setShowSuccessSnackBar(true));
                else dispatch(setShowSuccessSnackBar(false));
              });
            }
          });
        } else {
          console.log("formdata", formData, associated);

          sendMessageApi(formData, (res) => {
            dispatch(setChatType("ASSPRS"));
            dispatch(
              setChatDetails({
                chatId: selectedOthers.Id,
                chatIsPrescriber: false,
                showChat: true,
                chatName: othersName,
              })
            );
            setLoading(false);
            reSetFormInputs(true);
            handleCloseParent(true);
            dispatch(setRefreshChatList(true));
            dispatch(setRefreshChatComponent(true));
            dispatch(setMobileProfileSidebarView(false));
            if (res) dispatch(setShowSuccessSnackBar(true));
            else dispatch(setShowSuccessSnackBar(false));
          });
        }
      }
    }
  };
  const sendMessage = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("recipient_phone", othersPhone);
      formData.append("Campaign_ID", "1000");
      formData.append("wave_id", "1000");
      formData.append("product_id", "1000");
      const res = await axios.get(userQR, { responseType: "blob" });
      const blob = res.data;
      const QRAsFile = new File([blob], "master-logo.png", {
        type: "image/png",
      });
      console.log("QRAsFile:::::::::::::::\n", QRAsFile, blob);
      const smsContent = "Please scan the Qr code to add contact";
      formData.append("template", smsContent);
      formData.append("file", QRAsFile);
      if (!isWhatsapp) {
        formData.append("channel", "SMS");
      } else {
        formData.append("channel", "whatsa");
      }
      sendMessageForPresOrOthers(formData);
    } catch (error) {
      dispatch(setShowErrorSnackBar(true));
    } finally {
      setLoading(false);
    }
  };

  const setChat = (post: any) => {
    dispatch(
      setChatDetails({
        chatId: post.Id,
        chatIsPrescriber: post.Is_Prescriber == "Y",
        showChat: true,
        chatName: post.AcctFull_Nm,
      })
    );
    window.location.href = "/chat";
  };

  const brandInputdisable = (name, phone, asso_pres) => {
    if (formType || !formType) {
      if (name && phone && asso_pres) {
        
        setDisableBrandInput(false);
        setDisableCampaignInput(false);
        setDisableWaveInput(false);
        setDisableTemplate(false);
      } else {
        setDisableBrandInput(true);
        setDisableCampaignInput(true);
        setDisableWaveInput(true);
        setDisableTemplate(true);
      }
    }
  };

  const setPhoneNumber = (e) => {
    
    setInitialPhone(false);
    if (e) {
      setOthersPhone(e);
      brandInputdisable(
        assoPrescName.length > 0,
        e.length > 0,
        assoPrescName.length > 0
      );
    } else {
      setOthersPhone("");
      brandInputdisable(
        assoPrescName.length > 0,
        false,
        assoPrescName.length > 0
      );
    }
    if (e.length > 0) {
      setAsscPresInputDisabled(false);
    } else {
      setAsscPresInputDisabled(true);
    }
  };

  const setOthersFromList = (x) => {
    if (x.Is_Prescriber == "N") {
      let RecType = false;
      contactDetails({ Id: x.Id, Is_Prescriber: RecType }, (res) => {
        
        
        if (res) {
          console.log("jjjjjjjjjjjjjj", res);
          setShowOnce(false);
          console.log("lllllll", x);
          setDiscolor(false);

          setOptedOut(res.All_Ph_Cont_Flags === "N, N, N");
          setSelecteedOthers(res);
          setOthersName(res.AcctFull_Nm);
          setOthersDataHidden(true);
          if (res.Prescribers) {
            console.log("res::::::", res);
            if (res.Prescribers.length > 0) {
              setAssociated(res.Prescribers[0]);
              setDisabled(res.Prescribers[0].AcctFull_Nm ? false : true);
              setAssoPrescName(res.Prescribers[0].AcctFull_Nm);
            } else {
              brandInputdisable(
                true,
                res.AcctPh_Nbr.length > 0,
                res.Prescribers.length > 0
              );
            }
          }
        }
      });
    }

    setOthersPhone(getNumberFromItem(x));
  };
  const reSetFormInputs = (e) => {
    setSelecteedOthers({});
    setInitialPhone(true);
    setFormType(e);

    setTemplateId(0);
    setAssoPrescName("");
    setAssoPresc({ Id: "" });
    setHiddenPresList(true);
    setDisabled(true);
    setOthersPhone("");
    setOthersPhone("9999999");
    setOthersPhone("");
    setDisableBrandInput(true);
    setTemplateText("");
    setContactloading(false);
    setNotFound(false);
    setOriginalTemplateText("");
    setOthersName("");
    setDisableCampaignInput(true);
    setDisableWaveInput(true);
    setDisableTemplate(true);
    setAssets([]);
    setAssociated({});
    setOptedOut(false);
    setAsscPresInputDisabled(true);
    setDiscolor(true);
  };

  const overrideFunction = (e) => {
    let formData: any = formDataGlobal;
    if (e) {
      if (formType) {
        let phn: any = parsePhoneNumber(othersPhone);
        let payload: any = {
          AcctPh_Nbr: phn.nationalNumber,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Id: assoPresc.Id,
        };
        editContact(payload, (res: any) => {});
        sendToPrescriber(formData);
      } else {
        if (!selectedOthers.Id) {
          let phn: any = parsePhoneNumber(othersPhone);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            Prescriber_Id: [associated.Id],
            AcctFull_Nm: othersName,
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };

          addNewContact(payload, (res: any) => {
            if (res) {
              formData.append("recipient_id", res.Id);
              formData.append("recipient_name", othersName);
              formData.append("role_id", "1");
              formData.append(
                "association_id",
                res.Prescribers.filter((x) => x.Id == associated.Id)[0]
                  .Association_Id
              );
              formData.append("physcian_id", associated.Id);
              console.log("formdata", formData);

              sendMessageApi(formData, (resp: any) => {
                if (resp) {
                  dispatch(setChatType("ASSPRS"));
                  
                  dispatch(
                    setChatDetails({
                      chatId: res.Id,
                      chatIsPrescriber: false,
                      showChat: true,
                      chatName: othersName,
                    })
                  );
                  setLoading(false);
                  reSetFormInputs(true);
                  handleCloseParent(true);
                  dispatch(setRefreshChatList(true));
                  dispatch(setRefreshChatComponent(true));
                  dispatch(setShowSuccessSnackBar(true));
                } else dispatch(setShowSuccessSnackBar(false));
              });
            } else {
            }
          });
        } else {
          let phn: any = parsePhoneNumber(othersPhone);
          console.log(phn);
          let payload = {
            AcctPh_Nbr: phn.nationalNumber,
            AcctFull_Nm: othersName,
            Prescriber_Id: [associated.Id],
            Id: othersData.map((x: any) => x.Id),
            IntlCallPfx_Nbr: phn.countryCallingCode,
          };
          addNewContact(payload, async (cont) => {
            if (cont) {
              dispatch(setShowSuccessSnackBar(true));
            } else {
              dispatch(setShowErrorSnackBar(true));
            }
          });
          console.log("formdata", formData);

          sendMessageApi(formData, (res) => {
            //console.log(res)
            dispatch(setChatType("ASSPRS"));
            dispatch(
              setChatDetails({
                chatId: selectedOthers.Id,
                chatIsPrescriber: false,
                showChat: true,
                chatName: othersName,
              })
            );
            setLoading(false);
            reSetFormInputs(true);
            handleCloseParent(true);
            dispatch(setRefreshChatList(true));
            dispatch(setRefreshChatComponent(true));
            if (res) dispatch(setShowSuccessSnackBar(true));
            else dispatch(setShowSuccessSnackBar(false));
          });
        }
      }
    } else {
      setLoading(false);
      setDisabled(false);
    }
  };

  const setNumberLandline = (e) => {
    console.log(e);
    setDisabled(e);
    setDisableTemplate(assoPrescName.length > 0 && e);
    brandInputdisable(assoPrescName.length > 0, !e, assoPrescName.length > 0);
    setIsNumberLandline(e);
  };

  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="new_chat_modal"
      >
        <Fade in={show}>
          <Box sx={style}>
            <div className="modal-header d-flex">
              <h1 className="font-newchat">{t("Share QR")}</h1>
              <span
                onClick={() => {
                  handleCloseParent();
                  reSetFormInputs(true);
                }}
                className="close"
              >
                ×
              </span>
            </div>

            <div className="modal-body new_chat_body">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage();
                }}
              >
                <div className="selecttype-radio-btns">
                  <FormControl className="width100per">
                    <div className="newChatModalBody">
                      <FormLabel id="demo-customized-radios">
                        {t("Select Type")}
                      </FormLabel>
                      {messageChannelType &&
                      config.REACT_APP_SHOW_CHANNEL_TYPE_TOGGLE_BTN === "Y" ? (
                        <div className="headerright">
                          <label className="autopilot-switch font-sm me-3">
                            <input
                              checked={isWhatsapp}
                              onChange={(e) => {
                                setIsWhatsapp(e.target.checked);
                              }}
                              type="checkbox"
                              className="font-sm"
                              id="msg-wtsappChechedId"
                            />

                            <Tooltip
                              title={
                                isWhatsapp === false
                                  ? t("Message Mode")
                                  : t("WhatsApp Mode")
                              }
                              arrow
                            >
                              <span
                                id="msg-wtsapptoggle"
                                className="b-slider b-round"
                              ></span>
                            </Tooltip>
                          </label>
                        </div>
                      ) : null}
                    </div>

                    <RadioGroup
                      row
                      defaultValue="prescriber"
                      aria-labelledby="demo-customized-radios"
                      name="customized-radios"
                      onClick={(e: any) => {
                        e.target.value === "prescriber"
                          ? reSetFormInputs(true)
                          : reSetFormInputs(false);
                      }}
                    >
                      <FormControlLabel
                        value="prescriber"
                        control={<BpRadio />}
                        label={t("Prescriber")}
                      />
                      <FormControlLabel
                        value="others"
                        control={<BpRadio />}
                        label={t("Others")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="form-fields-div new-chat-prescriber">
                  {formType === true ? (
                    <AssociatedPresList
                      clearDataOnSelect={false}
                      placeholder={t("Search for a Prescriber")}
                      label={t("Prescriber Name") + "*"}
                      styleClass="inputEnable"
                      onChangeInputValue={(e: any) => {}}
                      setAssoPresc={(e: any) => {
                        setPrescriber(e);
                        setDisabled(false);
                      }}
                    />
                  ) : (
                    <div className="NewChatModalOthersName">
                      <TextField
                        className="roundborder inputEnable"
                        onChange={(e) => {
                          
                          setShowOnce(true);
                          if (e.target.value.length == 0) {
                            setSelecteedOthers({});
                            setOthersPhone("");
                            setAssoPrescName("");
                            setAsscPresInputDisabled(true);
                            setDiscolor(true);
                            brandInputdisable(
                              othersName.length > 0,
                              othersPhone.length > 0,
                              false
                            );
                          } else if (e.target.value.length > 0) {
                            setAsscPresInputDisabled(false);
                            brandInputdisable(
                              othersName.length > 0,
                              othersPhone.length > 0,
                              false
                            );
                          }

                          setOthersName(e.target.value);
                        }}
                        value={othersName}
                        label={t("Recipient Name") + "*"}
                        placeholder={t("Enter the recipient's name")}
                        color="secondary"
                        focused
                        autoComplete="off"
                        inputProps={{ maxLength: 170 }}
                        InputProps={
                          contactloading
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CircularProgress
                                      color={"inherit"}
                                      size={"1rem"}
                                    />
                                  </InputAdornment>
                                ),
                              }
                            : undefined
                        }
                      />
                      {showOnce && othersData.length > 0 ? (
                        <Paper hidden={othersDatahidden}>
                          <div id="presList">
                            {othersData.length > 0 &&
                              othersData.map((x: any) => (
                                <div
                                  key={x.Id}
                                  onClick={() => {
                                    setOthersFromList(x);
                                  }}
                                >
                                  {x.AcctFull_Nm}
                                </div>
                              ))}
                          </div>
                        </Paper>
                      ) : notFound == true ? (
                        <Paper hidden={othersDatahidden}>
                          <div id="presList">
                            <div>{t("No contact found")}</div>{" "}
                          </div>
                        </Paper>
                      ) : null}
                    </div>
                  )}
                  {optedOut ? (
                    <div className="optedOutModalText">
                      {t("Recipient Opted Out")}
                    </div>
                  ) : null}

                  <PhoneNumberDropdown
                    initiall={(e) => {}}
                    errorFunc={(e) => {
                      setDisabled(e);
                      setPhoneError(e);
                      console.log(disabled);
                      
                      if (e) {
                        setDisableTemplate(assoPrescName.length > 0 && true);
                        brandInputdisable(
                          assoPrescName.length > 0,
                          false,
                          assoPrescName.length > 0
                        );
                      } else {
                        
                        if (
                          (formType && assoPrescName.length <= 0) ||
                          (!formType &&
                            (othersName.length <= 0 ||
                              assoPrescName.length <= 0))
                        ) {
                          setDisabled(true);
                        }
                        setDisableTemplate(assoPrescName.length > 0 && false);
                      }
                    }}
                    countryList={countries}
                    initialYN={initialPhone}
                    falsifyShowOnce={falsifyShowOnce}
                    valueNumber={othersPhone}
                    onChangeNumber={(e: any) => {
                      setPhoneNumber(e);
                    }}
                    numberLandline={(e: any) => {
                      setNumberLandline(e);
                    }}
                    disableColored={discolor}
                  />

                  <GenericButton
                    label={t("Send")}
                    type="submit"
                    disable={
                      disabled ||
                      optedOut ||
                      isNumberLandline ||
                      othersPhone.length == 0
                    }
                    loading={loading}
                  />
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>
      <LogoutModal
        show={overrideModal}
        yorn={overrideFunction}
        modalText={overrideMessage}
        handleCloseParent={(e) => {
          setOverrideModal(false);
        }}
      />
    </div>
  );
}
