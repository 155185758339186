import LogoutBgImage from "../../assets/images/LogoutBgImage.png";
import i18n from "i18next";
import "./Logout.css";
import { initReactI18next, useTranslation } from "react-i18next";
import { useEffect } from "react";
function Logout() {
  const { t } = useTranslation();

  const handleLogout = () => {
    alert("Login Success!");
  };
  let transLocData:any = localStorage.getItem("translationData")
  transLocData = JSON.parse(transLocData)
  console.log("transLocData1234",transLocData);
  let browserLang= Object.keys(transLocData)[0];
  console.log("browserLang",browserLang);
  useEffect(()=>{
      i18n
      .use(initReactI18next)
      .init({
        resources: transLocData,
        lng: browserLang,
        interpolation: {
          escapeValue: false,
        },
      });      
  },[])
  return (
    <>
      <div className="login-layout-bg login-bg">
        <div className="login-left-div">
          <img src={LogoutBgImage} alt="LoginImage" />
        </div>

        <div className="login-right-div">
          <h1 className="login-text font--xl">{t("Goodbye")}</h1>
          <h4 className="login-text font--lg">
            {t("You are now logged out of the application")}.
          </h4>

          <button
            className="rounded-primary-40 btn font--btn"
            type="button"
            onClick={handleLogout}
          >
            {t("Go back to Login")}
          </button>
        </div>
      </div>
    </>
  );
}

export default Logout;
