import { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Contacts.css";
import axios from "axios";
import AddContact from "../../components/contact/AddContact";
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";
import ContactDetialsModal from "../../components/contact/ContactDetialsModal";
import SearchInput from "../../layouts/searchInput/SearchInput";
import config from "../../env.json";
import { connect } from "react-redux";
import CallModal from "../../components/call/CallModal";
import { getAllContacts } from "../../apis/contacts/contactsApi";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import { Keyboard } from "@capacitor/keyboard";
import ContactAddPopup from "../../components/contact/ContactAddPopup";
import {
  setCallModalView,
  setCallModalDetails,
} from "../../app/slice/callModalSlice";
import { setChatDetails, setChatType } from "../../app/slice/chatSlice";
import Spinner from "../../layouts/spinner/Spinner";
import Avatar from "../../components/Avatar/Avatar";
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";
import { getDeviceType } from "../../utils/deviceTypeUtil";
let cancelToken: any = undefined;

interface MyComponentProps {
  token: string;
}

class Contacts extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.iPadOrientation = this.iPadOrientation.bind(this);

    this.state = {
      contactList: [],
      page: 0,
      pageSize: 20,
      search: "",
      show: false,
      showDetails: false,
      contact: [],
      hasMore: true,
      tempValue: "",
      gridView: false,
      token: "",
      infiniteScrollHeight: 10,
      callDetails: {},
      CancelToken: new AbortController(),
      loading: false,
      selectedContactIndex: 0,
      msgOrCallIcon: false,
      paddingValRight: 0,
      paddingValBottom: 0,
      showContactCard: false,
      isHospitalContact:
        config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
        config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT ==
          this.props.campaign,
      isUserSearching: false,
    };
  }

  componentDidMount() {
    this.getHeight();
    document.documentElement.style.setProperty("--visibility", "hidden");
    console.log(this.props.token);
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
    this.getHeight();
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == this.props.campaign
    ) {
      this.setState({ isHospitalContact: true });
    } else {
      this.setState({ isHospitalContact: false });
    }
    if (!sessionStorage.getItem("contactViewType")) {
      this.setState({ gridView: false });
    } else if (sessionStorage.getItem("contactViewType") === "LIST") {
      this.setState({ gridView: false });
    } else {
      this.setState({ gridView: true });
    }
    this.getContactsData();
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        this.getHeight();
      }, 0);
    });

    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardWillShow", (info) => {});

      Keyboard.addListener("keyboardDidShow", (info) => {
        this.getHeight();
      });

      Keyboard.addListener("keyboardWillHide", () => {
        // console.log('keyboard will hide');
      });

      Keyboard.addListener("keyboardDidHide", () => {
        // console.log('keyboard did hide');
        this.getHeight();
        console.log("---Keyboard height---");
      });
    }
    //this.getHeight()
    window.addEventListener("resize", () => {
      setTimeout(() => {
        this.getHeight();
        let device = getDeviceType();
        if (
          device == "mobile" ||
          device == "tablet" ||
          config.REACT_APP_IS_CROSS_PLATFORM
        ) {
          this.setState({ gridView: false });
        } else {
          // this.setState({gridView:true});
        }
      }, 0);
    });
  }
  componentWillMount(): void {
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        this.getHeight();
      }, 0);
    });
    sessionStorage.removeItem("contactViewType");
  }

  componentWillUnmount(): void {
    window.removeEventListener("orientationchange", () => {
      setTimeout(() => {
        this.getHeight();
      }, 0);
    });
    sessionStorage.removeItem("contactViewType");
    window.removeEventListener("resize", () => {
      setTimeout(() => {
        this.getHeight();
      }, 0);
    });
  }
  componentDidUnmount(): void {
    window.removeEventListener("orientationchange", () => {
      setTimeout(() => {
        this.getHeight();
      }, 0);
    });
    sessionStorage.removeItem("contactViewType");
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    if (
      prevState.paddingValBottom !== this.state.paddingValBottom ||
      prevState.paddingValRight !== this.state.paddingValRight ||
      prevState.infiniteScrollHeight !== this.state.infiniteScrollHeight
    )
      this.getHeight();
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
  }

  iPadOrientation(e) {
    this.getHeight();
  }

  getHeight = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    this.setState({ paddingValBottom: divpaddingbottom });
    this.setState({ paddingValRight: divpaddingright });
    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" 
    ) {
        setTimeout(() => {
          this.setState({
            infiniteScrollHeight:
              window.innerHeight -
              (doc.getElementById("MobHeader").offsetHeight +
                document.getElementById("headerVal")?.offsetHeight +
                doc.getElementById("contactSearch").offsetHeight +
                doc.getElementById("footer").offsetHeight) 
          });
        }, 100);
      }else{
        setTimeout(() => {
          this.setState({
            infiniteScrollHeight:
              window.innerHeight -
              (doc.getElementById("MobHeader").offsetHeight +
                document.getElementById("headerVal")?.offsetHeight +
                doc.getElementById("contactSearch").offsetHeight +
                doc.getElementById("contactSearch").offsetHeight / 2 +
                doc.getElementById("footer").offsetHeight) 
          });
        }, 100);
      }
  };

  showContactDetials = (item: any, i: number) => {
    this.setState(
      { contact: item, showDetails: true, selectedContactIndex: i },
      () => {
        // this.setState({showDetails:true})
      }
    );
    this.props.dispatch(
      setChatDetails({
        chatId: item?.Id,
        chatIsPrescriber: item?.Is_Prescriber == "Y" ? true : false,
        showChat: false,
        chatName: item?.AcctFull_Nm,
      })
    );
  };

  callMakerModal = (item: any) => {
    this.props.dispatch(setCallModalView(true));
    this.setState({ contact: item }, () => {});
  };

  getContactsDataForContacts = () => {
    if (this.state.search.length != 1) {
      // this.setState({ contactList: [], page: 0, hasMore: true });
      // this.getContactsData(0);
      this.setState(
        { contactList: [], page: 0, hasMore: true, isUserSearching: true },
        () => {
          this.getContactsData();
        }
      );
    }
  };

  getContactsData = async () => {
    if (cancelToken) {
      console.log("hi");
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    getAllContacts(
      {
        page: this.state.page,
        pageSize: this.state.pageSize,
        search: this.state.search,
        CancelToken: cancelToken.token,
        contactType: this.state.isHospitalContact ? "HA" : "All",
      },
      (res: any) => {
        let temp = this.state.contactList;
        if (this.state.page === 0) temp = res;
        else temp = temp.concat(res);
        let hasMore = this.state.hasMore;
        if (res.length === 0 || res.length < this.state.pageSize)
          hasMore = false;
        else hasMore = true;

        this.setState({
          contactList: temp,
          hasMore: hasMore,
          loading: false,
          isUserSearching: false,
        });
      }
    );
  };

  getNumberFromItem = (item) => {
    if (item?.AcctPh_Nbr) {
      if (item?.AcctPh_Nbr.indexOf("+") >= 0) {
        return getFormatedPhoneNumber(item?.AcctPh_Nbr);
      } else
        return getFormatedPhoneNumber(
          (item?.IntlCallPfx_Nbr.indexOf("+") >= 0 ? "" : "+") +
            item?.IntlCallPfx_Nbr +
            item?.AcctPh_Nbr
        );
    } else {
      return "";
    }
  };
  truncate(source, size) {
    return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
  }

  refreshContactListFunc = () => {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();

    getAllContacts(
      {
        page: 0,
        pageSize: this.state.contactList.length,
        search: this.state.search,
        CancelToken: cancelToken.token,
        contactType: this.state.isHospitalContact ? "HA" : "All",
      },
      (res: any) => {
        let hasMore = this.state.hasMore;
        if (res.length === 0 || res.length < this.state.pageSize)
          hasMore = false;
        else hasMore = true;

        this.setState({
          contactList: res,
          hasMore: hasMore,
          loading: false,
        });
      }
    );
  };

  containsUppercase = (str: string) => {
    return str === (str || "").toUpperCase();
  };

  getItemName = (item) => {
    let checkUppercaseStr = this.containsUppercase(item["AcctFull_Nm"]);

    if (checkUppercaseStr) {
      return this.truncate(item["AcctFull_Nm"], 10);
    } else {
      return this.truncate(item["AcctFull_Nm"], 18);
    }
  };

  redirectInMobile = (item: any, isChat: any, i: any) => {
    console.log("gc 4622 - item", item, "ischat", isChat, "index", i);

    this.setState({ selectedContactIndex: i });
    console.log(this.getNumberFromItem(item));
    let tempData;
    tempData = item;
    this.setState({ callDetails: tempData });
    if (tempData.AcctPh_Nbr && tempData.AcctPh_Nbr.length > 0) {
      if (isChat) {
        console.log("chat");
        this.setChat(tempData);
      } else {
        console.log("call");
        this.setState({ callDetails: tempData }, () => {
          this.props.dispatch(
            setCallModalDetails({
              AcctFull_Nm: tempData.AcctFull_Nm,
              AcctFull_Nbr: this.getNumberFromItem(tempData),
            })
          );
          this.props.dispatch(setCallModalView(true));
        });
      }
    } else {
      this.setState({
        showAddContactModal: true,
        data: tempData,
        msgOrCallIcon: isChat,
      });
    }
  };
  setChat = (post: any) => {
    this.props.dispatch(
      setChatDetails({
        chatId: post?.Id,
        chatIsPrescriber: post?.Is_Prescriber == "Y",
        showChat: true,
        chatName: post?.AcctFull_Nm,
      })
    );
    this.props.dispatch(
      setChatType(post?.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS")
    );
  };
  render() {
    const {
      contactList,
      page,
      pageSize,
      search,
      hasMore,
      infiniteScrollHeight,
    } = this.state;
    return (
      <div className="contacts-container">
        {this.state.showDetails ? (
          this.state.isHospitalContact ? (
            <ContactDetialsModalGAM
              showChatIcon={true}
              showDetails={this.state.showDetails}
              showAddContactModal={(e, trueorFalse) => {
                this.setState({
                  callDetails: e,
                  showAddContactModal: true,
                  showDetails: false,
                  msgOrCallIcon: trueorFalse,
                });
              }}
              contact={this.state.contact}
              fetchDetails={true} //true call api or false for get details from parent
              showCallMakerModal={(e) => {
                this.setState({ callDetails: e, showDetails: false });
                this.props.dispatch(setCallModalView(true));
              }}
              funcForEdited={(e) => {
                this.getContactsData();
              }}
              handleCloseParent={(e, deleteValue) => {
                let Temp = this.state.contactList;

                if (deleteValue === false) {
                  Temp[this.state.selectedContactIndex] = e;
                } else {
                  Temp.splice(this.state.selectedContactIndex, 1);
                  this.getContactsData();
                }

                this.setState({ showDetails: false, contactList: Temp });
              }}
            />
          ) : (
            <ContactDetialsModal
              showChatIcon={true}
              showDetails={this.state.showDetails}
              editted={() => {}}
              showAddContactModal={(e, trueorFalse) => {
                this.setState({
                  callDetails: e,
                  showAddContactModal: true,
                  showDetails: false,
                  msgOrCallIcon: trueorFalse,
                });
              }}
              contact={this.state.contact}
              fetchDetails={true} //true call api or false for get details from parent
              showCallMakerModal={(e) => {
                this.setState({ callDetails: e, showDetails: false });
                let Temp = this.state.contactList;
                Temp[this.state.selectedContactIndex] = e;
                this.setState({ contactList: Temp });
                this.props.dispatch(setCallModalView(true));
                this.refreshContactListFunc();
              }}
              funcForEdited={(e) => {
                this.refreshContactListFunc();
              }}
              handleCloseParent={(e, deleteValue) => {
                let Temp = this.state.contactList;

                console.log(e, deleteValue, Temp);
                if (deleteValue === false) {
                  Temp[this.state.selectedContactIndex] = e;
                } else {
                  Temp.splice(this.state.selectedContactIndex, 1);
                  this.refreshContactListFunc();
                }
                console.log(e, deleteValue, Temp);
                this.setState({ showDetails: false, contactList: Temp });
              }}
            />
          )
        ) : (
          <AddContact
            show={this.state.show}
            handleCloseParent={(e) => {
              if (e) {
                this.getContactsData();
              }
              this.setState({ show: false });
            }}
          />
        )}
        <CallModal
          data={this.state.callDetails}
          handleCloseParent={(e) => {
            console.log(e);

            this.setState({ showDetails: false });
            this.props.dispatch(setCallModalView(false));
          }}
        />

        <div id="contactSearch" className="contacts-header row--three">
          <div className="content__header__searchbox">
            <div className="contactsSearchDiv">
              <SearchInput
                id={"allContactSearch"}
                classes={"form-control font-md search-contact"}
                placeholder={this.props.t("Search Contacts")}
                onChangeValue={(e) => {
                  this.setState({ search: e }, () => {
                    this.getContactsDataForContacts();
                  });
                }}
                onClicked={() => {}}
              />
            </div>
          </div>
          <div className="contactListView notMobile">
            <Tooltip title={this.props.t("Add Contact")} arrow>
              <div
                id="createContactId"
                className="floating-button create_contact notMobile"
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <i className="fas fa-plus"></i>
              </div>
            </Tooltip>
          </div>
          <div className="contactListView notMobile contactListViewButtonsContainer">
            <Tooltip title={this.props.t("List View")} arrow>
              <i
                id="listFormatId"
                className={
                  "fas fa-list i-2p5 " +
                  (this.state.gridView ? "btn-inactive" : "btn-active")
                }
                onClick={() => {
                  sessionStorage.setItem("contactViewType", "LIST");
                  this.setState({ gridView: false });
                }}
              ></i>
            </Tooltip>
            <Tooltip title={this.props.t("Grid View")} arrow>
              <i
                id="cardFormatId"
                className={
                  "fas fa-th-large i-2p5 " +
                  (this.state.gridView ? "btn-active" : "btn-inactive")
                }
                onClick={() => {
                  sessionStorage.setItem("contactViewType", "GRID");
                  this.setState({ gridView: true });
                }}
              ></i>
            </Tooltip>
          </div>
        </div>
        <div className="contacts-body" id="contacts-body">
          {this.state.gridView === false ? (
            <div className="list-title notMobile">
              <div className="contactNameHeader">{this.props.t("Name")}</div>
              <div className="contactTypeHeader">{this.props.t("Type")}</div>
              <div className="contactPhoneHeader">
                {this.props.t("Phone Number")}
              </div>
            </div>
          ) : (
            ""
          )}

          <InfiniteScroll
            dataLength={this.state.contactList.length}
            next={() => {
              if (!this.state.isUserSearching) {
                this.setState(
                  {
                    page: this.state.page + 1,
                  },
                  () => {
                    this.getContactsData();
                  }
                );
              }
            }}
            hasMore={hasMore}
            loader={<Spinner />}
            height={infiniteScrollHeight}
            endMessage={
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "lighter",
                }}
                className="content-spacing"
              >
                {this.state.contactList.length == 0 ? (
                  <span>{this.props.t("No contacts found")}</span>
                ) : (
                  <span></span>
                )}
              </p>
            }
          >
            {this.state.gridView === false ? (
              <ul className="contact-scroll-list contact-list">
                {contactList.map((item: any, i: number) => {
                  return (
                    <li key={item?.Id} id={item?.Id}>
                      <div
                        className="contacttListItem-div notMobile"
                        onClick={() => {
                          this.showContactDetials(item, i);
                        }}
                      >
                        <div className="avatar-div contactName">
                          <Avatar
                            imageType={
                              item?.Is_Prescriber === "Y"
                                ? "prescriber"
                                : "prescriberassociates"
                            }
                            avatarHeight="3rem"
                            avatarWidth="3rem"
                            presOrAssoc={item["AcctFull_Nm"]}
                          />

                          <div
                            style={{
                              fontSize: "1.098rem",
                              fontWeight: "bold",
                              paddingLeft: "1rem",
                            }}
                          >
                            {this.truncate(item["AcctFull_Nm"], 25)}
                          </div>
                        </div>

                        <div
                          className="contactType"
                          style={{
                            fontVariant: "",
                            fontSize: "1.098rem",
                          }}
                        >
                          {item.Is_Prescriber === "N"
                            ? this.props.t("Others")
                            : this.props.t("Prescriber")}
                        </div>
                        <div
                          className="contactPhone"
                          style={{
                            fontVariant: "small-caps",
                            fontSize: "1.098rem",
                            paddingRight: "1rem",
                          }}
                        >
                          {this.getNumberFromItem(item)}
                        </div>
                      </div>

                      <div className="contacttListItem-div contacttListItem-divMobile paddingCtnBlock">
                        <div
                          className="avatar-div contactName"
                          onClick={() => {
                            this.showContactDetials(item, i);
                          }}
                        >
                          <Avatar
                            imageType={
                              item.Is_Prescriber === "Y"
                                ? "prescriber"
                                : "prescriberassociates"
                            }
                            avatarHeight="3.5rem"
                            avatarWidth="3.5rem"
                            presOrAssoc={item["AcctFull_Nm"]}
                          />
                          <div>
                            <div
                              style={{
                                fontSize: "1.098rem",
                                fontWeight: "bold",
                                paddingLeft: "1rem",
                              }}
                              className="nameContact"
                            >
                              {this.truncate(item["AcctFull_Nm"], 25)}
                            </div>
                            <div
                              className="contactPhone"
                              style={{
                                fontVariant: "small-caps",
                                fontSize: "1.098rem",
                                paddingLeft: "1rem",
                              }}
                            >
                              {this.state.isHospitalContact && (
                                <div
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontVariant: "normal",
                                  }}
                                >
                                  {this.truncate(item?.HospFull_Nm, 25)}
                                </div>
                              )}
                              {this.getNumberFromItem(item)}
                            </div>
                          </div>
                        </div>
                        <div className="onlyMobile eachContactsIcons">
                          <i
                            className="material-icons i-2p5"
                            onClick={() => {
                              this.redirectInMobile(item, true, i);
                            }}
                          >
                            {this.props.t("chat")}
                          </i>
                          <i
                            className="material-icons i-2p5"
                            onClick={() => {
                              this.redirectInMobile(item, false, i);
                            }}
                          >
                            {this.props.t("phone")}
                          </i>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div>
                {this.state.contactList.length > 0 ? (
                  <div className="main-grid-container">
                    {/* This is the repeating div , so loop this div to display contents */}
                    {contactList.map((item: any, i: number) => {
                      return (
                        <div
                          className="grid-item-div"
                          key={item.Id}
                          id={item.Id}
                          onClick={() => {
                            this.showContactDetials(item, i);
                          }}
                        >
                          <div>
                            <div className="contact-item-div">
                              <div className="grid-avatar-div">
                                <Avatar
                                  imageType={
                                    item.Is_Prescriber === "Y"
                                      ? "prescriber"
                                      : "prescriberassociates"
                                  }
                                  avatarHeight="3rem"
                                  avatarWidth="3rem"
                                  presOrAssoc={item["AcctFull_Nm"]}
                                />
                              </div>
                              <div className="grid-usercontact-div">
                                <label
                                  style={{
                                    fontSize: "1.098rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.getItemName(item)}
                                </label>
                                <label style={{ fontSize: "1.098rem" }}>
                                  {item.Is_Prescriber === "N"
                                    ? this.props.t("Others")
                                    : this.props.t("Prescriber")}
                                </label>
                                <br />
                                <label style={{ fontSize: "1.098rem" }}>
                                  {this.getNumberFromItem(item)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
          </InfiniteScroll>

          <div
            className="create-dynamic-floating onlyMobile"
            style={{
              paddingBottom: this.state.paddingValBottom,
              paddingRight: this.state.paddingValRight,
            }}
          >
            <Tooltip title={this.props.t("Add Contact")} arrow>
              <div
                id="createContactId"
                className="floating-button create_contact"
                onClick={() => {
                  this.setState({ show: true });
                }}
              >
                <i className="fas fa-plus"></i>
              </div>
            </Tooltip>
          </div>
        </div>

        {/* Add contact modal if contact not added  user click on message icon in contact details popup will appear */}
        <ContactAddPopup
          showAddContactModal={this.state.showAddContactModal}
          data={this.state.callDetails}
          redirectPage={this.state.msgOrCallIcon}
          handleCloseParent={(e, num) => {
            this.setState({ showAddContactModal: false, showDetails: false });
            console.log(e);
            if (e) {
              if (e.AcctPh_Nbr && e.IntlCallPfx_Nbr) {
                let Temp = this.state.contactList;
                let objIndex = Temp.findIndex((obj) => obj.AcctFull_Nm == num);
                Temp[this.state.selectedContactIndex].AcctPh_Nbr = e.AcctPh_Nbr;
                Temp[this.state.selectedContactIndex].IntlCallPfx_Nbr =
                  e.IntlCallPfx_Nbr;
                Temp[objIndex].AcctPh_Nbr = "";
                this.setState({ showDetails: false, contactList: Temp });
              }
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  token: state.login.token,
  showCallModal: state.callModal.showCallModal,
  pageTitle: state.sideBar.title,
  campaign: state.personalisation.campaignName,
});

export default withTranslation()(connect(mapStateToProps)(Contacts));
