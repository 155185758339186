import { useRef } from "react";
import { ReactComponent as PdfIcon } from "../../assets/icons/video_logs_pdf.svg";
import moment from "moment";

export default function ListFiles(props: any) {
  const holdTimeoutRef = useRef<any>(null);

  const handleTouchStart = (value) => {
    holdTimeoutRef.current = setTimeout(() => {
      getSelectedFile(value);
    }, 500) as unknown as number; // Adjust the duration of holding as per your requirement
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    clearTimeout(holdTimeoutRef.current);
  };

  const checkValueInArrayObject = (value, array) => {
    return array.some(function (arr) {
      return arr["Item_Id"] === value.Item_Id;
    });
  };

  const getSelectedFile = (file) => {
    let edata: any = file;
    let selectefile: any = props.selectedFileData;

    if (!checkValueInArrayObject(file, props.selectedFileData)) {
      selectefile.push(edata);
    } else {
      selectefile = selectefile.filter((i) => i.Item_Id !== file.Item_Id);
    }
    props.setSelectedFileData(selectefile);
    console.log(props.selectedFileData.length);
  };

  return (
    <div className="onlyMobile">
      {props.data.map((value: any, key: any) => (
        <div className="" key={key}>
          <div className="d-flex">
            {!!props.selectedFileData.length && (
              <input
                type="checkbox"
                key={key}
                className="mycheckbox me-4"
                checked={checkValueInArrayObject(value, props.selectedFileData)}
                onChange={() => getSelectedFile(value)}
              />
            )}
            <div
              className="files-details"
              onTouchStart={(e) => handleTouchStart(value)}
              onTouchEnd={handleTouchEnd}
              onTouchCancel={handleTouchEnd}
            >
              {value?.File_Typ && (
                <div className="file-logo">
                  <PdfIcon
                    style={{ fill: "var(--primary-color)", scale: "1.8" }}
                    className="videofileicons"
                  />
                </div>
              )}
              <div className="" style={{ flexGrow: 1 }}>
                {value?.Item_Nm && (
                  <p className="past-files-name">
                    <a
                      href={value?.Item_Nm}
                      target="__blank"
                      className="templatesListItem-hyperlink"
                      style={{ color: "grey" }}
                    >
                      {value?.Item_Nm}
                    </a>
                  </p>
                )}

                {value?.Item_Nm ? (
                  <p className="past-files-name">
                    <a
                      href={value?.Item_Nm}
                      target="__blank"
                      className="templatesListItem-hyperlink"
                      style={{ color: "grey" }}
                    >
                      {props.Item_Nm}
                    </a>
                  </p>
                ) : null}

                <p className="past-files-date">
                  {moment(value?.CreatedBy_DtTm).format("MM/DD/YYYY")}.{" "}
                  {"270.2 KB"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
