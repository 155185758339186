import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { getMailById } from "../../apis/Email/Email";
import parse from "html-react-parser";
import "./EmailText.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
export default function AlignItemsList({ email }) {
  const [apicalled, setapicalled] = React.useState(false);
  const [emailListData, setEmailListData] = useState<any>([]);
  const [showprev, setshowprev] = useState(false);
  const [attachments, setAttachments] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.login.token);
  const fetchData = (id) => {
    getMailById(id, (resp: any) => {
      console.log(resp.data);
      if (resp.data) {
        if (resp.data[0].Attachments.length > 0) {
          setAttachments(resp.data[0].Attachments);
        }
      }
    });
  };

  const getDate = (Update_DtTm) => {
    const date = new Date(Update_DtTm);
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const forceFileDownload = (response: any, filename: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  const downloadWithAxios = async (url, filename) => {
    delete axios.defaults.headers.common["Authorization"];
    const instance = axios.create();
    const response = await instance.get(url,{responseType:"blob"});
    const blob = response.data
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link); // This line is needed for Firefox
    link.click();
    document.body.removeChild(link); // Clean up after ourselves
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem(`login`)}`
  }

  useEffect(() => {
    console.log("id:::::::::::::::", email);
    setshowprev(false);
    if (email) {
      setAttachments([]);
      fetchData(email.EmailConversation_Id);
      setEmailListData([email]);
    }
  }, [email.EmailConversation_Id]);

  const convertHtml = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    return body_element.innerHTML;
  };
  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  return (
    <div className="EmailText" style={{ textAlign: "start" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          height: "100vh",
          width: "100%",
          borderBlockEndStyle: 25,
          borderBlockStartStyle: 25,
        }}
      >
        {emailListData?.map((item, index) => {
          return (
            <div key={item.EmailConversation_Id + "-" + item.Id}>
              <List sx={{ width: "100%" }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.EmailConversation_Subject}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          To: {item.EmailTo_Email} <br />
                          cc: {item.EmailCC_Email}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
              <div
                className="mailContent"
                style={{ padding: "20px", textAlign: "start" }}
              >
                {attachments.length > 0 ? (
                  <div className="attached-media">
                    {attachments?.map((file: any, index) => {
                      return (
                        <div
                          key={
                            file.Display_Nm +
                            "-" +
                            file.ContentType +
                            "-" +
                            index +
                            "-" +
                            file.lastModifiedDate
                          }
                          id={
                            file.Display_Nm +
                            "-" +
                            file.ContentType +
                            "-" +
                            index +
                            "-" +
                            file.lastModifiedDate
                          }
                        >
                          <div className="file-wrapper">
                            <div className="media-tb">
                              <div className="media-left d-flex">
                                <i className="far fa-file-alt"></i>
                              </div>
                              <div>{truncate(file.Display_Nm, 15)}</div>
                            </div>
                          </div>
                          <p
                            onClick={() => {
                              downloadWithAxios(
                                file.EmailAttach_Url,
                                file.Display_Nm
                              );
                            }}
                            className="Download"
                          >
                            Download
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <p>{email && email.Email_Bdy && parse(email.Email_Bdy)}</p>
              </div>
            </div>
          );
        })}
      </Box>
    </div>
  );
}
