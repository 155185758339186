import { useEffect, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import "./VideoChatDetails.css";
import CallIcon from "@mui/icons-material/Call";
import { getVideoLogChats } from "../../apis/video/video";
import moment from "moment";
import Spinner from "../../layouts/spinner/Spinner";

export default function VideoChatDetails(props) {
  const [videoChatResult, setVideoChatResult] = useState([]);
  const [spin, setSpin] = useState(false);
  useEffect(() => {
    
    setSpin(true);
    let doc: any = document;
    let accvideo2 = doc.getElementsByClassName("vchat-main");
    console.log(accvideo2);
    for (var i = 0; i < accvideo2.length; i++) {
      console.log("fakeImage: ", accvideo2[i]);
      accvideo2[i].style.display = "block";
    }
    console.log(props);
    console.log(props.Roomid);
    let Roomid = props.Roomid;
    getVideoLogChats({ RoomId: Roomid }, (res) => {
      if (res) {
        setSpin(false);
        console.log(res.data.data);
        if (res.data)
          if (res.data.data) {
            const filterChats = res.data.data.filter(
              (item) => item.MessageType === "chat"
            );
            console.log(filterChats);
            setVideoChatResult(filterChats);
          }
      }
    });
  }, []);

  return (
    <div className="main-chat-details">
      <div className="vchat-main">
        <div className="items-view-main">
          {props.startDateTime != undefined ? (
            <div className="vchat-details videologListItem-content">
              <CallIcon className="svg-icons" />
              <div className="new-participant-details">
                Call started {moment(props.startDateTime).format("h:mm A")}
              </div>
            </div>
          ) : null}
          {spin && videoChatResult.length == 0 ? (
            <Spinner />
          ) : videoChatResult.length == 0 ? (
            <div className="chat-validation">No Chats found</div>
          ) : null}

          {videoChatResult != undefined &&
            videoChatResult.map((videoResult: any, key: any) => (
              <>
                <div className="vchat-details">
                  <div className="avatar-logo">
                    <Avatar
                      imageType={"PRESCR"}
                      avatarHeight="2rem"
                      avatarWidth="2rem"
                      presOrAssoc={videoResult.UserName}
                      showAvatarType="initialsOnly"
                    />
                  </div>

                  <div className="chat-main">
                    <div
                      className="chat-content"
                      style={{ display: "inline-block", fontWeight: "bold" }}
                    >
                      {videoResult.UserName}
                    </div>
                    <div className="timestamp">
                      {moment(videoResult.CreatedOn).format("MM/DD h:mm A")}
                    </div>
                    <div className="chat-content"> {videoResult.Message} </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
}
