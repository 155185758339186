import React, { useEffect, useState } from "react";
import Message from "./Message";
import axios from "axios";
import config from "../../env.json";
import ContactDetialsModal from "../contact/ContactDetialsModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { setSkipHeader } from "../../app/slice/loginSlice";
import CircularProgress from "@mui/material/CircularProgress";
import PreApprovedTemplates from "../preApprovedTemplates/preApprovedTemplate";
import { contactDetails } from "../../apis/contacts/contactsApi";
import { getConversationDetailsApi } from "../../apis/chat/chatApi";
import { useSelector, useDispatch } from "react-redux";
import {
  sendMessageApi,
  checkWhatsappValidation,
} from "../../apis/chat/messageApi";
import { getMsgReadAt } from "../../apis/chat/messageReadAt";
import { Keyboard } from "@capacitor/keyboard";
import { Link } from "react-router-dom";
import {
  setChatDetails,
  setChatShowChat,
  setChatType,
  setRefreshChatComponent,
  setRefreshChatList,
  setNumberAssigned,
} from "../../app/slice/chatSlice";
import {
  setCallModalDetails,
  setCallModalView,
} from "../../app/slice/callModalSlice";
import "./MobileChat.css";
import Avatar from "../Avatar/Avatar";
import { Tooltip } from "@mui/material";
import moment from "moment";
import CallModal from "../call/CallModal";
import { useTranslation } from "react-i18next";
import Pusher from "pusher-js";
import SaveIcon from "@mui/icons-material/Save";
import { scrollIntoView } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";
import SaveContactsForChat from "./SaveContactForChat";
import SaveContactsForChatGAM from "./SaveContactForChatGAM";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import TestMessage from "./MobileMessage";
import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
} from "../../app/slice/personalisationSlice";
import { getPhoneNumberType } from "../../apis/checkPhoneNumberType/landlineLookupApi";
import CallIcon from "@mui/icons-material/Call";
import sidebarHeaderBg from "../../assets/images/mobilesidebar_headerbg.jpg";
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";
import ContactAddPopup from "../contact/ContactAddPopup";
import { Popover } from "antd";
import MediaChatChannelModal from "../../components/MediaChannel/MediaChatChannelModal";
import AttachmentIcon from "../../assets/icons/Attachicon.svg";
import MediaAttachmentIcon from "../../assets/icons/templateicon.svg";
import { isAndroid } from "react-device-detect";

const Background = config.REACT_APP_PROFILE_BG_IMG;
let BestTimeFlag = config.REACT_APP_BESTTIME_FLAG;

function NewMobileChatComponent(props) {
  const { t } = useTranslation();
  const {
    chatId,
    chatName,
    chatIsPrescriber,
    showChat,
    refreshChatComponent,
    pageSelected,
    numberAssigned,
  } = useSelector((state: any) => state.chat);
  const messageChannelType = useSelector(
    (state: any) => state.authApi.EmployeeMsgorWhatsAppChannelType
  );
  const type = useSelector((state: any) => state.chat.type);
  const token = useSelector((state: any) => state.login.token);
  const dispatch = useDispatch();
  const userTerrId = useSelector(
    (state: any) => state.personalisation.EmployeeTerritoryId
  );

  const [changeTerritoryId, setChangeTerritoryId] = useState(false);
  const showchatfooter = useSelector((state: any) => state.personalisation.showchatfooter);
  const [messageData, setMessageData] = useState([]);
  const [contactData, setContactData] = useState<any>({});
  const [showDetails, setShowDetails] = useState(false);
  const [name, setName] = useState("");
  const [showPreApproveTemplates, setPreApproveTemplates] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState<String[]>([]);
  const [chatInput, setChatInput] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [messageId, setMessageId] = useState<any>();
  const [pusherChannel, setPusherChannel] = useState<any>(null);
  const [pusherMsgProps, setPusherMsgProps] = useState<any>(null);

  const [showTextArea, setShowTextArea] = useState(true);
  const [chatWidth, setChatwidth] = useState(0);
  const [chatHeight, setChatHeight] = useState(0);
  const [paddingValTop, setPaddingValTop] = useState(0);
  const [paddingValBottom, setPaddingValBottom] = useState(0);
  const [paddingValRight, setPaddingValRight] = useState(0);
  const [paddingValLeft, setPaddingValLeft] = useState(0);

  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(0);
  const [showImgPopup, setShowImgPopup] = useState("");
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [contactOptedOut,setContactOptedOut] = useState(false);
  const [unknown, setUnknown] = useState(false);
  const [isNumberLandline, setIsNumberLandline] = useState(false);
  const [openSaveContacts, setOpenSaveContacts] = useState(false);
  const [mobileFooterBottom, setMobileHeaderTop] = useState<any>(0);
  const [mobileHeaderTop, setMobileFooterBottom] = useState<any>(0);
  const [mainChatTop, setMainChatTop] = useState<any>(0);
  const [mainChatBottom, setMainChatBottom] = useState<any>(0);
  const [finished, setFinished] = useState(false);
  const [deletedOthers, setDeletedOthers] = useState(false);
  const { optOutKeywords, optInKeywords } = useSelector(
    (state: any) => state.personalisation
  );
  const [tempTemplateDataIfNoNumber, setTempTemplateDataIfNoNumber] =
    useState<any>(null);
  const [noNumber, setNoNumber] = useState(false);
  const [showAttachLoader, setShowAttachLoader] = useState<any>(false);
  const [isHospitalContact, setIsHospitalContact] = useState(false);
  const [addNumberPopup, setAddNumberPopup] = useState(false);
  const [chatPusher, setChatPusher] = useState<any>(null);
  const camp = useSelector((state: any) => state.personalisation.campaignName);
  const [mediaChannelPopup, setMediaChannelPopup] = useState(false);
  const [isWhatsappTimeExpired, setIsWhatsappTimeExpired] = useState(false);
  const [textAreaActive, setTextAreaActive] = useState(false);
  const [deletedPres, setDeletedPres] = useState(false);
  const [resData, setResData] = useState(false);

  let docElement: any = document;

  const EmployeePermissionCountry_Cd = useSelector(
    (state: any) => state.personalisation.EmployeePermissionCountry_Cd
  );
  const [isSMS, setIsSms] = useState(true); // if true then whatsapp or else message
  const fileSupportedExt = config.REACT_APP_FILESUPPORTED_EXT.split(",");
  const fileType = config.REACT_APP_FILESUPPORTED_TYPE;
  const default_wave_id = config.REACT_APP_DEFAULT_WAVE_ID;
  const default_campaign_id = config.REACT_APP_DEFAULT_CAMPAIGN_ID;
  const default_product_id = config.REACT_APP_DEFAULT_PRODUCT_ID;

  const getUserInfo = () => {
    let id = chatId,
      presOrNot = chatIsPrescriber;
    if (id)
      contactDetails({ Id: id, Is_Prescriber: presOrNot }, (res) => {
        if (res) {
          if (res.message == "No data found") {
            setUnknown(true);
            setResData(false);
          } else {
            setResData(true);
            if (res.Terr_Id || res.Terr_Id === 0) {
              if (
                userTerrId &&
                userTerrId !== "" &&
                config.REACT_APP_ABS_APPROVED === "Y"
              ) {
                for (let i = 0; i < userTerrId.length; i++) {
                  if (userTerrId[i]?.TerritoryAlgnTerr_Id === res.Terr_Id) {
                    setChangeTerritoryId(false);
                    break;
                  } else {
                    setChangeTerritoryId(true);
                  }
                }
              }
            }

            checkWhatsappValidation(
              {
                recipient_id: id,
                recipient_phone: res.AcctPh_Nbr,
                role_id: chatIsPrescriber ? 0 : 1,
              },
              (res, msgData) => {
                if (res && msgData && !msgData.success) {
                  setIsWhatsappTimeExpired(true);
                } else {
                  setIsWhatsappTimeExpired(false);
                  setDisableSendButton(false);
                }
              }
            );

            if (res.AcctPh_Nbr?.length > 0) {
              dispatch(setNumberAssigned(true));
            }
            setUnknown(false);

            chatSubscriber(1);
            setContactData(res);
            checkNumber(res);
            if (!res.AcctPh_Nbr) {
              setNoNumber(true);
              dispatch(setNumberAssigned(false));
            } else {
              setNoNumber(false);
            }
            dispatch(
              setCallModalDetails({
                AcctFull_Nm: res.AcctFull_Nm,
                AcctFull_Nbr: getNumberFromItem(res),
              })
            );

            if (res.All_Ph_Cont_Flags === "N, N, N") {
              setOptedOut(true);
              console.log("optout", res.All_Ph_Cont_Flags);
            } else {
              setOptedOut(false);
              console.log("optout", res.All_Ph_Cont_Flags);
            }
            if (res.Contact_OptOut === "Y") {
              setContactOptedOut(true)
            } else {
              setContactOptedOut(false);
            }
          }
        }
        setFinished(true);
      });
  };
  const handleFileSelected = (e) => {
    const files: any = Array.from(e.target.files);
    console.log("files:", files);
    setAttachedFiles(files);
    console.log("attachedFiles : ", attachedFiles);
  };

  const handleImgPopup = (imgdisurl) => {
    console.log("handling image enlarge parent", imgdisurl);
    setShowImgPopup(imgdisurl);
  };

  const forceFileDownload = (response: any, filename: any) => {
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    document.getElementsByClassName("mobile-footer")[0]?.addEventListener(
      "touchmove",
      (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
      },
      { passive: false }
    );

    let chatdiv: any = document?.getElementsByClassName("shadowin")[0];

    var lastScrollTop = 0;

    chatdiv.addEventListener(
      "touchmove",
      (ev) => {
        if (
          chatdiv.scrollTop >=
          chatdiv.scrollHeight - chatdiv.offsetHeight - 1
        ) {
          chatdiv.scrollTop = chatdiv.scrollHeight - chatdiv.offsetHeight - 3;
        }
        var st = window.pageYOffset || chatdiv.scrollTop;
        if (st > lastScrollTop) {
          if (
            chatdiv.scrollTop >=
            chatdiv.scrollHeight - chatdiv.offsetHeight - 1
          ) {
            ev.preventDefault();
            ev.stopPropagation();
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      { passive: false }
    );
  }, []);

  const downloadWithAxios = async (url) => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
    } else {
      console.log("handle click downloadWithAxios called", url);
      let urlSplit = url.split("/");
      let filename = urlSplit[urlSplit.length - 1];
      delete axios.defaults.headers.common["Authorization"];
      const instance = axios.create();
      const res = await instance.get(url, { responseType: "blob" });
      forceFileDownload(res.data, filename);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  };

  const getUploadContent = () => {
    return (
      <div id={"popup1"}>
        <div className="videopopup-row">
          <div className="msgoption_icon_wrapper">
            <img src={AttachmentIcon} alt="docIcon" />
          </div>
          <div
            className="videopopup-details"
            //   onClick={handleNewFile}
            onClick={() => {
              let doc: any = document;
              doc.getElementById("attachedfiles").click();
            }}
          >
            Upload file from Device
          </div>
        </div>

        <div className="videopopup-row">
          <div className="msgoption_icon_wrapper">
            <img src={MediaAttachmentIcon} alt="folderIcon" />
          </div>
          <div
            className="videopopup-details"
            onClick={() => {
              setMediaChannelPopup(true);
            }}
          >
            Attach file from Media
          </div>
        </div>
      </div>
    );
  };

  const removeFile = (i) => {
    console.log("functioin called for ", i);
    let attfile = attachedFiles.filter((x, index) => i != index);
    setAttachedFiles(attfile);
    validateGetfiles(attfile);
  };
  useEffect(() => {
    if (!optedOut && !deletedOthers && !unknown && !isNumberLandline) {
      let doc: any = document;
      setMainChatBottom(
        doc.getElementsByClassName("mobile-footer")[0]?.clientHeight
      );
    } else {
      setMainChatBottom("0px");
    }
  }, [optedOut, deletedOthers, unknown, isNumberLandline]);

  useEffect(() => {
    let doc: any = document;
    console.log(getNumberFromItem(contactData));
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind(getNumberFromItem(contactData));

      pusherChannel.bind(getNumberFromItem(contactData), (data) => {
        if (data) {
          console.log(messageData);
          let jsonData = JSON.parse(data.Body);
          console.log(JSON.parse(data.Body));
          optInOut(jsonData.isOptIn);
        }
      });
    }
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValTop(divpaddingTop);
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingleft);
    setPaddingValLeft(divpaddingright);

    console.log("Innerwidth" + window.innerHeight);

    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    scrollTOBottom();
    console.log("changes");
  }, [messageData, contactData]);

  useEffect(() => {
    setChatHeight(window.innerHeight);
  }, []);
  useEffect(() => {
    scrollTOBottom();
    chatSubscriber();
  }, [messageData]);

  const chatSubscriber = (p = 0) => {
    console.log(messageData);

    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      cluster: config.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe("chatdetail");
    setPusherChannel(channel);
    setPusherMsgProps(pusher.subscribe("chatdetailstatus"));

    let payload: any = {
      prescOrAssocId: chatId,
      prescOrAssocType:
        chatIsPrescriber === true
          ? "PRESCR"
          : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
            config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
          ? "ASSHSP"
          : "ASSPRS",
    };
    if (p == 0) {
      getMsgReadAt(payload, (res) => {
        console.log(res.data.updated);
        if (res.data.updated === true) {
          dispatch(setRefreshChatList(true));
        }
      });
    }
    let chatP: any = pusher.subscribe("chat");
    setChatPusher(chatP);

    return () => {
      pusher.unsubscribe("chatdetail");
      pusher.unsubscribe("chat");
    };
  };
  const messageSubscriber = () => {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      cluster: config.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe("chatdetailstatus");
    channel.bind(messageId, (data) => {
      let x = data;
      console.log(data);
    });

    return () => {};
  };
  const getFiles = (e) => {
    console.log("inside");
    const files: any = Array.from(e.target.files);

    let temp: any = [];
    let totalsize: any = 0;
    attachedFiles.map((x: any) => {
      temp.push(x);
      totalsize += x.size;
      console.log(temp);
    });
    files.map((e: any) => {
      e.testTime = new Date();
      temp.push(e);
      totalsize += e.size;
      console.log(temp);
    });

    console.log(temp);
    let { errorMesg } = validateGetfiles(temp);

    if (totalsize > 100000000) {
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      });

      t.push("File size exceeds 100MB");
      setErrorMessages(t);
      setTimeout(function () {
        setErrorMessages([]);
      }, 2000);
    } else if (temp.length > 10) {
      let errmsg = "Max 10 files are allowed";
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      });

      t.push("Max 10 files are allowed");
      setErrorMessages(t);
      setTimeout(function () {
        setErrorMessages([]);
      }, 2000);
    } else {
      setAttachedFiles(temp);

      console.log("attachedFiles : ", attachedFiles);
      console.log("hello world : ", attachedFiles.length);
    }
    if (errorMesg) {
      let t: any = [];
      errorMessages.map((x: any) => {
        t.push(x);
        return x;
      });
      t.push(errorMesg);
      setErrorMessages(t);
      setTimeout(function () {
        setErrorMessages([]);
      }, 2000);
    }
    let doc: any = document;
    doc.getElementById("attachedfiles").value = "";
  };
  const validateGetfiles = (files) => {
    setDisableSendButton(false);
    let errorMesg = "";
    files = Array.from(files);
    let file;
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      let fileExt = file.name.split(".").pop().toLowerCase();
      console.log("fileExt : ", fileExt);
      console.log("fileExt : ", fileSupportedExt);
      console.log("include file ext : ", fileSupportedExt.includes(fileExt));

      console.log("filetype : ", file.type);
      console.log("filetype : ", fileType);
      console.log("file type list : ", fileType.includes(file.type));
      if (i < 10) {
        if (
          file.type === "image/tiff" ||
          file.type === "audio/amr" ||
          fileExt === "amr" ||
          file.type === "" ||
          file.type === "audio/ogg"
        ) {
          errorMesg = file.name + " is not supported";
          setDisableSendButton(true);
        }
      } else {
        if (
          file.type === "image/tiff" ||
          file.type === "audio/amr" ||
          fileExt === "amr" ||
          file.type === "" ||
          file.type === "audio/ogg"
        ) {
          errorMesg =
            file.name + " is not supported and Max 10 files are allowed";
        }
      }
    }
    return { errorMesg };
  };
  const selectedTemplate = (tempData, contactData) => {
    let templateData: any = tempData.templateData;
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
    let formData = new FormData();
    formData.append("EmployeePermissionCountry_Cd", EmployeePermissionCountry_Cd);
    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }
    if (tempData.dynTempObj && Object.keys(tempData.dynTempObj)?.length > 0) {
      formData.append("dynTempObj", JSON.stringify(tempData.dynTempObj));
    }

    formData.append("Campaign_ID", default_campaign_id); //templateData.campaginId.toString());
    formData.append("wave_id", templateData.mainWaveId.toString()); //templateData.waveId.toString())
    formData.append("product_id", templateData.productId.toString());

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    if (templateData.FormId) {
      let formPay = {
        formId: templateData.FormId,
        fieldDetails: tempData.formData,
        recipientId: chatId,
        recipientType: chatIsPrescriber ? "PRESCR" : "ASSPRS",
      };
      axios
        .post(config.REACT_APP_FORMS_API_BASE + "/formRequest", formPay)
        .then((res: any) => {
          console.log(res.data);
          let temptest = templateData.TemplateBody.toString();

          if (temptest.indexOf("shorturl") >= 0)
            formData.append(
              "template",
              temptest.toString().replace("[shorturl_1]", res.data.FormUrl)
            );
          else formData.append("template", temptest + " " + res.data.FormUrl);
          formData.append("urls", res.data.FormUrl);
          let qtPayload = {
            quantityUsed: tempData.formData[0].valueOfFieldToAskFromEmp,
            prescriberId: chatId,
            productId: templateData.productId.toString(),
          };
          axios
            .post(
              config.REACT_APP_CAMPAIGN_API_BASE + "/srf/updateQauntity",
              qtPayload
            )
            .then((res: any) => {});
          sendMessageApi(formData, (res, msgData) => {
            console.log(msgData);
            setPreApproveTemplates(false);
            if (res) {
              getMessageData(props.messageData, false, 1);
              dispatch(setRefreshChatList(true));
              dispatch(setShowSuccessSnackBar(true));
            } else {
              dispatch(setShowErrorSnackBar(true));
            }
          });
        })
        .catch((err) => {
          dispatch(setShowErrorSnackBar(true));
        });
    } else {
      formData.append("template_id", templateData.TemplateId.toString());
      formData.append("template", templateData.TemplateBody);
      sendMessageApi(formData, (res, msgData) => {
        console.log(msgData);
        setPreApproveTemplates(false);
        if (res) {
          getMessageData(props.messageData, false, 1);
          dispatch(setRefreshChatList(true));
          dispatch(setShowSuccessSnackBar(true));
        } else {
          dispatch(setShowErrorSnackBar(true));
        }
      });
    }
  };
  const saveUnknown = (data: any) => {
    if (data.Id) {
      dispatch(
        setChatType(
          data.Is_Prescriber == "Y"
            ? "PRESCR"
            : config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
              config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
            ? "ASSHSP"
            : "ASSPRS"
        )
      );
      dispatch(
        setChatDetails({
          chatId: data.Id,
          chatName: data.AcctFull_Nm,
          chatIsPrescriber: data.Is_Prescriber == "Y",
          showChat: true,
        })
      );
      dispatch(setRefreshChatList(true));
      dispatch(setRefreshChatComponent(true));
    }
  };

  const getMessageData = (data, old, p) => {
    let id = chatId;
    getConversationDetailsApi({ page: p, limit, id, type: type }, (res) => {
      window.localStorage.setItem("sentFromWindow", "false");
      if (res.length > 0 && res[0]["Error"]) {
        setHasMore(false);
      }
      if (res.length == 0) {
        setHasMore(false);
      }
      let t = res.reverse();
      if (old) {
        t = messageData.concat(t);
      }

      for (let chatIndex = 0; chatIndex < t.length; chatIndex++) {
        if (chatIndex == 0) {
          t[chatIndex].showDateBanner = true;
        } else if (chatIndex != t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") !=
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = true;
          } else {
            t[chatIndex].showDateBanner = false;
          }
        } else if (chatIndex == t.length - 1) {
          if (
            moment(t[chatIndex]["Recipient Msg Start"]).format("MM/DD/YYYY") ==
            moment(t[chatIndex - 1]["Recipient Msg Start"]).format("MM/DD/YYYY")
          ) {
            t[chatIndex].showDateBanner = false;
          } else {
            t[chatIndex].showDateBanner = true;
          }
        }
      }

      console.log(t);
      if (res.length > 0 && res[0]["Error"] == undefined) {
        setMessageData(t);
      }
      let tt = t.filter((x) => x["Direction"] == "incoming");
      let optOutIndex;
      let optOutIndexArray = new Array();
      let optInIndexArray = new Array();
      let optInIndex;
      let tr = tt.reverse();
      if (tt.length > 0) {
        for (var i = 0; i < optOutKeywords.length; i++) {
          optOutIndex = tr.findIndex(
            (x) =>
              x["Recipient Msg Body"].toLowerCase() ==
              optOutKeywords[i].toLowerCase()
          );

          if (optOutIndex >= 0) {
            optOutIndexArray.push(optOutIndex);
          } else {
            optOutIndex = 100000000;
            optOutIndexArray.push(optOutIndex);
          }
        }
        for (var j = 0; j < optInKeywords.length; j++) {
          optInIndex = tr.findIndex(
            (x) =>
              x["Recipient Msg Body"].toLowerCase() ==
              optInKeywords[j].toLowerCase()
          );
          if (optInIndex >= 0) {
            optInIndexArray.push(optInIndex);
          } else {
            optInIndex = 100000000;
            optInIndexArray.push(optInIndex);
          }
        }
        var smallestOptOutIndex = optOutIndexArray.sort((a, b) => a - b);
        var smallestOptInIndex = optInIndexArray.sort((a, b) => a - b);
        if (smallestOptOutIndex[0] < smallestOptInIndex[0]) {
          setOptedOut(true);
          console.log("lllllooooo", optedOut);
        } else {
          setOptedOut(false);
        }
      }
      getUserInfo();
    });
  };
  const scrollTOBottom = () => {
    if (
      docElement.getElementsByClassName("mainchat") &&
      docElement.getElementsByClassName("mainchat").length > 0
    ) {
      setTimeout(function () {
        if (document.getElementsByClassName("mainchat")) {
          if (document.getElementsByClassName("mainchat").length > 0)
            document.getElementsByClassName("mainchat")[
              document.getElementsByClassName("mainchat").length - 1
            ].scrollTop =
              document.getElementsByClassName("mainchat")[
                document.getElementsByClassName("mainchat").length - 1
              ].scrollHeight;
        }

        if (document.getElementsByClassName("msg-attachment")) {
          let scroll: any = document.querySelector(
            ".infinite-scroll-component"
          );
          scroll.scrollIntoView(false);
        }
      }, 500);
    }
  };

  const resizeInputBox = () => {
    return "\t";
  };

  useEffect(() => {
    console.log("opppppp", optedOut);

    if (optedOut) {
      setMainChatBottom("0px");
    }
  }, [optedOut]);
  const checkNumber = (contactData: any) => {
    let phoneNumber = getNumberFromItem(contactData);
    if (Object.keys(contactData).length > 0) {
      getPhoneNumberType({ phone: phoneNumber }, (res) => {
        if (res.type == "landline") {
          setIsNumberLandline(true);
        } else {
          setIsNumberLandline(false);
        }
      });
    }
  };
  useEffect(() => {
    if (unknown && type == "ASSPRS") {
      setDeletedOthers(true);
      setDeletedPres(false);
    } else {
      setDeletedOthers(false);
      setDeletedPres(true);
    }
  }, [unknown]);
  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardWillShow", (info) => {
        document
          .getElementById("main")
          ?.setAttribute("style", "padding-bottom: 0 !important");
      });

      Keyboard.addListener("keyboardDidShow", (info) => {
        calculateHeight();
      });

      Keyboard.addListener("keyboardWillHide", () => {
        document.getElementById("main")?.removeAttribute("style");
      });

      Keyboard.addListener("keyboardDidHide", () => {
        calculateHeight();
      });
    }
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    setMobileFooterBottom(divpaddingbottom);
    setMobileHeaderTop(divpaddingTop);
    if (doc.getElementsByClassName("header-wrapper")[0]) {
      setMainChatTop(
        doc.getElementsByClassName("header-wrapper")[0].clientHeight
      );
    }
    if (doc.getElementsByClassName("mobile-footer")[0]) {
      console.log("Flag", optedOut);
      setMainChatBottom(
        doc.getElementsByClassName("mobile-footer")[0].clientHeight
      );
    } else {
      setMainChatBottom("0px");
    }
    // for ios
    if (!isAndroid) {
      window.visualViewport?.addEventListener("resize", (e) => {
        let div: any = document.querySelector("#main");
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setChatHeight(window.visualViewport?.height ?? window.innerHeight);
        setChatwidth(
          (window.visualViewport?.width ?? window.innerWidth) -
            (divpaddingright + divpaddingleft)
        );
        getHeight();
        scrollTOBottom();
        console.log("resize");
      });
    } else {
      // for android
      window.addEventListener("resize", (e) => {
        let div: any = document.querySelector("#main");
        let divpaddingleft: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-left")
        );
        let divpaddingright: any = parseInt(
          window.getComputedStyle(div, "").getPropertyValue("padding-right")
        );
        setChatHeight(window.innerHeight);
        setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
        getHeight();
        scrollTOBottom();
      });
    }
  }, [optedOut]);

  useEffect(() => {
    window.addEventListener("orientationchange", orientationChange);
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    ) {
      setIsHospitalContact(true);
    } else {
      setIsHospitalContact(false);
    }
    return () => {
      window.removeEventListener("orientationchange", orientationChange);
    };
  }, []);

  function orientationChange() {
    let doc: any = document;
    if (doc.getElementsByClassName("header-wrapper")[0]) {
      setMainChatTop(
        doc.getElementsByClassName("header-wrapper")[0].clientHeight
      );
    }
    if (doc.getElementsByClassName("mobile-footer")[0]) {
      console.log("Flag 2", optedOut);

      setMainChatBottom(
        doc.getElementsByClassName("mobile-footer")[0].clientHeight
      );
    } else {
      setMainChatBottom("0px");
    }
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    getHeight();
    scrollTOBottom();
  }

  const calculateHeight = () => {
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setPaddingValTop(divpaddingTop);
    setPaddingValBottom(divpaddingbottom);
    setPaddingValRight(divpaddingleft);
    setPaddingValLeft(divpaddingright);

    console.log("Innerwidth" + window.innerHeight);

    if (
      document.getElementsByClassName("mobile-footer") &&
      document.getElementsByClassName("mobile-footer").length > 0
    )
      setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
    else setChatHeight(window.innerHeight - (divpaddingTop + divpaddingbottom));
    setChatwidth(window.innerWidth - (divpaddingright + divpaddingleft));
    scrollTOBottom();
  };
  const getHeight = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    setMobileFooterBottom(divpaddingbottom);
    setMobileHeaderTop(divpaddingTop);

    if (doc.getElementsByClassName("mainchat")[0]) {
      let ht = doc.getElementsByClassName("mainchat")[0].clientHeight;
      let footertop;

      if (doc.getElementsByClassName("mobile-footer")[0]) {
        footertop = doc.getElementsByClassName("mobile-footer")[0].offsetTop;
        if (config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName("mainchat")[0].style.height = "100%";
        } else {
        }
      } else {
        if (config.REACT_APP_IS_CROSS_PLATFORM) {
          doc.getElementsByClassName("mainchat")[0].style.height = "100%";
        } else {
        }
      }
    }
  };
  useEffect(() => {
    console.log(chatId);
    debugger;
    console.log("inchatId");
    if (showChat && chatId) {
      setHasMore(true);
      setAttachedFiles([]);
      getMessageData(props.messageData, false, 1);
      getUserInfo();
    }
    let doc: any = document;
    return () => {
      setMessageData([]);
    };
  }, [chatId, showChat]);
  const getNumberFromItem = (item) => {
    if (item) {
      if (item.AcctPh_Nbr) {
        if (item.AcctPh_Nbr.indexOf("+") >= 0) {
          return item.AcctPh_Nbr;
        } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
      } else return "";
    } else return "";
  };

  const optInOut = (e) => {
    if (e == "Y") {
      setOptedOut(false);
      console.log("optout", e);
    } else if (e == "N") {
      setOptedOut(true);
      console.log("optout", e);
    }
  };

  useEffect(() => {
    if (refreshChatComponent) {
      setAttachedFiles([]);
      getMessageData(props.messageData, false, 1);
      dispatch(setRefreshChatComponent(false));
    }
  }, [refreshChatComponent]);
  const sendMessage = (contactData) => {
    window.localStorage.setItem("sentFromWindow", "true");
    // });
    setDisableSendButton(true);
    console.log(contactData);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
    // setTempTemplateDataIfNoNumber(null);
    // if(!phone){
    //     setNoNumber(true)
    //     setShowDetails(true)
    //     dispatch(setShowErrorSnackBar(true));
    //     return;
    // }
    // setNoNumber(false)
    let formData = new FormData();

    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }

    formData.append("template", chatInput);
    formData.append("Campaign_ID", default_campaign_id);
    formData.append("wave_id", default_wave_id);
    formData.append("product_id", default_product_id);

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }
    if (attachedFiles.length > 0) {
      setShowAttachLoader(true);
      for (var i = 0; i < attachedFiles.length; i++) {
        let file = attachedFiles[i];
        formData.append("file", file);
      }
    }

    // sendMessageApi(formData,(res, msgData)=>{
    //     console.log(msgData)
    //     setAttachedFiles([]);
    //     setChatInput('')
    //    })
    checkWhatsappValidation(
      {
        recipient_id: chatId,
        recipient_phone: phone,
        role_id: chatIsPrescriber ? 0 : 1,
      },
      (res, msgData) => {
        if (res && msgData && !msgData.success) {
          setIsWhatsappTimeExpired(true);
        } else {
          setIsWhatsappTimeExpired(false);
          sendMessageApi(formData, (res, msgData) => {
            console.log(res);

            if (msgData) {
              if (msgData.message === "Inappropriate message body") {
                setDisableSendButton(false);
                setShowAttachLoader(false);
                console.log(msgData.message);
                let t: any = [];
                t.push(msgData.message);
                // errorMessages.map((x) => {
                //   t.push(x);
                //   return x;
                // });
                setErrorMessages(t);
                setTimeout(function () {
                  setErrorMessages([]);
                }, 2000);
              } else if (
                msgData.message ===
                "Conversation window closed, please send approved template first"
              ) {
                setDisableSendButton(false);
                setShowAttachLoader(false);
                console.log(msgData.message);
                let t: any = [];
                t.push(msgData.message);
                errorMessages.map((x) => {
                  t.push(x);
                  return x;
                });
                setErrorMessages(t);
                setTimeout(function () {
                  setErrorMessages([]);
                }, 2000);
              }
            } else if (res) {
              // console.log(msgData.message)
              setShowAttachLoader(false);
              // updateElSize('');
              setDisableSendButton(false);
              setAttachedFiles([]);
              setChatInput("");
              getMessageData(props.messageData, false, 1);
              dispatch(setRefreshChatList(true));
              dispatch(setShowSuccessSnackBar(true));
              let doc: any = document;
              doc.getElementById("footerinput").style.height = "0px";
              scrollTOBottom();
            } else {
              setDisableSendButton(false);
              setShowAttachLoader(false);
              setAttachedFiles([]);
              setChatInput("");
              dispatch(setShowErrorSnackBar(true));
            }
          });
        }
      }
    );
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  // send selected file based on channel type

  const selectedFileSendMsg = (fileData) => {
    let Urlstring: any;
    let tempChat: any;
    window.localStorage.setItem("sentFromWindow", "true");
    setDisableSendButton(true);
    let cData = contactData ? contactData : {};
    let phone = getNumberFromItem(contactData);
    let formData = new FormData();

    formData.append("recipient_id", chatId);
    formData.append("recipient_name", chatName);
    formData.append("recipient_phone", phone);
    if (chatIsPrescriber) {
      formData.append("physcian_id", chatId);
      formData.append("role_id", "0"); //for prescriber
    } else {
      formData.append("physcian_id", cData.Prescribers[0].Id);
      formData.append("role_id", "1"); //for prescriber
      formData.append("association_id", cData.Prescribers[0].Association_Id);
    }

    Urlstring = fileData.map((x: any) => {
      return x.Item_url.split(",").join(" ");
    });
    tempChat = chatInput + " " + Urlstring.join(" ");
    formData.append("template", tempChat);
    formData.append("Campaign_ID", default_campaign_id);
    formData.append("wave_id", default_wave_id);
    formData.append("product_id", default_product_id);
    formData.append("urls", Urlstring);

    if (!isSMS) {
      formData.append("channel", "SMS");
    } else {
      formData.append("channel", "whatsa");
    }

    sendMessageApi(formData, (res, msgData) => {
      if (msgData) {
        if (msgData.message === "Inappropriate message body") {
          setDisableSendButton(false);
          setShowAttachLoader(false);
          let t: any = [];
          t.push(msgData.message);
          // errorMessages.map((x) => {
          //   t.push(x);
          //   return x;
          // });
          setErrorMessages(t);
          setTimeout(function () {
            setErrorMessages([]);
          }, 2000);
        }
      } else if (res) {
        setDisableSendButton(false);
        setShowAttachLoader(false);
        setAttachedFiles([]);
        setChatInput("");
        getMessageData(props.messageData, false, 1);
        dispatch(setRefreshChatList(true));
        let doc: any = document;
        doc.getElementById("footerinput").style.height = "0px";
        dispatch(setShowSuccessSnackBar(true));
      } else {
        setDisableSendButton(false);
        setShowAttachLoader(false);
        setAttachedFiles([]);
        setChatInput("");
        dispatch(setShowErrorSnackBar(true));
      }
    });
  };

  if (props.showActiveChatPage) {
    return (
      <div
        className="mobileChatPage msg-component-div"
        style={{
          width: chatWidth + "px",
          height: chatHeight + "px",
          top: !isAndroid ? window.visualViewport?.offsetTop : undefined,
        }}
      >
        {showDetails && !unknown ? (
          isHospitalContact ? (
            <ContactDetialsModalGAM
              showChatIcon={false}
              showDetails={showDetails}
              showAddContactModal={(e, trueorFalse) => {
                setContactData(e);
                setAddNumberPopup(true);
              }}
              contact={contactData}
              handleCloseParent={(post: any, showDelete: any) => {
                setContactData(post);
                getUserInfo();
                setShowDetails(false);
                setDisableSendButton(false);
                setFinished(false);
              }}
              fetchDetails={true}
              showCallMakerModal={(e) => {
                console.log(e);
                setShowDetails(false);
                dispatch(setCallModalView(true));
              }}
            />
          ) : (
            <ContactDetialsModal
              showChatIcon={false}
              showDetails={showDetails}
              isFromChat = {true}
              showAddContactModal={(e, trueorFalse) => {
                setContactData(e);
                setAddNumberPopup(true);
              }}
              contact={contactData}
              handleCloseParent={(post: any, showDelete: any) => {
                setContactData(post);
                getUserInfo();
                setShowDetails(false);
                setDisableSendButton(false);
                setFinished(false);
              }}
              fetchDetails={true}
              showCallMakerModal={(e) => {
                console.log(e);
                setShowDetails(false);
                dispatch(setCallModalView(true));
              }}
              editted={() => {
                getMessageData(props.messageData, false, 1);
              }}
            />
          )
        ) : null}

        <CallModal
          data={contactData}
          handleCloseParent={(e) => {
            console.log(e);
            setShowDetails(false);
            dispatch(setCallModalView(false));
          }}
        />
        <MediaChatChannelModal
          show={mediaChannelPopup}
          handleClose={() => {
            setMediaChannelPopup(false);
          }}
          selectedFileSendMsg={selectedFileSendMsg}
        />
        {openSaveContacts ? (
          isHospitalContact ? (
            <SaveContactsForChatGAM
              unknownid={chatId}
              number={chatName}
              deletedOthers={deletedOthers}
              show={openSaveContacts}
              handleCloseParent={(torf, data: any) => {
                console.log(data);
                saveUnknown(data);
                setOpenSaveContacts(false);
              }}
            />
          ) : (
            <SaveContactsForChat
              unknownid={chatId}
              number={chatName}
              deletedOthers={deletedOthers}
              show={openSaveContacts}
              handleCloseParent={(torf, data: any) => {
                console.log(data);
                if (config.REACT_APP_UNKNOWN_FLOW_IS_SAME_REP_NUMBER == "N") {
                  saveUnknown(data);
                }
                setOpenSaveContacts(false);
              }}
            />
          )
        ) : null}
        <PreApprovedTemplates
          show={showPreApproveTemplates}
          selectedTemplate={(e) => {
            selectedTemplate(e, contactData);
          }}
          handleCloseParent={(e) => {
            setPreApproveTemplates(false);
            window.localStorage.removeItem("activeCampaign");
            window.localStorage.removeItem("activeWave");
            window.localStorage.removeItem("activeProduct");
          }}
          contact={contactData}
        />
        <div className="header-wrapper">
          {/* Add Key vault  */}
          <div
            className="besttime-banner"
            style={{ display: BestTimeFlag === "Y" ? "block" : "none" }}
          >
            <h5 className="bannertext m-0">
              <b> {t("Best time to connect: 10:00 AM - 03:30 PM")}</b>
            </h5>
          </div>

          <div className="header d-flex">
            <Link
              onClick={() => {
                dispatch(setChatShowChat(false));
              }}
              to={window.location.href == "/Home" ? "/Chat" : pageSelected}
            >
              <i className="fas fa-chevron-left i-2"> </i>
            </Link>
            <div className="avatar avt-2 ms-3 m-2">
              <Avatar
                imageType={type}
                avatarHeight="2.5rem"
                avatarWidth="2.5rem"
                presOrAssoc={chatName}
              />
            </div>
            <div
              className="contact-details m-2"
              onClick={
                config.REACT_APP_SHOW_INFO_BTN == "N" &&
                ((!deletedOthers && finished) || (!deletedOthers && unknown))
                  ? () => {
                      setShowDetails(true);
                    }
                  : () => {}
              }
            >
              <h5 className="contacttext">
                {chatName
                  ? truncate(getFormatedPhoneNumber(chatName), 15)
                  : "Unknown"}
              </h5>
            </div>
            <div className="headerright">
              {messageChannelType === true &&
              config.REACT_APP_SHOW_CHANNEL_TYPE_TOGGLE_BTN === "Y" ? (
                <label className="autopilot-switch font-sm me-3">
                  <input
                    type="checkbox"
                    className="font-sm"
                    id="msg-wtsappChechedId"
                    checked={isSMS}
                    onChange={(e) => {
                      setIsSms(e.target.checked);
                    }}
                  />
                  <span
                    id="msg-wtsapptoggle"
                    className="b-slider b-round"
                  ></span>
                </label>
              ) : null}

              {noNumber &&
              !changeTerritoryId &&
              !deletedOthers &&
              finished &&
              !numberAssigned ? null : !deletedOthers &&
                finished &&
                !unknown &&
                !(
                  changeTerritoryId &&
                  config.REACT_APP_ABS_CONFIG_ALLOWCALL == "N"
                ) ? (
                <Tooltip title={t("Call")} arrow>
                  <button
                    className="header-callbtn"
                    onClick={() => {
                      !noNumber && dispatch(setCallModalView(true));
                    }}
                  >
                    <CallIcon style={{ width: "20px", height: "20px" }} />
                  </button>
                </Tooltip>
              ) : null}

              {unknown ? (
                !chatIsPrescriber ? (
                  <Tooltip title="" arrow>
                    <SaveIcon
                      onClick={() => {
                        setOpenSaveContacts(true);
                      }}
                      style={{
                        fill: "var(--primary)",
                        fontSize: "2.5rem",
                        marginLeft: "1rem",
                      }}
                    />
                  </Tooltip>
                ) : null
              ) : config.REACT_APP_SHOW_INFO_BTN == "Y" ? (
                <div>
                  {((changeTerritoryId == true && type == "ASSPRS") ||
                    changeTerritoryId == false) &&
                  finished ? (
                    <Tooltip title={t("")} arrow>
                      <i
                        id="infoBtnId"
                        data-v-4d521fc4=""
                        data-name="info"
                        data-tags=""
                        data-type="info"
                        className="feather feather--info i-2p5 p-0"
                        onClick={() => {
                          setShowDetails(true);
                        }}
                      >
                        <svg
                          style={{
                            color: "#734bd1",
                            verticalAlign: "sup",
                            display: "flex",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-info feather__content"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="12" y1="16" x2="12" y2="12"></line>
                          <line x1="12" y1="8" x2="12.01" y2="8"></line>
                        </svg>
                      </i>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {noNumber &&
        !changeTerritoryId &&
        !deletedOthers &&
        finished &&
        !numberAssigned &&
        resData ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Assign number to reply")}.</li>
            </ul>
          </div>
        ) : null}
        {changeTerritoryId &&
        !deletedOthers &&
        type != "UNKN" &&
        config.REACT_APP_ABS_CONFIG_SHOWBANNER == "Y" ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              {type != "ASSPRS" ? (
                <li>{t("Chat unavailable, territory/HCP mismatch")}.</li>
              ) : (
                <li>{t("Territory/HCP mismatch")}.</li>
              )}
            </ul>
          </div>
        ) : null}
        {(unknown || type == "UNKN") &&
        !deletedOthers &&
        !changeTerritoryId &&
        !deletedPres ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Save contact to reply")}.</li>
            </ul>
          </div>
        ) : null}
        {(deletedOthers || deletedPres) && finished && !resData ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Contact has been deleted")}.</li>
            </ul>
          </div>
        ) : null}
        {optedOut &&
        !noNumber &&
        finished &&
        !deletedOthers &&
        !changeTerritoryId &&
        resData ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("Recipient Opted Out")}.</li>
            </ul>
          </div>
        ) : null}
        {isNumberLandline ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>{t("This is a landline number")}</li>
            </ul>
          </div>
        ) : null}
        {isWhatsappTimeExpired &&
        !changeTerritoryId &&
        !deletedOthers &&
        !optedOut &&
        !(noNumber && !deletedOthers && finished && !numberAssigned) &&
        resData ? (
          <div className="limit-msg error-footer optOut">
            <ul>
              <li>
                {t(
                  "Conversation window closed, please send approved template first"
                )}
              </li>
            </ul>
          </div>
        ) : null}
        {showAttachLoader && <span className="loader"></span>}

        <div
          className="shadowin  mainchat"
          id="scrollableDiv"
          style={{
            overflowY: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={messageData.length}
            next={() => {
              console.log("next");
              setHasMore(false);
            }}
            refreshFunction={() => {
              console.log("reverse");
            }}
            inverse={true} //
            style={{
              display: "flex",
              overflow: "hidden",
              flexDirection: "column",
            }}
            hasMore={hasMore}
            loader={
              <div className="spinnerCircle">
                <CircularProgress color="inherit" size={"2rem"} />
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            {messageData.map((x: any) => (
              <TestMessage
                message={x}
                key={x["Recipient Msg Start"]}
                msgPusherSubscriber={pusherMsgProps}
                handleImgPopup={(imgdisurl) => handleImgPopup(imgdisurl)}
                avatarchatName={chatName}
              />
            ))}
          </InfiniteScroll>
        </div>
        {errorMessages.length > 0 ? (
          <div
            className="limit-msg error-footer error-footer-mobile"
            style={{
              top: config.REACT_APP_IS_CROSS_PLATFORM
                ? disableSendButton
                  ? "-7.68rem"
                  : "-4.5rem"
                : "0",
            }}
          >
            <ul>
              {errorMessages.map((error: String) =>
                error === "" ? null : (
                  <li>
                    {t(`${error}`, { error })} <br />
                  </li>
                )
              )}
            </ul>
          </div>
        ) : null}

        {
       (showchatfooter || (!optedOut)) && (!contactOptedOut) &&
        !unknown &&
        !noNumber &&
        !isNumberLandline &&
        !(changeTerritoryId && config.REACT_APP_ABS_CONFIG_ALLOWCHAT == "N") ? (
          <div
            className="footer mobile-footer"
            style={{
              backgroundImage: `url(${Background})`,
              bottom: "0px",
            }}
          >
            <div
              className="insidefooter d-flex"
              style={{ color: "var(--primary-color)" }}
            >
              <div style={{ display: "none" }}> {errorMessages.length}</div>
              <div
                onClick={() => {
                  console.log("hi");
                  setPreApproveTemplates(true);
                }}
              >
                <i className="btn far fa-newspaper i-2 get-template-btn"></i>
              </div>
              {config.REACT_APP_ATTACHMENT_ENABLED == "Y" && (
                <button className="btn" type="button">
                  <Popover
                    content={getUploadContent}
                    placement="bottomLeft"
                    trigger="click"
                  >
                    <i className="fas fa-paperclip i-2"></i>
                  </Popover>
                </button>
              )}
              <label>
                <input
                  type="file"
                  id="attachedfiles"
                  name="attachedfiles"
                  className="custom-file-input"
                  multiple
                  accept="application/pdf, application/vcard, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory, image/bmp, image/tiff, image/jpeg, image/jpg, image/gif, image/png, video/H264, video/H263-2000, video/H263-1998, video/H263, video/H261, video/3gpp-tt, video/3gpp2, video/3gpp, video/webm, video/quicktime, video/mp4, video/mpeg, audio/basic, audio/L24, audio/mp4, audio/mpeg, audio/ogg, audio/vnd.rn-realaudio, audio/vnd.wave, audio/3gpp, audio/3gpp2, audio/ac3, audio/vnd.wave, audio/webm, audio/amr-nb, audio/amr"
                  style={{ display: "none" }}
                  onChange={getFiles}
                />
              </label>

              <textarea
                id="footerinput"
                placeholder={t(`Type your message`) + "..."}
                onPaste={resizeInputBox}
                value={chatInput}
                onChange={(e) => {
                  const emojiRegex =
                    /\uD83C\uDFF4(\uDB40[\uDC61-\uDC7A])+\uDB40\uDC7F|(\ud83c[\udde6-\uddff]){2}|([\#\*0-9]\ufe0f?\u20e3)|(\u00a9|\u00ae|[\u203c-\u3300]|[\ud83c-\ud83e][\ud000-\udfff])((\ud83c[\udffb-\udfff])?(\ud83e[\uddb0-\uddb3])?(\ufe0f?\u200d([\u2000-\u3300]|[\ud83c-\ud83e][\ud000-\udfff])\ufe0f?)?)*/g;
                  let inputValue = e.target.value;
                  let sanitizedValue: string;
                  if (emojiRegex.test(inputValue)) {
                    sanitizedValue = inputValue?.replace(emojiRegex, "");
                    setErrorMessages(["Emojis are not allowed"]);
                    setTimeout(function () {
                      setErrorMessages([]);
                    }, 1000);
                  } else {
                    sanitizedValue = inputValue;
                  }
                  setChatInput(sanitizedValue);
                }}
                rows={1}
                maxLength={1500}
              ></textarea>
              <div className="mobileFooterSend">
                <button
                  className="btn btn-light sendbutton"
                  disabled={
                    ((chatInput.length <= 0
                      ? true
                      : !chatInput.replace(/\s/g, "").length
                      ? true
                      : false) &&
                      attachedFiles.length <= 0) ||
                    attachedFiles.length > 10 ||
                    disableSendButton ||
                    isWhatsappTimeExpired ||
                    optedOut
                  }
                  onClick={() => {
                    sendMessage(contactData);
                  }}
                >
                  <i
                    data-v-4d521fc4=""
                    data-name="send"
                    data-tags="message,mail,email,paper airplane,paper aeroplane"
                    data-type="send"
                    className="feather feather--send i-2"
                  >
                    <svg
                      style={{ color: "var(--primary-color)" }}
                      data-v-4d521fc4=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-send feather__content"
                    >
                      <line x1="22" y1="2" x2="11" y2="13"></line>
                      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                    </svg>
                  </i>
                </button>
              </div>
            </div>

            {attachedFiles.length > 0 ? (
              <div className="attached-media grid-container">
                {attachedFiles.map((file: any, index) => (
                  <div key={file.name + "-" + file.type + "-" + index}>
                    <div className="media-tb">
                      <div className="media-left d-flex">
                        <i className="far fa-file-alt"></i>
                        <p>{(file.size / 1e6).toFixed(2)}</p>
                      </div>

                      <div>{file.name}</div>

                      <div>
                        <i
                          className="fas fa-times"
                          onClick={() => removeFile(index)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
        {showImgPopup ? (
          <div className="mobileimagePopup">
            <div className="mobileouterdiv">
              <div className="mobileimageBox">
                <a
                  id="mobilecloseBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => setShowImgPopup("")}
                >
                  <i className="fa fa-times"></i>
                </a>
                <img src={showImgPopup} alt="" />
                <a
                  id="mobiledownloadBtn"
                  className="mobilepopupBtn"
                  onClick={(e) => downloadWithAxios(showImgPopup)}
                >
                  <i className="fa fa-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
        ) : null}
        <ContactAddPopup
          showAddContactModal={addNumberPopup}
          data={contactData}
          redirectPage={false}
          handleCloseParent={(e) => {
            setShowDetails(false);
            setAddNumberPopup(false);
            getUserInfo();
          }}
        />
      </div>
    );
  } else return null;
}

export default NewMobileChatComponent;
