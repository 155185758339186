import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import {
  Chip,
  CircularProgress,
  Fade,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Paper,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stack from "@mui/material/Stack";
import {
  editOutOfOffice,
  getOutOfOffice,
  setOutOfOffice,
} from "../../apis/autopilotApi/autopilotApi";
import Radio, { RadioProps } from "@mui/material/Radio";
import styled from "styled-components";
import "./OutOfOfficeModal.css";
import DropdownInupt from "../shared/Dropdown";
import config from "../../env.json";
import { getEmployeeList } from "../../apis/contentSharingApi/contentsharing";
import { useDebounce } from "usehooks-ts";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme?.palette?.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme?.palette?.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme?.palette?.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme?.palette?.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme?.palette?.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primcolor
    ? document.documentElement.style.getPropertyValue("--primary-color")
    : "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor
      ? document.documentElement.style.getPropertyValue("--primary-color")
      : "var(--primary-color)",
  },
});

const OutOfOfficeModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { chatId, chatName, chatIsPrescriber } = useSelector(
    (state: any) => state.chat
  );
  const [mobileStyle, setMobileStyle] = useState(false);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);

  const [endDate, setEndDate] = React.useState();
  const [startDate, setstartDate] = React.useState();
  const [startTime, setstartTime] = useState(moment());
  const [endTime, setendTime] = useState(moment());
  const [lessStarttimeError, setLessStarttimeError] = useState(false);
  const [lessEndDateError, setlessEndDateError] = useState(false);
  const [lessEndtimeError, setLessEndtimeError] = useState(false);
  const [equalStartEndtimeError, setEqualStartEndtimeError] = useState(false);
  const [selectedView, setSelectedView] = useState("new");
  const [selectedMessageType, setselectedMessageType] = useState({
    type: "BACKUP",
  });
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [backupEmp, setbackupEmp] = useState("");
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [showOnce, setShowOnce] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [empDataHidden, setEmpDataHidden] = useState(true);
  const [backupEmpId, setBackupEmpId] = useState(0);
  const [messageText, setMessageText] = useState("");
  const [searchEmpDisabled, setSearchEmpDisabled] = useState(false);
  const [oooData, setOOOData] = useState<any>([]);
  const debouncedValue = useDebounce<string>(backupEmp, 350);
  const [messageTypes, setMessageTypes] = useState<any>(
    config.REACT_APP_OOO_MESSAGE_TYPE.split(",").map((code: string) => ({
      type: code,
    }))
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [oooId, setoooId] = useState(0);
  const [backUpEmployeeError, setBackUpEmployeeError] = useState(false);
  const [errorData, setErrorData] = useState("");

  useEffect(() => {
    getEmployeeData(debouncedValue);
  }, [debouncedValue]);

  const getOutOfOfficeData = () => {
    setEmployeeLoading(true);

    getOutOfOffice((res) => {
      if (res) {
        setEmployeeLoading(false);
        setOOOData(res.data);
      }
    });
  };

  async function handleSave(activeFlag: string) {
    setIsEditMode(!isEditMode);
    resetValidation();
    let s_time = moment(startTime).format("HH:mm");
    let e_time = moment(endTime).format("HH:mm");
    const avail_start = moment(
      moment(startDate).format("YYYY-MM-DD") + " " + s_time
    );
    const newavailstrt = moment(avail_start).toISOString();
    const avail_end = moment(
      moment(endDate).format("YYYY-MM-DD") + " " + e_time
    );
    const newavailend = moment(avail_end).toISOString();
    const newBackupId = backupEmpId;
    const newMsgText = messageText;
    const newMsgType = selectedMessageType.type;
    const id = oooId;
    const flag = activeFlag;
    editOutOfOffice(
      {
        newBackupId,
        newMsgText,
        newMsgType,
        newavailstrt,
        newavailend,
        id,
        flag,
      },
      (res: any) => {
        if (res) handleClose();
      }
    );
  }

  const handleCancelEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleOOOEdit = (e) => {
    console.log("Edit OOO Called ");
    const button = document.getElementById("newRadio");
    if (button) button.click();
    if (!isEditMode) setIsEditMode(!isEditMode);
    setSelectedView("new");
    setOOOFormData(e);
  };

  const setOOOFormData = (e) => {
    setoooId(e.Id);
    setselectedMessageType({ type: e.OutOfOfficeMsg_Typ });
    setMessageText(e.Custom_Msg);
    setstartDate(e.OutofOfficeStartTime);
    setEndDate(e.OutofOfficeEndTime);
    const start = new Date(e.OutofOfficeStartTime).getTime();
    const end = new Date(e.OutofOfficeEndTime).getTime();
    console.log("Nikhil" + start + end);
    setstartTime(moment(start));
    setendTime(moment(end));
  };

  const getEmployeeData = (value) => {
    if (value.length >= 2) {
      setEmployeeLoading(true);

      getEmployeeList({ page: 0, pageSize: 10, search: value }, (res) => {
        if (res) {
          console.log(res.data.data);
          setEmpData(res.data.data);
          setEmployeeLoading(false);
          setEmpDataHidden(false);
        } else {
          setEmpDataHidden(true);
        }
      });
    }
  };

  const validateForm = () => {
    let s_time = moment(startTime).format("HH:mm");
    let e_time = moment(endTime).format("HH:mm");
    const avail_start = moment(
      moment(startDate).format("YYYY-MM-DD") + " " + s_time
    );
    const avail_end = moment(
      moment(endDate).format("YYYY-MM-DD") + " " + e_time
    );
    if (backupEmpId === 0) {
      setBackUpEmployeeError(true);
      setErrorData(t(getErrorMessage("BackUpEmpError")));
      return false;
    } else if (
      moment(startDate).format("YYYY-MM-DD") >
      moment(endDate).format("YYYY-MM-DD")
    ) {
      setlessEndDateError(true);
      setErrorData(t(getErrorMessage("LessEndDate")));
      return false;
    } else if (avail_start.isBefore(moment())) {
      setErrorData(t(getErrorMessage("StartTimeError")));
      setLessStarttimeError(true);
      return false;
    } else if (avail_end < avail_start) {
      setErrorData(t(getErrorMessage("EndTimeError")));
      setLessEndtimeError(true);
      return false;
    } else if (avail_start.diff(avail_end, "seconds") === 0) {
      setErrorData(t(getErrorMessage("StartEndTimeEqualError")));
      setEqualStartEndtimeError(true);
      return false;
    } else {
      return true;
    }
  };

  const resetValidation = () => {
    setLessStarttimeError(false);
    setLessEndtimeError(false);
    setBackUpEmployeeError(false);
    setEqualStartEndtimeError(false);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    resetValidation();

    let s_time = moment(startTime).format("HH:mm");
    let e_time = moment(endTime).format("HH:mm");
    console.log(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      startTime,
      endTime
    );
    const avail_start = moment(
      moment(startDate).format("YYYY-MM-DD") + " " + s_time
    );
    const newavailstrt = moment(avail_start).toISOString();
    const avail_end = moment(
      moment(endDate).format("YYYY-MM-DD") + " " + e_time
    );
    const newavailend = moment(avail_end).toISOString();
    console.log(newavailstrt, newavailend);
    const newBackupId = backupEmpId;
    const newMsgText = messageText;
    const newMsgType = selectedMessageType.type;
    let isFormValid: boolean = await validateForm();
    if (isFormValid) {
      setOutOfOffice(
        { newBackupId, newMsgText, newMsgType, newavailstrt, newavailend },
        (res: any) => {
          if (res) handleClose();
        }
      );
    }
  }

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        sx={{
          "&:hover": {
            bgcolor: "transparent",
          },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  return (
    <>
      <Modal
        className="free-time-modal"
        show={show}
        backdrop="static"
        closeAfterTransition
      >
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {t("Out Of Office")}
          </h5>
          <span
            onClick={() => {
              handleClose();
            }}
            className="close"
          >
            ×
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="ooo-main">
            <div className="ooo-options">
              <RadioGroup
                row
                defaultValue="new"
                name="ooo-radios"
                onClick={(e: any) => {
                  setSelectedView(e.target.value);
                  if (e.target.value === "existing") {
                    getOutOfOfficeData();
                  }
                }}
              >
                <FormControlLabel
                  value="new"
                  id="newRadio"
                  control={<BpRadio />}
                  label={isEditMode ? "Edit" : "Create"}
                />
                <FormControlLabel
                  value="existing"
                  control={<BpRadio />}
                  label="View all"
                />
              </RadioGroup>
            </div>

            {selectedView === "new" && (
              <>
                <form onSubmit={handleSubmit}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={1}>
                      {messageTypes.length > 1 ? (
                        <div className="ooo-dropdown">
                          <DropdownInupt
                            activeValue={selectedMessageType}
                            disableColored={false}
                            keepPlaceHolderAtStart={false}
                            placeHolder={t("Select a type")}
                            label="Message Type*"
                            data={[{ type: "BACKUP" }]}
                            id=""
                            name="type"
                            func={(e) => {
                              setselectedMessageType(e);
                            }}
                          />
                        </div>
                      ) : null}

                      {selectedMessageType.type === "CUSTOM" && (
                        <div>
                          <TextField
                            className="roundborder inputEnable"
                            onChange={(e) => {
                              setMessageText(e.target.value);
                            }}
                            value={messageText}
                            label={t("Message Text") + "*"}
                            placeholder={t("Enter the message here")}
                            color="secondary"
                            focused
                            autoComplete="off"
                            inputProps={{ maxLength: 300 }}
                          />
                        </div>
                      )}

                      {selectedMessageType.type === "BACKUP" && (
                        <div>
                          <div
                            className="secPresDiv"
                            style={
                              secPrescribers.length > 0
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {secPrescribers?.map((x: any) => (
                              <Chip
                                key={x.Id}
                                id={x.Id}
                                label={x.EmployeeFull_Nm}
                                onClick={() => {}}
                                onDelete={() => {
                                  setSecPrescribers([]);
                                  setSearchEmpDisabled(false);
                                }}
                              />
                            ))}
                          </div>

                          <div>
                            <TextField
                              value={backupEmp}
                              label={t("Employee Name*")}
                              focused
                              disabled={searchEmpDisabled}
                              InputProps={
                                employeeLoading
                                  ? {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CircularProgress
                                            color={"inherit"}
                                            size={"1rem"}
                                          />
                                        </InputAdornment>
                                      ),
                                    }
                                  : undefined
                              }
                              placeholder={t("Search for an Employee")}
                              color="secondary"
                              id="messageBox"
                              onChange={(e) => {
                                getEmployeeData(debouncedValue);
                                setShowOnce(true);
                                const result = e.target.value.replace(
                                  /[^A-Za-z0-9 _.]/gi,
                                  ""
                                );
                                if (result.length > 0 && result) {
                                  setbackupEmp(result);
                                  setBackUpEmployeeError(false);
                                }
                              }}
                              autoComplete="off"
                              aria-expanded="true"
                              aria-controls="presList"
                              className={
                                "associateInput inputEnable ooo-employeeName"
                              }
                            />
                            {backUpEmployeeError ? (
                              <p className="form-error-message">
                                {t(errorData)}
                              </p>
                            ) : null}
                            {showOnce && empData.length > 0 ? (
                              <Paper hidden={empDataHidden}>
                                <div id="presList">
                                  {empData.length > 0 &&
                                    empData.map((x: any) => (
                                      <div
                                        key={x.Id}
                                        onClick={() => {
                                          setSecPrescribers([x]);
                                          setBackupEmpId(x.Id);
                                          setbackupEmp("");
                                          setEmpData([]);
                                          setEmpDataHidden(true);
                                          setSearchEmpDisabled(true);
                                        }}
                                      >
                                        {x.EmployeeFull_Nm}
                                      </div>
                                    ))}
                                </div>
                              </Paper>
                            ) : (
                              employeeLoading == false && (
                                <Paper hidden={empDataHidden}>
                                  <div id="presList">
                                    <div>
                                      {t("No suggestion list is displayed")}
                                    </div>{" "}
                                  </div>
                                </Paper>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      <div className="StartDateDiv">
                        <InputLabel className="genericCalDropdown_label">
                          {t("Start Date") + "*"}
                        </InputLabel>
                        <DesktopDatePicker
                          className="StartDate"
                          inputFormat="MM/dd/yyyy"
                          value={startDate}
                          disablePast={true}
                          onChange={(e: any) => setstartDate(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>

                      <div className="form-group form-group--third floating-form">
                        <InputLabel className="genericCalDropdown_label">
                          {t("Start Time") + "*"}
                        </InputLabel>
                        <TimePicker
                          className="form-control font-md calendar-starttime"
                          value={startTime}
                          showSecond={false}
                          use12Hours={true}
                          minuteStep={15}
                          defaultValue={moment(startTime)}
                          allowEmpty={false}
                          onChange={(e: any) => setstartTime(e)}
                          inputReadOnly={true}
                        />

                        {lessStarttimeError ? (
                          <p className="form-error-message">{t(errorData)}</p>
                        ) : null}
                      </div>

                      <div className="StartDateDiv">
                        <InputLabel className="genericCalDropdown_label">
                          {t("End Date") + "*"}
                        </InputLabel>
                        <DesktopDatePicker
                          className="EndDate"
                          inputFormat="MM/dd/yyyy"
                          value={endDate}
                          disablePast={true}
                          onChange={(e: any) => setEndDate(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        {lessEndDateError ? (
                          <p className="form-error-message">{t(errorData)}</p>
                        ) : null}
                      </div>

                      <div className="form-group form-group--third floating-form">
                        <InputLabel className="genericCalDropdown_label">
                          {t("End Time") + "*"}
                        </InputLabel>
                        <TimePicker
                          className="form-control font-md calendar-starttime"
                          value={endTime}
                          showSecond={false}
                          use12Hours={true}
                          minuteStep={15}
                          defaultValue={moment(endTime)}
                          allowEmpty={false}
                          onChange={(e: any) => setendTime(e)}
                          inputReadOnly={true}
                        />

                        {lessEndtimeError ? (
                          <p className="form-error-message">{t(errorData)}</p>
                        ) : null}
                        {equalStartEndtimeError ? (
                          <p className="form-error-message">{t(errorData)}</p>
                        ) : null}
                      </div>
                    </Stack>
                  </LocalizationProvider>
                  {!isEditMode ? (
                    <div className="submitform">
                      <button
                        type="submit"
                        className="submit-btn btn rounded-primary-40 mx-auto set-freetime"
                      >
                        <span> {t("Set Out Of Office")}</span>
                      </button>
                    </div>
                  ) : null}
                </form>
                {isEditMode && (
                  <div className="ooo-editoptions">
                    <button
                      type="button"
                      onClick={() => {
                        handleCancelEdit();
                      }}
                      className="btn rounded-primary-40 ooo-cancel"
                    >
                      <span> {t("Cancel")}</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleSave("N");
                      }}
                      className="btn rounded-primary-40 ooo-cancel"
                    >
                      <span> {t("Delete")}</span>
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleSave("Y");
                      }}
                      className="btn rounded-primary-40 ooo-cancel"
                    >
                      <span> {t("Save")}</span>
                    </button>
                  </div>
                )}
              </>
            )}

            {selectedView === "existing" && (
              <div>
                <ul className="ooo-view-all-main">
                  {oooData.map((data: any, key: any) => (
                    <li
                      onClick={(e) => {}}
                      id={"oooDetails-" + key}
                      className="ooo-list"
                    >
                      <div className="ooo-list-item">
                        {/* Start time */}
                        <div className="ooo-starttime">
                          Start Time :{" "}
                          {moment(data.OutofOfficeStartTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                        {/* End time */}
                        <div className="ooo-endtime">
                          End Time :{" "}
                          {moment(data.OutofOfficeEndTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                        {/* Message */}
                        <div className="ooo-msg-text">
                          <span>{`${data.OutOfOfficeMsg_Typ} message: ${data.Custom_Msg}`}</span>
                        </div>
                        {/* Edit */}
                        <button
                          className="mx-auto ooo-edit"
                          onClick={(e) => {
                            handleOOOEdit(data);
                          }}
                        >
                          <span> {t("Edit")}</span>
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OutOfOfficeModal;
