import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  setComposeEmailView,
  setEmailContentView,
  setEmailInboxView,
  setSentEmailView,
} from "../../app/slice/emailSubMenuSlice";
import { deleteDraftMail, getMailsByName } from "../../apis/Email/Email";
import Spinner from "../../layouts/spinner/Spinner";
import { useEffect } from "react";
import parse from "html-react-parser";
import {
  setShowErrorSnackBar,
  setShowSuccessSnackBar,
} from "../../app/slice/personalisationSlice";

export default function DraftList(props) {
  const dispatch = useDispatch();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [data, setData] = React.useState<any>([]);
  const [inboxMails, setinboxMails] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const getDraftView = useSelector((state: any) => state.email.showDraft);
  const [apicalled, setapicalled] = React.useState(false);

  const openEmail = () => {
    setOpen(true);
  };

  const fetchData = () => {
    getMailsByName("DRAFT", (resp) => {
      if (resp) {
        setData(resp);
        console.log("Emailds", resp);
        setapicalled(!apicalled);
        console.log("data::::::::::", data);
      }
    });
  };

  function truncate(source, size) {
    console.log(source);
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  const convertHtml = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    console.log(body_element.innerHTML);
    let newString = body_element.innerHTML;
    let strippedHtml = newString.replace(/<[^>]+>+/g, "");
    console.log(strippedHtml);
    let strippedHtml1 = strippedHtml.replace(/\&nbsp;/g, "");
    let finalEmailText = strippedHtml.includes("From")
      ? strippedHtml1.split("From")[0]
      : strippedHtml;
    return finalEmailText;
  };

  useEffect(() => {
    dispatch(setSentEmailView(false));
    dispatch(setEmailInboxView(false));
    dispatch(setEmailContentView(true));
    console.log(props);
    if (!apicalled && props) {
      fetchData();
    }
  }, [getDraftView]);
  const deleteMail = (info) => {
    deleteDraftMail(info.RefEmail_Id, (res) => {
      console.log(res);
      if (res) {
        dispatch(setShowSuccessSnackBar(true));
        getMailsByName("DRAFT", (resp) => {
          if (resp) {
            setData(resp);
            console.log("Emailds", resp);
            setapicalled(!apicalled);
            console.log("data::::::::::", data);
          }
        });
      } else {
        dispatch(setShowErrorSnackBar(true));
      }
    });
  };
  return (
    <div className="containter-div" data-testid="draft-list">
      <div
        className="chatlistbackground"
        id="scrollableDiv"
        style={{
          height: "100vh",
          width: "100%",
          overflow: "auto",
          display: "flex",
        }}
      >
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            margin: "20",
          }}
        >
          <InfiniteScroll
            style={{
              height: "100vh",
            }}
            dataLength={data?.length}
            next={() => {}}
            hasMore={false}
            scrollableTarget="scrollableDiv"
            loader={<Spinner />}
          >
            {data?.map((info: any) => (
              <div key={info?.EmailConversation_Id}>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => {
                    props.handleEmailSelect(info);
                    dispatch(setComposeEmailView(false));
                    dispatch(setEmailContentView(true));
                  }}
                >
                  <ListItemText
                    primary={truncate(parse(info.EmailTo_Email), 30)}
                    secondary={
                      <>
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {truncate(
                              parse(info?.EmailConversation_Subject),
                              25
                            )}
                          </Typography>
                        </React.Fragment>
                        <p>{truncate(convertHtml(info.Email_Bdy), 20)}</p>
                      </>
                    }
                  />
                  <ListItemIcon onClick={() => deleteMail(info)}>
                    <DeleteOutlinedIcon sx={{ fontsize: 16 }} />
                  </ListItemIcon>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </div>
  );
}
