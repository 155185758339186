import { useEffect, useState } from "react";
import "./Calendar.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CalendarComponent from "../../components/tabs/CalendarComponent";
import { callSyncApi } from "../../apis/calendarApi/calendarApi";
import Requests from "../../components/tabs/Requests";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../env.json";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import Pusher from "pusher-js";

export default function Calendar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("1");
  const [outlookval, setOutlookVal] = useState(false);
  const [syncAni, setSyncAni] = useState(false);
  const [reqCount, setReqCount] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [autorefresh, setAutoRefresh] = useState(true);
  const pageTitle = useSelector((state: any) => state.sideBar.title);
  const [errorMessages, setErrorMessages] = useState<String[]>([]);
  const RepId = useSelector((state: any) => state.authApi.Id);

  useEffect(() => {
    RequestCount();
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, []);
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "initial");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [pageTitle]);
  const handleChange = (event, newValue) => {
    setAutoRefresh(true);
    setValue(newValue);
  };

  const handleSync = () => {
    setSyncAni(true);
    setRefresh(false);
    callSyncApi((res) => {
      if (res) {
        setSyncAni(false);
        if (res && res.error) {
          let t: any = [];
          errorMessages.map((x: any) => {
            t.push(x);
            return x;
          });

          t.push(res.data.message);
          setErrorMessages(t);
          setTimeout(function () {
            setErrorMessages([]);
          }, 2000);
        } else {
          setRefresh(true);
        }
      } else {
        setSyncAni(false);
      }
    });
    setOutlookVal(true);
  };

  useEffect(() => {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      cluster: config.REACT_APP_PUSHER_CLUSTER,
    });

    const channel = pusher.subscribe("event_update_request");
    channel.unbind("event_update_request_" + RepId);
    RequestCount();
    channel.bind("event_update_request_" + RepId, (data: any) => {
      RequestCount();
    });
    return () => {
      pusher.unsubscribe("event_update_request");
    };
  }, [RepId, reqCount]);

  const RequestCount = () => {
    axios
      .get(
        config.REACT_APP_CALENDAR_API_BASE +
          "/calendarevent?PageSize=10000&Page=0&Status_Val=request"
      )
      .then((res) => {
        let count = res.data.data.length;
        setReqCount(count);
        setAutoRefresh(true);
      })
      .catch((err) => {
        console.log("Freetime Error:" + err);
      });
  };
  return (
    <div className="calendar-container">
      <TabContext value={value}>
        <Box
          className="CalendarBox"
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <TabList
            className="calendarTablist"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label={t("Calendar")} className="calendarTab" value="1" />
            <Tab
              label={
                <Badge badgeContent={reqCount} color="error">
                  {t("Requests")}
                </Badge>
              }
              className="requestTab"
              value="2"
            />

            <div>
              <Tooltip title={t("Sync")} arrow>
                {syncAni ? (
                  <i
                    id="sync1"
                    onClick={handleSync}
                    className="fas fa-sync m-3 lg primary Sync syncAni"
                  ></i>
                ) : (
                  <i
                    id="sync1"
                    onClick={handleSync}
                    className="fas fa-sync m-3 lg Sync primary"
                  ></i>
                )}
              </Tooltip>
            </div>
          </TabList>
        </Box>
        <TabPanel value="1" className="calendarTabPanel">
          <CalendarComponent
            refresh={refresh}
            stoprefresh={() => {
              setRefresh(false);
            }}
            setCount={(count) => {
              setReqCount(count);
            }}
          />
        </TabPanel>
        <TabPanel value="2" className="requestTabPanel">
          <Requests
            setCount={(count) => {
              setReqCount(count);
            }}
            autorefresh={autorefresh}
            stopautorefresh={() => {
              setAutoRefresh(false);
            }}
          />
        </TabPanel>
      </TabContext>

      {errorMessages.length > 0 ? (
        <div className="limit-msg error-footer error-footer-mobile">
          <ul>
            {errorMessages.map((error: String) =>
              error === "" ? null : (
                <li>
                  {error} <br />
                </li>
              )
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
