import ClipLoader from "react-spinners/ClipLoader";

function VideoSpinner() {
  return (
    <div style={{ width: "35px", margin: "auto", display: "block" }}>
      <ClipLoader color="#595959" size={35} />
    </div>
  );
}

export default VideoSpinner;
