import { useEffect, useState } from "react";
import "./campaign.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Campaign() {
  const pageTitle = useSelector((state: any) => state.sideBar.title);
  const { t } = useTranslation();
  const [campaignHeight, setCampaignHeight] = useState(0);
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
    let viewportHeight = window.innerHeight;
    let div: any = document.getElementById("main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let doc: any = document;
    if (doc.getElementsByClassName("campaign-body")[0])
      setCampaignHeight(
        viewportHeight -
          (doc.getElementById("MobHeader").offsetHeight +
            document.getElementById("headerVal")?.offsetHeight +
            doc.getElementsByClassName("campaign-header ")[0].offsetHeight +
            doc.getElementById("footer").offsetHeight) -
          divpaddingTop -
          divpaddingbottom
      );
  }, []);
  return (
    <div className="campaign-container">
      <div className="campaign-header row--three"></div>
      <div
        className="campaign-body"
        id="campaignbody"
        style={{ height: campaignHeight }}
      >
        <div className="list-title notMobile">
          <div className="campaignNameHeader">{t("Name")}</div>
          <div className="campaignTypeHeader">{t("Type")}</div>
          <div className="campaignPhoneHeader">{t("Phone Number")}</div>
        </div>
      </div>
      <ul>
        <li>
          <div className="contacttListItem-div notMobile">
            <div className="contactName">HCP</div>

            <div
              className="contactType"
              style={{
                fontVariant: "small-caps",
                fontSize: "1.098rem",
              }}
            >
              Other Staff
            </div>
            <div
              className="contactPhone"
              style={{
                fontVariant: "small-caps",
                fontSize: "1.098rem",
                paddingRight: "1rem",
              }}
            >
              738836838383
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
