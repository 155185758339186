import moment from "moment";
export function formatDateIntoTodayYesterdayFormat(input,dtFormat) {
  dtFormat=dtFormat.replace(/dd/gi,"DD");
  const today = moment(new Date()).format(dtFormat);
  var yesterdayDate = new Date(today);
  // const yesterday = moment(
  //   yesterdayDate.setDate(yesterdayDate.getDate() - 1)
  // ).format(dtFormat);
  const yesterday = moment().subtract(1, 'days').format(dtFormat);
  console.log("date upgrade",moment().subtract(1, 'days').format(dtFormat))
  var inputDate = moment(input).format(dtFormat);
  if (today == inputDate) {
    return "Today";
  } else if (yesterday == inputDate) {
    return "Yesterday";
  } else {
    return inputDate;
  }
}

export function getDateFormat(input: any) {
  return moment(input).format("YYYY-MM-DD")
}
export function getTimeFormat(input: any) {
  return moment(input).format("mm:ss A")
}
export function formatDuration(time: any) {
  let duration = time;
  let finalDuration: any;
  let d = Number(duration);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  finalDuration = hDisplay + mDisplay + sDisplay;
  return finalDuration;
}