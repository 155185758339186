
import Avatar from "../../components/Avatar/Avatar";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../layouts/spinner/Spinner";
import { useTranslation } from "react-i18next";
import './VideoLogs.css';
import { useEffect, useState } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { makeStyles } from "@material-ui/styles";
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoChatDetails from "./VideoChatDetails";
import VideoFiles from "./VideoFiles";
import VideoParticipants from "./VideoParticipants";
import config from '../../env.json'
import axios from "axios";
import { getVideoLogs } from "../../apis/video/video";
import moment from 'moment';
import { useNavigate} from 'react-router-dom';
import { setVideoMobileDetails } from "../../app/slice/videoCallSlice";
import VideoSpinner from "../../layouts/spinner/VideoSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import SharePopup from "../SharePopup/SharePopup";





  const useStyles = makeStyles({
    iconStyle: {
      color: '#734BD1',
    },
  });
  
export default function VideoLogs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useStyles();
  const { t } = useTranslation();
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
  const camp=useSelector((state: any) => state.personalisation.campaignName);
  const RepId=useSelector((state:any)=>state.authApi.Id)
  const [videoDetails,setVideoDetails]=useState(false)
  const [activeaccordion,setActiveAccordion]=useState('');
  const [active,setActive]=useState(false);
  const [videoLogResult,setVideoLogResult]=useState([]) as any;
  const [mobileVideoDetails,setMobileVideoDetails]=useState(Number);
  const [spin, setSpin] = useState(true);
  const [infiniteScrollHeight,setInfiniteScrollHeight]=useState(0);
  const [paddingValBottom,setPaddingValBottom]=useState(0);
 const [paddingValRight,setPaddingValRight]=useState(0);
 const [page,setPage]=useState(1);
 const[hasMore,setHasMore]=useState(true);
//  let page = 1;
  

  const [activeTab, setActiveTab] = useState("");

  const [showSharePopup, setShowSharePopup] = useState(false);
  const [sharePopupDetails, setSharePopupDetails] = useState({});
  //  Functions to handle Tab Switching
 
useEffect(() => {
  let doc:any=document;
  let div:any=document.querySelector('#main') 
  let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    let divpaddingleft:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-left'));
    let divpaddingright:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-right'));
    setPaddingValBottom(divpaddingbottom);
     setPaddingValRight(divpaddingright);
    setInfiniteScrollHeight(window.innerHeight-(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom);

  let accvideo2= doc.getElementsByClassName("vchat-main");
      console.log(accvideo2)
      for (var i = 0; i < accvideo2.length; i++) {
       console.log('fakeImage: ', accvideo2[i]);
       accvideo2[i].style.display="none";
  
     }
     let tabs= doc.getElementsByClassName("Tabs");
     console.log(tabs)
     for (var i = 0; i < tabs.length; i++) {
      console.log('fakeImage: ', tabs[i]);
      tabs[i].style.display="none";
 
    }

    FetchVideologs(setVideoLogResult,videoLogResult);
     
}, [])


const FetchVideologs=(setVideoLogResult,videoLogResult)=>{
  
  getVideoLogs({Page:page},(res)=>{
    console.log("Page",page)
    setSpin(true);
    if(res) {
      setSpin(false);
      console.log(res.data.data)
 
      let pageNum=page+1;
      setPage(pageNum);
      setVideoLogResult([...videoLogResult, ...res.data.data]);
      if (res.data.data.length < 20)
      setHasMore(false);
    else setHasMore(true);
      // setVideoLogResult(res.data.data);
    }
    //setVideoLogResult(res.data.data);
  })
}

function formatDuration(End_DtTm: any, Start_DtTm:any, videoResult:any): string {
  const duration = moment.duration(moment(End_DtTm).diff(moment(Start_DtTm)));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const durationString = `${hours ? `${hours}h` : ""} ${
    minutes ? `${minutes}m` : ""
  } ${seconds ? `${seconds}s` : ""}`;
  return durationString;
}

const getItemType = (item, outOrnot) => {
  if (outOrnot) {
    if (item.RequestedBy) {
      const val= item.RequestedBy === "" ? "" : item.RequestedBy.split(",")[item.RequestedBy.split(",").length - 1];
      console.log(val)
      return val;
    }
  } 
  return "";
};
  const enableDropdown=(heightid,displayid)=>{
    
    let doc:any=document;
    let el=doc.getElementById(heightid)
    let el2=doc.getElementById(displayid)
    if(activeaccordion==heightid&&el.classList.contains('activevideolog')){
      el.classList.remove("activevideolog");
      el2.style.display="none";
      setActive(false)
    }
    else{
      setVideoDetails(true)
      setActiveAccordion(heightid);
      console.log(heightid,displayid);
    
  
      console.log(el,el2)
      // doc.getElementById("vidDetails").style.height='30rem';
      // doc.getElementById("vidDetails").style.background='#ffffff'
       let accvideo= doc.getElementsByClassName("accordion-video");
       console.log(accvideo)
       for (var i = 0; i < accvideo.length; i++) {
        console.log('fakeImage: ', accvideo[i]);
        accvideo[i].classList.remove("activevideolog");
        accvideo[i].removeAttribute("style");
      }
      el.classList.add("activevideolog");
      el.style.margin='0.1rem 1rem'
      setActive(true)
      setActiveTab("tab1")
    //   let accvideo3= doc.getElementsByClassName("vchat-main");
    //   console.log(accvideo3)
    //   for (var i = 0; i < accvideo3.length; i++) {
    //    console.log('fakeImage: ', accvideo3[i]);
    //    accvideo3[i].style.display="none";
  
    //  }
      let accvideo2= doc.getElementsByClassName("Tabs");
      console.log(accvideo2)
      for (var i = 0; i < accvideo2.length; i++) {
       console.log('fakeImage: ', accvideo2[i]);
       accvideo2[i].style.display="none";
  
     }
     
     
     el2.style.display="block";
    }
    
    //  if(videoDetails)
    //  {
    //   setVideoDetails(false);
    //   doc.getElementById("vidDetails").style.removeProperty("height");
    //  }
    

  }
  const getUserName =(item:any, outOrnot:boolean)=>{
    console.log(item, outOrnot)
    if (outOrnot) {
      if (item.RequestedBy) {
        return (item.RequestedBy);        
      } 
    return ''
  }
}

  const handleChat = (e) => {
    
    // update the state to tab1
    setActiveTab("tab1");
    e.stopPropagation();
  };
  const handleFiles = (e) => {
    // update the state to tab2
    setActiveTab("tab2");
    e.stopPropagation();
  };
  const handleParticipants = (e) => {
    // update the state to tab2
    setActiveTab("tab3");
    e.stopPropagation();
  };

  const handleNavigate = (Id,meetingName,EmployeeName,startdatetime,trncallId,recordName) => {
    navigate('/Meeting')
  console.log(trncallId)
     dispatch(setVideoMobileDetails({
      Id:Id,
      meetingName:meetingName,
      EmployeeName:EmployeeName,
      StartDateTime:startdatetime,
      TrnCallId:trncallId,
      RecordName:recordName
     }));
    
  }
  const truncate=(source, size) =>{
    return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
  }

  const getSharedPopUpData=(data)=>{
    if(data){
      setShowSharePopup(true)
      setSharePopupDetails(data)      
    }
    
  }

  const closeSharePopup = () => {
    setShowSharePopup(false);
  }
  return (
    <div className="videolist-body">
      {/* {showSharePopup ?
        <SharePopup contentDetails={sharePopupDetails} closeSharePopup={closeSharePopup} />
        // <SharePopup2/>
        : null
      } */}
      <div className="video-list-header notMobile">
        <div className="video-list-header-item">{t('Meeting Name')}</div>
        <div className="video-list-header-item">{t('Meeting Date')}</div>
        <div className="video-list-header-item">{t('Meeting Time')}</div>
        <div className="video-list-header-item">{t('Created by')}</div>
        <div className="video-list-header-item">{t('Meeting Duration')}</div>
        <div></div>
      </div>

      <div className='videoListScroll' style={{height:infiniteScrollHeight}}>

      <InfiniteScroll
     dataLength={videoLogResult.length} //This is important field to render the next data
     next={() => {
      FetchVideologs(setVideoLogResult, videoLogResult);
     }}
     hasMore={hasMore}
     loader={<VideoSpinner/>}
     height={700}
     endMessage={
      <p
        style={{
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "lighter",
        }}
        className="content-spacing"
      >
        {videoLogResult.length == 0 ? (
          <span>{t("No Video logs found")}</span>
        ) : (
          <span></span>
        )}
      </p>
    }
   > 
      {/* Desktop */}
      <ul className="notMobile">
      {spin  ? <VideoSpinner /> : null}
      {videoLogResult.map((videoResult:any,key:any) => (
            <>   
            <li onClick={(e)=>enableDropdown("vidDetails-"+key,"vl-l"+key)} id={"vidDetails-"+key} className="accordion-video">
              <div className="video-list-past-items">
                <div className="videologListItem-content">
                  <div
                    className="videologListItem-content"
                    style={{
                      fontSize: "1.098rem",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                    }}
                  >
                    {truncate(videoResult.Room_Nm, 12)}
                  </div>
                </div>
                <div
                  className="videologListItem-content"
                  style={{ fontSize: "1.098rem" }}
                >                
                   {moment(videoResult.Start_DtTm).format('MM/DD/YYYY')}
                </div>
                <div
                  className="videologListItem-content"
                  style={{ fontSize: "1.098rem" }}
                >
                {moment(videoResult.Start_DtTm).format('hh:mm A')}
                </div >
                <div
                className="avatar-div videologListItem-content"
                >
                  <Avatar
                        imageType={getItemType(videoResult, true)}
                        avatarHeight="2.5rem"
                        avatarWidth="2.5rem"
                        presOrAssoc={getUserName(videoResult, true)}                        
                      />
                       <div className="videocalllogListItem-content-name">
                        {videoResult.RequestedBy.split(",")[0].length > 15 ? videoResult.RequestedBy.split(",")[0].substring(0, 12) + "..." : videoResult.RequestedBy.split(",")[0].substring(0, 15)}
                      </div>
                  </div>
                  <div
                  className="videologListItem-content"
                  style={{ fontSize: "1.098rem" }}
                >
                {/* { 
                (moment(moment(videoResult.Leaving_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.Start_DtTm,"hh:mm:ss")),"minutes"))==0?"0":(moment(moment(videoResult.Leaving_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.Start_DtTm,"hh:mm:ss")),"minutes"))
                } mins  */}

                {/* {console.log(videoResult)} */}
                {formatDuration(videoResult?.Participant_End_DtTm, videoResult?.Participant_Start_DtTm, videoResult)}
                {/* { (moment(moment(videoResult.Leaving_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.Start_DtTm,"hh:mm:ss")),"seconds")) } secs */}
                {/* {(moment(moment(videoResult.Leaving_DtTm,"hh:mm:ss")).diff(moment(moment(videoResult.Start_DtTm,"hh:mm:ss")),"minutes"))==0?"":"mins"}  */}
                
                </div >
                {active&&activeaccordion=='vidDetails-'+key?<ArrowDropUpIcon  className="videodropdown"/>:<ArrowDropDownIcon className="videodropdown" />}
              </div>
             
            </li>
            <div className="Tabs" id={"vl-l"+key} >
                  <ul className="nav">
                    <li
                      className={activeTab === "tab1" ? "activetab" : ""}
                      onClick={handleChat}
                    >
                      <div id='chatheadericon'><ChatIcon/></div>
                      <div id='chatheader'>Chat</div>
                       
                    </li>
                    <li
                      className={activeTab === "tab2" ? "activetab" : ""}
                      onClick={handleFiles}
                    >
                        <div id='chatheadericon'><DescriptionIcon/></div>
                      <div id='chatheader'>Files</div>
                    </li>
                    <li
                      className={activeTab === "tab3" ? "activetab" : ""}
                      onClick={handleParticipants}
                    >
                        <div id='chatheadericon'><GroupAddIcon/></div>
                         <div id='chatheader'>Participants</div>
                    </li>
                  </ul>
                   
                  <div className="outlet">
                    {activeTab === "tab1"&& activeaccordion=='vidDetails-'+key? <VideoChatDetails Roomid={videoResult.Room_Id} startDateTime={videoResult.Participant_Start_DtTm}/> : activeTab === "tab2"&&activeaccordion=='vidDetails-'+key?<VideoFiles meetingStartTime={videoResult.Start_DtTm} files={videoResult.Trn_Call_Id} recordName={videoResult.Room_Nm}/>: <VideoParticipants files={videoResult.Trn_Call_Id}/>}
                  </div>
               </div> 
               </>
))}

           
      </ul>
     
      {/* Mobile view */}
      <ul className="meeting-list onlyMobile">
      {spin  ? <VideoSpinner /> : null}
        {
          videoLogResult.map((videoResult:any,key:any) =>(
            <li onClick={(e)=> handleNavigate(videoResult.Room_Id,videoResult.Room_Nm,
              videoResult.RequestedBy.split(",")[0].length > 15 ? videoResult.RequestedBy.split(",")[0].substring(0, 12) + "..." : videoResult.RequestedBy.split(",")[0].substring(0, 15),
              videoResult.Participant_Start_DtTm,videoResult.Trn_Call_Id,videoResult.Room_Nm)} id={"vidDetails-"+key} className="accordion-video">
              <div className="meeting-list-item">
                {/* Date */}
                  <div className="meeting-date">
                   {moment(videoResult.Start_DtTm).format('Do MMM dddd')}
                  </div>
                {/* Name */}
                <div className="meeting-name">                
                    {videoResult.Room_Nm}
                </div>
                {/* Time */}
                <div className="meeting-time">                
                {moment(videoResult.Start_DtTm).format('h:mm a')}
                </div>
                {/* Host */}
                <div className="meeting-host">   
                  <span>{'Created by: '}</span>             
                  {videoResult.RequestedBy.split(",")[0].length > 15 ? videoResult.RequestedBy.split(",")[0].substring(0, 12) + "..." : videoResult.RequestedBy.split(",")[0].substring(0, 15)}
                </div>
                {active&&activeaccordion=='vidDetails-'+key?<ArrowDropUpIcon  className="meeting-icon videodropdown"/>:<ArrowDropDownIcon className="meeting-icon videodropdown" />}
              </div>
             
            </li>
          ))
        }
            
            

               
      </ul>
      </InfiniteScroll>
         </div>
    
    </div>
  );
}
