import axios from "axios";
import config  from '../../env.json';
// import { useSelector, useDispatch } from "react-redux";

function getlangTransData(params,callback, token){
  // const token = useSelector((state: any) => state.login.token)
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
        axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation')
        .then(res=>{ 
          ;
          callback(res);
        }).catch(()=>{
          ;
          callback([])
        })
    } catch (error) {
      ;
        callback([])
        
    }
  //   try {
  //     axios.get(config.REACT_APP_AUTH_API_BASE+'/employeepersonalisation',{headers:{"Authorization":"Bearer "+sessionStorage.getItem('login')}})
  //     .then(res=>{ 
        
  //     callback(getval);
  //       // callback(res);
  //     }).catch(()=>{
  //       callback([])
  //     })
  // } catch (error) {
  //     callback([])
      
  // }
    
}

function getval(){
  let trans={"hi": {
    "translation": {
      "Contacts": "संपर्क"
      ,"Call Log":"कॉल लॉग"
      ,"Assets":"संपत्ति"
      ,"Templates":"तेम्प्लेत्स"
      ,"Video":"वीडियो"
      ,"Calendar":"पंचांग"
      ,"Campaign":"अभियान"
      ,"Chat":"चेट"  
    }
  }
}
return trans;
}

export {getlangTransData}