import "../../components/errors/Error404.css";
import erroricon from "../../assets/icons/erroricon.svg";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";
export default function Error404(props) {
  const { t } = useTranslation();
  let transLocData:any = localStorage.getItem("translationData")
  transLocData = JSON.parse(transLocData)
  console.log("transLocData1234",transLocData);
  let browserLang= Object.keys(transLocData)[0];
  console.log("browserLang",browserLang);
  useEffect(()=>{
      i18n
      .use(initReactI18next)
      .init({
        resources: transLocData,
        lng: browserLang,
        interpolation: {
          escapeValue: false,
        },
      });      
      document.documentElement.style.setProperty("--visibility", "initial");
      document.getElementById("page-name")?.setAttribute("data-content", "");
  },[])

  return (
    <>
      <div className="errordiv">
        <div className="error">
          <img src={erroricon} />
          <h1>{t("ERROR - Page")}</h1>
          <p>{t("page not found")}</p>
        </div>
      </div>
    </>
  );
}
