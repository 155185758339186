import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { parsePhoneNumber } from "react-phone-number-input";
import { Keyboard } from "@capacitor/keyboard";
import Chip from "@mui/material/Chip";
import config from "../../env.json";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";

import {
  addNewContact,
  checkNumberExist,
} from "../../apis/contacts/contactsApi";
import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
} from "../../app/slice/personalisationSlice";
import { useDispatch, useSelector } from "react-redux";
import ContactAlreadyAssign from "../../components/logoutModal/LogoutModal";
import { getDeviceType } from "../../utils/deviceTypeUtil";

import "./AddContact.css";
import { useTranslation } from "react-i18next";
import { pickContact } from "../../utils/phoneNumberUtil";
import MultiContactSelect from "./MultiContactSelect/MultiContactSelect";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";
import { isValid } from "../../utils/CheckValidString";
import getErrorMessage from "../.././utils/getErrorCodeFromJsonFile";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: primcolor
    ? document.documentElement.style.getPropertyValue("--primary-color")
    : "#734bd1",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: primcolor
      ? document.documentElement.style.getPropertyValue("--primary-color")
      : "#734bd1",
  },
});
function BpRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function AddContact({ show, handleCloseParent }) {
  const { t } = useTranslation();
  const [othersName, setOthersName] = useState("");
  const [recipientCharacterCount, setRecipientCharacterCount] = useState(true);
  const [assoPrescName, setAssoPrescName] = useState("");
  const [othersPhone, setOthersPhone] = useState("");
  const [hiddenPresList, setHiddenPresList] = useState(true);
  const [assoPresc, setAssoPresc] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);

  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [mutliAssociatePrescriberCheck, setMutliAssociatePrescriberCheck] =
    useState<any>(true);
  const dispatch = useDispatch();
  const [phoneErr, setPhoneErr] = useState<any>(true);

  const [isValidString, setIsValidString] = useState(false);

  // State for multi contact select
  const [multiContact, setMultiContact] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState('');
  const [clearDataOnDelete, setClearDataOnDelete] = useState(false)
  const [overrideMessage, setOverrideMessage] = useState('');
  const [errorData, setErrorData] = useState('');
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);

  function handleClose(value: string) {
    setOpen(false);
    setSelectedContact(value);
    setOthersPhone(value);
  }
  useEffect(() => {
    setErrorData("*"+t(getErrorMessage('NameError')))
    setCountryList()
  },[]);
  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    return () => {
      window.removeEventListener("orientationchange", calcHeightWidth);
    };
  }, []);

  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }
    if (config.REACT_APP_MULTI_PRES_ASSOCIATION) {
      let value = config.REACT_APP_MULTI_PRES_ASSOCIATION;

      setMutliAssociatePrescriberCheck(
        config.REACT_APP_MULTI_PRES_ASSOCIATION === "N" ? false : true
      );
    }

    calcHeightWidth();
    window.addEventListener("resize", (e) => {
      calcHeightWidth();
    });
  }, []);
  const calcHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };
  const setPrescriber = (x) => {
    setAssoPrescName(x.AcctFull_Nm);
    setAssoPresc(x);
    setHiddenPresList(true);
    setDisabled(false);
  };
  const handleYOrNPopup = (e: any) => {
    console.log(e);
    if (e === true) {
      let phn: any = parsePhoneNumber(othersPhone);
      console.log(phn);
      let payload = {
        AcctPh_Nbr: phn.nationalNumber,
        Prescriber_Id: secPrescribers.map((x: any) => x.Id),
        AcctFull_Nm: othersName,
        IntlCallPfx_Nbr: phn.countryCallingCode,
      };
      addNewContact(payload, (res: any) => {
        if (res) {
          closePopup(true);
          dispatch(setShowSuccessSnackBar(true));
        } else {
          setDisabled(false);
          closePopup(false);
          dispatch(setShowErrorSnackBar(true));
        }
      });
    } else {
      setDisabled(false);
      setShowModal(false);
    }
  };
  const setMultiPresAssocation = (e) => {
    console.log(e);
    console.log(secPrescribers);
  };
  const closePopup = (torf) => {
    setOthersName("");
    setOthersPhone("");
    setAssoPrescName("");
    setDisabled(true);
    setAssoPresc({});
    setSecPrescribers([]);
    handleCloseParent(torf);
    setRecipientCharacterCount(true);
    setIsValidString(false);
  };

  const handleOverrideMsg = (accType, accName) => {
    if (config.REACT_APP_PH_OVERRIDE_MSG) {
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
      let type = accType === "N" ? t("Others") : t("Prescriber");
      let name = accName;
      if (type) {
        msg = msg.replace("TYPE", type);
      } else {
        msg = msg.replace("TYPE -", "");
      }
      if (name) {
        msg = msg.replace("NAME", name);
      }
      setOverrideMessage(msg);
    }
  };

  async function addContact() {
    setDisabled(true);
    let phn: any = parsePhoneNumber(othersPhone);
    let isValidStringEntered = isValid(othersName);
    let ContactName = othersName.replace(/\s+/g, " ").trim();

    if (ContactName && isValidStringEntered) {
      let payload = {
        AcctPh_Nbr: phn.nationalNumber,
        Prescriber_Id: secPrescribers.map((x: any) => x.Id),
        AcctFull_Nm: ContactName,
        IntlCallPfx_Nbr: phn.countryCallingCode,
      };
      console.log(payload);
      checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
        console.log(res);
        if (res) {
          handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
          setShowModal(true);
        } else {
          addNewContact(payload, (res: any) => {
            if (res) {
              closePopup(true);
              dispatch(setShowSuccessSnackBar(true));
              setDisabled(true);
            } else {
              setDisabled(false);
              closePopup(false);
              dispatch(setShowErrorSnackBar(true));
            }
          });
        }
      });
    } else {
      setIsValidString(!isValidStringEntered);

      checkDisabled(false, !phoneErr, secPrescribers.length > 0);

      return;
    }
  }

  const clearData = (e) => {
    console.log("gc values changes e set", secPrescribers.length);
    setClearDataOnDelete(false);
    if (!mutliAssociatePrescriberCheck && e.length == 0) {
      setSecPrescribers([]);
      console.log("gc values changes e cleared!");
      checkDisabled(othersName.length > 0, !phoneErr, e.length > 0);
    }
  };
  const checkDisabled = (name, phone, presList) => {
    if (name && phone && presList) {
      setDisabled(false);
    } else setDisabled(true);
  };

  const pickcontact = () => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      pickContact().then((resp: any) => {
        if (resp.contact.phones.length > 1) {
          setOthersName(resp.contact.name.display);
          setMultiContact(resp.contact);
          setOpen(true);
        } else {
          setOthersName(resp.contact.name.display);
          setOthersPhone(resp.contact.phones[0].number.split("-").join(" "));
        }
      });
    }
  }
  let countryCode = useSelector((state:any)=> state.personalisation.EmployeePersonalizationPhone_Allow_CountryCd)  
  const setCountryList = () => {
    let userCountryList:Array<any>= countryCode?.split(",")?.map((code: string) =>code?.toLowerCase()) 
    setCountries(userCountryList)
  };
  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div
              className="modal-box contact-box addcontact-height"
              style={
                mobileStyle
                  ? {
                    height:
                      window.innerWidth > 768
                        ? "94.7458vh"
                        : "87.75vh",
                    width: modalWidth + "px",
                    overflow: "auto"
                  }
                  : undefined
              }
            >
              <div className="modal-header d-flex " style={{
                position:"sticky",
                top:0,
                zIndex:10
              }}>
                <h1 className="font-createcontact">{t("Create Contact")}</h1>
                <span
                  onClick={() => {
                    closePopup(false);
                  }}
                  className="close"
                >
                  ×
                </span>
              </div>

              <div className="modal-body addContacts">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addContact();
                  }}
                >
                  <div className="selecttype-radio-btns">
                    <FormControl>
                      <FormLabel id="demo-customized-radios">
                        {t("Select Type")}
                      </FormLabel>
                      <RadioGroup
                        row
                        defaultValue="others"
                        aria-labelledby="demo-customized-radios"
                        name="customized-radios"
                      >
                        <FormControlLabel
                          disabled
                          value="prescriber"
                          control={<BpRadio />}
                          label={t("Prescriber")}
                        />
                        <FormControlLabel
                          value="others"
                          control={<BpRadio />}
                          label={t("Others")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="form-fields-div">
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <TextField
                        className="roundborder"
                        onChange={(e) => {
                          setIsValidString(false);
                          const result = e.target.value.replace(
                            /[^A-Za-z0-9 _]/gi,
                            ""
                          );
                          if (result?.length > 0) {
                            if (result.trim().length < 2) {
                              setRecipientCharacterCount(true);
                            } else {
                              setRecipientCharacterCount(false);
                              checkDisabled(
                                true,
                                !phoneErr,
                                secPrescribers.length > 0
                              );
                            }
                          } else {
                            setRecipientCharacterCount(true);
                            checkDisabled(
                              false,
                              !phoneErr,
                              secPrescribers.length > 0
                            );
                          }
                          setOthersName(result);
                        }}
                        value={othersName}
                        label={t("Recipient Name") + "*"}
                        placeholder={t("Enter the recipient's name")}
                        color="secondary"
                        inputProps={{
                          maxLength: config.REACT_APP_CONTACT_NAME_MAXLENGTH,
                        }}
                        focused
                      />
                    </div>
                    {isValidString && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          margin: "0",
                          wordWrap: "break-word",
                        }}
                      >
                        {errorData}
                      </p>
                    )}

                    <div className="">
                      <PhoneNumberDropdown
                        initiall={(e) => {}}
                        errorFunc={(e) => {
                          setPhoneErr(e);
                          checkDisabled(
                            othersName.length > 0,
                            !e,
                            secPrescribers.length > 0
                          );
                        }}
                        countryList={countries}
                        disableColored={recipientCharacterCount}
                        valueNumber={othersPhone}
                        onChangeNumber={(e) => {
                          setOthersPhone(e);
                        }}
                      />
                      {config?.REACT_APP_IS_CROSS_PLATFORM &&
                      config?.REACT_APP_SHOULD_SHOW_CONTACT_PICKUP === "Y" ? (
                        <button className="create-btn" onClick={pickcontact}>
                          {t("Add")}
                        </button>
                      ) : null}

                      {multiContact !== undefined &&
                      multiContact !== null &&
                      Object.keys(multiContact).length > 0 ? (
                        <MultiContactSelect
                          selectedValue={selectedContact}
                          open={open}
                          onClose={handleClose}
                          multiContact={multiContact}
                        />
                      ) : null}
                    </div>
                    <h1 className="font-md title-assocaite_prescribers">
                      {t("Associated Prescribers") + "*"}
                    </h1>
                    {mutliAssociatePrescriberCheck ? (
                      <div
                        className="secPresDiv"
                        style={
                          secPrescribers.length > 0
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {secPrescribers.map((x: any) => (
                          <Chip
                            key={x.Id}
                            id={x.Id}
                            label={x.AcctFull_Nm}
                            onDelete={() => {
                              let temp = secPrescribers.filter((p: any) => {
                                if (p.Id !== x.Id) return p;
                              });
                              console.log(temp);
                              setSecPrescribers(temp);
                              checkDisabled(
                                othersName.length > 0,
                                !phoneErr,
                                temp.length > 0
                              );
                            }}
                          />
                        ))}
                      </div>
                    ) : null}
                    <AssociatedPresList
                      placeholder={t("Search for an Associated Prescriber")}
                      label=""
                      styleClass=""
                      disable={recipientCharacterCount}
                      clearDataOnSelect={mutliAssociatePrescriberCheck}
                      clearDataOnDelete={clearDataOnDelete}
                      multisel={mutliAssociatePrescriberCheck}
                      onChangeInputValue={(e: any) => {
                        setMultiPresAssocation(e);
                        clearData(e);
                      }}
                      setAssoPresc={(e: any) => {
                        let t: any = [e];
                        console.log("gc values changes e t", e);
                        if (mutliAssociatePrescriberCheck) {
                          t = t.concat(secPrescribers);
                        }
                        const result: any = [];
                        const map = new Map();
                        for (const item of t) {
                          if (!map.has(item.Id)) {
                            map.set(item.Id, true); // set any value to Map
                            result.push(item);
                          }
                        }
                        checkDisabled(
                          othersName.length > 0,
                          !phoneErr,
                          result.length > 0
                        );

                        setSecPrescribers(result);
                        setClearDataOnDelete(true);
                      }}
                    />

                    <button
                      type="submit"
                      className="create-btn"
                      disabled={disabled}
                      style={{ opacity: disabled ? 0.3 : 1 }}
                    >
                      {t("Create")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
        show={showModal}
        yorn={handleYOrNPopup}
        modalText={overrideMessage}
        handleCloseParent={(e) => {
          console.log(e);
          setShowModal(false);
        }}
      />
    </div>
  );
}
