import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import "./EditContact.css";
import axios from "axios";
import config from "../../env.json";
import "./AddContact.css";
import {
  editContact,
  addNewContact,
  checkNumberExist,
} from "../../apis/contacts/contactsApi";
import ContactAlreadyAssign from "../logoutModal/LogoutModal";

import { parsePhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setChatDetails, setRefreshChatList } from "../../app/slice/chatSlice";
import {
  setShowSuccessSnackBar,
  setShowErrorSnackBar,
} from "../../app/slice/personalisationSlice";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { FormHelperText } from "@mui/material";
import PhoneNumberDropdown from "../../layouts/phoneNumberDropdown/PhoneNumberDropdown";

export default function EditContactGAM({
  show,
  handleCloseParent,
  contact,
  onChangefunction,
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [modalHeight, setModalHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [phoneErr, setPhoneErr] = useState<any>(true);
  const [emailError, setEmailError] = useState(false);
  const [IsPrescriber, setIsPrescriber] = useState(false);
  const [secPrescribers, setSecPrescribers] = useState<any>([]);
  const [overrideMessage, setOverrideMessage] = useState("");

  const { chatId, chatIsPrescriber } = useSelector((state: any) => state.chat);
  const [addContactFormData, setAddContactFormData] = useState({});
  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  const FORM_DATA_KEYS = {
    ACCOUNT_NAME: "accountName",
    CONTACT_NAME: "contactName",
    TITLE: "title",
    PHONE_NUMBER: "phoneNo",
    EMAIL: "email",
  };
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    setCountryList()
    return () => {
      window.removeEventListener("orientationchange", calcHeightWidth);
    };
  }, []);
  let countryCode = useSelector((state:any)=> state.personalisation.EmployeePersonalizationPhone_Allow_CountryCd)  
  const setCountryList = () => {
    let userCountryList:Array<any>= countryCode?.split(",")?.map((code: string) =>code?.toLowerCase()) 
    setCountries(userCountryList)
  };
  useEffect(() => {
    calcHeightWidth();
    window.addEventListener("resize", (e) => {
      calcHeightWidth();
    });
  });

  const handleOverrideMsg = (accType, accName) => {
    if (config.REACT_APP_PH_OVERRIDE_MSG) {
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
      let type = accType === "N" ? t("Others") : t("Prescriber");
      let name = accName;
      if (type) {
        msg = msg.replace("TYPE", type);
      } else {
        msg = msg.replace("TYPE -", "");
      }
      if (name) {
        msg = msg.replace("NAME", name);
      }
      setOverrideMessage(msg);
    }
  };

  const getNumberFromItem = (item) => {
    if (item.AcctPh_Nbr) {
      if (item.AcctPh_Nbr.indexOf("+") >= 0) {
        return item.AcctPh_Nbr;
      } else return "+" + item.IntlCallPfx_Nbr + item.AcctPh_Nbr;
    } else return "";
  };
  const calcHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  async function updateContact() {
    let phn: any = parsePhoneNumber(
      addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
    );
    console.log(phn);
    let payload: any = {
      AcctPh_Nbr: phn.nationalNumber,
      IntlCallPfx_Nbr: phn.countryCallingCode,
      Id: contact.Id,
    };
    if (!IsPrescriber) {
      payload = {
        AcctPh_Nbr: phn.nationalNumber,
        AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
        Id: contact.Id,
        IntlCallPfx_Nbr: phn.countryCallingCode,
        Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
        Title: addContactFormData[FORM_DATA_KEYS.TITLE],
      };

      if (
        getNumberFromItem(contact) !==
        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
      ) {
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          if (res) {
            handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
            setShowModal(true);
          } else {
            addNewContact(payload, async (cont) => {
              if (cont) {
                if (
                  cont.Id == chatId &&
                  chatIsPrescriber == (cont.Is_Prescriber == "Y")
                ) {
                  dispatch(
                    setChatDetails({
                      chatId: cont.Id,
                      chatIsPrescriber: cont.Is_Prescriber == "Y",
                      showChat: true,
                      chatName: cont.AcctFull_Nm,
                    })
                  );
                }
                dispatch(setRefreshChatList(true));

                payload.Prescribers = secPrescribers;
                payload.Is_Prescriber = "N";

                clearAll(true, cont);
                dispatch(setShowSuccessSnackBar(true));
              } else {
                clearAll(false, {});
                dispatch(setShowErrorSnackBar(true));
              }
            });
          }
        });
      } else {
        console.log("is", IsPrescriber);
        addNewContact(payload, async (cont) => {
          if (cont) {
            if (
              cont.Id == chatId &&
              chatIsPrescriber == (cont.Is_Prescriber == "Y")
            ) {
              dispatch(
                setChatDetails({
                  chatId: cont.Id,
                  chatIsPrescriber: cont.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: cont.AcctFull_Nm,
                })
              );
            }
            dispatch(setRefreshChatList(true));
            clearAll(true, cont);
            dispatch(setShowSuccessSnackBar(true));
          } else {
            clearAll(false, {});
            dispatch(setShowErrorSnackBar(true));
          }
        });
      }
    } else {
      if (
        getNumberFromItem(contact) !==
        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
      ) {
        checkNumberExist(payload.AcctPh_Nbr, (res: any) => {
          console.log(res);
          if (res) {
            handleOverrideMsg(res.Is_Prescriber, res.AcctFull_Nm);
            setShowModal(true);
          } else {
            editContact(payload, (res) => {
              if (res) {
                if (
                  res.Id == chatId &&
                  chatIsPrescriber == (res.Is_Prescriber == "Y")
                ) {
                  dispatch(
                    setChatDetails({
                      chatId: res.Id,
                      chatIsPrescriber: res.Is_Prescriber == "Y",
                      showChat: true,
                      chatName: res.AcctFull_Nm,
                    })
                  );
                }
                dispatch(setRefreshChatList(true));
                clearAll(true, res);
                dispatch(setShowSuccessSnackBar(true));
              } else {
                clearAll(false, {});
                dispatch(setShowErrorSnackBar(true));
              }
            });
          }
        });
      } else {
        editContact(payload, (res) => {
          if (res) {
            if (
              res.Id == chatId &&
              chatIsPrescriber == (res.Is_Prescriber == "Y")
            ) {
              dispatch(
                setChatDetails({
                  chatId: res.Id,
                  chatIsPrescriber: res.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: res.AcctFull_Nm,
                })
              );
            }
            dispatch(setRefreshChatList(true));
            
            clearAll(true, res);
            dispatch(setShowSuccessSnackBar(true));
          } else {
            clearAll(false, {});
            dispatch(setShowErrorSnackBar(true));
          }
        });
      }
    }
  }

  const handleYOrNPopup = (e: any) => {
    if (e === true) {
      let phn: any = parsePhoneNumber(
        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
      );
      console.log(phn);
      let payload: any = {
        AcctPh_Nbr: phn.nationalNumber,
        IntlCallPfx_Nbr: phn.countryCallingCode,
        Id: contact.Id,
      };
      if (!IsPrescriber) {
        payload = {
          AcctPh_Nbr: phn.nationalNumber,
          AcctFull_Nm: addContactFormData[FORM_DATA_KEYS.CONTACT_NAME],
          Id: contact.Id,
          IntlCallPfx_Nbr: phn.countryCallingCode,
          Email: addContactFormData[FORM_DATA_KEYS.EMAIL],
          Title: addContactFormData[FORM_DATA_KEYS.TITLE],
        };
        addNewContact(payload, async (cont) => {
          if (cont) {
            if (
              cont.Id == chatId &&
              chatIsPrescriber == (cont.Is_Prescriber == "Y")
            ) {
              console.log("ifffffffffffffffffffffffffffffffff");
              dispatch(
                setChatDetails({
                  chatId: cont.Id,
                  chatIsPrescriber: cont.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: cont.AcctFull_Nm,
                })
              );
            }
            payload.Prescribers = secPrescribers;
            payload.Is_Prescriber = "N";
            
            let done = false;
            let original = contact.Prescribers;
            let modified = secPrescribers;
            let difference = original
              .filter((x) => {
                if (modified.findIndex((p) => p.Id == x.Id) < 0) return x;
              })
              .concat(
                modified.filter((x) => {
                  if (original.findIndex((p) => p.Id == x.Id) < 0) return x;
                })
              );
            let forDelete: any = difference
              .filter((x: any) => {
                if (x.Association_Id) return x.Id;
              })
              .map((x) => x.Association_Id);
            let forAdd: any = difference
              .filter((x: any) => {
                if (!x.Association_Id) return x.Id;
              })
              .map((x) => x.Id);
            let a, d;
            if (forAdd.length > 0) {
              let assoPayload = {
                PresciberAccociatesPrescriberAccoc_Id: payload.Id,
                PresciberAccociatesPrescrber_Id: forAdd,
              };
              await axios
                .post(
                  config.REACT_APP_CONTACTS_API_BASE +
                    "/contact/associateassociation",
                  assoPayload
                )
                .then((res) => {
                  a = res;
                })
                .catch(() => {});
            }
            if (forDelete.length > 0) {
              let assoPayload = {
                Ids: forDelete,
              };
              await axios
                .post(
                  config.REACT_APP_CONTACTS_API_BASE +
                    "/contact/associateassociation/delete",
                  assoPayload
                )
                .then((res) => {
                  d = res;
                })
                .catch(() => {});
            }
            let final = payload;
            console.log(final);
            clearAll(true, final);
            dispatch(setShowSuccessSnackBar(true));
          } else {
            clearAll(false, {});
            dispatch(setShowErrorSnackBar(true));
          }
        });
      } else {
        editContact(payload, (res) => {
          if (res) {
            if (
              res.Id == chatId &&
              chatIsPrescriber == (res.Is_Prescriber == "Y")
            ) {
              dispatch(
                setChatDetails({
                  chatId: res.Id,
                  chatIsPrescriber: res.Is_Prescriber == "Y",
                  showChat: true,
                  chatName: res.AcctFull_Nm,
                })
              );
            }
            dispatch(setRefreshChatList(true));
            clearAll(true, res);
            dispatch(setShowSuccessSnackBar(true));
          } else {
            clearAll(false, {});
            dispatch(setShowErrorSnackBar(true));
          }
        });
      }
    } else {
      setShowModal(false);
    }
  };

  const clearAll = (passOrNot, data) => {
    onChangefunction(true);
    setDisabled(true);
    setAddContactFormData({});
    if (passOrNot) {
      handleCloseParent(true, data);
    } else handleCloseParent(false, {});
  };
  useEffect(() => {
    if (contact) {
      if (contact.Is_Prescriber === "N") {
        if (contact.Prescribers) {
        }
        setIsPrescriber(false);
        setDisabled(true);
      } else {
        setDisabled(true);
      }
      console.log("contact.AcctPh_Nbr", contact.AcctPh_Nbr);
      setAddContactFormData({
        [FORM_DATA_KEYS.CONTACT_NAME]: contact.AcctFull_Nm,
        [FORM_DATA_KEYS.PHONE_NUMBER]: getNumberFromItem(contact),
        [FORM_DATA_KEYS.EMAIL]: contact.Email_Adr,
        [FORM_DATA_KEYS.TITLE]: contact.Title_Cd,
        [FORM_DATA_KEYS.ACCOUNT_NAME]: contact.HospFull_Nm,
      });
      console.log("contact.AcctPh_Nbr", addContactFormData);
    }
  }, [contact, show]);

  useEffect(() => {
    getModalDimensions();
  }, []);

  const getModalDimensions = () => {
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);
      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };
  useEffect(() => {
    let formkeyArray = Object.keys(FORM_DATA_KEYS);
    let hasNullData = formkeyArray.some((data) => {
      if (
        !addContactFormData[FORM_DATA_KEYS[data]] ||
        addContactFormData[FORM_DATA_KEYS[data]] == ""
      ) {
        return data;
      }
    });
    if (hasNullData || phoneErr || emailError) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [addContactFormData]);

  /**
   * This method used to fill the form data which will be used as  contact create request data
   * @param key It is key of the object for which we will be setting value
   * @param value It value to the key i.e the value of the user input
   */
  const fillForm = (key, value) => {
    setAddContactFormData({ ...addContactFormData, [key]: value });
  };
  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div
              className="modal-box edit-contact-box ipad-top-margin"
              style={
                mobileStyle ? { height: modalHeight, width: modalWidth } : {}
              }
            >
              <div className="modal-header d-flex">
                <h1 className="font-createcontact">{t("Edit Contact")}</h1>
                <span onClick={() => clearAll(false, {})} className="close">
                  ×
                </span>
              </div>

              <div
                className="modal-body editContacts"
                style={mobileStyle ? { height: modalHeight - 99.7 + "px" } : {}}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateContact();
                  }}
                >
                  <div className="form-fields-div">
                    <TextField
                      className="roundborder"
                      onChange={(e) => {
                        fillForm(FORM_DATA_KEYS.ACCOUNT_NAME, e.target.value);
                      }}
                      value={addContactFormData[FORM_DATA_KEYS.ACCOUNT_NAME]}
                      label={t("Account")}
                      placeholder={t("Premier Medical Centre")}
                      color="secondary"
                      focused
                      inputProps={{ maxLength: 170 }}
                      disabled
                      style={{ opacity: disabled ? 0.3 : 1 }}
                    />

                    <TextField
                      className="roundborder"
                      onChange={(e) => {
                        fillForm(FORM_DATA_KEYS.CONTACT_NAME, e.target.value);
                      }}
                      value={addContactFormData[FORM_DATA_KEYS.CONTACT_NAME]}
                      label={t("Contact Name")}
                      placeholder={t("Enter contact name")}
                      color="secondary"
                      focused
                      inputProps={{ maxLength: 170 }}
                    />
                    <TextField
                      className="roundborder"
                      onChange={(e) => {
                        fillForm(FORM_DATA_KEYS.TITLE, e.target.value);
                      }}
                      value={addContactFormData[FORM_DATA_KEYS.TITLE]}
                      label={t("Title")}
                      placeholder={t("Enter a title")}
                      color="secondary"
                      focused
                      inputProps={{ maxLength: 10 }}
                    />
                    <PhoneNumberDropdown
                      initiall={(e) => {}}
                      errorFunc={(e) => {
                        setPhoneErr(e);
                      }}
                      countryList={countries}
                      disableColored=""
                      numberLandline={() => {}}
                      falsifyShowOnce={() => {}}
                      placeholder={t("Enter a phone number")}
                      valueNumber={
                        addContactFormData[FORM_DATA_KEYS.PHONE_NUMBER]
                      }
                      class={"phoneNumberInputAddContactGam"}
                      onChangeNumber={(e) => {
                        console.log("contact.AcctPh_Nbr");
                        fillForm(FORM_DATA_KEYS.PHONE_NUMBER, e);
                      }}
                    />
                    <TextField
                      className="roundborder"
                      onChange={(event) => {
                        if (
                          event.target.value &&
                          !emailPattern.test(event?.target?.value)
                        ) {
                          setEmailError(true);
                        } else {
                          setEmailError(false);
                        }
                        fillForm(FORM_DATA_KEYS.EMAIL, event.target.value);
                      }}
                      value={addContactFormData[FORM_DATA_KEYS.EMAIL]}
                      label={t("Email Address")}
                      placeholder={t("Enter an email address")}
                      color="secondary"
                      focused
                      InputProps={{
                        inputProps: {
                          pattern:
                            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                          maxLength: 50,
                        },
                      }}
                      error={emailError}
                      helperText={
                        emailError && (
                          <FormHelperText>
                            <p className="erorrPhone form-error-message">
                              {t("Enter a valid email address")}
                            </p>{" "}
                          </FormHelperText>
                        )
                      }
                    />
                    <button
                      type="submit"
                      className="create-btn"
                      disabled={disabled}
                      style={{ opacity: disabled ? 0.3 : 1 }}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
        show={showModal}
        yorn={handleYOrNPopup}
        modalText={overrideMessage}
        handleCloseParent={(e) => {
          console.log(e);
          setShowModal(false);
        }}
      />
    </div>
  );
}
