import axios from "axios";
import config  from '../../env.json';

// let CancelToken = axios.CancelToken;

// let cancel;

function getAllConversationsApi(params,callback){
  let {page,limit,search,Role,UnknFlwIsSameRepNbr}=params;
  // if (cancel != undefined) {
  //   cancel();
  // }
    try {
        axios.get( 
          config.REACT_APP_MESSAGE_API_BASE +
          "/conversations/list?page="+page+"&limit="+limit+"&Role="+Role+"&UnknFlwIsSameRepNbr="+UnknFlwIsSameRepNbr+"&searchquery=" + search,
            // {
            //   cancelToken: new CancelToken(function executor(c) {
            //     // An executor function receives a cancel function as a parameter
            //     cancel = c;
            //   })
            // }       
          )
        .then(res=>{ 
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }   
}

function getConversationDetailsApi(params,callback){
    let {page,limit,id,type}=params;
    try {
        //axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/details?&page="+page+"&limit="+limit+"&recipientId="+id)
       axios.get(config.REACT_APP_MESSAGE_API_BASE+"/conversations/detailsNew?recepientId="+id+"&recepientType="+type)
        .then(res=>{
          callback(res.data);
        }).catch(()=>{
          callback([])
        })
    } catch (error) {
        callback([])
    }
} 

export {getAllConversationsApi,getConversationDetailsApi}