import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ParticipantItem from "./ParticipantItem";
function ParticipantsList() {
  const room = useSelector(
    (state: any) => state.floaterVideoCall.videoCallRoom
  );
  const disconnectedParticipants = useSelector(
    (state: any) => state.floaterVideoCall.disconnectedParticipants
  );
  const [localParticipant, setLocalParticipant] = useState<any>(null);
  const [totalParticipants, setTotalParticipants] = useState<any>([]);
  const participantConnected = (participant) => {
    console.log(room);
    let p: any = Array.from(room.participants.values()).filter(
      (x: any) => x.state != "disconnected"
    );
    if (disconnectedParticipants) {
      if (disconnectedParticipants.length > 0) {
        let t = p.filter((x) => disconnectedParticipants.indexOf(x.sid) < 0);
        setTotalParticipants(t);
      } else setTotalParticipants(p);
    } else {
      setTotalParticipants(p);
    }
  };

  const participantDisconnected = (participant) => {
    setTotalParticipants(
      Array.from(room.participants.values()).filter(
        (x: any) => x.state != "disconnected" && x.sid != participant.sid
      )
    );
  };
  useEffect(() => {
    if (room) {
      if (room._options) {
        setLocalParticipant(room.localParticipant);

        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
        room.participants.forEach((p) => {});
      }
    }
  }, [room]);

  useEffect(() => {
    console.log(disconnectedParticipants, room);

    if (disconnectedParticipants && room) {
      if (room._options) {
        let p: any = Array.from(room.participants.values()).filter(
          (x: any) => x.state != "disconnected"
        );

        if (disconnectedParticipants.length > 0) {
          let t = p.filter((x) => disconnectedParticipants.indexOf(x.sid) < 0);
          setTotalParticipants(t);
        }
      }
    }
  }, [disconnectedParticipants]);
  return (
    <div>
      {localParticipant ? (
        <ParticipantItem local={true} participant={localParticipant} />
      ) : null}
      {totalParticipants.map((x) => (
        <ParticipantItem local={false} participant={x} />
      ))}
    </div>
  );
}

export default ParticipantsList;
