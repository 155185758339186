import { useEffect, useState } from "react";
import {
  formatDateIntoTodayYesterdayFormat,
  formatDuration,
} from "../../utils/Date";
import moment from "moment";
import config from "../../env.json";
import "./Calllog.css";
import Avatar from "../../components/Avatar/Avatar";
import ContactDetialsModal from "../../components/contact/ContactDetialsModal";
import CallModal from "../../components/call/CallModal";
import {
  getCallLogsAPi,
  getUnknownCallLogsAPi,
} from "../../apis/calllog/callLogApi";
import {
  setCallModalView,
  setCallModalDetails,
} from "../../app/slice/callModalSlice";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../layouts/spinner/Spinner";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveContactsForChat from "../../components/chat/SaveContactForChat";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import ContactAddPopup from "../../components/contact/ContactAddPopup";
import Pusher from "pusher-js";
import ContactDetialsModalGAM from "../../components/contact/ContactDetialsModalGAM";

export default function Calllog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sRepRole = useSelector((state: any) => state.chat.repRole);
  const [calllogList, setCalllogList] = useState([]);
  const [contactData, setContactData] = useState({});

  const [showDetails, setShowDetails] = useState(false);
  const [RefreshCalllog, setRefreshCalllog] = useState(false);
  const { showCallModal } = useSelector((state: any) => state.callModal);

  const [showCallModalPopup, setShowCallModalPopup] = useState(false);
  const pageTitle = useSelector((state: any) => state.sideBar.title);
  const sidebr = useSelector((state: any) => state.sideBar);

  const [spin, setSpin] = useState(false);
  const [callLog, setCallLog] = useState<any>(0);

  const [unknownId, setUnknownId] = useState<any>(null);
  const [unknownNumber, setUnknownNumber] = useState<any>(null);
  const [openSaveContacts, setOpenSaveContacts] = useState(false);
  const [addNumberPopup,setAddNumberPopup]=useState(false)
  const [msgOrCallIcon,setMsgOrCallIcon]=useState(false)
  const [isHospitalContact,setIsHospitalContact]=useState(false)
  const camp=useSelector((state: any) => state.personalisation.campaignName);
  const translatedValues=useSelector((state: any) => state.personalisation.transData);
  const personalizationDateFormat = useSelector((state: any) => state.personalisation.personalizationDateFormat);
  const RepId=useSelector((state:any)=>state.authApi.Id)
  const getCallLogData = () => {
    let Role = setSpin(true);
    if (sRepRole == "sAdm") {
      getUnknownCallLogsAPi((res) => {
        
        setSpin(false);
        res.map((r) => {});
        setCalllogList(res);
      });
    } else{
      getCallLogsAPi(sRepRole,(res) => {
        
        setSpin(false);
        res.map((r) => {});
        setCalllogList(res);
      });
    }
  };
  useEffect(() => {
    const pusher = new Pusher(config.REACT_APP_PUSHER_KEY, {
      cluster: config.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe("incomingcall");
    console.log("call-refresh-" + RepId);

    channel.unbind("call-refresh-" + RepId);
    channel.bind("call-refresh-" + RepId, (data: any) => {
      console.log(data);
      getCallLogData();
      console.log("kkkkkkkkkkkkkkkdd1");
    });
    return () => {
      pusher.unsubscribe("incomingcall");
    };
  }, [calllogList, sRepRole]);

  const contactDetailModal = (data) => {
    if (data) {
      
      if (data.direction === "inbound" && data.fromName) {
        
        if (data.fromName.split(",")[1].trim() == "unknown") {
          setUnknownId(data.senderId);
          setUnknownNumber(data.fromNumber);
          return;
        } else {
          setUnknownId(null);
          setUnknownNumber(null);
        }
      } else if (data.toName) {
        if (data.toName.split(",")[1].trim() == "unknown") {
          setUnknownId(data.recepientId);
          setUnknownNumber(data.toNumber);
          return;
        } else {
          setUnknownId(null);
          setUnknownNumber(null);
        }
      }
      if (data.fromName.split(",")[1].trim() == "unknown") {
        dispatch(
          setCallModalDetails({
            AcctFull_Nm:
              data.direction === "inbound"
                ? data.fromName?.split(",")[0]
                : data.toName?.split(",")[0],
            AcctFull_Nbr:
              data.direction === "inbound" ? data.fromNumber : data.toNumber,
          })
        );
      }
      let prescriber =
        data.direction === "inbound"
          ? data.fromName?.split(",")[1].trim()
          : data.toName?.split(",")[1].trim();

      setContactData({
        Id: data.direction === "inbound" ? data.senderId : data.recepientId,
        Is_Prescriber: prescriber === "prescriber" ? "Y" : "N",
      });
    }

    setShowDetails(true);
  };
  useEffect(() => {
    if (unknownId && unknownNumber) {
      setOpenSaveContacts(true);
    } else {
      setOpenSaveContacts(false);
    }
  }, [unknownId, unknownNumber]);
  useEffect(() => {
    getCallLogData();
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [RefreshCalllog]);
  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "initial");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [translatedValues,pageTitle]);
  useEffect(() => {
    getCalllogHeight();
  });
  useEffect(() => {
    getCalllogHeight();
  });

  useEffect(() => {
    window.addEventListener("orientationchange", getCalllogHeight);
    if (
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    ) {
      setIsHospitalContact(true);
    } else {
      setIsHospitalContact(false);
    }
    return () => {
      window.removeEventListener("orientationchange", getCalllogHeight);
    };
  }, []);

  const getCalllogHeight = () => {
    let viewportHeight = window.innerHeight;
    let div: any = document.getElementById("main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );

    let doc: any = document;
    console.log(doc.getElementById("MobHeader").offsetHeight);
    console.log(doc.getElementById("headerVal")?.offsetHeight);
    console.log(doc.getElementById("footer").offsetHeight);
    if (doc.getElementsByClassName("calllog-body")[0]) {
      setTimeout(() => {
        setCallLog(
          viewportHeight -
            (doc.getElementById("MobHeader").offsetHeight +
              document.getElementById("headerVal")?.offsetHeight +
              doc.getElementById("footer").offsetHeight) -
            (divpaddingTop + divpaddingbottom)
        );
      }, 100);
    }
  };

  const getItemType = (item, outOrnot) => {
    if (outOrnot) {
      if (item.toName) {
        return item.toName === ""
          ? ""
          : item.toName.split(",")[item.toName.split(",").length - 1];
      }
    } else {
      if (item.fromName) {
        return item.fromName === ""
          ? ""
          : item.fromName.split(",")[item.fromName.split(",").length - 1];
      }
    }
    return "";
  };

  const getUserName = (item: any, outOrnot: boolean) => {
    console.log(item, outOrnot);
    if (outOrnot) {
      if (item.toName) {
        return item.toName === "" || item.toName.split(",")[1] === "unknown"
          ? getFormatedPhoneNumber(item.toNumber)
          : item.toName;
      } else if (item.toNumber) return getFormatedPhoneNumber(item.toNumber);
    } else {
      if (item.fromName) {
        return item.fromName === "" || item.fromName.split(",")[1] === "unknown"
          ? getFormatedPhoneNumber(item.fromNumber)
          : item.fromName;
      } else if (item.fromNumber)
        return getFormatedPhoneNumber(item.fromNumber);
    }
    return "";
  };
  const getfromName = (item: any, outOrnot: boolean) => {
    if (outOrnot) {
      if (item.toName) {
        return item.toName === "" || item.toName.split(",")[1] === "unknown" ? (
          <>
            <p className="calllogListItemName">
              {getFormatedPhoneNumber(item.toNumber)}
            </p>
            <p className="designation">{t("Unknown")}</p>
          </>
        ) : (
          <>
            <p className="calllogListItemName">
              {item.toName.split(",")[0].length > 15
                ? item.toName.split(",")[0].substring(0, 15) + "..."
                : item.toName.split(",")[0].substring(0, 15)}
            </p>
            <p className="designation">
              {item.toName.split(",")[item.toName.split(",").length - 1] ===
              "prescriber"
                ? t("Prescriber")
                : item.toName.split(",")[item.toName.split(",").length - 1] ===
                  "prescriberassociates"
                ? t("Others")
                : item.AcctFull_Nm}
            </p>
          </>
        );
      } else if (item.toNumber) return getFormatedPhoneNumber(item.toNumber);
    } else {
      if (item.fromName) {
        return item.fromName === "" ||
          item.fromName.split(",")[1] === "unknown" ? (
          <>
            <p className="calllogListItemName">
              {getFormatedPhoneNumber(item.fromNumber)}
            </p>
            <p className="designation">{t("Unknown")}</p>
          </>
        ) : (
          <>
            <p className="calllogListItemName">
              {item.fromName.split(",")[0].length > 15
                ? item.fromName
                    .split(",")[0]
                    .substring(0, 18)
                    .substring(0, 18) + "..."
                : item.fromName.split(",")[0].substring(0, 18).substring(0, 18)}
            </p>
            <p className="designation">
              {item.fromName.split(",")[item.fromName.split(",").length - 1] ===
              "prescriber"
                ? t("Prescriber")
                : item.fromName.split(",")[
                    item.fromName.split(",").length - 1
                  ] === "prescriberassociates"
                ? t("Others")
                : t("Unknown")}
            </p>
          </>
        );
      } else if (item.fromNumber)
        return getFormatedPhoneNumber(item.fromNumber);
    }
    return "";
  };

  useEffect(() => {
    if (showCallModal != showCallModal) {
      getCallLogData();
    }
  }, [showCallModal]);

  function isIOS() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true;
    } else {
      return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      );
    }
  }

  const saveUnknown = (data: any) => {
    getCallLogData();
  };
  return (
    <div className="calllog-container">
      {showDetails ? (
        isHospitalContact ?
        <ContactDetialsModalGAM
          showChatIcon={true}
          showDetails={showDetails}
          contact={contactData}
          showAddContactModal={(e, trueorFalse) => {setContactData(e);setMsgOrCallIcon(trueorFalse);setAddNumberPopup(true)}}
          handleCloseParent={() => {
            setShowDetails(false);
            //getCallLogData();
          }}
          fetchDetails={true}
          showCallMakerModal={(e) => {
            setContactData(e);
            setShowDetails(false);
            setShowCallModalPopup(true);
            dispatch(setCallModalView(true));
          }}
          funcForEdited={(e)=>{
            getCallLogData();
          }}
        />:
        <ContactDetialsModal
          showChatIcon={true}
          showDetails={showDetails}
          editted={()=>{}}
          contact={contactData}
          showAddContactModal={(e, trueorFalse) => {setContactData(e);setMsgOrCallIcon(trueorFalse);setAddNumberPopup(true)}}
          handleCloseParent={() => {
            setShowDetails(false);
            //getCallLogData();
          }}
          fetchDetails={true}
          showCallMakerModal={(e) => {
            setContactData(e);
            setShowDetails(false);
            setShowCallModalPopup(true);
            dispatch(setCallModalView(true));
          }}
          funcForEdited={(e)=>{
            getCallLogData();
          }}
        />
      ) : null}
      {showCallModalPopup ? (
        <CallModal
          data={contactData}
          handleCloseParent={(e) => {
            console.log(e);

            setShowDetails(false);

            dispatch(setCallModalView(false));

            if (config.REACT_APP_IS_CROSS_PLATFORM) {
              getCallLogData();
            } else {
              setTimeout(() => {
                console.log("yes");
                setRefreshCalllog(!RefreshCalllog);
              }, 50);
            }
          }}
        />
      ) : null}
      {openSaveContacts ? (
        <SaveContactsForChat
          unknownid={unknownId}
          number={unknownNumber}
          show={openSaveContacts}
          handleCloseParent={(torf, data: any) => {
            
            console.log(data);
            if (torf) saveUnknown(data);
            setUnknownId(null);
            setUnknownNumber(null);
            setOpenSaveContacts(false);
          }}
        />
      ) : null}

      <div className="calllog-header notMobile"></div>
      <div className="calllog-body" style={{ height: callLog }}>
        <div className="list-title call-log-sub-header notMobile">
          <div className="call-log-list-item">{t("Name")}</div>
          <div className="call-log-list-item">{t("Type")}</div>
          <div className="call-log-list-item">{t("Occurrence")}</div>
          <div className="call-log-list-item">{t("Duration")}</div>
        </div>

        {/* Desktop */}
        <ul className="notMobile">
          {spin && calllogList.length == 0 ? <Spinner /> : null}
          {calllogList.map((item: any) => {
            return (
              <li key={item.Id} id={item.Id}>
                {item.direction === "outbound-dial" ? (
                  <div className="calllogListItem-div notMobile">
                    <div
                      className="avatar-div calllogListItem-content"
                      onClick={() => {
                        contactDetailModal(item);
                      }}
                    >
                      <Avatar
                        imageType={getItemType(item, true)}
                        avatarHeight="3rem"
                        avatarWidth="3rem"
                        presOrAssoc={getUserName(item, true)}
                      />

                      <div className="calllogListItem-content-name">
                        {getfromName(item, true)}
                      </div>
                    </div>

                    <div className="calllogListItem-content">
                      {/* Outgoing call */}
                      <div className="calllogListItem-type align-items-center">
                        <div className="calllog-icons">
                          <div className="calllog-icon-container outgoing-call">
                            <Tooltip title={t("Outgoing Call")} arrow>
                              {/* <PhoneForwardedIcon /> */}
                              <img
                                src={config.REACT_APP_PHONE_CALL_FORWARDED_ICON}
                                alt="Outgoing Call Icon"
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <p>{t("Outgoing Call")}</p>
                      </div>
                    </div>
                    <div className="calllogListItem-content">
                      <p>
                        {t(formatDateIntoTodayYesterdayFormat(item.datetime,personalizationDateFormat))}{" "}
                        <span>{moment(item.datetime).format("hh:mm A")}</span>
                      </p>
                    </div>

                    <div className="calllogListItem-content">
                      <span>{formatDuration(item.callDuration)}</span>
                    </div>
                  </div>
                ) : (
                  <div className="calllogListItem-div">
                    <div
                      className="avatar-div calllogListItem-content"
                      onClick={() => {
                        contactDetailModal(item);
                      }}
                    >
                      <Avatar
                        imageType={getItemType(item, false)}
                        avatarHeight="3rem"
                        avatarWidth="3rem"
                        presOrAssoc={getUserName(item, false)}
                      />

                      <div className="calllogListItem-content-name">
                        {getfromName(item, false)}
                      </div>
                    </div>

                    <div className="calllogListItem-content">
                      <div>
                        {item.callDuration == "0" && item.status.toUpperCase()!="BUSY" ? (
                          <div className="calllogListItem-type align-items-center">
                            <div className="calllog-icons call-missed-container">
                              <div className="calllog-icon-container miss-call">
                                <Tooltip title={t("Missed Call")} arrow>
                                  {/* <PhoneCallMissedIcon /> */}
                                  <img
                                    src={
                                      config.REACT_APP_PHONE_CALL_MISSED_ICON
                                    }
                                    alt="Missed Call Icon"
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <p>{t("Missed Call")}</p>
                          </div>
                        ) : (
                          <div className="calllogListItem-type align-items-center">
                            <div className="calllog-icons">
                              <div className="calllog-icon-container incoming-call">
                                <Tooltip title={t("Incoming Call")} arrow>
                                  {/* <PhoneCallbackIcon /> */}
                                  <img
                                    src={config.REACT_APP_PHONE_CALL_BACK_ICON}
                                    alt="Incoming Call Icon"
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <p>{t("Incoming Call")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="calllogListItem-content">
                      <p>
                        {t(formatDateIntoTodayYesterdayFormat(item.datetime,personalizationDateFormat))}{" "}
                        <span>{moment(item.datetime).format("hh:mm A")}</span>
                      </p>
                    </div>

                    <div className="calllogListItem-content">
                      <span>{formatDuration(item.callDuration)}</span>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        {/* Mobile view */}
        <ul className="onlyMobile calllog-mobile">
          {spin ? <Spinner /> : null}
          {calllogList.map((item: any) => {
            return (
              <li key={item.Id} id={item.Id} className="calllog-scroll-box">
                {item.direction === "outbound-dial" ? (
                  <div
                    className="calllog-mobile-list-item"
                    onClick={() => {
                      contactDetailModal(item);
                    }}
                  >
                    <div className="d-flex">
                      <Avatar
                        imageType={getItemType(item, true)}
                        avatarHeight="3rem"
                        avatarWidth="3rem"
                        presOrAssoc={getUserName(item, true)}
                      />

                      <div className="calllog-details">
                        {getfromName(item, true)}
                        <div className="calllog-icon-container outgoing-call">
                          <Tooltip title={t("Outgoing Call")} arrow>
                            {/* <PhoneForwardedIcon /> */}
                            <img
                              src={config.REACT_APP_PHONE_CALL_FORWARDED_ICON}
                              alt="Outgoing Call Icon"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div
                      className="colllog-date"
                      style={{ fontSize: "1.098rem" }}
                    >
                      <p className="colllog-date-day">
                        {t(formatDateIntoTodayYesterdayFormat(item.datetime,personalizationDateFormat))}
                      </p>
                      <span>{moment(item.datetime).format("hh:mm A")}</span>
                      <p> {formatDuration(item.callDuration)}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    className="calllog-mobile-list-item"
                    onClick={() => {
                      contactDetailModal(item);
                    }}
                  >
                    <div className="d-flex">
                      <Avatar
                        imageType={getItemType(item, false)}
                        avatarHeight="3rem"
                        avatarWidth="3rem"
                        presOrAssoc={getUserName(item, false)}
                      />

                      <div className="calllog-details">
                        {getfromName(item, false)}
                        <div>
                        {item.callDuration == "0" && item.status.toUpperCase()!="BUSY" ? (
                            <div className="calllog-icon-container miss-call">
                              <Tooltip title={t("Missed Call")} arrow>
                                {/* <PhoneCallMissedIcon /> */}
                                <img
                                  src={config.REACT_APP_PHONE_CALL_MISSED_ICON}
                                  alt="Missed Call Icon"
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <div className="calllog-icon-container incoming-call">
                              <Tooltip title={t("Incoming Call")} arrow>
                                {/* <PhoneCalllbackIcon /> */}
                                <img
                                  src={config.REACT_APP_PHONE_CALL_BACK_ICON}
                                  alt="Incoming Call Icon"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="colllog-date"
                      style={{ fontSize: "1.098rem" }}
                    >
                      <p className="colllog-date-day">
                        {t(formatDateIntoTodayYesterdayFormat(item.datetime,personalizationDateFormat))}
                      </p>
                      <span>{moment(item.datetime).format("hh:mm A")}</span>
                      <p> {formatDuration(item.callDuration)}</p>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        <ContactAddPopup
          showAddContactModal={addNumberPopup}
          data={contactData}
          redirectPage={msgOrCallIcon}
          handleCloseParent={(e) => {
            if (!msgOrCallIcon) {
              setShowCallModalPopup(true);
            }
            setShowDetails(false);
            setAddNumberPopup(false);
          }}
        />
      </div>
    </div>
  );
}
