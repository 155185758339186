import { Component } from "react";
import "./Forms.css";
import Tooltip from "@mui/material/Tooltip";
import SearchInput from "../../layouts/searchInput/SearchInput";
import { connect } from "react-redux";
import FormsModal from "../../components/forms/FormsModal";
import {
  getAllFormsHistory,
  getFormRequestsList,
} from "../../apis/forms/formsapi";
import { getForm } from "../../apis/forms/formsapi";
import { UpdateForm } from "../../apis/forms/formsapi";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import LogoutModal from "../../components/logoutModal/LogoutModal";
import { withTranslation } from "react-i18next";

interface MyComponentProps {
  token: string;
}

class Forms extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      formList: [],
      page: 0,
      pageSize: 10,
      search: "",
      show: false,
      showDetails: false,
      contact: [],
      hasMore: true,
      tempValue: "",
      gridView: false,
      token: "",
      infiniteScrollHeight: 10,
      callDetails: {},
      CancelToken: null,
      loading: false,
      selectedContactIndex: 0,
      msgOrCallIcon: false,
      modalShow: false,
      titlename: "Create",
      viewform: false,
      ID: "",
      deleteModal: false,
      FormId: "",
      formtab: "Tab1",
      value: "1",
      formRequestList: [],
      Id: undefined,
    };
  }

  componentDidMount() {
    document.documentElement.style.setProperty("--visibility", "hidden");
    console.log(this.props.token);
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
    this.getData();

    let doc: any = document;
    let formstitles: any = doc.getElementsByClassName("formlist-title")
      ? doc.getElementsByClassName("formlist-title")
      : null;
    if (formstitles)
      this.setState({
        infiniteScrollHeight:
          window.innerHeight -
          (formstitles[0].offsetTop + formstitles[0].offsetHeight) -
          20,
      });
  }
  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>): void {
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", this.props.t(this.props.pageTitle));
  }

  handleClickOpen = (event) => {
    this.setState({ deleteModal: true });
    console.log(event);
    this.setState({ FormId: event });
  };
  deleteContact = (e: any) => {
    if (e === true) {
      console.log("delete:" + this.state.FormId);
      getForm({ Id: this.state.FormId }, (res: any) => {
        const activeflg = false;
        this.setState({ formList: res.reverse() });
        console.log("forms data is here", res);
        let s_Date = res[0].EffectiveStart_DtTm.split("T");
        let e_Date = res[0].EffectiveEnd_DtTm.split("T");
        console.log(s_Date[0]);
        res.map((x: any) => {
          let formupdatepayload = {
            Form_Nm: x.Form_Nm,
            Active_Flg: activeflg == false ? "N" : "Y",
            Form_Typ: x.Form_Typ,
            FormSignReq_Flg: x.FormSignReq_Flg,
            FormSignProvider_Typ: x.FormSignProvider_Typ,
            FormLocation_Url: x.FormLocation_Url,
            FormStorage_Loc: x.FormStorage_Loc,
            FormPrepopulateReq_Flg: x.FormPrepopulateReq_Flg,
            FormCallback_Url: x.FormCallback_Url,
            FormToMedia_Flg: x.FormToMedia_Flg,
            EffectiveStart_DtTm: s_Date[0],
            EffectiveEnd_DtTm: e_Date[0],
            Template_Val: x.Template_Val,
          };
          console.log(formupdatepayload);
          UpdateForm(
            formupdatepayload,
            { FormId: this.state.FormId },
            (res: any) => {
              if (res) {
                console.log(res.formId);
                this.getData();
              } else {
                console.log(res);
              }
            }
          );
        });
      });
    } else {
      this.getData();
    }
  };

  getData = () => {
    getFormRequestsList((res: any) => {
      this.setState({ formRequestList: res });
    });
    getAllFormsHistory((res: any) => {
      this.setState({ formList: res.reverse() });
      console.log("forms data is here", res);
    });
  };
  handleTabChange = (e) => {
    this.setState({ formtab: e });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  getSearch = () => {};

  render() {
    const { formList, page, pageSize, search, hasMore, infiniteScrollHeight } =
      this.state;

    return (
      <div className="contacts-container">
        <FormsModal
          show={this.state.modalShow}
          handleCloseParent={(e) => {
            if (e) {
              this.getData();
            }
            this.setState({ modalShow: false, Id: undefined });
          }}
          title={this.state.titlename}
          Id={this.state.Id}
          viewform={this.state.viewform}
        />

        <div className="Forms-header row--three">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={this.state.value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={this.handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={this.props.t("Form History")} value="1" />
                  <Tab label={this.props.t("Form Status")} value="2" />
                  <div className="formcontent__header__searchbox">
                    <SearchInput
                      id={"allContactSearch"}
                      classes={"form-control font-md search-form"}
                      placeholder={this.props.t("Search Forms")}
                      onClicked={() => {}}
                      onChangeValue={(e) => {
                        this.setState(
                          {
                            search: e,
                            contactList: [],
                            page: 0,
                            hasMore: true,
                          },
                          () => {
                            this.getSearch();
                          }
                        );
                      }}
                    />
                  </div>

                  <Tooltip title={this.props.t("Add Form")} arrow>
                    <div
                      id="createFormId"
                      className="floating-button create_contact notMobile"
                      onClick={() => {
                        this.setState({ modalShow: true, titlename: "Create" });
                      }}
                    >
                      <i className="fas fa-plus"></i>
                    </div>
                  </Tooltip>
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="form-body" id="contacts-body">
                  {this.state.gridView === false ? (
                    <div className="formlist-title notMobile">
                      <div className="FormNameHeader">
                        {this.props.t("Name")}
                      </div>
                      <div className="FormTypeHeader">
                        {this.props.t("Type")}
                      </div>
                      <div className="FormURLHeader">{this.props.t("URL")}</div>
                      <div className="FormStatusHeader">
                        {this.props.t("Status")}
                      </div>
                      <div className="FormDateHeader">
                        {this.props.t("Date")}
                      </div>
                      <div className="FormDateHeader"></div>
                    </div>
                  ) : (
                    ""
                  )}

                  <ul
                    className="contact-scroll-list"
                    style={{ height: infiniteScrollHeight }}
                  >
                    {formList.map((item: any) => {
                      return (
                        <li id={item.Id}>
                          <div className="FormtListItem-div notMobile">
                            <div className="FormName">
                              <div
                                style={{
                                  fontSize: "1.098rem",
                                  fontWeight: "bold",
                                  paddingLeft: "2rem",
                                }}
                              >
                                {item.Form_Nm}
                              </div>
                            </div>

                            <div
                              className="FormType"
                              style={{
                                fontVariant: "small-caps",
                                fontSize: "1.098rem",
                                width: "9%",
                              }}
                            >
                              {item.Form_Typ}
                            </div>
                            <div
                              className="FormURL"
                              style={{
                                fontSize: "1.098rem",
                                width: "33%",
                              }}
                            >
                              <a
                                className="link"
                                target="_blank"
                                href={
                                  item.FormLocation_Url == ""
                                    ? "https://p360zingforms.z13.web.core.windows.net"
                                    : item.FormLocation_Url
                                }
                              >
                                {item.FormLocation_Url == ""
                                  ? "https://p360zingforms.z13.web.core.windows.net"
                                  : item.FormLocation_Url}
                              </a>
                            </div>
                            <div className="Status">
                              {item.Active_Flg == "Y" ? "Active" : "Inactive"}
                            </div>
                            <div
                              className="CreatedDate"
                              style={{
                                fontVariant: "small-caps",
                                fontSize: "1.098rem",
                              }}
                            >
                              {moment(item.CreatedBy_DtTm).format(
                                "MM/DD/YYYY hh:mm a"
                              )}
                            </div>

                            <div
                              style={{
                                paddingLeft: "4rem",
                              }}
                            >
                              <a
                                className="edit"
                                title={this.props.t("Edit")}
                                data-toggle="tooltip"
                                onClick={() => {
                                  this.setState({
                                    modalShow: true,
                                    titlename: "Edit",
                                    Id: item.Id,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xE254;</i>
                              </a>
                              <a
                                className="view"
                                title={this.props.t("View")}
                                data-toggle="tooltip"
                                onClick={() => {
                                  this.setState({
                                    modalShow: true,
                                    titlename: "View",
                                    Id: item.Id,
                                  });
                                }}
                              >
                                <i className="material-icons">&#xe8f4;</i>
                              </a>
                              {item.Active_Flg == "Y" ? (
                                <a
                                  className="delete"
                                  title={this.props.t("Delete")}
                                  data-toggle="tooltip"
                                  onClick={() => {
                                    this.handleClickOpen(item.Id);
                                  }}
                                >
                                  <i className="material-icons">&#xE872;</i>
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  <LogoutModal
                    show={this.state.deleteModal}
                    yorn={this.deleteContact}
                    modalText="Are you sure you want to delete ?"
                    handleCloseParent={(e) => {
                      console.log(e);
                      this.setState({ deleteModal: false });
                    }}
                  />

                  <div className="floating onlyMobile">
                    <Tooltip title={this.props.t("Add Contact")} arrow>
                      <div
                        id="createContactId"
                        className="floating-button create_contact"
                        onClick={() => {
                          this.setState({ show: true });
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="form-request-list">
                  <div className="form-request-list-title notMobile row">
                    <div className="col-sm">{this.props.t("Form Name")}</div>
                    <div className="col-sm">{this.props.t("Prescriber")}</div>
                    <div className="col-sm">{this.props.t("Status")}</div>
                    <div className="col-sm">
                      {this.props.t("Status Updated At")}
                    </div>
                  </div>
                  <div
                    className=" notMobile"
                    style={{ height: infiniteScrollHeight }}
                  >
                    {this.state.formRequestList.map((item: any) => (
                      <div className="form-request-list-item row">
                        <div className="col-sm">{item.Form_Nm}</div>
                        <div className="col-sm">
                          {item.PrescriberAcctFull_Nm}
                        </div>
                        <div className="col-sm">
                          {item.FormStatus.toUpperCase()}
                        </div>
                        <div className="col-sm">
                          {moment(item.FormStatusUpdatedAt).format(
                            "MM/DD/YYYY hh:mm a"
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  token: state.login.token,
  showCallModal: state.callModal.showCallModal,
  pageTitle: state.sideBar.title,
});

export default withTranslation()(connect(mapStateToProps)(Forms));
