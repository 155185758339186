import axios from "axios";
import config  from '../../env.json';
function getCampaignsDataStaff(params,callback){
  let {page,pageSize,searchQuery, campaignId, productId,contactId}=params;
    try {
      axios.get(config.REACT_APP_CAMPAIGN_API_BASE +"/campaign/hierarchystaff", {
        params: {
          searchQuery: searchQuery,
          campaignId,
          productId,
          contactId,
        },
      })
        .then(res=>{
         
          callback(res.data);
        }).catch(()=>{
         
          callback([])
        })
    } catch (error) {
        callback([])
    }
}

export {getCampaignsDataStaff}
