import { useEffect, useState } from "react";
import Avatar from "../../components/Avatar/Avatar";
import "./VideoParticipants.css";
import { getVideoLogParticipants } from "../../apis/video/video";
import moment from "moment";
import Spinner from "../../layouts/spinner/Spinner";

export default function VideoParticipants(props) {
  const [videoPopup, setVideoPopup] = useState(false);
  const [value, setValue] = useState("");
  const [videoParticipantResult, setVideoParticipantResult] = useState(
    []
  ) as any;
  const [spin, setSpin] = useState(false);
  useEffect(() => {
    setSpin(true);
    let doc: any = document;
    let accvideo2 = doc.getElementsByClassName("vchat-main");
    for (var i = 0; i < accvideo2.length; i++) {
      console.log("fakeImage: ", accvideo2[i]);
      accvideo2[i].style.display = "block";
    }
    console.log(props);
    console.log(props.files);
    let fileid = props.files;
    getVideoLogParticipants({ Id: fileid }, (res) => {
      if (res) {
        setSpin(false);
        console.log(res.data.data);
        setVideoParticipantResult(res.data.data);
      }
    });
  }, []);
  const openpopup = (el) => {
    setValue(el);
    setVideoPopup(true);
  };
  return (
    <div className="main-chat-details">
      <div className="vfiles-main notMobile">
        <div className="video-files-headers">
          <div className="video-participant-list-item">
            {"Participant Name"}
          </div>
          <div className="video-participant-list-item">{"Start time"}</div>
          <div className="video-participant-list-item">{"End time"}</div>
          <div className="video-participant-list-item">{"Duration"}</div>
        </div>
      </div>
      <ul className="notMobile">
        {spin && videoParticipantResult.length == 0 ? (
          <Spinner />
        ) : videoParticipantResult.length == 0 ? (
          <div className="validation">No Participants found</div>
        ) : null}
        {videoParticipantResult.map((videoResult: any, key: any) => (
          <>
            <li>
              <div className="vchat-details">
                <div className="participant-logo">
                  <Avatar
                    imageType={videoResult.Participant_typ}
                    avatarHeight="2.5rem"
                    avatarWidth="2.5rem"
                    presOrAssoc={videoResult.Participant_Nm}
                    showAvatarType="initialsOnly"
                  />
                </div>

                <div className="participantListItem-content-name">
                  {videoResult.Participant_Nm}
                </div>
                <div className="participantListItem-content-name">
                  {moment(videoResult.ParticipantStart_DtTm).format("hh:mm A")}
                </div>
                <div className="participantListItem-content-name">
                  {moment(videoResult.ParticipantEnd_DtTm).format("hh:mm A")}
                </div>
                <div className="participantListItem-content-name">
                  {moment
                    .duration(
                      moment(videoResult.ParticipantEnd_DtTm).diff(
                        moment(videoResult.ParticipantStart_DtTm)
                      )
                    )
                    .minutes()}{" "}
                  m
                </div>
              </div>
            </li>
          </>
        ))}
      </ul>
      <div className="participants onlyMobile">
        <ul className="items-view-main">
          {spin && videoParticipantResult.length == 0 ? (
            <Spinner />
          ) : videoParticipantResult.length == 0 ? (
            <div className="mob-validation">No Participants found</div>
          ) : (
            <p className="pchat-length">
              {videoParticipantResult.length}{" "}
              {videoParticipantResult.length == 1
                ? "Participant"
                : "Participants"}{" "}
            </p>
          )}
          {videoParticipantResult.map((videoResult: any, key: any) => (
            <li>
              <div className="pchat-details">
                <div className="participant-logo">
                  <Avatar
                    imageType={videoResult.Participant_typ}
                    avatarHeight="3.5rem"
                    avatarWidth="3.5rem"
                    presOrAssoc={videoResult.Participant_Nm}
                    showAvatarType="initialsOnly"
                  />
                </div>

                <div className="pchat-detail-items">
                  <div className="pchat-detail-item">
                    <p className="pchat-name">{videoResult.Participant_Nm}</p>
                    <p className="pchat-date">
                      {moment(videoResult.ParticipantStart_DtTm).format(
                        "dddd, Do MMM, hh:mma"
                      )}
                    </p>
                  </div>
                  <div className="pchat-detail-item">
                    <p className="pchat-duration">
                      {moment
                        .duration(
                          moment(videoResult.ParticipantEnd_DtTm).diff(
                            moment(videoResult.ParticipantStart_DtTm)
                          )
                        )
                        .minutes() == 0
                        ? "0"
                        : moment
                            .duration(
                              moment(videoResult.ParticipantEnd_DtTm).diff(
                                moment(videoResult.ParticipantStart_DtTm)
                              )
                            )
                            .minutes()}{" "}
                      mins
                    </p>
                    <p className="pchat-time">
                      {moment(videoResult.ParticipantEnd_DtTm).format("hh:mma")}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
