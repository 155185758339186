import React, { useState, useEffect } from "react";
import RescheduleModal from "../requests/RescheduleModal";
import "./Requests.css";
import config from "../../env.json";
import axios from "axios";
import moment from "moment";
import Spinner from "../../layouts/spinner/Spinner";
import { useTranslation } from "react-i18next";
export default function Requests(props) {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const [requestData, setRequestData] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [spin, setSpin] = useState(false);
  const event = [
    {
      title: "event 1",
      start: "2022-07-14T10:00:00",
      end: "2022-07-14T12:00:00",
    },
    {
      title: "event 2",
      start: "2022-07-14T13:00:00 - 2022-07-14T18:00:00",
      end: "2022-07-14T18:00:00",
    },
    { title: "event 3", start: "2022-07-14", end: "2022-07-14" },
  ];
  useEffect(() => {
    if (props.autorefresh) {
      getRequests();
    }
    getReqHeight();
    console.log(requestData);
    console.log(props.autorefresh);
  }, [props.autorefresh]);

  const getReqHeight = () => {
    let viewportHeight = window.innerHeight;
    let div: any = document.getElementById("main");
    let requestdiv: any = document.getElementsByClassName("requestTabPanel")[0];
    let toolbardiv: any =
      document.getElementsByClassName("appointment-header")[0];
    let toolbarmargin: any = parseInt(
      window.getComputedStyle(toolbardiv, "").getPropertyValue("margin-bottom")
    );
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let reqPadding: any = parseInt(
      window.getComputedStyle(requestdiv, "").getPropertyValue("padding")
    );
    let doc: any = document;
    if (window.innerWidth <= 1200) {
      if (doc.getElementsByClassName("calendar-container")[0]) {
        const finalheight =
          viewportHeight -
          (doc.getElementById("MobHeader").offsetHeight +
            document.getElementById("headerVal")?.offsetHeight +
            doc.getElementsByClassName("CalendarBox")[0].offsetHeight +
            doc.getElementById("footer").offsetHeight +
            doc.getElementsByClassName("appointment-header")[0].offsetHeight +
            toolbarmargin) -
          divpaddingTop -
          divpaddingbottom -
          reqPadding -
          reqPadding;
        console.log(finalheight);

        doc.querySelector(".requestBody").style.height = finalheight + "px";
      }
    }
  };

  const getRequests = () => {
    setSpin(true);
    axios
      .get(
        config.REACT_APP_CALENDAR_API_BASE +
          "/calendarevent?PageSize=10000&Page=0&Status_Val=request"
      )
      .then((res) => {
        setSpin(false);
        console.log(res.data.data.reverse());
        let temp = res.data.data.sort((a: any, b: any) => b.Id - a.Id);
        let count = res.data.data.length;
        props.setCount(count);
        props.stopautorefresh();
        let result: any = [];
        let presc_name: any;
        temp.map((x, index) => {
          if (x.Status_Val == "request") {
            if (x.Participants.length > 0) {
              if (
                x.Participants[0].ParticipantType == "prescriber_associate" &&
                x.Participants[0].Prescriber_Associate != undefined
              ) {
                presc_name = x.Participants[0].Prescriber_Associate.AcctFull_Nm;
              } else if (x.Participants[0].Prescriber != undefined) {
                presc_name = x.Participants[0].Prescriber.AcctFull_Nm;
              }
              result.push({
                Id: x.Id,
                EmployeeId: x.Employee_id,
                start: x.RequestStart_DtTm,
                endtime: x.RequestedEnd_DtTm,
                subject: x.Subject_Val,
                Description: x.Description_Val,
                Name: presc_name,
              });
            }
          }
        });
        console.log(result);
        let tempval = result;
        setRequestData(result);
        console.log(requestData);
      })
      .catch((err) => {
        setSpin(false);
        console.log("Freetime Error:" + err);
      });
  };
  const handleAcceptClick = (event) => {
    console.log(event);
    axios
      .post(
        config.REACT_APP_CALENDAR_API_BASE + "/calendarevent",
        {
          Employee_id: event.EmployeeId,
          RequestStart_DtTm: event.start,
          RequestedEnd_DtTm: event.endtime,
          Subject_Val: event.subject,
          Description_Val: event.Description,
          Status_Val: "accept",
          Id: event.Id,
          ProposedStart_DtTm: event.start,
          ProposedEnd_DtTm: event.endtime,
          RejectReason_Val: "I am available",
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("login"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        getRequests();
      })
      .catch((err) => {
        console.log("Freetime Error:" + err);
      });
  };
  const handleRescheduleClick = (e) => {
    console.log(e);
    setModalShow(true);
    setPopupData(e);
  };
  const getHeight = () => {
    let viewportHeight = window.innerHeight;
    let doc: any = document;
    if (doc.getElementsByClassName("requestBody")[0])
      return (
        viewportHeight -
        doc.getElementsByClassName("requestBody")[0].offsetTop -
        50
      );
    else return "100%";
  };
  return (
    <>
      <RescheduleModal
        show={modalShow}
        onHide={(x) => {
          getRequests();
          setModalShow(false);
        }}
        value={true}
        start={popupData}
      />
      <div className="col-md-12 calendar-request">
        <div className="appointment-header">
          <h3 className="appt-name">{t("Appointment Request")}</h3>
        </div>
        <div className="Requestcard">
          <div className="card-body requestBody">
            {spin ? <Spinner /> : null}
            {requestData.map((item: any) => (
              <div className="card m-0 p-1">
                <div className="col-lg-12">
                  <div className="font-sm m-0 d-flex flex-column">
                    <label>
                      <b>{t("Name")}: </b>
                      {item.Name}
                    </label>
                  </div>
                  <div className="font-sm m-0 d-flex flex-column">
                    <label className="mb-1 ">
                      <b>{t("Start Time")}: </b>
                      {moment(item.start).format("MM/DD/YYYY HH:mm A")}
                    </label>
                    <label className="mb-1 ">
                      <b>{t("End Time")}: </b>
                      {moment(item.endtime).format("MM/DD/YYYY HH:mm A")}
                    </label>
                  </div>
                  <div className="" style={{ marginBottom: "0.3rem" }}>
                    <label>
                      <b>{t("Subject")}:</b>
                      {item.subject}
                    </label>
                  </div>
                  <div className="">
                    <label>
                      <b>{t("Description")}: </b>
                      {item.Description}{" "}
                    </label>
                  </div>
                </div>
                <div className="d-flex m-auto mb-1 appointment-btn">
                  <button
                    className="btn btn-primary btn-primary-accept font-sm mr-2 accept-btn"
                    onClick={(e) => handleAcceptClick(item)}
                    disabled={
                      item.endtime < new Date().toISOString() ? true : false
                    }
                  >
                    {" "}
                    {t("Accept")}{" "}
                  </button>
                  <button
                    className="btn btn-danger font-sm reject-btn"
                    onClick={(e) => handleRescheduleClick(item)}
                  >
                    {" "}
                    {t("Reschedule")}{" "}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
