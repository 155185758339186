import { useEffect, useState } from "react";
import config from "../../env.json";
import MultiselectDropdown from "../../layouts/mutliselectDropdown/MultiselectDropdown";
import "./Templates.css";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import Spinner from "../../layouts/spinner/Spinner";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { useSelector } from "react-redux";
import PreviewComponent from "../../components/chat/PreviewComponent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
export default function Templates() {
  const { t } = useTranslation();

  const [template, setTemplate] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [TemplateHeight, setTemplateHeight] = useState(0);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [activeCampaign, setActiveCampagign] = useState([]);
  const [allWaves, setAlllWaves] = useState([]);
  const [activeWaves, setActiveWaves] = useState([]);
  const [allProducts, setAlllProducts] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [spin, setSpin] = useState(false);
  const pageTitle = useSelector((state: any) => state.sideBar.title);
  const translatedValues = useSelector(
    (state: any) => state.personalisation.transData
  );
  const EmployeePermissionCountry_Cd = useSelector(
    (state: any) => state.personalisation.EmployeePermissionCountry_Cd
  );

  useEffect(() => {
    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener(
      "resize",
      function (event) {
        let device = getDeviceType();
        if (
          device == "mobile" ||
          device == "tablet" ||
          config.REACT_APP_IS_CROSS_PLATFORM
        ) {
          setIsMobile(true);
          getHeight();
        } else setIsMobile(false);
      },
      true
    );
    getHeight();
    getTemplatesFromCampign();
    document.documentElement.style.setProperty("--visibility", "hidden");
    console.log(pageTitle);
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--visibility", "initial");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
  }, [pageTitle, translatedValues]);
  const getTemplatesFromCampign = () => {
    setSpin(true);
    getCampaignsData({ page: 0, pageSize: 10 }, (res) => {
      setSpin(false);
      if (res.length > 0) {
        let tempAssets: any = [];

        console.log(res);
        let dataForTree = res;
        let campaigns: any = [];
        let waves: any = [];
        let products: any = [];

        res.map((x: any) => {
          campaigns.push(x);
          x.type = "Campaign";
          x.idName = ["type", "CampaignName", "CamapignId"];
          x.labelName = "CampaignName";
          x.childName = "WaveDetails";
          x.WaveDetails.map((y: any) => {
            y.UiKey = y.WaveId + "-" + x.CamapignId; // create a new unique key for ui loading as the WaveId is not unique
            waves.push(y);
            y.type = "Wave";
            y.idName = ["type", "WaveName", "WaveId"];
            y.labelName = "WaveName";
            y.childName = "ProductDetails";
            y.parentName = y.WaveName + "(" + x[x.labelName] + ")";
            y.ProductDetails.map((z: any) => {
              z.waveId = y.WaveId;
              z.UiKey = y.WaveId + "-" + x.CamapignId + "-" + z.ProductId; //Unique key for product
              products.push(z);
              z.type = "Product";
              z.idName = ["type", "ProductName", "ProductId"];
              z.labelName = "ProductName";
              z.childName = "TemplateDetails";
              z.parentName = z["ProductName"] + "(" + y[y.labelName] + ")";
           //   z.TemplateDetails = filter24HourTemplateList(z.TemplateDetails);
              z.TemplateDetails = z.TemplateDetails
              z.TemplateDetails = z.TemplateDetails.sort(function (
                a: any,
                b: any
              ) {
                return a.SortOrder_Nbr - b.SortOrder_Nbr;
              });
              z.TemplateDetails.map((p: any) => {
                p.type = "Template";
                if (p.FormId) {
                  p.TemplateName = p.FormName;
                }
                p.idName = ["type", "TemplateName", "TemplateId"];
                p.labelName = "TemplateName";
                p.childName = "AssetDetails";
                p.parentName = p.TemplateName + "(" + z[z.labelName] + ")";
                p.AssetDetails.map((o: any) => {
                  o.mainItem = true;
                  o.type = "Asset";
                  o.idName = ["type", "AssetFile_Nm", "AssetId"];
                  o.labelName = "AssetFile_Nm";
                  return o;
                });
                tempAssets.push(p);
                return p;
              });
              return z;
            });
            return y;
          });
          return x;
        });

        setAllCampaigns(campaigns);
        setActiveCampagign(campaigns);

        setAlllProducts(products);
        setActiveProducts(products);

        setAlllWaves(waves);
        setActiveWaves(waves);
        let sortedTemplates = tempAssets.sort(function(a, b) {
        let textA = a.TemplateName.toUpperCase();
        let textB = b.TemplateName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        setTemplate(sortedTemplates);
        getHeight();
      }
    });
  };

  const selectActiveProducts = (e) => {
    setActiveProducts(e);
    let a = [];
    e.map((x: any) => {
      a = a.concat(x.TemplateDetails);

      return x;
    });
    let sortedTemplates = a.sort(function(a:any, b:any) {
    let textA = a.TemplateName.toUpperCase();
    let textB = b.TemplateName.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setTemplate(sortedTemplates);
  };

  const selectActiveWave = (e) => {
    setActiveWaves(e);
    let p = [];
    e.map((x) => {
      p = p.concat(x.ProductDetails);
      return x;
    });
    console.log(p);

    selectActiveProducts(p);
    setAlllProducts(p);
  };
  const selectActiveCampaign = (e) => {
    setActiveCampagign(e);
    let w = [];
    e.map((x) => {
      w = w.concat(x.WaveDetails);
      return x;
    });
    selectActiveWave(w);
    setAlllWaves(w);
  };
  useEffect(() => {
    getHeight();
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        getHeight();
      }, 0);
    });
    return () => {
      window.removeEventListener("orientationchange", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
    };
  }, []);

  useEffect(() => {
    getHeight();
  }, [allProducts]);

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        getHeight();
      }, 0);
    });
    return () => {
      window.removeEventListener("orientationchange", () => {
        setTimeout(() => {
          getHeight();
        }, 0);
      });
    };
  }, []);

  const filter24HourTemplateList = (templateList) =>
    templateList.filter((template) => template.TemplateType !== "24H");

  const linkProps = {
    onClick: (event) => {
      event.preventDefault();
    },
  };

  const getTabtoPreviewData = (value, type) => {
    if (config.REACT_APP_VIDEO_PREVIEW_FLAG === "Y") {
      return (
        <div>
          <PreviewComponent value={value.trimStart()} />

          <div>
            <span
              onClick={() => {
                window.open(
                  value.indexOf("https://") >= 0 ||
                    value.indexOf("http://") >= 0
                    ? value
                    : "https://" + value,
                  "_blank"
                );
              }}
            >
              {" "}
              {value}
            </span>
          </div>
        </div>
      );
    } else {
      return value;
    }
  };

  const getHeight = () => {
    let viewportHeight = window.visualViewport?.height ?? window.innerHeight;
    setTemplateHeight(viewportHeight);
  };
  return (
    <div className="templates-container">
      <div
        className={`templates-header ${
          isMobile && allProducts.length <= 1 ? "p-0" : ""
        }`}
      >
        <div className="d-flex fullwidth">
          {!isMobile ? (
            <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Campaign")}
                selectedData={activeCampaign}
                data={allCampaigns}
                name="CampaignName"
                id="CamapignId"
                func={(e) => {
                  selectActiveCampaign(e);
                }}
              />
            </div>
          ) : null}

          {allWaves.length > 1 && !isMobile ? (
            <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Wave")}
                selectedData={activeWaves}
                data={allWaves}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveWave(e);
                }}
              />
            </div>
          ) : null}
          {allProducts.length > 1 ? (
            <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Brand")}
                selectedData={activeProducts}
                data={allProducts}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveProducts(e);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="templates-body"
        style={{
          height:
            getDeviceType() === "desktop" ? "unset" : TemplateHeight - 140,
        }}
      >
        <div className="list-title templateHeader notMobile">
          <div className="templateHeaderItem">{t("Template Name")}</div>
          <div className="templateHeaderItem">{t("Template Body")}</div>
          <div className="templateHeaderItem">{t("Attachment")}</div>
          <div className="templateHeaderItem">{t("")}</div>
        </div>
        {spin ? <Spinner /> : null}
        {template.length > 0 ? (
          <ul>
            {template
              .map((item: any) => {
                return (
                  <li id={item.id}>
                    {isMobile ? (
                      <div className="templatesListItem-div">
                        <div
                          className="temp d-flex"
                          style={{ fontSize: "1.098rem", fontWeight: "bolder" }}
                        ><div>
                          {item.TemplateName}</div>
                          <div>
                          {item.TemplateType === "24H" &&
                                    item.WhatsappApproved_Flg === "Y" && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="whatsapp twenty-four-hr-template twenty-fourH"
                                      >
                                        <g>
                                          <path fill="none" d="M0 0H24V24H0z" />{" "}
                                          <path d="M12 13c1.657 0 3 1.343 3 3 0 .85-.353 1.616-.92 2.162L12.17 20H15v2H9v-1.724l3.693-3.555c.19-.183.307-.438.307-.721 0-.552-.448-1-1-1s-1 .448-1 1H9c0-1.657 1.343-3 3-3zm6 0v4h2v-4h2v9h-2v-3h-4v-6h2zM4 12c0 2.527 1.171 4.78 3 6.246v2.416C4.011 18.933 2 15.702 2 12h2zm8-10c5.185 0 9.449 3.947 9.95 9h-2.012C19.446 7.054 16.08 4 12 4 9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4z" />
                                        </g>
                                      </svg>
                                    )}
                                    </div>
                        </div>
                        <div className="temp" style={{ fontSize: "1.098rem" }}>
                          {item.TemplateBody.replace(/(?:\r\n|\r|\n)/g, "\n")}
                        </div>
                        <div
                          className="temp"
                          style={{ fontSize: "1.098rem" }}
                        ></div>
                        <div className="temp" style={{ fontSize: "1.098rem" }}>
                          {item.Type}
                        </div>
                        <div className="temp" style={{ fontSize: "1.098rem" }}>
                          {item.AssetDetails.map((x) => (
                            <div>
                              <a
                                href={x.AssetFile_Url}
                                target="__blank"
                                className="templatesListItem-hyperlink"
                              >
                                {" "}
                                {x.Asset_Nm}
                              </a>
                              {x.AssetFile_Typ != "random" ? (
                                <a
                                  href={x.AssetFile_Url}
                                  target="__blank"
                                  className="templatesListItem-hyperlink template-download"
                                  download={x.AssetFile_Nm}
                                >
                                  <i className="fas fa-download fa-x "></i>
                                </a>
                              ) : null}
                            </div>
                          ))}
                        </div>
                        {item.WhatsappApproved_Flg === "Y" &&
                        config.REACT_APP_CHAT_ICON === "Y" ? (
                          <WhatsAppIcon className="whatsapp"></WhatsAppIcon>
                        ) : null}
                        {item.TemplateFooter && item.TemplateFooter !== "" && (
                          <div className={EmployeePermissionCountry_Cd =="BR" ? `mt-3 temp-footer`: `mt-1 temp-footer`}>
                            {item.TemplateFooter}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="templatesListItem-div">
                        <div
                          className="temp d-flex space"
                          style={{ fontSize: "1.098rem", fontWeight: "bolder" }}
                        >
                          {item.TemplateName}
                          {item.TemplateType === "24H" &&
                                    item.WhatsappApproved_Flg === "Y" && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="whatsapp twenty-four-hr-template twenty-fourH"
                                      >
                                        <g>
                                          <path fill="none" d="M0 0H24V24H0z" />{" "}
                                          <path d="M12 13c1.657 0 3 1.343 3 3 0 .85-.353 1.616-.92 2.162L12.17 20H15v2H9v-1.724l3.693-3.555c.19-.183.307-.438.307-.721 0-.552-.448-1-1-1s-1 .448-1 1H9c0-1.657 1.343-3 3-3zm6 0v4h2v-4h2v9h-2v-3h-4v-6h2zM4 12c0 2.527 1.171 4.78 3 6.246v2.416C4.011 18.933 2 15.702 2 12h2zm8-10c5.185 0 9.449 3.947 9.95 9h-2.012C19.446 7.054 16.08 4 12 4 9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4z" />
                                        </g>
                                      </svg>
                                    )}
                        </div>
                        <div className="temp" style={{ fontSize: "1.098rem" }}>
                          {item.TemplateBody.replace(/(?:\r\n|\r|\n)/g, "\n")}
                          {item.TemplateFooter &&
                            item.TemplateFooter !== "" && (
                              <div className={EmployeePermissionCountry_Cd =="BR" ? `mt-3 temp-footer-new`: `mt-1 temp-footer-new`}>
                                {item.TemplateFooter}
                              </div>
                            )}
                        </div>
                        <div className="temp" style={{ fontSize: "1.098rem" }}>
                          {item.AssetDetails.map(
                            (x) => (
                              <div>
                                <a
                                  href={x.AssetFile_Url}
                                  target="__blank"
                                  className="templatesListItem-hyperlink"
                                >
                                  {" "}
                                  {x.Asset_Nm}
                                </a>
                                {x.AssetFile_Typ != "random" ? (
                                  <a
                                    href={x.AssetFile_Url}
                                    target="__blank"
                                    className="templatesListItem-hyperlink template-download"
                                    download={x.AssetFile_Nm}
                                  >
                                    <i className="fas fa-download fa-x "></i>
                                  </a>
                                ) : null}
                              </div>
                            )
                            // )
                          )}
                        </div>
                        {item.WhatsappApproved_Flg === "Y" &&
                        config.REACT_APP_CHAT_ICON === "Y" ? (
                          <WhatsAppIcon className="whatsapp"></WhatsAppIcon>
                        ) : null}
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "large",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            {!spin ? <span>{t("No templates found")}</span> : null}
          </div>
        )}
      </div>
    </div>
  );
}
