import { useEffect, useState } from "react";
import MicOff from "@mui/icons-material/MicOff";
import { useTranslation } from "react-i18next";

function ParticipantItem({ local, participant }) {
  const { t } = useTranslation();
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [muted, setMuted] = useState(false);
  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);
  useEffect(() => {
    if (participant.audioTracks instanceof Map)
      setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log("un");
      if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setAudioTracks([]);
    };
  }, [participant]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (!audioTrack.isEnabled) {
        setMuted(true);
      }
      audioTrack.on("disabled", () => {
        setMuted(true);
      });
      audioTrack.on("enabled", () => {
        setMuted(false);
      });

      return () => {
        audioTrack.off("disabled", () => {
          setMuted(false);
        });
        audioTrack.off("enabled", () => {
          setMuted(false);
        });
      };
    }
  }, [audioTracks]);
  if (participant)
    return (
      <h6 style={{ padding: "1rem", display: "flex" }}>
        <div id={"participnat-" + participant.identity + participant.sid}>
          {" "}
          {participant.identity}
          {local ? <span>({t("You")})</span> : null}
        </div>
        <div>{muted ? <MicOff /> : null}</div>
      </h6>
    );
  else return null;
}

export default ParticipantItem;
