import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { initReactI18next } from "react-i18next";
import { store } from "./app/newStore";
import App from "./App";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import config from "./env.json";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import * as translang from "../src/assets/translations/translation.json";

i18n.use(initReactI18next).init({
  // the translations    // (tip move them in a JSON file and import them,    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)    // resources: transData[0],    // resources: {},
  resources: translang,
  lng: config.REACT_APP_DEFAULT_LANGUAGE, // if you're using a language detector, do not define the lng option
  interpolation: {
    escapeValue: false,
  },
});

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    msalInstance.setActiveAccount(account);
  }
});

if (
  config.REACT_APP_ENV === "UAT" ||
  config.REACT_APP_ENV === "QA" ||
  config.REACT_APP_ENV === "Prod"
) {
  console.log = () => {};
  console.info = () => {};
  console.error = () => {};
}

const container =
  document.getElementById("root") || document.createElement("div");
const root = ReactDOM.createRoot(container);

let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={config.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App pca={msalInstance} />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

reportWebVitals();
