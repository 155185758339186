import React, { useEffect, useState, useCallback } from "react";
import Avatar from "@mui/material/Avatar";
import "./Assets.css";
import { Tooltip } from "@mui/material";

import axios from "axios";
import DropdownInupt from "../../components/shared/Dropdown";
import MultiselectDropdown from "../../layouts/mutliselectDropdown/MultiselectDropdown";
import TreeView from "../../layouts/treeView/TreeView";
import config from "../../env.json";

import { useDispatch ,useSelector} from 'react-redux';
import {setActiveTab} from '../../app/slice/sideBarSlice';
import {getCampaignsData} from '../../apis/campaigns/campaigns'
import Spinner from '../../layouts/spinner/Spinner';
import { useTranslation } from "react-i18next";
import {getDeviceType} from '../../utils/deviceTypeUtil';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function Assets() {
  const { t } = useTranslation();
  const pageTitle=useSelector((state:any)=>state.sideBar.title)
  const dispatch= useDispatch()
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [campaign, setCampaign] = useState([]);
  const [userId, setUserId] = useState(1000);
  const [assetHeight,setAssetHeight]=useState<number>(0);  
  
  
  const [activeWave, setActiveWave] = useState([]);
  const [waves, setWaves] = useState([]);
  const [assets, setAssets] = useState([]);
  const [dataForTree, setDataForTree] = useState([]);

  const [isMobile, setIsMobile] = useState(false);
  const [ListView, setListView] = useState(true);


  const [allCampaigns,setAllCampaigns]=useState([]);
  const [activeCampaign, setActiveCampagign] = useState([]);
  const [allWaves,setAlllWaves]=useState([]);
  const [activeWaves,setActiveWaves]=useState([]);
  const [allProducts,setAlllProducts]=useState([]);
  const [activeProducts,setActiveProducts]=useState([]);  
  const [spin,setSpin]=useState(false);
  const [uniqueKeyCampaign, setUniqueKeyCampaign]=useState<any>()
  const translatedValues=useSelector((state: any) => state.personalisation.transData);

  useEffect(() => {
   // dispatch(setActiveTab("Assets"))
    getAssetsFromCampign(userId);
    //getCampaign();
    document.documentElement.style.setProperty("--visibility", "hidden");
    document
      .getElementById("page-name")
      ?.setAttribute("data-content", t(pageTitle));
      
      let device = getDeviceType()
      if(device=="mobile" || device=="tablet" || config.REACT_APP_IS_CROSS_PLATFORM){
      setIsMobile(true);
    } else setIsMobile(false);
    window.addEventListener(
      "resize",
      function (event) {
        
        let device = getDeviceType()
      if(device=="mobile" || device=="tablet" ||  config.REACT_APP_IS_CROSS_PLATFORM){
          setIsMobile(true);
        } else setIsMobile(false);
      },
      true
    );
    
  }, []);
  useEffect(()=>{
    document.documentElement.style.setProperty('--visibility', 'initial');
    document.getElementById("page-name")?.setAttribute('data-content', t(pageTitle));
  },[pageTitle,translatedValues])
  const getAssetsFromCampign = (userId: any) => {
    
    setSpin(true);
   getCampaignsData({page:0,pageSize:10},(res)=>{
    setSpin(false);
    if (res.length>0) {
            let tempAssets: any = [];
            
            console.log(res);
            let dataForTree = res;
            // dataForTree = dataForTree.map((x: any) => {
            //   x.type = "Campaign";
            //   x.idName = ["type", "campaignNm", "id"];
            //   x.labelName = "campaignNm";
            //   x.childName = "Wave";
            //   x.Waves.map((y: any) => {
            //     y.type = "Wave";
            //     y.idName = ["type", "campaignWaveNm", "id"];
            //     y.labelName = "campaignWaveNm";
            //     y.childName = "relatedAsset";
            //     y["relatedAsset"] = [];
            //     y["relatedAsset"].push({
            //       ...y.campaignWaveAsset,
            //       mainItem: true,
            //       type: "Asset",
            //       idName: ["type", "assetNm", "id"],
            //       labelName: "assetNm",
            //     });
            //     return y;
            //   });
            //   return x;
            // });

            //setDataForTree(dataForTree);
            // setWaves(res[0].Waves);
            // setActiveWave(res[0].Waves);
            // setCampaign(res);
            let campaigns:any=[];
            let waves:any=[];
            let products:any=[];

dataForTree= res.map((x:any) => {
  
  campaigns.push(x);  
  x.type = "Campaign";
  x.idName = ["type", "CampaignName", "CamapignId"];
  x.labelName = "CampaignName";
  x.childName = "WaveDetails";
  x.WaveDetails.map((y: any) => {
    y.UiKey = (y.WaveId)+'-'+(x.CamapignId); // create a new unique key for ui loading as the WaveId is not unique
    waves.push(y);
    y.type = "Wave";
    y.idName = ["type", "WaveName", "WaveId"];
    y.labelName = "WaveName";
    y.childName = "ProductDetails";
    y.parentName = y.WaveName+'('+x[x.labelName]+')';
    
    //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
    y.ProductDetails.map((z:any)=>{
      z.waveId=y.WaveId;
      z.UiKey = (y.WaveId)+'-'+(x.CamapignId)+'-'+(z.ProductId); //Unique key for product 
      products.push(z)
      z.type = "Product";
      
    z.idName = ["type", "ProductName", "ProductId"];
    z.labelName = "ProductName";
    z.childName = "TemplateDetails";
    z.parentName = z['ProductName']+'('+y[y.labelName]+')';
    z.TemplateDetails= z.TemplateDetails.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
    console.log(z.TemplateDetails)
      z.TemplateDetails.map((p:any)=>{
        p.type = "Template";
        if(p.FormId){
          p.TemplateName=p.FormName
        }
    p.idName = ["type", "TemplateName", "TemplateId"];
    p.labelName = "TemplateName";
    p.childName = "AssetDetails";
    p.parentName = p.TemplateName+'('+z[z.labelName]+')';
        tempAssets=tempAssets.concat(p.AssetDetails)
        p.AssetDetails.map((o:any)=>{
          o.mainItem=true
      o.type= "Asset"
      o.idName= ["type", "Asset_Nm", "AssetId"]
      o.labelName="Asset_Nm"
          return o;
        })
        return p;

        //tempAssets.push(y.campaignWaveAsset);
      })
      return z;
    })
    return y;
  });
  return x;
});
        dataForTree.map((item) => {
          item.WaveDetails.map((wave) => {
            wave.ProductDetails.map((prod) => {
              prod.TemplateDetails = prod.TemplateDetails.filter((temp) => {
              //  return temp.Category_Cd != "24H";
              return temp
              });
            });
          });
        });
            console.log(dataForTree)
            setDataForTree(dataForTree);
            setAllCampaigns(campaigns);
            setActiveCampagign(campaigns);

            setAlllProducts(products);
            setActiveProducts(products)

            setAlllWaves(waves);
            setActiveWaves(waves);
            // tempAssets =tempAssets.sort(function(a:any,b:any){return a.SortOrder_Nbr - b.SortOrder_Nbr})
            // tempAssets = tempAssets.sort(function (a: any, b: any) {return a.AssetId - b.AssetId})
            let sortedAssets = tempAssets.sort(function(a, b) {
            let textA = a.Asset_Nm.toUpperCase();
            let textB = b.Asset_Nm.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          setAssets(sortedAssets);
   }
  }
   )

 
  };
  // const selectCampagin = (e: any) => {
  //   setCampaign(e);
  //   setWaves(e.refCampaignWaves);
  //   setActiveWave(e.refCampaignWaves);
  //   let tempAssets: any = [];
  //   e.map((x) => {
  //     x.refCampaignWaves.map((y: any) => {
  //       tempAssets.push(y.campaignWaveAsset);
  //       return y;
  //     });
  //     return x;
  //   });
  //   // tempAssets.push(res.data.data[0].refCampaignWaves[0].campaignWaveAsset);
  //   setAssets(tempAssets);
  // };
  // const selectWave = (e: any) => {
  //   // setWaves(e);
  //   setActiveWave(e);
  //   let tempAssets: any = [];
  //   e.map((x: any) => {
  //     tempAssets.push(x.campaignWaveAsset);
  //   });
  //   setAssets(tempAssets);
  // };

  // const getHeight=()=>{
    // let viewportHeight = window.innerHeight;
    // let div:any=document.getElementById('main');
    // let divpaddingTop:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-top'));
    // let divpaddingbottom:any=parseInt(window.getComputedStyle(div, '').getPropertyValue('padding-bottom'));
    // let doc:any=document;
    // if(doc.getElementsByClassName('assets-body')[0])
    // return (viewportHeight -(doc.getElementById('MobHeader').offsetHeight+document.getElementById('headerVal')?.offsetHeight+doc.getElementsByClassName('assets-header')[0].offsetHeight+doc.getElementById('footer').offsetHeight)-divpaddingTop-divpaddingbottom) ;
    // else return "100%"
  // }
  useEffect(() => {
    getHeight();
  },[allProducts])

  useEffect(() => {
    window.addEventListener('orientationchange', getHeight);
    window.addEventListener('resize', getHeight);
    return () => {
      window.removeEventListener('orientationchange', getHeight)
    }
  }, []) 

  const getHeight = () => {
    let viewportHeight = window.visualViewport?.height ?? window.innerHeight;
    if(document.getElementsByClassName('assets-body')[0]){
      setAssetHeight(viewportHeight);
    }
  }
   
  const selectActiveProducts=(e)=>{
    setActiveProducts(e);
    let a=[];
    e.map((x:any)=>{
      x.TemplateDetails
      .map((p:any)=>{
        a=a.concat(p.AssetDetails)
        return p;
        //tempAssets.push(y.campaignWaveAsset);
      })
      return x;
    })
    
    let sortedAssets = a.sort(function(c:any, d:any) {
      let textA = c.Asset_Nm.toUpperCase();
      let textB = d.Asset_Nm.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setAssets(sortedAssets);

  }
  const selectActiveWave=(e)=>{
    setActiveWaves(e);
    let p=[];
    e.map(x=>{
      p=p.concat(x.ProductDetails);
      return x;
    })
    console.log(p)
    
    selectActiveProducts(p);
    setAlllProducts(p)
  }
  const selectActiveCampaign=(e)=>{

    setActiveCampagign(e);
    let w=[];
    e.map(x=>{
      w=w.concat(x.WaveDetails
        );
      return x;
    })
    //w=w.concat(e.map(x=>x.Waves));
    selectActiveWave(w);
    setAlllWaves(w);
  }
  return (
    <div className="assets-container">
      <div className={`${(isMobile && allProducts.length < 2) ? 'assets-header-mobile':'assets-header'}`}>
        <></>
        {ListView ? (
          <div className="d-flex fullwidth" id={(isMobile && allProducts.length < 2) ? 'dropdown-assets-height-0':'dropdown-assets'}>
            {/* <DropdownInupt
              label="Campaign"
              data={allCampaigns}
              selectedData={ac}
              name="Campaign_Nm"
              func={(e) => {
                selectCampagin(e);
              }}
              
            /> */}
            {
             allCampaigns.length > 1 && !isMobile?<div className="filterDropdownAssets">
              <MultiselectDropdown
                  label={t("Campaign")}
                  selectedData={activeCampaign}
                  data={allCampaigns}
                  name="CampaignName"
                  id="CamapignId"
                  func={(e) => {
                    selectActiveCampaign(e);
                  }}
                />
                </div>:null
            }
            

            {allWaves.length > 1 && !isMobile ? (
               <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Wave")}
                selectedData={activeWaves}
                data={allWaves}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveWave(e);
                }}
                
              />
              </div>
            ) : null}
            {allProducts.length > 1 ? (
              <div className="filterDropdownAssets">
              <MultiselectDropdown
                label={t("Brand")}
                selectedData={activeProducts}
                data={allProducts}
                name="parentName"
                id="UiKey"
                func={(e) => {
                  selectActiveProducts(e);
                }}
              />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="contactListView notMobile assetListViewButtonsContainer">
        <Tooltip title={t('List View')} arrow>
          <i
            id="listFormatId"
            className="fas fa-list i-2p5"
            onClick={() => {
              setListView(true);
            }}
          ></i>
          </Tooltip>
          <Tooltip title={t('Hierarchy View')} arrow>
          <i
            id="cardFormatId"
            className="fas fa-sitemap i-2p5"
            onClick={() => {
              setListView(false);
            }}
          ></i>
          </Tooltip>
        </div>
      </div>
      {ListView ? (
        <div className="assets-body" style={{height: getDeviceType() === "desktop" ? "unset" : (assetHeight - 130)}}>
          <div className="list-title notMobile">
            <div className="w-25">{t('Assets Name')}</div>
            <div className="w-30 d-flex">{t('Assets Body')}</div>
            <div className="">{t('Action')}</div>
            {/* <div className="">{t('')}</div> */}
          </div>
          {spin?<Spinner/>:null}
          {assets.length > 0 ? (
            <ul className="asset-list-ul">
              {assets.map((item: any) => {
                return (
                  <li key={item.Id} id={item.Id}>
                    {isMobile ? (
                      <div className="assetsListItem-div">
                        <div className="p-0 mx-1 d-xl-none onlyMobile">
                          <div className="avatar avt-5">
                            <i className="far fa-file-pdf fa-x "></i>
                          </div>
                        </div>
                        <div style={{paddingLeft:'0.4rem',flex:'1'}}>
                          <h3
                            style={{
                              fontVariant: "",
                              fontSize: "1.098rem",
                              fontWeight: "bolder",
                            }}
                            className=""
                          >
                            {item.Asset_Nm}
                          </h3>
                          <div
                            style={{ fontVariant: "", fontSize: "1.098rem"}}
                             className="assetdesc"
                          >
                            {item.Asset_Desc}
                          </div>

                          

                        </div>

                        {item.AssetFile_Url ? (
                          <a target="__blank" href={item.AssetFile_Url}>
                            <Tooltip title={t('Download')} arrow>
                            <i
                              className="fas fa-download icon-2 primary m-1 px-2"
                              id="'asset' + asset.id"
                            ></i>
                            </Tooltip>
                          </a>
                        ) : 
                        <a target="__blank" href={item.AssetFile_Url === ""?item.AssetPreview_Url:"#"}>
                            <Tooltip title={t('Download')} arrow>
                            <i
                              className="fas fa-download icon-2 primary m-1 px-2"
                              id="'asset' + asset.id"
                            ></i>
                            </Tooltip>
                          </a>
                          }
                                {
                    item.WhatsappApproved_Flg === 'Y'?
                      <WhatsAppIcon className="whatsapp"></WhatsAppIcon>:null

                    }

                      </div>
                    ) : (
                      <div className="assetsListItem-div">
                        <h3
                          style={{
                            fontVariant: "",
                            fontSize: "1.098rem",
                            fontWeight: "bolder",
                          }}
                          className="w-25"
                        >
                          {item.Asset_Nm}
                        </h3>
                        <div
                          style={{ fontVariant: "", fontSize: "1.098rem" }}
                          className="w-30 d-flex"
                        >
                          {item.Asset_Desc}
                        </div>
                        {item.AssetFile_Url ? (
                          <a target="__blank" href={item.AssetFile_Url}>
                            <Tooltip title={t('Download')} arrow>
                            <i
                              className="fas fa-download icon-2 primary m-1 px-2"
                              id="'asset' + asset.id"
                            ></i>
                            </Tooltip>
                          </a>
                        ) : <a target="__blank" href={item.AssetFile_Url === ""?item.AssetPreview_Url:"#"}>
                        <Tooltip title={t('No URL to Download')} arrow>
                        <i
                          className="fas fa-download icon-2 primary m-1 px-2"
                          id="'asset' + asset.id"
                        ></i>
                        </Tooltip>
                      </a>
                      }
                            {
                    item.WhatsappApproved_Flg === 'Y'?
                      <WhatsAppIcon className="whatsapp"></WhatsAppIcon>:null

                    }
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : (
            <div style={{ width: "100%", textAlign: "center", fontSize: "large", display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
              {!spin?<span>{t('No assets found')}</span>:null}
            </div>
          )}
        </div>
      ) : (
        <div className="assets-body">
          <TreeView
            data={dataForTree}
            mainItemView={(item) => {
              console.log(item);
              return (
                <div
                  className=""
                  style={{ fontVariant: "", fontSize: "1.098rem" }}
                >
                  
                  <a target="__blank" href={item.AssetFile_Url?item.AssetFile_Url:''}>
                  {/* <i className="far fa-file-pdf fa-x "></i> */}
                  {
                    (item.AssetFile_Typ=='PDF')? <i className="far fa-file-pdf fa-x "></i> : (
                      (item.AssetFile_Typ=='Video')? <i className="far fa-file-video"></i>: (
                        (item.AssetFile_Typ=='Image')? <i className="far fa-file-image"></i>: (
                          (item.AssetFile_Typ=='PPT')? <i className="far fa-file-powerpoint"></i>: (
                          (item.AssetFile_Typ=='Link')? <i className="far fa-share-square"></i>: (
                    <i className="far fa-sticky-note"></i>)))))
                  }
                  {item.Asset_Desc}
                  </a>
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
}
