import { useState, useEffect } from "react";
import "./MobileHeader.css";
import MobileSideBar from "../mobileSidebar/MobileSideBar";
import { useDispatch, useSelector } from "react-redux";
import MobileProfileSidebar from "../../components/mobileProfileSidebar/MobileProfileSidebar";
import config from "../../env.json";
import { setTitle } from "../../app/slice/sideBarSlice";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
});

function MobileHeader({ showSidebar }) {
  const dispatch = useDispatch();
  const camp = useSelector((state: any) => state.personalisation.campaignName);
  const [showSideBar, setShowSideBar] = useState(false);
  const bottomNavBar = useSelector(
    (state: any) => state.personalisation.bottomNavBarFlag
  );
  let count: any = 0;
  let pageName: any = "New Chat";
  const mobileProfileSidebar = useSelector(
    (state: any) => state.login.mobileProfileSidebar
  );
  useEffect(() => {
    window.addEventListener("orientationchange", function () {
      setShowSideBar(false);
    });
  }, []);

  useEffect(() => {
    setShowSideBar(showSidebar);
  }, [showSidebar]);

  const handleNewChat = () => {
    console.log(
      "gc gam check 1 ",
      config.REACT_APP_ENABLE_HOSPITAL_CONTACT,
      config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
    );
    if (config.REACT_APP_LOGO_REDIRECT_PATH != "none") {
      if (config.REACT_APP_LOGO_REDIRECT_PATH == "/Home") {
        if (
          config.REACT_APP_ENABLE_HOSPITAL_CONTACT &&
          config.REACT_APP_CAMP_LIST_ENABLE_HOSPITAL_CONTACT == camp
        )
          window.location.href = "/HomeHosp";
        else window.location.href = "/Home";
      } else {
        window.location.href = config.REACT_APP_LOGO_REDIRECT_PATH;
      }
      dispatch(setTitle(pageName));
    }
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="MobileHeader" id="MobHeader">
      <div className="content__header">
        <div className="row row--one">
          {bottomNavBar == "N" ||
          config.REACT_APP_MOBILE_BOTTOM_NAV_FLAG == "Y" ? ( //'N' means No Bottom Navigation
            <div className="col-1 content__header__bars">
              <div style={{ height: "1.7rem" }}></div>
            </div>
          ) : (
            <>
              <div
                className="col-1 content__header__bars"
                onClick={() => {
                  setShowSideBar(!showSideBar);
                }}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </>
          )}

          <div
            className="col-2 offset-4 content__header__logo"
            onClick={() => {
              if (config.REACT_APP_SHOW_NEW_CHAT_ICON == "Y") handleNewChat();
            }}
          >
            <img
              src={config.REACT_APP_LOGO}
              alt="sRep Logo"
              onContextMenu={handleContextMenu}
            />
          </div>
        </div>
      </div>
      <MobileSideBar
        show={showSideBar}
        hideSideBar={() => {
          setShowSideBar(!showSideBar);
        }}
      />
      {mobileProfileSidebar === true ? <MobileProfileSidebar /> : null}
    </div>
  );
}

export default MobileHeader;
