import * as React from "react";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { getMailById } from "../../apis/Email/Email";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function AlignItemsList({ email, handleClose }) {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [apicalled, setapicalled] = React.useState(false);
  const fetchData = () => {
    getMailById(email.EmailConversation_Id, (resp: any) => {
      setapicalled(!apicalled);
    });
  };

  const getDate = (Update_DtTm) => {
    const date = new Date(Update_DtTm);
    return `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  useEffect(() => {
    console.log(email, handleClose);
    if (!apicalled && email) {
      fetchData();
    }
  }, [email]);
  return (
    <div className="modal">
      <Modal open={email}>
        <Box
          sx={{
            bgcolor: "background.paper",
            height: "100vh",
            width: "100%",
            borderBlockEndStyle: 25,
            borderBlockStartStyle: 25,
          }}
        >
          <div className="modal-header d-flex ">
            <span
              onClick={() => {
                console.log("===================");
                handleClose();
              }}
            >
              <ArrowBackIcon />
            </span>
          </div>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={email.Subject}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      To: {email?.EmailTo_Email} <br />
                      cc: {email?.EmailCC_Email}
                    </Typography>
                  </React.Fragment>
                }
              />

              <ListItemIcon>
                <p style={{ fontSize: 12 }}>{getDate(email.Update_DtTm)}</p>
              </ListItemIcon>
            </ListItem>
          </List>
          <div
            className="mailContent"
            style={{ padding: "20px", textAlign: "start" }}
          >
            <p>{email.Email_Bdy}</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
