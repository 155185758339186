import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  setComposeEmailView,
  setEmailContentView,
} from "../../app/slice/emailSubMenuSlice";
import { getMailsByName } from "../../apis/Email/Email";
import Spinner from "../../layouts/spinner/Spinner";
import { useEffect } from "react";
import parse from "html-react-parser";

export default function DraftList(props) {
  const dispatch = useDispatch();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const getIndoxView = useSelector((state: any) => state.email.showInbox);
  const [apicalled, setapicalled] = React.useState(false);

  const openEmail = () => {
    setOpen(true);
  };

  const fetchData = () => {
    getMailsByName("SENT", (resp) => {
      setData(resp);
      setapicalled(!apicalled);
    });
  };

  const convertHtml = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    let newString = body_element.innerHTML;
    let strippedHtml = newString.replace(/<[^>]+>+/g, "");
    let strippedHtml1 = strippedHtml.replace(/\&nbsp;/g, "");
    let finalEmailText = strippedHtml.includes("From")
      ? strippedHtml1.split("From")[0]
      : strippedHtml;
    return finalEmailText;
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  useEffect(() => {
    if (!apicalled) {
      fetchData();
    }
  }, []);
  return (
    <div className="containter-div">
      <div
        className="chatlistbackground"
        id="scrollableDiv"
        style={{
          height: "100vh",
          width: "100%",
          overflow: "auto",
          display: "flex",
        }}
      >
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            margin: "20",
          }}
        >
          <InfiniteScroll
            dataLength={data?.length}
            next={() => {}}
            hasMore={false}
            scrollableTarget="scrollableDiv"
            loader={<Spinner />}
          >
            {data?.map((info: any) => (
              <div key={info?.EmailConversation_Id}>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => {
                    props.handleEmailSelect(info);
                    dispatch(setComposeEmailView(false));
                    dispatch(setEmailContentView(true));
                  }}
                >
                  <ListItemAvatar></ListItemAvatar>
                  <ListItemText
                    primary={truncate(parse(info.EmailTo_Email), 30)}
                    secondary={
                      <>
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {parse(
                              info.EmailConversation_Subject.substring(
                                0,
                                15
                              ).substring(0, 15)
                            )}
                          </Typography>
                        </React.Fragment>
                        <p>{truncate(convertHtml(info.Email_Bdy), 20)}</p>
                      </>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </div>
  );
}
