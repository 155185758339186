import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import "./RescheduleModal.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import config from "../../env.json";
import axios from "axios";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../../utils/deviceTypeUtil";

export const RescheduleModal = (props) => {
  const { t } = useTranslation();
  
  const [show, setShow] = useState(false);

  const [endDate, setEndDate] = React.useState<any>();
  const [startDate, setstartDate] = React.useState<any>();
  const [startTime, setstartTime] = useState(moment());
  const [endTime, setendTime] = useState(moment());
  const [note, setNote] = useState("");
  const [error, setDateError] = useState(false);
  const [timeerror, setTimeError] = useState(false);
  const [greaterError, setGreaterError] = useState(false);
  const [greatertimeError, setGreaterTimeError] = useState(false);
  const [rescheduleMeeting, setRescheduleMeeting] = useState<any>(0);
  const [modalLeftPadding, setModalLeftPadding] = useState<any>(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [startLessThan, setStartLessThan] = useState(false);
  const [endLessThan, setEndLessThan] = useState(false);

  useEffect(() => {
    setstartDate(moment());
    setEndDate(moment());
    setShow(props.show);
    setDateError(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
    return () => {
      setDateError(false);
      setTimeError(false);
      setGreaterError(false);
      setGreaterTimeError(false);
    };
  }, [props.show]);

  let modalSize = "lg";
  const handlestartDateChange = (newValue) => {
    setstartDate(newValue);
    setStartLessThan(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
  };
  const handleendDateChange = (newValue) => {
    setEndDate(newValue);
    setStartLessThan(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
  };
  const handleStartTimeChange = (newValue) => {
    
    const s_time:any=newValue;
    setstartTime(s_time);
    setStartLessThan(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
  };
  const handleEndTimeChange = (newValue) => {
    const e_time: any = newValue;
    setendTime(e_time);
    setStartLessThan(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
  };
  const handleRescheduleReason = (newValue) => {
    const reason: any = newValue.target.value;
    setNote(reason);
    setStartLessThan(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setEndLessThan(false);
  };
  const handleClose = () => {
    props.onHide();
    setDateError(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setStartLessThan(false);
    setstartDate(moment());
    setEndLessThan(false);
    setEndDate(moment());
    setstartTime(moment());
    setendTime(moment());
    setShow(false);
  };

  useEffect(() => {
    let viewportWidth = window.innerWidth;
    let div: any = document.getElementById("main");
    let divpaddingLeft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingRight: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setModalLeftPadding(divpaddingLeft);
    let doc: any = document;

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      if (doc.getElementsByClassName("reschedule-meeting")[0]) {
        setRescheduleMeeting(
          viewportWidth - (divpaddingLeft + divpaddingRight)
        );
      }
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event.target);
    var today = new Date();
    let s_date = moment(startDate).format("YYYY-MM-DD");
    let e_date = moment(endDate).format("YYYY-MM-DD");
    let s_time = moment(startTime).format("HH:mm");
    let e_time = moment(endTime).format("HH:mm");
    let currentTime = moment(today).format("HH:mm");
    console.log(
      "startdate:" +
        startDate +
        "EndDate" +
        endDate +
        "startTime" +
        startTime +
        "endtime" +
        endTime +
        note
    );
    console.log(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      startTime,
      endTime
    );

    const avail_start = moment(
      moment(startDate).format("YYYY-MM-DD") + " " + s_time
    );
    const newavailstrt = moment(avail_start).toISOString();
    const avail_end = moment(
      moment(endDate).format("YYYY-MM-DD") + " " + e_time
    );
    const newavailend = moment(avail_end).toISOString();
    console.log(newavailstrt, newavailend);
    if (s_date == "Invalid date" || e_date == "Invalid date") {
      setDateError(true);
    } else if (s_date > e_date) {
      setGreaterError(true);
    } else if (s_time == null || e_time == null) {
      setTimeError(true);
    } else if (
      s_time < currentTime &&
      s_date == moment().format("YYYY-MM-DD") &&
      e_date == moment().format("YYYY-MM-DD")
    ) {
      setStartLessThan(true);
    } else if (
      s_time >= e_time &&
      s_date == moment().format("YYYY-MM-DD") &&
      e_date == moment().format("YYYY-MM-DD")
    ) {
      setGreaterTimeError(true);
    } else if (s_time >= e_time && s_date == e_date) {
      setGreaterTimeError(true);
    } else {
      axios
        .post(config.REACT_APP_CALENDAR_API_BASE + "/calendarevent", {
          Employee_id: props.start.EmployeeId,
          RequestStart_DtTm: props.start.start,
          RequestedEnd_DtTm: props.start.endtime,
          Subject_Val: props.start.subject,
          Description_Val: props.start.Description,
          Status_Val: "reject",
          Id: props.start.Id,
          ProposedStart_DtTm: newavailstrt,
          ProposedEnd_DtTm: newavailend,
          RejectReason_Val: note,
        })
        .then((res) => {
          console.log(res);
          props.onHide();
        })
        .catch((err) => {
          props.onHide();
          console.log("Freetime Error:" + err);
        });
    }
  };

  return (
    <>
      {
        <Modal
          className="reschedule-meeting"
          style={
            mobileStyle
              ? { width: rescheduleMeeting, left: modalLeftPadding }
              : {}
          }
          show={props.show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <h5 className="modal-title" id="exampleModalLabel">
              {t("Reschedule Meeting")}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form onSubmit={handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <div className="StartDateDiv">
                      <DesktopDatePicker
                        label="Proposed StartDate"
                        className="StartDate"
                        inputFormat="MM/dd/yyyy"
                        disablePast={true}
                        value={startDate}
                        onChange={handlestartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div className="form-group form-group--third floating-form">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Proposed StartTime")}
                      </InputLabel>
                      <TimePicker
                        className="form-control font-md calendar-starttime"
                        value={startTime}
                        showSecond={false}
                        use12Hours={true}
                        minuteStep={15}
                        defaultValue={moment(startTime)}
                        allowEmpty={false}
                        onChange={handleStartTimeChange}
                        inputReadOnly={true}
                      />
                      {timeerror ? (
                        <p className="form-error-message">
                          {t("Start time can not be less than current time")}
                        </p>
                      ) : null}
                      {startLessThan ? (
                        <p className="form-error-message">
                          {t("Start time can not be less than current time")}
                        </p>
                      ) : null}
                    </div>
                    <div className="StartDateDiv">
                      <DesktopDatePicker
                        label="Proposed EndDate"
                        className="EndDate"
                        inputFormat="MM/dd/yyyy"
                        disablePast={true}
                        value={endDate}
                        onChange={handleendDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {error ? (
                        <p className="form-error-message">
                          {t(
                            "End date should always be greater than Start date"
                          )}
                        </p>
                      ) : null}
                      {greaterError ? (
                        <p className="form-error-message">
                          {t(
                            "End date should always be greater than Start date"
                          )}
                        </p>
                      ) : null}
                    </div>
                    <div className="form-group form-group--third floating-form">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Proposed EndTime")}
                      </InputLabel>
                      <TimePicker
                        className="form-control font-md calendar-starttime"
                        value={endTime}
                        showSecond={false}
                        use12Hours={true}
                        minuteStep={15}
                        defaultValue={moment(endTime)}
                        allowEmpty={false}
                        onChange={handleEndTimeChange}
                        inputReadOnly={true}
                      />
                      {timeerror ? (
                        <p className="form-error-message">
                          {t(
                            "End time should always be greater than Start time"
                          )}
                        </p>
                      ) : null}
                      {greatertimeError ? (
                        <p className="form-error-message">
                          {t(
                            "End time should always be greater than Start time"
                          )}
                        </p>
                      ) : null}
                    </div>
                    <div className="form-group form-group--third floating-form">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Note")}
                      </InputLabel>
                      <input
                        id="note"
                        name="note"
                        type="text"
                        maxLength={150}
                        className="form-control font-md calendar-rejectmeeting-note"
                        onChange={handleRescheduleReason}
                        required
                      />
                    </div>
                  </Stack>
                </LocalizationProvider>

                <div className="submitform">
                  <button
                    type="submit"
                    className="submit-btn btn rounded-primary-40 mx-auto set-freetime"
                  >
                    <span> {t("Reschedule Meeting")}</span>
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      }{" "}
    </>
  );
};

export default RescheduleModal;
