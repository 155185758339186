import React, { useState, useEffect, useRef, useCallback } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import { getCampaignsDataStaff } from "../../apis/campaigns/campaignsstaff";
import DropdownInupt from "../shared/Dropdown";
import { Keyboard } from "@capacitor/keyboard";
import GenericButton from "../../layouts/button/Button";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import config from "../../env.json";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./preApprovedTemplates.css";
import { getAOCStatus, getSRFQuantity } from "../../apis/srfApi/srfApi";
import {
  setAOCStatus,
  setPresQuantity,
  setRepQuantity,
} from "../../app/slice/srfSlice";
import { useSelector, useDispatch } from "react-redux";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MenuItem from "@mui/material/MenuItem";
import validator from "validator";
import SearchInput from "../../layouts/searchInput/SearchInput";
import Spinner from "../../layouts/spinner/Spinner";
import { debounce } from "lodash";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function PreApprovedTemplates(props: any) {
  const {
    show,
    handleCloseParent,
    selectedTemplate,
    contact,
    isWhatsappTimeExpired,
    isSMS,
    contactData,
  } = props;
  const restrictedProduct = contactData?.Restricted_Product
    ? contactData?.Restricted_Product.split(";")
    : [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState([]);
  const [clickedTemplateId, setClickedTemplateId] = useState(-1); //userId
  const [template, setTemplate] = useState([]);
  const [selectedTemplateData, setSelectedTemplateData] = useState<any>({});
  const [sendButtonDisbale, setSendButtonDisbal] = useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [hitSearch, setHitSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [allCampaign, setAllCampaign] = useState<any>([]);
  const [InitialAllCampaign, setInitialAllCampaign] = useState<any>([]);
  const [allWaves, setAllWaves] = useState<any>([]);
  const [originalTemplateText, setOriginalTemplateText] = useState("");
  const [templateFieldDetails, setTemplateFieldDetails] = useState<any>([]);
  const [dynamicTemplateText, setDynamicTemplateText] = useState("");
  // const refForm=useRef<any>(null);
  const [contentModeratorCheckArray, setContentModeratorCheckArray] = useState<
    string[]
  >([]);
  const [noTemplateFound, setNoTemplateFound] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeWave, setActiveWave] = useState<any>(null);
  const [activeProduct, setActiveProduct] = useState<any>(null);
  const [activeCampaign, setActiveCampagign] = useState<any>(null); //userId
  const [tempTemplateId, setTempTemplateId] = useState<any>(null);
  const shouldGetSRFQuantity = false;
  const [repQuantity, setRepQuantityLocal] = useState<any>([]);
  const [presQauntity, setPresQauantityLocal] = useState<any>([]);
  const [aoc, setAoc] = useState<any>([]);
  const [tempProductId, setTempProductId] = useState<any>(0);
  const [formValues, setFormValues] = useState<any>([]);
  const [eventDateValue, setEventDateValue] = useState<any>("");
  const personalizationDateFormat = useSelector(
    (state: any) => state.personalisation.personalizationDateFormat
  );
  const EmployeePermissionCountry_Cd = useSelector(
    (state: any) => state.personalisation.EmployeePermissionCountry_Cd
  );
  const tempDtForm = "MM/dd/yyyy";
  const [fieldData, setFieldData] = useState<any>([]);
  const [disablebtnInfo, setDisablebtnInfo] = useState<any>([]);
  const [showError, setShowError] = useState<any>([]);
  // useEffect(() => {
  //   window.localStorage.removeItem("activeCampaign");
  //   window.localStorage.removeItem("activeWave");
  //   window.localStorage.removeItem("activeProduct");

  //   return () => {};
  // }, [handleCloseParent]);

  const sampleFieldData = [
    { FieldId: 1063, FieldName: "Date", FieldType: "Date" },
    { FieldId: 1064, FieldName: "Time", FieldType: "Time" },
    { FieldId: 1065, FieldName: "Checkbox", FieldType: "Checkbox" },
    { FieldId: 1062, FieldName: "String", FieldType: "String" },
  ];
  const calcHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    // let modalHeaderHeight=doc.getElementsByClassName('modal-header')[0].offsetHeight;
    // let modalSearchHeight=doc.getElementsByClassName('pre_approvetemplate_head')[0].offsetHeight;
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    // console.log("gc modal toppad", divpaddingTop);
    // console.log("gc modal bottompad", divpaddingbottom);
    // console.log("gc modal leftpad", divpaddingleft);
    // console.log("gc modal rightpad", divpaddingright);
    // console.log("gc modal height", window.innerHeight - divpaddingTop - divpaddingbottom);
    // console.log("gc modal width", window.innerWidth - divpaddingleft - divpaddingright);
    // console.log("gc modal body height",modalHeaderHeight);
    // console.log("gc modal search body height",modalSearchHeight);
    // pre_approvetemplate_head modal-header

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setModalHeight(window.innerHeight - divpaddingTop - divpaddingbottom);

      setModalWidth(window.innerWidth - divpaddingleft - divpaddingright);
    }
  };

  useEffect(() => {
    if (show) {
      if (shouldGetSRFQuantity) {
        getSRFQuantity((res: any) => {
          if (res) {
            setRepQuantityLocal(res.employeeQnt ? res.employeeQnt : []);
            setPresQauantityLocal(res.prescriberQnt ? res.prescriberQnt : []);
            dispatch(setRepQuantity(res.employeeQnt ? res.employeeQnt : []));
            dispatch(
              setPresQuantity(res.prescriberQnt ? res.prescriberQnt : [])
            );
          }
        });
      }
      getAOCStatus((res: any) => {
        if (res) {
          setAoc(res);
          dispatch(setAOCStatus(res));
        }
      });
      if (contact.Is_Prescriber === "Y") {
        getTemplatesFromCampign("");
      } else {
        setIsLoading(true);
        getTemplatesFromCampignStaff("");
      }
      calcHeightWidth();
    }
    return () => {
      setClickedTemplateId(-1);
      setSendButtonDisbal(false);
      setTemplate([]);
    };
  }, [show]);
  const getRepLimit = () => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == contact.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };
  const getPresLimit = () => {
    let rep = presQauntity.filter(
      (x) =>
        x.product_id == tempProductId &&
        x.prescriber_id == contact.Id &&
        contact.Is_Prescriber
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };
  const getFormStatus = (value: any, FormId: any) => {
    let rep = repQuantity.filter(
      (x) => x.product_id == tempProductId && x.prescriber_id == contact.Id
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    let pres = presQauntity.filter(
      (x) =>
        x.product_id == tempProductId &&
        x.prescriber_id == contact.Id &&
        contact.Is_Prescriber
    );
    let pq = pres.length > 0 ? pres[0].quantity : 0;
    let n = aoc.filter(
      (x) =>
        x.product_id == tempProductId &&
        x.prescriber_id == contact.Id &&
        x.aoc_status == "pending" &&
        contact.Is_Prescriber
    );
    let nc = n.length > 0 ? n[0].aoc_status : "unknown";

    let errortext = "";
    if (nc == "pending") errortext = "Your previous AOC is pending.<br>";
    if (value > rq && rq != 0)
      errortext += "Your available limit is " + getRepLimit() + "<br>";
    if (value > pq && pq != 0)
      errortext += "Prescriber's available limit is " + getPresLimit();

    let doc: any = document;
    if (doc.getElementById("error-form-" + FormId))
      doc.getElementById("error-form-" + FormId).innerHTML = errortext;

    if ((value > rq && rq != 0) || (value > pq && pq != 0) || nc == "pending")
      setSendButtonDisbal(true);
    else setSendButtonDisbal(false);
  };

  useEffect(() => {
    window.addEventListener("orientationchange", calcHeightWidth);
    return () => {
      window.removeEventListener("orientationchange", calcHeightWidth);
    };
  }, []);
  useEffect(() => {
    const debouncedSearch = debounce((value) => {
      setSearchLoading(true);
      if (contact.Is_Prescriber === "Y") {
        getTemplatesFromCampign(value);
      } else {
        getTemplatesFromCampignStaff(value);
      }
      calcHeightWidth();
    }, 500);

    if (searchValue.length > 2 || searchValue === "") {
      debouncedSearch(searchValue.trim());
    }

    return () => {
      debouncedSearch.cancel();
      setClickedTemplateId(-1);
      setSendButtonDisbal(false);
    };
  }, [searchValue, hitSearch]);
  useEffect(() => {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      Keyboard.addListener("keyboardDidShow", (info) => {
        calcHeightWidth();
      });

      Keyboard.addListener("keyboardDidHide", () => {
        calcHeightWidth();
      });
    }
  }, []);
  useEffect(() => {
    let tempDisablebtnInfo: any = [];
    if (template.length > 0) {
      template.map((item: any, i) => {
        if (item?.Category_Cd == "DYN") {
          tempDisablebtnInfo.push({ Disable: true });
        } else {
          tempDisablebtnInfo.push({ "": "" });
        }
      });

      setDisablebtnInfo(tempDisablebtnInfo);
    }
  }, [template]);
  const getDateWithFormattedTimestampAnsTimezoneOffset = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth()).padStart(2, "0");
    const year = date.getFullYear();
    return new Date(year, Number(month), Number(day));
  };
  const checkValidDt = (selectedDt, f) => {
    let statusY = -1;
    let statusM = -1;
    let statusD = -1;
    f.map((item, i) => {
      if (item.toLowerCase() == "yyyy") {
        statusY = parseInt(selectedDt[i]);
      }

      if (item.toLowerCase() == "mm" || item.toLowerCase() == "m") {
        statusM = parseInt(selectedDt[i]) - 1;
      }

      if (item.toLowerCase() == "dd" || item.toLowerCase() == "d") {
        statusD = parseInt(selectedDt[i]);
      }
    });
    return [statusY, statusM, statusD];
  };
  useEffect(() => {
    let tempDisablebtnInfo = disablebtnInfo;
    let filterdata;
    template.map((tempitem: any, index) => {
      if (tempitem?.Category_Cd == "DYN" && clickedTemplateId == index) {
        let sendbtnDisable = false;
        if (templateFieldDetails && templateFieldDetails?.length > 0) {
          templateFieldDetails.map((item, i) => {
            if (item.Value) {
              if (item?.FieldType.toLowerCase() === "date") {
                // let todayDate = getDateWithFormattedTimestampAnsTimezoneOffset(
                //  new Date()
                // );
                // let dateParts = item.Value.split("/");
                // const day = parseInt(dateParts[0], 10);
                // const month = parseInt(dateParts[1], 10) - 1;
                // const year = parseInt(dateParts[2], 10);
                // let selectedDate = new Date(year, month, day);
                let todayDate = getDateWithFormattedTimestampAnsTimezoneOffset(
                  new Date()
                );
                let tempDtFmt = personalizationDateFormat
                  ? personalizationDateFormat
                  : "MM/DD/YYYY";
                tempDtFmt = tempDtFmt.replace(/dd/gi, "DD");
                const dtSplit = item.Value.split("/");
                const formatDtSplit = tempDtFmt.split("/");
                let validDt = checkValidDt(dtSplit, formatDtSplit);
                let selectedDate = new Date(validDt[0], validDt[1], validDt[2]);
                if (
                  todayDate > selectedDate ||
                  isNaN(dtSplit[2]) ||
                  isNaN(dtSplit[1]) ||
                  isNaN(dtSplit[0])
                ) {
                  let tempErr = showError;
                  tempErr[i] = "please enter valid date";
                  setShowError(tempErr);
                  sendbtnDisable = sendbtnDisable || true;
                } else {
                  let tempErr = showError;
                  tempErr[i] = "";
                  setShowError(tempErr);
                }
              }
              if (
                item.Value == "" && item?.IsRequired
                  ? item?.IsRequired == "Y"
                  : false
              ) {
                sendbtnDisable = sendbtnDisable || true;
              } else {
                if (item?.FieldType.toLowerCase() === "link") {
                  if (item.Value.length > 600) {
                    sendbtnDisable = sendbtnDisable || true;
                    let tempErr = showError;
                    tempErr[i] = "Limit Exceeded";
                    setShowError(tempErr);
                  } else {
                    let result = validator.isURL(item.Value);
                    if (result) {
                      let tempErr = showError;
                      tempErr[i] = "";
                      setShowError(tempErr);
                      sendbtnDisable = sendbtnDisable || false;
                    } else {
                      sendbtnDisable = sendbtnDisable || true;
                      let tempErr = showError;
                      tempErr[i] = "please enter valid link";
                      setShowError(tempErr);
                    }
                  }
                } else {
                  sendbtnDisable = sendbtnDisable || false;
                }
              }
            } else {
              sendbtnDisable = sendbtnDisable || true;
            }
          });
          sendbtnDisable
            ? (tempDisablebtnInfo[index].Disable = true)
            : (tempDisablebtnInfo[index].Disable = false);
          filterdata = tempDisablebtnInfo.map((obj, x) => {
            if (index === x) {
              return { ...obj, Disable: sendbtnDisable };
            }
            return obj;
          });
          setDisablebtnInfo(filterdata);
        }
      }
    });
  }, [templateFieldDetails]);

  const checkWhatsappValidation = (index, i, valueInp) => {
    let value = valueInp.trimStart();
    let size = valueInp.length;
    if (size > 2) {
      if (valueInp[size - 2] == " " && valueInp[size - 1] == " ") {
        value = valueInp.trim();
        value = value + " ";
      }
    }
    let maxLengthText = 70;
    let tempDisablebtnInfo = disablebtnInfo;
    tempDisablebtnInfo[index].Disable = true;
    let sendbtnDisable = false;
    if (value.length > 0) {
      //general validations
      const nameValid = value.replace(/^[a-zA-Z0-9 \u00C0-\u00ff]+$/gi, "");
      const addressValid = value.replace(
        /^[a-zA-Z0-9 _.,\u00C0-\u00ff]+$/gi,
        ""
      );
      if (templateFieldDetails[i]?.FieldType.toLowerCase() === "link") {
        if (value.length > 600) {
          sendbtnDisable = sendbtnDisable || true;
          let tempErr = showError;
          tempErr[i] = "Limit Exceeded";
          setShowError(tempErr);
        }
        updateFormValue(i, value);
        let result = validator.isURL(value);
        if (result) {
          let tempErr = showError;
          tempErr[i] = "";
          setShowError(tempErr);
          sendbtnDisable = sendbtnDisable || false;
        } else {
          sendbtnDisable = sendbtnDisable || true;
          let tempErr = showError;
          tempErr[i] = "please enter valid link";
          setShowError(tempErr);
        }
      } else if (
        addressValid.length > 0 &&
        addressValid.length <= maxLengthText &&
        addressValid &&
        templateFieldDetails[i]?.FieldType.toLowerCase() === "address"
      ) {
        updateFormValue(i, addressValid);
        sendbtnDisable = sendbtnDisable || false;
      } else if (
        nameValid.length > 0 &&
        nameValid.length <= maxLengthText &&
        nameValid &&
        templateFieldDetails[i]?.FieldType.toLowerCase() === "name"
      ) {
        updateFormValue(i, nameValid);
        sendbtnDisable = sendbtnDisable || false;
      } else if (value.length <= maxLengthText) {
        sendbtnDisable = sendbtnDisable || false;
        updateFormValue(i, value);
      }
      if (templateFieldDetails && templateFieldDetails?.length > 0) {
        templateFieldDetails.map((item) => {
          if (item.Value) {
            if (
              item.Value == "" && item?.IsRequired
                ? item?.IsRequired == "Y"
                : false
            ) {
              sendbtnDisable = sendbtnDisable || true;
            } else {
              sendbtnDisable = sendbtnDisable || false;
            }
          } else {
            sendbtnDisable = sendbtnDisable || true;
          }
        });
        sendbtnDisable
          ? (tempDisablebtnInfo[index].Disable = true)
          : (tempDisablebtnInfo[index].Disable = false);
      }
    } else {
      updateFormValue(i, "");
      tempDisablebtnInfo[index].Disable = true;
    }
    setDisablebtnInfo(tempDisablebtnInfo);
  };
  const updateFormValue = (i, value) => {
    let formVal: any = [...templateFieldDetails];
    let item = {
      ...formVal[i],
      Value: value,
    };
    formVal[i] = item;

    // setFormValues(formVal);
    setTemplateFieldDetails(formVal);
    updateTemplateText(formVal);
  };
  const updateTemplateText = (formData) => {
    var templateBody = originalTemplateText;
    var selTempData = { ...selectedTemplateData };
    let tempFieldData: any = [];
    formData.map((x: any, i: any) => {
      if ("Value" in x) {
        //remove this condition
        var replaceWord = "[" + x.FieldName + "]";
        templateBody = templateBody.replace(replaceWord, x.Value.trim());
        // let tempkey = x.FieldName.replaceAll(" ", "");
        tempFieldData.push(x.Value);
      }
    });
    setFieldData(tempFieldData);
    selTempData.TemplateBody = templateBody;
    setDynamicTemplateText(templateBody);
    setSelectedTemplateData(selTempData);
  };

  // const
  const selectTemplate = (e: any, data: any) => {
    //Do not select the template if whatsapp and whatsapp time expired, only allow WhatsappApproved_Flg Y
    if (
      isSMS &&
      isWhatsappTimeExpired &&
      data.WhatsappApproved_Flg &&
      data.WhatsappApproved_Flg == "N"
    ) {
      return;
    }

    let doc: any = document;

    if (
      tempTemplateId &&
      e != clickedTemplateId &&
      doc.getElementById("dynmaicTemplateFields-" + tempTemplateId)
    )
      doc.getElementById("dynmaicTemplateFields-" + tempTemplateId).reset();
    setClickedTemplateId(e);
    setTempTemplateId(selectedTemplateData.TemplateId);
    if (data.TemplateId != selectedTemplateData.TemplateId) {
      setDynamicTemplateText("");
      setSelectedTemplateData(data);
      setOriginalTemplateText(data.TemplateBody);
      setFormValues(data.FieldDetails);
      setShowError([]);
      setTemplateFieldDetails(data.FieldDetails);
    }
  };
  const sendTemplate = () => {
    let tempData: any = {
      templateData: selectedTemplateData,
      formData: [],
    };
    //     var formElements=document.getElementById("form-").elements;
    // var postData={};
    // for (var i=0; i<formElements.length; i++)
    //     if (formElements[i].type!="submit")//we dont want to include the submit-buttom
    //         postData[formElements[i].name]=formElements[i].value;

    if (selectedTemplateData.FormId) {
      let doc: any = document;
      if (doc.getElementById("form-" + selectedTemplateData.FormId))
        var formElements = doc.getElementById(
          "form-" + selectedTemplateData.FormId
        ).elements;
      let details: any = [];
      for (var i = 0; i < formElements.length; i++) {
        if (formElements[i].type != "submit")
          details.push({
            idOfFieldToAskFromEmp: formElements[i].name,
            valueOfFieldToAskFromEmp: formElements[i].value,
          });
      }
      tempData.formData = details;
    }
    let temp = selectedTemplateData.TemplateBody.toString();
    if (
      !selectedTemplateData.FormId &&
      selectedTemplateData.FieldDetails.length > 0
    ) {
      selectedTemplateData.FieldDetails.map((x: any) => {
        temp = temp.replace("[" + x.FieldName + "]", "");
        return x;
      });
      tempData.dynTempObj = fieldData;
      selectedTemplateData.TemplateBody = temp;
    }

    setSendButtonDisbal(true);
    selectedTemplate(tempData);

    setShowConfirm(true);
    setEventDateValue("");
    setTemplateFieldDetails(formValues);
    setShowError([]);
    setDynamicTemplateText("");
  };

  const getTemplatesFromCampign = (searchQuery) => {
    setIsLoading(true);
    setContentModeratorCheckArray([]);
    let campp: any = window.localStorage.getItem("activeCampaign");
    let prodd: any = window.localStorage.getItem("activeProduct");
    let activcamp = JSON.parse(campp)?.CamapignId || activeCampaign?.CamapignId;

    getCampaignsData(
      {
        page: 0,
        pageSize: 10,
        searchQuery: searchQuery,
        campaignId: JSON.parse(campp)?.CamapignId || activeCampaign?.CamapignId,
        productId: JSON.parse(prodd)?.ProductId || activeProduct?.ProductId,
        contactId : contact.Id,
        
      },
      (res) => {
        if (res.length > 0) {
          setNoTemplateFound(false);
          let tempAssets: any = [];
          let campaigns: any = [];
          let waves: any = [];
          let products: any = [];

          res.map((x: any) => {
            campaigns.push(x);
            x.type = "Campaign";
            x.idName = ["type", "CampaignName", "CamapignId"];
            x.labelName = "CampaignName";
            x.childName = "WaveDetails";
            x.WaveDetails.map((y: any) => {
              waves.push(y);
              y.type = "Wave";
              y.idName = ["type", "WaveName", "WaveId"];
              y.labelName = "WaveName";
              y.childName = "ProductDetails";
              //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
              y.ProductDetails.map((z: any) => {
                z.waveId = y.WaveId;
                products.push(z);
                z.type = "Product";
                z.idName = ["type", "ProductName", "ProductId"];
                z.labelName = "ProductName";
                z.childName = "TemplateDetails";
                z.TemplateDetails = z.TemplateDetails.sort(function (
                  a: any,
                  b: any
                  ) {
                  let textA = a.TemplateName.toUpperCase();
                  let textB = b.TemplateName.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  });
                z.TemplateDetails.map((p: any) => {
                  p.type = "Template";
                  p.productId = z.ProductId;
                  p.mainWaveId =
                    p.AssetDetails.length == 0
                      ? p.CampaignWaveId
                      : p.AssetDetails[0].CampaignWaveId;
                  if (p.FormId) {
                    p.TemplateName = p.FormName;
                  }
                  p.idName = ["type", "TemplateName", "TemplateId"];
                  p.labelName = "TemplateName";
                  p.childName = "AssetDetails";
                  tempAssets = tempAssets.concat(p.AssetDetails);
                  p.AssetDetails.map((o: any) => {
                    o.mainItem = true;
                    o.type = "Asset";
                    o.idName = ["type", "AssetFile_Nm", "AssetId"];
                    o.labelName = "AssetFile_Nm";
                    return o;
                  });
                  return p;

                  //tempAssets.push(y.campaignWaveAsset);
                });
                return z;
              });
              return y;
            });
            return x;
          });
          let templatesNotFound = false;
          let filtercamp = InitialAllCampaign?.map((camp, campindex) => {
            if (camp?.CamapignId == activcamp) {
              camp.WaveDetails.map((wave, waveIndex) => {
                if (
                  wave.ProductDetails.length !=
                    campaigns[campindex].WaveDetails[waveIndex].ProductDetails
                      .length ||
                  camp.WaveDetails.length !=
                    campaigns[campindex].WaveDetails.length ||
                  campaigns.length != InitialAllCampaign.length
                ) {
                  wave.ProductDetails.map((product, productIndex) => {
                    if (
                      InitialAllCampaign[campindex]?.WaveDetails[waveIndex]
                        ?.ProductDetails[productIndex].ProductId ==
                      (JSON.parse(prodd)?.ProductId || activeProduct?.ProductId)
                    ) {
                      templatesNotFound = true;
                      console.log("productinside");
                      camp[campindex].WaveDetails[waveIndex].ProductDetails[
                        productIndex
                      ].TemplateDetails = [];
                    }
                  });
                }
              });
            }
            return camp;
          });

          if (searchQuery != "" && templatesNotFound) {
            campaigns = filtercamp;
            setAllCampaign(filtercamp);
          } else {
            setInitialAllCampaign(campaigns);
            setAllCampaign(campaigns);
          }
          let activeC: any = window.localStorage.getItem("activeCampaign");
          if (activeC) {
            let index = campaigns.findIndex(
              (x) => x.CamapignId == JSON.parse(activeC).CamapignId
            );
            if (index >= 0) {
              activeC = campaigns[index];
              setActiveCampagign(campaigns[index]);
            } else {
              activeC = campaigns[0];
              setActiveCampagign(campaigns[0]);
            }
          } else {
            activeC = campaigns[0];
            setActiveCampagign(campaigns[0]);
          }

          let tempWaves = activeC.WaveDetails ? activeC.WaveDetails : [];
          setAllWaves(tempWaves);

          let activeW: any = window.localStorage.getItem("activeWave");

          if (activeW) {
            let index = tempWaves.findIndex(
              (x) => x.WaveId == JSON.parse(activeW).WaveId
            );
            if (index >= 0) {
              activeW = tempWaves[index];
              setActiveWave(tempWaves[index]);
            } else {
              activeW = tempWaves[0];
              setActiveWave(tempWaves[0]);
            }
          } else {
            activeW = tempWaves[0];
            setActiveWave(tempWaves[0]);
          }

          // Earlier
          let tempProducts = activeW.ProductDetails
            ? activeW.ProductDetails
            : [];

          setCampaign(tempProducts); //campagins is used as products
          let activep: any = window.localStorage.getItem("activeProduct");

          if (activep) {
            let index = tempProducts.findIndex(
              (x) => x.ProductId == JSON.parse(activep).ProductId
            );
            if (index >= 0) {
              activep = tempProducts[index];
              setActiveProduct(tempProducts[index]);
            } else {
              activep = tempProducts[0];
              setActiveProduct(tempProducts[0]);
            }
          } else {
            activep = tempProducts[0];
            setActiveProduct(tempProducts[0]);
          }

          let tempTemplates = activep.TemplateDetails
            ? activep.TemplateDetails
            : [];
          setTemplate(tempTemplates);

          // let o=window.localStorage.getItem('activeProduct')
          // setActiveProduct(o?JSON.parse(o):null)

          // getFormsData()
          // setTemplate(tempAssets);
          // setAlllProducts(products);
          // setActiveProducts(products)

          // setAlllWaves(waves);
          // setActiveWaves(waves);
          // setAssets(tempAssets);
          setSearchLoading(false);
        } else {
          setNoTemplateFound(true);
          //setTemplate([]);
        }
        setIsLoading(false);
        setSearchLoading(false);
      }
    );
  };
  const getTemplatesFromCampignStaff = (searchQuery) => {
    setIsLoading(true);
    setContentModeratorCheckArray([]);
    let campp: any = window.localStorage.getItem("activeCampaign");
    let prodd: any = window.localStorage.getItem("activeProduct");
    let activcamp = JSON.parse(campp)?.CamapignId || activeCampaign?.CamapignId;
     if (!contact.Id) return
    getCampaignsDataStaff(
      {
        page: 0,
        pageSize: 10,
        searchQuery: searchQuery,
        campaignId: JSON.parse(campp)?.CamapignId || activeCampaign?.CamapignId,
        productId: JSON.parse(prodd)?.ProductId || activeProduct?.ProductId,
        contactId : contact.Id,
      },
      (res) => {
        if (res.length > 0) {
          setNoTemplateFound(false);
          let tempAssets: any = [];
          let campaigns: any = [];
          let waves: any = [];
          let products: any = [];

          res.map((x: any) => {
            campaigns.push(x);
            x.type = "Campaign";
            x.idName = ["type", "CampaignName", "CamapignId"];
            x.labelName = "CampaignName";
            x.childName = "WaveDetails";
            x.WaveDetails.map((y: any) => {
              waves.push(y);
              y.type = "Wave";
              y.idName = ["type", "WaveName", "WaveId"];
              y.labelName = "WaveName";
              y.childName = "ProductDetails";
              //y.CampaignWave_Nm=y.WaveDetails?y.WaveDetails.Wave_Nm:"";
              y.ProductDetails.map((z: any) => {
                z.waveId = y.WaveId;
                products.push(z);
                z.type = "Product";
                z.idName = ["type", "ProductName", "ProductId"];
                z.labelName = "ProductName";
                z.childName = "TemplateDetails";
                z.TemplateDetails = z.TemplateDetails.sort(function (
                  a: any,
                  b: any
                  ) {
                  let textA = a.TemplateName.toUpperCase();
                  let textB = b.TemplateName.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                  });
                z.TemplateDetails.map((p: any) => {
                  p.type = "Template";
                  p.productId = z.ProductId;
                  p.mainWaveId =
                    p.AssetDetails.length == 0
                      ? p.CampaignWaveId
                      : p.AssetDetails[0].CampaignWaveId;
                  if (p.FormId) {
                    p.TemplateName = p.FormName;
                  }
                  p.idName = ["type", "TemplateName", "TemplateId"];
                  p.labelName = "TemplateName";
                  p.childName = "AssetDetails";
                  tempAssets = tempAssets.concat(p.AssetDetails);
                  p.AssetDetails.map((o: any) => {
                    o.mainItem = true;
                    o.type = "Asset";
                    o.idName = ["type", "AssetFile_Nm", "AssetId"];
                    o.labelName = "AssetFile_Nm";
                    return o;
                  });
                  return p;

                  //tempAssets.push(y.campaignWaveAsset);
                });
                return z;
              });
              return y;
            });
            return x;
          });
          let templatesNotFound = false;
          let filtercamp = InitialAllCampaign?.map((camp, campindex) => {
            if (camp?.CamapignId == activcamp) {
              camp.WaveDetails.map((wave, waveIndex) => {
                if (
                  wave.ProductDetails.length !=
                    campaigns[campindex].WaveDetails[waveIndex].ProductDetails
                      .length ||
                  camp.WaveDetails.length !=
                    campaigns[campindex].WaveDetails.length ||
                  campaigns.length != InitialAllCampaign.length
                ) {
                  wave.ProductDetails.map((product, productIndex) => {
                    if (
                      InitialAllCampaign[campindex]?.WaveDetails[waveIndex]
                        ?.ProductDetails[productIndex].ProductId ==
                      (JSON.parse(prodd)?.ProductId || activeProduct?.ProductId)
                    ) {
                      templatesNotFound = true;
                      console.log("productinside");
                      camp[campindex].WaveDetails[waveIndex].ProductDetails[
                        productIndex
                      ].TemplateDetails = [];
                    }
                  });
                }
              });
            }
            return camp;
          });

          if (searchQuery != "" && templatesNotFound) {
            campaigns = filtercamp;
            setAllCampaign(filtercamp);
          } else {
            setInitialAllCampaign(campaigns);
            setAllCampaign(campaigns);
          }
          let activeC: any = window.localStorage.getItem("activeCampaign");
          if (activeC) {
            let index = campaigns.findIndex(
              (x) => x.CamapignId == JSON.parse(activeC).CamapignId
            );
            if (index >= 0) {
              activeC = campaigns[index];
              setActiveCampagign(campaigns[index]);
            } else {
              activeC = campaigns[0];
              setActiveCampagign(campaigns[0]);
            }
          } else {
            activeC = campaigns[0];
            setActiveCampagign(campaigns[0]);
          }

          let tempWaves = activeC.WaveDetails ? activeC.WaveDetails : [];
          setAllWaves(tempWaves);

          let activeW: any = window.localStorage.getItem("activeWave");

          if (activeW) {
            let index = tempWaves.findIndex(
              (x) => x.WaveId == JSON.parse(activeW).WaveId
            );
            if (index >= 0) {
              activeW = tempWaves[index];
              setActiveWave(tempWaves[index]);
            } else {
              activeW = tempWaves[0];
              setActiveWave(tempWaves[0]);
            }
          } else {
            activeW = tempWaves[0];
            setActiveWave(tempWaves[0]);
          }

          // Earlier
          let tempProducts = activeW.ProductDetails
            ? activeW.ProductDetails
            : [];

          setCampaign(tempProducts); //campagins is used as products
          let activep: any = window.localStorage.getItem("activeProduct");

          if (activep) {
            let index = tempProducts.findIndex(
              (x) => x.ProductId == JSON.parse(activep).ProductId
            );
            if (index >= 0) {
              activep = tempProducts[index];
              setActiveProduct(tempProducts[index]);
            } else {
              activep = tempProducts[0];
              setActiveProduct(tempProducts[0]);
            }
          } else {
            activep = tempProducts[0];
            setActiveProduct(tempProducts[0]);
          }

          let tempTemplates = activep.TemplateDetails
            ? activep.TemplateDetails
            : [];
          setTemplate(tempTemplates);

          // let o=window.localStorage.getItem('activeProduct')
          // setActiveProduct(o?JSON.parse(o):null)

          // getFormsData()
          // setTemplate(tempAssets);
          // setAlllProducts(products);
          // setActiveProducts(products)

          // setAlllWaves(waves);
          // setActiveWaves(waves);
          // setAssets(tempAssets);
          setSearchLoading(false);
        } else {
          setNoTemplateFound(true);
          //setTemplate([]);
        }
        setIsLoading(false);
        setSearchLoading(false);
      }
    );
  };

  const selectbrand = (e) => {
    window.localStorage.setItem("activeProduct", JSON.stringify(e));
    setTemplate(e.TemplateDetails ? e.TemplateDetails : []);
    // setDisableTemplate(false)
    // if(e.TemplateDetails.length==1){
    //   setDisableTemplate(false)
    // }
    // else{
    //   setDisableTemplate(true)
    // }
    setActiveProduct(e);
    setDynamicTemplateText("");

    if (searchValue !== "") {
      if (contact.Is_Prescriber === "Y") {
        getTemplatesFromCampign(searchValue);
      } else {
        getTemplatesFromCampignStaff(searchValue);
      }
    }

    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)

    //  getFormsData(1001)
  };

  function getTemplateText(item) {
    if (
      item.FieldDetails.length > 0 &&
      !item.FormId &&
      item.TemplateId == selectedTemplateData.TemplateId &&
      dynamicTemplateText.length > 0
    ) {
      return dynamicTemplateText;
    } else {
      return item.TemplateBody.replace(/(?:\r\n|\r|\n)/g, "\n");
    }
  }
  const selectWave = (e) => {
    // setDisableTemplate(true)
    window.localStorage.setItem("activeWave", JSON.stringify(e));
    setActiveWave(e);
    // setActiveWave(null);
    setActiveProduct(null);
    const ProductDetails = e.ProductDetails;
    //setCampaign(e.ProductDetails ? e.ProductDetails : []);
    setCampaign(ProductDetails);
    console.log("Values", ProductDetails);
    if (ProductDetails.length >= 1) {
      selectbrand(ProductDetails[0]);
    }
    setDynamicTemplateText("");
    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)
  };
  const isDateDisabled = (date) => {
    return date.getMonth() < 0 || date.getMonth() > 11; // Disable months outside the range 0-11 (January to December)
  };

  const selectCampaign = (e) => {
    // setDisableTemplate(true)
    window.localStorage.setItem("activeCampaign", JSON.stringify(e));
    setActiveCampagign(e);
    // setActiveWave(null);
    // setActiveProduct(null);
    setAllWaves(e.WaveDetails ? e.WaveDetails : []);
    if (e.WaveDetails.length >= 1) {
      selectWave(e.WaveDetails[0]);
    }
    setDynamicTemplateText("");
    // setTemplateText("");
    // setTemplateId(0)
    // setAssets([]);
    // setIsForm(false)
  };
  const clearDynamicform = () => {
    setTemplateFieldDetails(formValues);
    setShowError([]);
    setDynamicTemplateText("");
    setEventDateValue("");
    handleCloseParent();
    setInitialAllCampaign([]);
  };
  const handleDateChange = (index, value) => {
    if (value === "Invalid Date" || !value) {
      setEventDateValue("");
      let tempErr = showError;
      tempErr[0] = "please enter valid date";
      setShowError(tempErr);
      //  showError.push("please enter valid date1");
      setShowError(showError);
      updateFormValue(index, "");
    } else {
      setShowError([]);
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      // const formattedDate = `${day}/${month}/${year}`;
      let tempDt = personalizationDateFormat
        ? personalizationDateFormat
        : tempDtForm;
      tempDt = tempDt.replace(/dd/gi, "DD");
      let formattedDate = moment(date).format(tempDt);
      setEventDateValue(value);
      updateFormValue(index, formattedDate);
    }
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={show}
        // onClose={handleCloseParent}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="pre_approvetemplate"
      >
        <Fade in={show}>
          <Box>
            <div
              className="pre-approve-modal-box"
              style={
                mobileStyle
                  ? { width: modalWidth + "px", height: "75%", display: "flex", flexDirection: "column"}
                  : { height: "80vh", display: "flex", flexDirection: "column"}
              }
            >
              <div id="ModalHeader" className="modal-header d-flex">
                <h1 className="font-newchat">{t("Pre-Approved Templates")}</h1>
                <span onClick={clearDynamicform} className="close">
                  ×
                </span>
              </div>

              {!template?.length && isLoading ? (
                <div
                  id="ModalSearch"
                  className="modal-body pre_approvetemplate_body pt-5"
                >
                  <Spinner />
                </div>
              ) : (
                <>
                  <div id="ModalSearch" className="pre_approvetemplate_head">
                    {allCampaign.length > 1 ? (
                      <div>
                        <DropdownInupt
                          activeValue={activeCampaign}
                          disableColored={false}
                          keepPlaceHolderAtStart={false}
                          placeHolder={t("Select a campaign")}
                          label="Campaign"
                          data={allCampaign}
                          id=""
                          name="CampaignName"
                          func={(e) => {
                            selectCampaign(e);
                          }}
                        />
                      </div>
                    ) : null}
                    {allWaves.length > 1 ? (
                      <div className="mt-3">
                        <DropdownInupt
                          activeValue={activeWave}
                          disableColored={false}
                          keepPlaceHolderAtStart={allWaves.length > 1}
                          placeHolder={t("Select a wave")}
                          label="Wave"
                          data={allWaves}
                          id=""
                          name="WaveName"
                          func={(e) => {
                            selectWave(e);
                          }}
                        />
                      </div>
                    ) : null}
                    {campaign.length > 1 ? (
                      <div className="mt-3">
                        <DropdownInupt
                          activeValue={activeProduct}
                          disableColored={false}
                          keepPlaceHolderAtStart={campaign.length > 1}
                          placeHolder={t("Select a brand")}
                          label="Brand"
                          data={campaign}
                          id=""
                          name="ProductName"
                          func={(e) => {
                            setClickedTemplateId(-1);
                            // setTemplate(e.TemplateDetails);
                            selectbrand(e);
                            // getTemplatesFromCampign(searchValue);
                          }}
                        />
                      </div>
                    ) : null}
                    {/* <DropdownInupt label="Select Brand" data={campaign} id="Id" name="ProductName"  func={(e)=>{
                    setClickedTemplateId(-1)
                    setTemplate(e.TemplateDetails);
                  }}/> */}
                  </div>
                  <div className="searchHeader">
                    <div className="content__header__searchbox chatListPage">
                      <div className="content__header__searchbox">
                        <SearchInput
                          id={"templateSearch"}
                          classes={"form-control font-md search-templates"}
                          placeholder={t("Search Template")}
                          onChangeValue={(e) => {
                            setSearchValue(e);
                            setHitSearch(e);
                          }}
                          onClicked={(e) => {
                            console.log("fffff");
                            setHitSearch(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {searchLoading && (
                    <div className="searchTemplateSpinner">
                      <Spinner />
                    </div>
                  )}
                  <div className="modal-body pre_approvetemplate_body">
                    <ul className="template-modal-list">
                      {noTemplateFound ||
                      activeCampaign?.WaveDetails[0]?.ProductDetails[0]
                        ?.TemplateDetails?.length == 0 ? (
                        <p className="text-center">{t("No Template Found")}</p>
                      ) : (
                        template.map((item: any, index: number) => {
                          return (
                            <li
                              className="template-modal-list-item"
                              onClick={() => {
                                selectTemplate(index, item);
                              }}
                            >
                              <div className="template-modal-body-title">
                                <div className="template-name">
                                  <b>{item.TemplateName}</b>
                                  {item.TemplateType === "24H" &&
                                    item.WhatsappApproved_Flg === "Y" && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="whatsapp twenty-four-hr-template"
                                      >
                                        <g>
                                          <path fill="none" d="M0 0H24V24H0z" />{" "}
                                          <path d="M12 13c1.657 0 3 1.343 3 3 0 .85-.353 1.616-.92 2.162L12.17 20H15v2H9v-1.724l3.693-3.555c.19-.183.307-.438.307-.721 0-.552-.448-1-1-1s-1 .448-1 1H9c0-1.657 1.343-3 3-3zm6 0v4h2v-4h2v9h-2v-3h-4v-6h2zM4 12c0 2.527 1.171 4.78 3 6.246v2.416C4.011 18.933 2 15.702 2 12h2zm8-10c5.185 0 9.449 3.947 9.95 9h-2.012C19.446 7.054 16.08 4 12 4 9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4z" />
                                        </g>
                                      </svg>
                                    )}
                                  {item.WhatsappApproved_Flg === "Y" &&
                                    item.TemplateType !== "24H" && (
                                      <WhatsAppIcon className="whatsapp"></WhatsAppIcon>
                                    )}
                                </div>
                                <p className="template-modal-body-content">
                                  {getTemplateText(item)}
                                </p>
                                {item.TemplateFooter &&
                                  item.TemplateFooter !== "" && (
                                    <p className={EmployeePermissionCountry_Cd =="BR" ? `mt-3 template-modal-body-content` : `template-modal-body-content`}>
                                      {item.TemplateFooter}
                                    </p>
                                  )}
                                {item.AssetDetails ? (
                                  <div>
                                    {item.AssetDetails.length > 0 ? (
                                      <>
                                        <p className="template-modal-body-assets">
                                          <b> {t("Assets")} </b>{" "}
                                          <span className="badge badge-info">
                                            {item.AssetDetails.length}
                                          </span>
                                        </p>
                                        <ul className="template-modal-body-footer">
                                          {item.AssetDetails.map((x) => (
                                            <li>
                                              <a
                                                href={x.AssetFile_Url}
                                                target="__blank"
                                              >
                                                {x.Asset_Nm}
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </>
                                    ) : null}
                                  </div>
                                ) : null}
                                {/* {JSON.stringify(item.FieldDetails)} */}
                                {item.FormId ? (
                                  <div className="template_body_form">
                                    <form id={"form-" + item.FormId}>
                                      {item.FieldDetails.map(
                                        (x: any, i: any) => (
                                          <>
                                            <p className="para">
                                              {x.FieldName} max( {getRepLimit()}{" "}
                                              )
                                            </p>
                                            <textarea
                                              style={{ height: "1rem" }}
                                              name={x.FieldId}
                                              id={x.FieldId}
                                              onChange={(e) => {
                                                // quantity.map(q=>{
                                                //   if(q.productid==='1000' && e.target.value>q.quantity)
                                                //   setDisabled(true)
                                                // })
                                                getFormStatus(
                                                  e.target.value,
                                                  item.FormId
                                                );
                                              }}
                                            ></textarea>
                                          </>
                                        )
                                      )}
                                      <span
                                        id={"error-form-" + item.FormId}
                                        style={{
                                          color: "red",
                                          fontSize: "1rem",
                                        }}
                                      ></span>
                                    </form>
                                  </div>
                                ) : null}
                              </div>

                              {item.FieldDetails.length > 0 && !item.FormId && (
                                <div className="template_body_form">
                                  <form
                                    id={
                                      "dynmaicTemplateFields-" + item.TemplateId
                                    }
                                  >
                                    {item.FieldDetails.map((x: any, i: any) => (
                                      <>
                                        {x.FieldType.toLowerCase() ===
                                          "checkbox" && (
                                          <div className="checkBoxField">
                                            <input
                                              type="checkbox"
                                              id={x.FieldId}
                                              name={x.FieldId}
                                              onChange={(e) => {
                                                updateFormValue(
                                                  i,
                                                  e.target.checked
                                                );
                                              }}
                                            />
                                            <label
                                              htmlFor={x.FieldId}
                                              className="checkboxLabel"
                                            >
                                              {x.FieldName}
                                            </label>
                                          </div>
                                        )}
                                        {(x.FieldType.toLowerCase() ===
                                          "string" ||
                                          x.FieldType.toLowerCase() ===
                                            "address" ||
                                          x.FieldType.toLowerCase() ===
                                            "name" ||
                                          x.FieldType.toLowerCase() ===
                                            "link") && (
                                          <div className="meeting-textfield">
                                            <TextField
                                              name={x.FieldId}
                                              id={x.FieldId}
                                              required={
                                                x.IsRequired
                                                  ? x.IsRequired == "Y"
                                                  : false
                                              }
                                              className="roundborder inputEnable"
                                              sx={{ width: 400 }}
                                              label={t(x.FieldName)}
                                              value={
                                                clickedTemplateId === index &&
                                                templateFieldDetails?.length > 0
                                                  ? templateFieldDetails[i]
                                                      ?.Value
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                checkWhatsappValidation(
                                                  index,
                                                  i,
                                                  e.target.value
                                                );
                                              }}
                                              color="secondary"
                                              focused
                                            />
                                          </div>
                                        )}
                                        {x.FieldType.toLowerCase() ===
                                          "dropdown" && (
                                          <div className="meeting-textfield">
                                            <TextField
                                              name={x.FieldId}
                                              id={x.FieldId}
                                              className="roundborder inputEnable"
                                              select
                                              label={t(x.FieldName)}
                                              required={
                                                x.IsRequired
                                                  ? x.IsRequired == "Y"
                                                  : false
                                              }
                                              value={
                                                clickedTemplateId === index &&
                                                templateFieldDetails?.length > 0
                                                  ? templateFieldDetails[i]
                                                      ?.Value
                                                  : " "
                                              }
                                              defaultValue={" "}
                                              sx={{ width: 400 }}
                                              // placeholder={t("select " + x.FieldName + "")}
                                              size="small"
                                              inputProps={{
                                                style: {
                                                  height: "84",
                                                  padding: "1rem",
                                                  color: "grey",
                                                },
                                              }}
                                              onChange={(e) => {
                                                updateFormValue(
                                                  i,
                                                  e.target.value
                                                );
                                              }}
                                              disabled={
                                                clickedTemplateId === index
                                                  ? false
                                                  : true
                                              }
                                            >
                                              {x.FieldVal.map((option) => (
                                                <MenuItem
                                                  key={option}
                                                  value={option}
                                                >
                                                  {option}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </div>
                                        )}
                                        {x.FieldType.toLowerCase() ===
                                          "date" && (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                          >
                                            <DesktopDatePicker
                                              className="Datepicker"
                                              inputFormat={
                                                personalizationDateFormat
                                                  ? personalizationDateFormat
                                                  : tempDtForm
                                              }
                                              value={
                                                clickedTemplateId === index &&
                                                templateFieldDetails?.length > 0
                                                  ? eventDateValue
                                                  : ""
                                              }
                                              label={t(x.FieldName)}
                                              disablePast={true}
                                              shouldDisableDate={isDateDisabled}
                                              onChange={(value) => {
                                                handleDateChange(i, value);
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  InputLabelProps={{
                                                    shrink: true, // Disable the label animation
                                                  }}
                                                  required={
                                                    x.IsRequired
                                                      ? x.IsRequired === "Y"
                                                      : false
                                                  }
                                                />
                                              )}
                                            />
                                          </LocalizationProvider>

                                          // <TextField
                                          //   name={x.FieldId}
                                          //   id={x.FieldId}
                                          //   className="Datepicker"
                                          //   label={t(x.FieldName)}
                                          //   required = {x.IsRequired ? x.IsRequired == "Y" : false}
                                          //   type="date"
                                          //   value={clickedTemplateId === index && templateFieldDetails?.length > 0 ? templateFieldDetails[i]?.Value : ""}
                                          //   placeholder="MM/DD/YYYY"
                                          //   sx={{ width: 220 }}
                                          //   inputProps={{
                                          //     style: {
                                          //       height: "84",
                                          //       padding: "1rem",
                                          //       color: "grey",
                                          //     },
                                          //     min: new Date().toISOString().split("T")[0],
                                          //     max: "9999-01-01"
                                          //   }}
                                          //   onChange={(e) => {
                                          //     updateFormValue(i, e.target.value);
                                          //   }}
                                          //   InputLabelProps={{
                                          //     shrink: true,
                                          //   }}
                                          //   focused
                                          // />
                                        )}
                                        {x.FieldType.toLowerCase() ===
                                          "time" && (
                                          <TextField
                                            className="Timepicker"
                                            name={x.FieldId}
                                            id={x.FieldId}
                                            label={t(x.FieldName)}
                                            required={
                                              x.IsRequired
                                                ? x.IsRequired == "Y"
                                                : false
                                            }
                                            type="time"
                                            placeholder="--:--"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              style: {
                                                height: "84",
                                                padding: "1rem",
                                                color: "grey",
                                              },
                                            }}
                                            onChange={(e) => {
                                              updateFormValue(
                                                i,
                                                e.target.value
                                              );
                                            }}
                                            sx={{ width: 150 }}
                                            focused
                                          />
                                        )}
                                        {clickedTemplateId === index &&
                                        showError[i]?.length > 0 ? (
                                          <span className="dyn-templ-error">
                                            {t(showError[i])}
                                          </span>
                                        ) : null}
                                      </>
                                    ))}
                                  </form>
                                </div>
                              )}

                              {clickedTemplateId === index ? (
                                <div className="floating_btn">
                                  <button
                                    disabled={
                                      sendButtonDisbale ||
                                      (disablebtnInfo &&
                                        disablebtnInfo[index]?.Disable)
                                    }
                                    className={
                                      sendButtonDisbale ||
                                      (disablebtnInfo &&
                                        disablebtnInfo[index]?.Disable)
                                        ? "disbaleBtn"
                                        : ""
                                    }
                                    onClick={sendTemplate}
                                  >
                                    <i className="far fa-paper-plane"></i>
                                    {/* <i className="fas fa-paper-plane-top"></i> */}
                                  </button>
                                </div>
                              ) : null}
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
