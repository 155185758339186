import React,{useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from "@mui/material/Backdrop";
import Button from '@mui/material/Button';
import './ContactAddPopup.css'
import { fabClasses } from '@mui/material';
import config from '../../env.json';
import CountryDropdown from '../../layouts/countryDropdown/CountryDropdown';
import { editContact,addNewContact,addAssociation,deleteAssociation, checkNumberExist } from "../../apis/contacts/contactsApi";
import { setChatDetails, setChatType } from '../../app/slice/chatSlice';
import { useSelector, useDispatch } from 'react-redux';
import {parsePhoneNumber}from 'react-phone-number-input';
import { setCallModalView, setCallModalDetails} from '../../app/slice/callModalSlice';
import { useTranslation } from "react-i18next";
import ContactAlreadyAssign from '../../components/logoutModal/LogoutModal'
import { setTitle } from '../../app/slice/sideBarSlice';
import PhoneNumberDropdown from '../../layouts/phoneNumberDropdown/PhoneNumberDropdown';
import { setActiveTab } from "../../app/slice/sideBarSlice";
import { useNavigate} from 'react-router-dom';


export default function ContactAddPopup({showAddContactModal,handleCloseParent, data, redirectPage}) {
  const { t } = useTranslation();
    const dispatch=useDispatch()
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disabled,setDisabled]=useState(false)
  const [showModal,setShowModal]=useState(false);
  const [overrideMessage, setOverrideMessage] = useState('');
  const [existingContact, setExistingContact] = useState('');
  const setNumber = (e) =>{
    setPhoneNumber(e)
  }
  const navigate = useNavigate();
  const chatPermId = useSelector((state: any) =>state.sideBar.chatPermId);

  const [countries, setCountries] = useState<any>(
    config.REACT_APP_COUNTRY_LIST?.split(",")?.map((code: string) =>
      code.toLowerCase()
    )
  );
  const repId = useSelector((state: any) => state.authApi.Id);
  const handleOverrideMsg = (accType,accName) => {
    if(config.REACT_APP_PH_OVERRIDE_MSG){
      let msg = t(config.REACT_APP_PH_OVERRIDE_MSG);
        let type = accType === "N" ? t("Others") : t("Prescriber");
        let name = accName;
        if(type){
          msg = msg.replace("TYPE", type);
        }else{
          msg = msg.replace("TYPE -", "");
        }
        if(name){
          msg = msg.replace("NAME", name);
        }
        setOverrideMessage(msg);
        setExistingContact(accName)
    }
  } 
  useEffect(()=>{
    setCountryList()
  },[])
  let countryCode = useSelector((state:any)=> state.personalisation.EmployeePersonalizationPhone_Allow_CountryCd)  
  const setCountryList = () => {
    let userCountryList:Array<any>= countryCode?.split(",")?.map((code: string) =>code?.toLowerCase()) 
    setCountries(userCountryList)
  };
const saveContact = () =>{
  
    let phn:any=parsePhoneNumber(phoneNumber);
    let payload:any={
        AcctPh_Nbr:phn.nationalNumber,
        IntlCallPfx_Nbr:phn.countryCallingCode,
        Id:data.Id,
      }
      if(data.Is_Prescriber=="N"){
        payload={
          AcctPh_Nbr:phn.nationalNumber,
          AcctFull_Nm:data.AcctFull_Nm,
          Prescriber_Id:data?.Prescribers?.map((x:any)=>x?.Id),
          Id:data.Id,
          IntlCallPfx_Nbr:phn.countryCallingCode
        }  
        checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
          console.log(res)  
          if(res)  {
            handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
            setShowModal(true)
                }  else{
                  addNewContact(payload,async (cont:any)=>{
                    if(cont){
                      if(redirectPage === false){
                        
                        dispatch(setCallModalDetails({
                          AcctFull_Nbr:phn.number,
                          AcctFull_Nm:cont.AcctFull_Nm
                        }))
                        dispatch(setCallModalView(true))
                        setPhoneNumber('')
                        handleCloseParent(payload)
                       
                      }else{
                       
                        setChat(cont)
                        setPhoneNumber('')
                        handleCloseParent(payload)
                        // dispatch(setCallModalDetails({
                        //   AcctPh_Nbr:'',
                        //   AcctFull_Nm: ''
                        // }))
                      }
                    }
                                
                  })
                }})
       
      }else{
        checkNumberExist(payload.AcctPh_Nbr,(res:any)=>{
          console.log(res)  
          if(res)  {
            handleOverrideMsg(res.Is_Prescriber,res.AcctFull_Nm);
            setShowModal(true)
                }  else{
                  editContact(payload,(res)=>{
                    
                    console.log(redirectPage)
                    if(res){
                        console.log(res)
                        console.log(redirectPage)
                        if(redirectPage === false){
                          
                          console.log(phn)
                          
                          dispatch(setCallModalDetails({
                            AcctFull_Nbr:phn.number,
                            AcctFull_Nm:res.AcctFull_Nm
                          }))
                          dispatch(setCallModalView(true))
                          setPhoneNumber('')
                          handleCloseParent(payload)
                          // setChat(res)
                                
                                // payload.AcctFull_Nm=tempAsso;
                                // payload.Is_Prescriber="Y"
            
                                // handleCloseParent()
                                // dispatch(setCallModalDetails({
                                //   AcctPh_Nbr: '',
                                //   AcctFull_Nm: ''
                                // }))
                        }else{
                          console.log(phn)
                          
                          // dispatch(setCallModalView(true))
                          // dispatch(setCallModalDetails({
                          //   AcctPh_Nbr:phn,
                          //   AcctFull_Nm:res.AcctFull_Nm
                          // }))
                          setChat(res)
                          setPhoneNumber('')
                          handleCloseParent(payload)
                          // dispatch(setCallModalDetails({
                          //   AcctPh_Nbr:'',
                          //   AcctFull_Nm: ''
                          // }))
                        }
                        
                    }
                    else{
                    //    clearAll(false,{})
                    }
                  })
                }})
     
    }

}
 const handleYOrNPopup =(e:any) =>{
  
    if(e=== true){
      let phn:any=parsePhoneNumber(phoneNumber);
      let payload:any={
          AcctPh_Nbr:phn.nationalNumber,
          IntlCallPfx_Nbr:phn.countryCallingCode,
          Id:data.Id,
        }
      if(data.Is_Prescriber=="N"){
        payload={
          AcctPh_Nbr:phn.nationalNumber,
          AcctFull_Nm:data.AcctFull_Nm,
          Prescriber_Id:data?.Prescribers?.map((x:any)=>x?.Id),
          Id:data.Id,
          IntlCallPfx_Nbr:phn.countryCallingCode
        }
        addNewContact(payload,async (cont:any)=>{
          if(cont){
            if(redirectPage === false){
              dispatch(setCallModalDetails({
                AcctFull_Nbr:phn.number,
                AcctFull_Nm:cont.AcctFull_Nm
              }))
              dispatch(setCallModalView(true))
              setPhoneNumber('')
              handleCloseParent(payload, existingContact)
              
             
            }else{
             
              setChat(cont)
              setPhoneNumber('')
              handleCloseParent(payload)
              // dispatch(setCallModalDetails({
              //   AcctPh_Nbr:'',
              //   AcctFull_Nm: ''
              // }))
            }
          }
                      
        })
      } else{
        editContact(payload,(res)=>{
          
          console.log(redirectPage)
          if(res){
              console.log(res)
              console.log(redirectPage)
              if(redirectPage === false){
                
                console.log(phn)
                
                dispatch(setCallModalDetails({
                  AcctFull_Nbr:phn.number,
                  AcctFull_Nm:res.AcctFull_Nm
                }))
                dispatch(setCallModalView(true))
                setPhoneNumber('')
                handleCloseParent(payload, existingContact)
                // setChat(res)
                      
                      // payload.AcctFull_Nm=tempAsso;
                      // payload.Is_Prescriber="Y"
  
                      // handleCloseParent()
                      // dispatch(setCallModalDetails({
                      //   AcctPh_Nbr: '',
                      //   AcctFull_Nm: ''
                      // }))
              }else{
                console.log(phn)
                
                // dispatch(setCallModalView(true))
                // dispatch(setCallModalDetails({
                //   AcctPh_Nbr:phn,
                //   AcctFull_Nm:res.AcctFull_Nm
                // }))
                setChat(res)
                setPhoneNumber('')
                handleCloseParent(payload, existingContact)
                // dispatch(setCallModalDetails({
                //   AcctPh_Nbr:'',
                //   AcctFull_Nm: ''
                // }))
              }
              
          }
          else{
          //    clearAll(false,{})
          }
        })
      }
      // setDisabled(true)      
      
    } else{
      setShowModal(false) 
    }
  }
const setChat=(post:any)=>{
    console.log(post)
    
    console.log('asdasd')
    dispatch(setChatType(post.Is_Prescriber == "Y" ? "PRESCR" : "ASSPRS"));
    dispatch(setChatDetails(
      {
        chatId:post.Id,
        chatIsPrescriber:post.Is_Prescriber=="Y",
        showChat:true,
        chatName:post.AcctFull_Nm
      }))
      // dispatch(setTitle('Chat'))
      navigate("/chat");
      dispatch(setActiveTab(chatPermId));
    
  }
 
  return (
    <div>
        <Modal
      
      open={showAddContactModal}
      onClose={handleCloseParent}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      >
        <Fade 
        in={showAddContactModal}
        >
        <Box>
            <div className='modal-box contact-popup'>
            <div className="modal--body">
                <div className='modal--wrapper'>
                    {/* <p className='modal--content--contact'>{t('Please enter the number')}</p> */}
                    <form onSubmit={(e)=>{e.preventDefault();saveContact();}}>
                    {/* <CountryDropdown 
                    errorFunc={(e:any)=>{
                      if(e)
                      setDisabled(true)
                      else
                      setDisabled(false)
                    }} 
                    disableColored={false}
                    valueNumber={phoneNumber}
                    onChangeNumber={(e:any)=>{setNumber(e)}}
                     /> */}

                    <PhoneNumberDropdown 
                    initiall={(e)=>{}}
                    errorFunc={(e:any)=>{
                      if(e)
                      setDisabled(true)
                      else
                      setDisabled(false)
                    }} 
                    countryList={countries}
                    disableColored={false}
                    valueNumber={phoneNumber}
                    onChangeNumber={(e:any)=>{setNumber(e)}}
                     />
                   
                    <div className="modal--btn contact-popup-btn">
                        <div className='modal--btn--left'>
                            <button disabled={disabled}>
                          
                            {t("Proceed")} </button>
                        </div>
                        <div className='modal--btn--right'>
                            <button type="button" onClick={()=>{handleCloseParent(null); setPhoneNumber('')}}>
                            
                              {t("Cancel")} </button>
                        </div>
                    </div>
                        
                    </form>
                   
                </div>
            </div>
            </div>
        </Box>
        </Fade>
      </Modal>
      <ContactAlreadyAssign
      show={showModal}
      yorn={handleYOrNPopup}
      modalText={overrideMessage}
      // data={this.state.callDetails}
      handleCloseParent={(e)=>{
        console.log(e);
        setShowModal(false)
      }} />
    </div>
  )
}
