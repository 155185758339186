import { useEffect, useState } from "react";
import MainRoutes from "./Routes";
import MyModal from "./components/modal/Modal";
import { updatePushToken } from "./apis/notificationApi/notificationApi";
import { capacitormsal } from "capacitormsal";
import { deleteUserDetails } from "./apis/dbApi/dbApi";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { msalInstance } from "./index";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import config from "./env.json";
import { useSQLite } from "react-sqlite-hook";
import { useIdleTimer } from "react-idle-timer";

export let sqlite: any;
export let existingConn: any;

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  const dispatch = useDispatch();
  const loginDetails = useSelector((state: any) => state.login);
  const [existConn, setExistConn] = useState(false);
  const {
    echo,
    isConnection,
    getPlatform,
    createConnection,
    closeConnection,
    retrieveConnection,
    retrieveAllConnections,
    closeAllConnections,
    addUpgradeStatement,
    importFromJson,
    isJsonValid,
    copyFromAssets,
    isAvailable,
  } = useSQLite();
  document.documentElement.style.setProperty(
    "--primary-color",
    `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--tertiary-color",
    `${config.REACT_APP_MAIN_APP_CSS_TERTIARY_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--primary-text-color",
    `${config.REACT_APP_MAIN_APP_CSS_PRIMARY_TEXT_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--secondary-text-color",
    `${config.REACT_APP_MAIN_APP_CSS_SECONDARY_TEXT_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--placeholder-text-color",
    `${config.REACT_APP_MAIN_APP_CSS_PLACEHOLDER_TEXT_COLOR}`
  );
  document.documentElement.style.setProperty(
    "--date-banner-bg-color",
    `${config.REACT_APP_DATE_BANNER_BG_COLOR}`
  );

  const onIdle = () => {
    if (config.REACT_APP_INACTIVITY_ENABLED) {
      logoutUser();
    }
  };

  const { getRemainingTime, reset } = useIdleTimer({
    onIdle,
    timeout: parseInt(config.REACT_APP_INACTIVITY_ENABLED_TIME),
  });

  function logoutUser() {
    if (config.REACT_APP_IS_CROSS_PLATFORM) {
      updatePushToken(
        {
          DeviceToken_Val: sessionStorage.getItem("pushToken"),
          Device_Typ: "iOS",
          is_active: "N",
        },
        (res) => {}
      );
      if (config.REACT_APP_AUTH_TYPE == "AD") {
        capacitormsal
          .signOut({
            authority: config.REACT_APP_AD_AUTHORITY as string,
            clientId: config.REACT_APP_AD_CLIENTID as string,
            redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
            scope: config.REACT_APP_CROSS_SCOPE as string,
          })
          .then(async (response) => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/";
            });
          })
          .catch((error) => {
            sessionStorage.setItem("login", "");
            deleteUserDetails().then(() => {
              window.location.href = "/";
            });
          });
      } else {
        let browser = InAppBrowser.create(
          config.REACT_APP_PING_LOGOUT_URL,
          "_blank",
          "location=no"
        );
        browser.on("loadstop").subscribe(async (event) => {
          browser.close();
        });
        sessionStorage.setItem("login", "");
        deleteUserDetails().then(() => {
          window.location.href = "/";
        });
      }
    } else {
      if (config.REACT_APP_AUTH_TYPE == "AD") {
        let account = sessionStorage.getItem("loginName") as string;
        dispatch({ type: "RESET_APP" });
        msalInstance
          .logoutRedirect({
            account: msalInstance.getAccountByUsername(account),
          })
          .catch((e: any) => {
            console.log(e);
          });
      } else {
        sessionStorage.clear();
        localStorage.clear();
        var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
        window.location.href = logoutUrl;
        window.location.href = config.REACT_APP_PING_REDIRECT_URL;
      }
    }
  }
  if (config.REACT_APP_IS_CROSS_PLATFORM) {
    existingConn = { existConn: existConn, setExistConn: setExistConn };
    sqlite = {
      echo: echo,
      isConnection: isConnection,
      getPlatform: getPlatform,
      createConnection: createConnection,
      closeConnection: closeConnection,
      retrieveConnection: retrieveConnection,
      retrieveAllConnections: retrieveAllConnections,
      closeAllConnections: closeAllConnections,
      addUpgradeStatement: addUpgradeStatement,
      importFromJson: importFromJson,
      isJsonValid: isJsonValid,
      copyFromAssets: copyFromAssets,
      isAvailable: isAvailable,
    };
  }
  useEffect(() => {
    document.title = config.REACT_APP_TITLE;
    const favicon = document.getElementById("favicon");
    favicon?.setAttribute("href", config.REACT_APP_FAVICON);
  }, []);

  const logoutUserIdeally = () => {
    let inactivityTimer;

    function resetTimer() {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(
        logoutUser,
        parseInt(config.REACT_APP_INACTIVITY_ENABLED_TIME)
      ); // Adjust the time as per your requirement (in milliseconds)
    }

    //  Below function is for user inactivity logout. When user not intractive with screen.
    //   We cannot move this function in separate file beacuse of dispatch used in this function

    function logoutUser() {
      if (config.REACT_APP_IS_CROSS_PLATFORM) {
        updatePushToken(
          {
            DeviceToken_Val: sessionStorage.getItem("pushToken"),
            Device_Typ: "iOS",
            is_active: "N",
          },
          (res) => {}
        );
        if (config.REACT_APP_AUTH_TYPE == "AD") {
          capacitormsal
            .signOut({
              authority: config.REACT_APP_AD_AUTHORITY as string,
              clientId: config.REACT_APP_AD_CLIENTID as string,
              redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
              scope: config.REACT_APP_CROSS_SCOPE as string,
            })
            .then(async (response) => {
              sessionStorage.setItem("login", "");
              deleteUserDetails().then(() => {
                window.location.href = "/";
              });
            })
            .catch((error) => {
              sessionStorage.setItem("login", "");
              deleteUserDetails().then(() => {
                window.location.href = "/";
              });
            });
        } else {
          let browser = InAppBrowser.create(
            config.REACT_APP_PING_LOGOUT_URL,
            "_blank",
            "location=no"
          );
          browser.on("loadstop").subscribe(async (event) => {
            browser.close();
          });
          sessionStorage.setItem("login", "");
          deleteUserDetails().then(() => {
            window.location.href = "/";
          });
        }
      } else {
        if (config.REACT_APP_AUTH_TYPE == "AD") {
          let account = sessionStorage.getItem("loginName") as string;
          dispatch({ type: "RESET_APP" });
          msalInstance
            .logoutRedirect({
              account: msalInstance.getAccountByUsername(account),
            })
            .catch((e: any) => {
              console.log(e);
            });
        } else {
          sessionStorage.clear();
          localStorage.clear();
          var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
          window.location.href = logoutUrl;
          window.location.href = config.REACT_APP_PING_REDIRECT_URL;
        }
      }
    }

    function handleActivity() {
      resetTimer();
    }

    function handleVisibilityChange() {
      if (!document.hidden) {
        resetTimer();
      }
    }

    //reset inactivity if user receive message
    axios.interceptors.response.use(
      (res) => {
        if (res.status > 199 || res.status < 300) {
          handleActivity();
        }
        return res;
      },
      (err) => {
        console.log(err);
        return err;
      }
    );

    //add listeners in case of ios
    window.visualViewport?.addEventListener("mousemove", handleActivity);
    window.visualViewport?.addEventListener("mousedown", handleActivity);
    window.visualViewport?.addEventListener("keydown", handleActivity);
    window.visualViewport?.addEventListener("touchstart", handleActivity);
    window.visualViewport?.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("mousedown", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("touchstart", handleActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("mousedown", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  };
  useEffect(() => {
    if (config.REACT_APP_INACTIVITY_ENABLED) {
      //reset inactivity if user receive message
      axios.interceptors.response.use(
        (res) => {
          if (res.status > 199 || res.status < 300) {
            reset();
          }
          return res;
        },
        (err) => {
          console.log(err);
          return err;
        }
      );
    }
  }, []);

  useEffect(() => {
    console.log(`loginDetails`, loginDetails);
    if (loginDetails.token && loginDetails.refresh_token) {
      sessionStorage.setItem("login", loginDetails.token);
      sessionStorage.setItem("refreshtoken", loginDetails.refresh_token);
      delete axios.defaults.headers.common["Authorization"];
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + loginDetails.token;
    }
  }, [loginDetails]);
  return (
    <>
      <MsalProvider instance={pca}>
        <MyModal />
        <MainRoutes />
      </MsalProvider>
    </>
  );
}

export default App;
