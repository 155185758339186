import { useEffect,useState} from 'react';
import '../../components/errors/ErrorAuth.css';
import authorizeKey from '../../assets/icons/authorizeKey.svg';
import QRcode from '../../assets/icons/qr-code.svg';
import {msalInstance} from '../../index';
import { useNavigate} from 'react-router-dom';
import initLogo from "../../assets/images/Zinglogo.png";
import { initReactI18next, useTranslation } from "react-i18next";
import { deleteUserDetails } from '../../apis/dbApi/dbApi';
import  config  from '../../env.json';
import {capacitormsal} from "capacitormsal";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { updatePushToken } from '../../apis/notificationApi/notificationApi';
import { ForgerockLogout } from '../../apis/forgerockAuthApi/forgerockauthApi';
import i18n from "i18next";
export default function ErrorAuth(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [isLoadingTranslation, setLoadingTranslation] = useState(true);
    // useEffect(() => {
    //     let timer1 = setTimeout(() => {
    //          if(config.REACT_APP_IS_CROSS_PLATFORM) {
    //             updatePushToken({
    //                 "DeviceToken_Val": sessionStorage.getItem("pushToken"),
    //                 "Device_Typ": "iOS",
    //                 "is_active": 'N'
    //               },(res)=>{       
    //               })
    //             if(config.REACT_APP_AUTH_TYPE=="AD") {
    //                 capacitormsal.signOut({
    //                     authority: config.REACT_APP_AD_AUTHORITY as string,
    //                     clientId: config.REACT_APP_AD_CLIENTID as string,
    //                     redirectUri: config.REACT_APP_CROSS_REDIRECT_URI as string,
    //                     scope: config.REACT_APP_CROSS_SCOPE as string,
    //                     })
    //                     .then(async (response) => {
    //                         sessionStorage.setItem("login", "");
    //                         deleteUserDetails().then(() => {
    //                             window.location.href = "/"  
    //                         });              
    //                     })
    //                     .catch(error => {
    //                         sessionStorage.setItem("login", "");
    //                         deleteUserDetails().then(() => {
    //                             window.location.href = "/"  
    //                         });
    //                     })
    //             }             
    //             if(config.REACT_APP_AUTH_TYPE=="forgerock"){
    //                 ForgerockLogout((res)=>{
    //                   if(res){
    //                     sessionStorage.setItem("login", "");
    //                     deleteUserDetails().then(() => {
    //                       window.location.href = "/"  
    //                     });
    //                   }
    //                 })
    //             }
    //             else {
    //                 let browser = InAppBrowser.create(config.REACT_APP_PING_LOGOUT_URL, '_blank', 'location=no');
    //                 browser.on('loadstop').subscribe(async event => {
    //                   browser.close();
    //                 });
    //                 sessionStorage.setItem("login", "");
    //                 deleteUserDetails().then(() => {
    //                   window.location.href = "/"  
    //                 });
    //             }

    //           } else {
    //            if(config.REACT_APP_AUTH_TYPE=="AD"){
    //             let account=sessionStorage.getItem('loginName') as string
    //             msalInstance.logoutRedirect({
    //                 account: msalInstance.getAccountByUsername('/logout')
    //             }).catch((e:any) => {
    //             console.log(e);
    //             });
    //          }
    //          if(config.REACT_APP_AUTH_TYPE=="forgerock"){
    //             ForgerockLogout((res)=>{
    //               if(res){
    //                 sessionStorage.clear();
    //                 localStorage.clear();
    //                 window.location.href =config.REACT_APP_FORGEROCK_REDIRECT_URL;      
    //               }
    //             })
    //           }
    //         else
    //         {
    //             sessionStorage.clear();
    //             localStorage.clear();
    //             var logoutUrl = config.REACT_APP_PING_LOGOUT_URL;
    //             window.location.href = logoutUrl     
    //              window.location.href =config.REACT_APP_PING_REDIRECT_URL;
    //         }
    //           }
    //     },  5000);
    //     // this will clear Timeout
    //     // when component unmount like in willComponentUnmount
    //     // and show will not change to true
    //     return () => {
    //       clearTimeout(timer1);
    //     };
    // })
    // let transLocData:any = localStorage.getItem("translationData")
    // transLocData = JSON.parse(transLocData)
    //  console.log("transLocData1234",transLocData);
    // let browserLang= Object.keys(transLocData)[0];
    //  console.log("browserLang",browserLang);
    useEffect(()=>{        
        try {
            let browserLang= navigator.language;
            let url = config.REACT_APP_AUTH_API_BASE +"/getlanguage?lang="+browserLang
            // const url = "http://localhost:8080/api/getlanguage?lang="+browserLang
            browserLang= (browserLang.split("-")[0] == "en")?"en-USA":browserLang
            console.log("browserLang",browserLang);
            fetch(url).then((res)=>res.json()).then((response)=>{
              i18n
              .use(initReactI18next) 
              .init({
                resources: response,
                lng: browserLang,
                interpolation: {
                  escapeValue: false, 
                },
              });
              console.log("data" ,response)
              localStorage.setItem("translationData",JSON.stringify(response))
              console.log("transLocData4567",response);
              setLoadingTranslation(false)
            })
          } catch (error) {
            setLoadingTranslation(false)
            console.log("Unable to get browser language or the language data is not present: ",error);
          }

    },[])
    return (
        <>
         {(isLoadingTranslation) &&
        <div className="intial-loader">
          {/* <div className="init-page-loader"></div> */}
          <img src={initLogo} alt=""></img>
          <div className="init-page-loader-div">
          <div className="init-page-loader" >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          </div>
         
        </div>
      
      }
            <div className='errordiv'>
                <div className='error'>
                    <img src={QRcode} />
                    <h1>{t('QR code is invalid')}</h1>
                    <p>{t('The QR code for this rep is either invalid or does not exist.')}</p>
                </div>
            </div>
        </>
    )
}


