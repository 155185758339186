import axios from "axios";
import config from "../../env.json";
function getCampaignsData(params, callback) {
  let { page, pageSize, searchQuery, campaignId, productId,contactId } = params;
  try {
    axios
      .get(config.REACT_APP_CAMPAIGN_API_BASE + "/campaign/hierarchy", {
        params: {
          searchQuery: searchQuery,
          campaignId,
          productId,
          contactId
        },
      })
      .then((res) => {
        callback(res.data);
      })
      .catch((error) => {
        callback([]);
      });
  } catch (error) {
    callback([]);
  }
}

export { getCampaignsData };
