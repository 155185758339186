import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  setComposeEmailView,
  setEmailContentView,
  setEmailDraftView,
  setEmailInboxView,
  setSentEmailView,
} from "../../app/slice/emailSubMenuSlice";
import {
  getEmailIds,
  getMailById,
  getMailsByName,
} from "../../apis/Email/Email";
import Spinner from "../../layouts/spinner/Spinner";
import { useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import "./Inbox.css";
const EMIAL_CONV = {
  id: "",
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: 0,
  border: "none !important",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  border: "none",
  backgroundColor: theme.palette.mode === "dark" ? "ffffff" : "#ffffff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function DraftList(props) {
  const dispatch = useDispatch();
  const parse = require("html-react-parser");
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [data, setData] = React.useState<Array<any>>([]);
  const getIndoxView = useSelector((state: any) => state.email.showInbox);
  const [apicalled, setapicalled] = React.useState(false);
  const [emailList, setEmailList] = React.useState<any>([]);
  const [showmore, setshowmore] = React.useState(false);
  const [gmailData, setGmailData] = React.useState([]);

  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const fetchDataofMail = () => {
    getMailById(EMIAL_CONV.id, (resp: any) => {
      if (resp) {
        console.log("::::::::::::::::::::::::::::::::::::/n", resp);
        let tempData: Array<any> = [...resp.data];
        console.log("1::::::::::::::::::::::::::::::::::::/n", emailList);
        setEmailList(tempData.reverse());
        console.log("2::::::::::::::::::::::::::::::::::::/n", emailList);
      }
    });
  };

  useEffect(() => {
    fetchDataofMail();

    getEmailIds((resp: any) => {
      resp.data.map((item) => {
        if (item.provider === "google") {
          localStorage.setItem("gmailId", item.email);
        }
      });
    });
  }, []);

  const fetchData = () => {
    getMailsByName("INBOX", (resp: any) => {
      console.log("::::::::::::::::::::::::::::::::::::::::::::", resp);
      setData(resp);
      setapicalled(!apicalled);
    });

    const gmailId = localStorage.getItem("gmailId");
    if (gmailId) {
      getMailById(gmailId, (resp: any) => {
        console.log("Google data=>", resp.data);
        setGmailData(resp.data);
      });
    }
  };

  const convertHtml = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    let parser = new DOMParser();
    let dom_document = parser.parseFromString(str, "text/html");
    let body_element = dom_document.getElementsByTagName("body")[0];
    let newString = body_element.innerHTML;
    let strippedHtml = newString.replace(/<[^>]+>+/g, "");
    let strippedHtml1 = strippedHtml.replace(/\&nbsp;/g, "");
    let finalEmailText = strippedHtml.includes("From")
      ? strippedHtml1.split("From")[0]
      : strippedHtml;
    return finalEmailText;
  };

  function truncate(source, size) {
    console.log(source);
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  useEffect(() => {
    dispatch(setEmailDraftView(false));
    dispatch(setSentEmailView(false));
    if (!apicalled) {
      fetchData();
    }
  }, [getIndoxView]);
  return (
    <div className="containter-div">
      <div
        className="chatlistbackground"
        id="scrollableDiv"
        style={{
          height: "700px",
          width: "100%",
          overflow: "auto",
          display: "flex",
        }}
      >
        <List
          dense
          sx={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            margin: "20",
          }}
          className="inboxList"
        >
          <InfiniteScroll
            style={{
              height: "100vh",
            }}
            dataLength={data?.length}
            next={() => {}}
            hasMore={false}
            scrollableTarget="scrollableDiv"
            loader={<Spinner />}
          >
            {data?.map((info: any, index) => (
              <div key={info?.EmailConversation_Id}>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => {
                    EMIAL_CONV.id = info?.EmailConversation_Id;
                    fetchDataofMail();
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel" + index}
                    onChange={handleChange("panel" + index)}
                  >
                    <AccordionSummary
                      aria-controls={"panel" + index + "d-content"}
                      id={"panel" + index + "d-header"}
                    >
                      <ListItemAvatar></ListItemAvatar>
                      <ListItemText
                        primary={parse(info.EmailFrom_Email)}
                        secondary={
                          <>
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {parse(info?.Subject?.toString() + "")}
                              </Typography>
                            </React.Fragment>
                            <p>
                              {truncate(
                                convertHtml(info.Email_Bdy?.toString()),
                                20
                              )}
                            </p>
                          </>
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        {emailList?.map((email: any, index) => {
                          return (
                            <List>
                              <ListItem
                                disablePadding
                                onClick={() => {
                                  console.log(
                                    ":::::::::::::::::::::::::::",
                                    email,
                                    index
                                  );
                                  props.handleEmailSelect(email, index);
                                  dispatch(setComposeEmailView(false));
                                  dispatch(setEmailContentView(true));
                                  setshowmore(!showmore);
                                }}
                              >
                                <ListItemText
                                  primary={email?.EmailFrom_Email}
                                  secondary={
                                    <>
                                      <React.Fragment>
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                        >
                                          {email?.EmailConversation_Subject}
                                        </Typography>
                                      </React.Fragment>
                                      <p>
                                        {truncate(
                                          convertHtml(
                                            email.Email_Bdy?.toString()
                                          ),
                                          20
                                        )}
                                      </p>
                                    </>
                                  }
                                />
                              </ListItem>
                              <hr></hr>
                            </List>
                          );
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}

            {gmailData.length &&
              gmailData?.map((info: any, index) => (
                <>
                  <div className="googleMails">
                    <div className="arrowIconForGoogleMail">
                      <ArrowForwardIosIcon className="arrorForward" />
                    </div>
                    <div
                      className="gmail-data-list"
                      key={info?.EmailConversation_Id}
                      onClick={() => {
                        console.log(":::::::::::::::::::::::::::", info, index);
                        props.handleEmailSelect(info, index);
                        dispatch(setComposeEmailView(false));
                        dispatch(setEmailContentView(true));
                        setshowmore(!showmore);
                      }}
                    >
                      <p className="gmail-data-list-item">
                        {info?.EmailFrom_Email}
                      </p>
                      <p className="gmail-data-list-subitem">
                        {truncate(convertHtml(info.Email_Bdy?.toString()), 20)}
                      </p>
                    </div>
                  </div>

                  <Divider variant="inset" component="li" />
                </>
              ))}
          </InfiniteScroll>
        </List>
      </div>
    </div>
  );
}
