import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import "./AocModal.css";
import AssociatedPresList from "../../layouts/AssociatedPrescriber/AssociatedPresList";
import { getCampaignsData } from "../../apis/campaigns/campaigns";
import MultiselectDropdown from "../../layouts/mutliselectDropdown/MultiselectDropdown";
import config from "../../env.json";
import DropdownInupt from "../shared/Dropdown";
import { getAOCStatus, getSRFQuantity } from "../../apis/srfApi/srfApi";
import {
  setAOCStatus,
  setPresQuantity,
  setRepQuantity,
} from "../../app/slice/srfSlice";
import axios from "axios";
import { setFormId } from "../../app/slice/srfSlice";
import { useDispatch } from "react-redux";
import { GetFormDetails } from "../../apis/forms/formsapi";
import moment from "moment";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

let primcolor =
  document.documentElement.style.getPropertyValue("--primary-color");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "28%",
  borderRadius: "1rem",
  padding: ".5rem",
  bgcolor: "white",
  border: "none",
};

export default function AocModal({
  show,
  formId,
  handleCloseParent,
  modalFormType,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [assoPrescName, setAssoPrescName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [productQuantity, setProductQuantity] = useState<any>("");
  const [activeProducts, setActiveProducts] = useState([]);
  const [disableBrandInput, setDisableBrandInput] = useState(false);
  const [repQuantityError, setRepQuantityError] = useState(false);
  const [presQuantityError, setPresQuantityError] = useState(false);
  const [repQuantity, setRepQuantityLocal] = useState<any>([]);
  const [presQauntity, setPresQauantityLocal] = useState<any>([]);
  const [aoc, setAoc] = useState<any>([]);
  const [productId, setProductId] = useState<any>();
  const [presId, setPresId] = useState<any>();
  const [aocError, setAocError] = useState(false);
  const [formName, setFormName] = useState("");
  const [rosw, setRows] = useState<any>([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));

  const [userId, setUserId] = useState(1000);

  useEffect(() => {
    getAssetsFromCampign(userId);
    console.log(modalFormType);
  }, []);
  useEffect(() => {
    if (formId != undefined) {
      GetFormDetails({ FormId: formId }, (res: any) => {
        if (res) {
          let data = res;
          console.log(data);
          const signval = res.FormSignReq_Flg == "Y" ? true : false;

          console.log(res);
          setFormName(res.Form_Nm);
          let formfields = res.formFields;
          let formdata: any = [];
          console.log(formfields);
          formfields.map((x: any) => {
            formdata = [
              ...formdata,
              {
                id: x.Id,
                Name: x.Field_Nm,
                Type: x.Field_Typ,
                Value: x.FieldValByEmp_Flg == "Y" ? "Yes" : "No",
                Query: x.FieldQueryExec_Val,
                Prepopulate: x.FieldPrepopulate_Flg == "Y" ? "Yes" : "No",
                Active: x.Active_Flg == "Y" ? "Yes" : "No",
                EffectiveStartDate: moment(x.EffectiveStart_DtTm).format(
                  "YYYY-MM-DD"
                ),
                EffectiveEndDate: moment(x.EffectiveEnd_DtTm).format(
                  "YYYY-MM-DD"
                ),
              },
            ];
            return x;
          });
          console.log(formdata);
          setRows(formdata);
        }
      });
    }
  }, [formId]);

  useEffect(() => {
    getSRFQuantity((res: any) => {
      console.log(res);
      if (res) {
        setRepQuantityLocal(res.employeeQnt ? res.employeeQnt : []);
        setPresQauantityLocal(res.prescriberQnt ? res.prescriberQnt : []);
        dispatch(setRepQuantity(res.employeeQnt ? res.employeeQnt : []));
        dispatch(setPresQuantity(res.prescriberQnt ? res.prescriberQnt : []));
      }
    });
    getAOCStatus((res: any) => {
      if (res) {
        setAoc(res);
        dispatch(setAOCStatus(res));
      }
    });
  }, [show]);

  const getFormStatus = (value: any) => {
    let rep = repQuantity.filter(
      (x) => x.product_id == productId && x.prescriber_id == presId
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    let pres = presQauntity.filter(
      (x) => x.product_id == productId && x.prescriber_id == presId
    );
    let pq = pres.length > 0 ? pres[0].quantity : 0;
    let n = aoc.filter(
      (x) =>
        x.product_id == productId &&
        x.prescriber_id == presId &&
        x.aoc_status == "pending"
    );
    let nc = n.length > 0 ? n[0].aoc_status : "unknown";

    if (value > rq && rq != 0) {
      setRepQuantityError(true);
    } else {
      setRepQuantityError(false);
    }
    if (value > pq && pq != 0) {
      setPresQuantityError(true);
    } else {
      setPresQuantityError(false);
    }
    if (nc == "pending") setAocError(true);
    else setAocError(false);

    if ((value > rq && rq != 0) || (value > pq && pq != 0) || nc == "pending")
      setDisabled(true);
    else setDisabled(false);
  };
  const getRepLimit = () => {
    let rep = repQuantity.filter(
      (x) => x.product_id == productId && x.prescriber_id == presId
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };
  const getPresLimit = () => {
    let rep = presQauntity.filter(
      (x) => x.product_id == productId && x.prescriber_id == presId
    );
    let rq = rep.length > 0 ? rep[0].quantity : 0;
    return rq;
  };
  const selectActiveProducts = (e) => {
    console.log(e.ProductId);
    setProductId(e.ProductId);

    let a = [];

    console.log(a);
  };

  const generateForm = () => {
    let frmId = modalFormType === "SRF" ? 1062 : 1063;
    let idOfField = modalFormType === "SRF" ? 1076 : 1077;
    let formPayLoad = {
      formId: frmId,
      fieldDetails: [
        {
          idOfFieldToAskFromEmp: idOfField,
          valueOfFieldToAskFromEmp: productQuantity ? productQuantity : 10,
        },
      ],
      recipientId: presId,
      recipientType: "PRESCR",
    };
    axios
      .post(config.REACT_APP_FORMS_API_BASE + "/formRequest", formPayLoad)
      .then((res: any) => {
        if (res) if (res.data) handleCloseParent(res.data.FormUrl);
      });
  };

  const saveAocFormDetail = () => {
    console.log("e");
  };
  const setPrescriberName = (e) => {
    if (assoPrescName.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const setPrescriber = (x: any) => {
    setPresId(x.Id);
    console.log(x);
    dispatch(setFormId(x));
  };

  const getAssetsFromCampign = (userId: any) => {
    getCampaignsData({ page: 0, pageSize: 10 }, (res) => {
      if (res.length > 0) {
        let tempAssets: any = [];

        console.log(res);
        let dataForTree = res;

        let campaigns: any = [];
        let waves: any = [];
        let products: any = [];

        dataForTree = res.map((x: any) => {
          x.WaveDetails.map((y: any) => {
            y.ProductDetails.map((z: any) => {
              products.push(z);

              return z;
            });
            return y;
          });
          return x;
        });
        console.log(dataForTree);
        setActiveProducts(products);
        tempAssets = tempAssets.sort(function (a: any, b: any) {
          return a.SortOrder_Nbr - b.SortOrder_Nbr;
        });
        console.log(tempAssets);
      }
    });
  };

  return (
    <div>
      <Modal
        open={show}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Box>
            <div className="modal-box contact-box addcontact-height">
              <div className="modal-header d-flex ">
                <h1 className="font-createcontact">{t(`${formName}`)}</h1>
                <span
                  onClick={() => {
                    handleCloseParent(null);
                  }}
                  className="close"
                >
                  ×
                </span>
              </div>

              <div className="modal-body addContacts srf-form-body">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveAocFormDetail();
                  }}
                >
                  <div className="form-fields-div">
                    <AssociatedPresList
                      clearDataOnSelect={false}
                      placeholder={t("Search for a Prescriber")}
                      label={t("Prescriber Name")}
                      styleClass="inputEnable"
                      onChangeInputValue={(e: any) => {
                        setPrescriberName(e);
                        setDisabled(true);
                      }}
                      setAssoPresc={(e: any) => {
                        window.localStorage.setItem(
                          "formContact",
                          JSON.stringify(e)
                        );
                        setPrescriber(e);
                        setDisabled(false);
                      }}
                    />

                    <div>
                      <DropdownInupt
                        activeValue={activeProducts}
                        disableColored={disableBrandInput}
                        keepPlaceHolderAtStart={activeProducts}
                        placeHolder={t("Select a brand")}
                        label="Brand"
                        data={activeProducts}
                        id=""
                        name="ProductName"
                        func={(e) => {
                          selectActiveProducts(e);
                        }}
                      />
                    </div>

                    {rosw.map((x: any) => (
                      <div>
                        <div>
                          {x.Type.toLowerCase() == "string" ? (
                            <TextField
                              label={x.Name}
                              onChange={(e) => {
                                setProductQuantity(e.target.value);
                                getFormStatus(e.target.value);
                              }}
                              value={productQuantity}
                              color="secondary"
                              focused
                            />
                          ) : null}
                        </div>
                        {x.Type.toLowerCase() == "date" ? (
                          <TextField
                            id="date"
                            className="Datepicker"
                            label={x.Name}
                            type="date"
                            placeholder="MM/DD/YYYY"
                            sx={{ width: 220 }}
                            inputProps={{
                              style: {
                                height: "84",
                                padding: "1rem",
                                color: "grey",
                              },
                            }}
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            focused
                          />
                        ) : null}
                      </div>
                    ))}

                    {aocError ? (
                      <span style={{ color: "red", fontSize: "1rem" }}>
                        {t("Previous AOC is pending.")}
                      </span>
                    ) : null}
                    {repQuantityError ? (
                      <h6 style={{ color: "red", fontSize: "1rem" }}>
                        {t("Your available limit is")} {getRepLimit()}
                      </h6>
                    ) : null}
                    {presQuantityError ? (
                      <h6 style={{ color: "red", fontSize: "1rem" }}>
                        {t("Prescriber's available limit is")} {getPresLimit()}
                      </h6>
                    ) : null}

                    <button
                      type="submit"
                      className="create-btn"
                      onClick={generateForm}
                      disabled={disabled}
                      style={{ opacity: disabled ? 0.3 : 1 }}
                    >
                      {t("Generate")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
