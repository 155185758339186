import React, { useState, useEffect } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setMobileProfileSidebarView } from "../../app/slice/loginSlice";
import Tooltip from "@mui/material/Tooltip";
import {
  updatePersonalizationData,
  getEmailDetails,
} from "../../apis/personalizationApi/personalization";
import Avatar from "@mui/material/Avatar";
import { getlangTransData } from "../../apis/langtranslation/langTranslation";
import i18n from "i18next";
import poweredByP360 from "../../assets/images/powered_by_p360.png";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { ReactComponent as LangIcon } from "../../assets/icons/langIcon.svg";
import { ReactComponent as LangLocIcon } from "../../assets/icons/langLocIcon.svg";
import { ReactComponent as TimeZoneIcon } from "../../assets/icons/timeZoneIcon.svg";
import config from "../../env.json";
import "./MobileProfileSidebar.css";
import datalist from "../../assets/personalization/datalist.json";
import { getFormatedPhoneNumber } from "../../utils/phoneNumberUtil";
import {
  setOooValue,
  setUserPersonalisationDeatils,
} from "../../app/slice/personalisationSlice";
import MessageIcon from "@mui/icons-material/Message";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { getDeviceType } from "../../utils/deviceTypeUtil";
import EmailDetailsModal from "../../components/EmailDetailsModal/EmailDetailsModal";
import QRModal from "../QR/QR";
import OutOfOfficeMobile from "../OutOfOfficeModal/OutOfOfficeMobile";
import {
  getOutOfOffice,
  setOOOStatus,
} from "../../apis/autopilotApi/autopilotApi";

const Background: any = config.REACT_APP_PROFILE_BG_IMG;

function stringProfileSidebarAvatar(name: string) {
  if (
    config["REACT-APP-SHOW-PROFILE-PHOTO"] === "Y" &&
    config["REACT-APP-PROFILE-PHOTO"]
  ) {
    return {
      src: config["REACT-APP-PROFILE-PHOTO"],
      width: "7rem",
      height: "7rem",
      sx: {
        width: "7.2rem",
        height: "8rem",
      },
    };
  }
  return {
    sx: {
      bgcolor: "#FAF8FF",
      color: "#734BD1",
      fontSize: "2rem",
      width: "7rem",
      height: "7rem",
    },
    children: name ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}` : " ",
  };
}

export default function MobileProfileSidebar(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  const mobileProfileSidebar = useSelector(
    (state: any) => state.login.mobileProfileSidebar
  );
  const userPhno = useSelector((state: any) => state.authApi.EmployeeV_Nbr);
  const userEmail = useSelector(
    (state: any) => state.authApi.EmployeeEmail_Adr
  );
  const userQR = useSelector((state: any) => state.authApi.EmployeeQR_Cd);
  const userName = useSelector((state: any) => state.authApi.EmployeeFull_Nm);
  const empPersonalisationCallType = useSelector(
    (state: any) => state.personalisation
  );
  const oooValue = useSelector((state: any) => state.personalisation.oooValue);
  const empShowCallType = useSelector(
    (state: any) => state.personalisation.showCallType
  );
  const [showOutOfOfficeModal, setShowOutOfOfficeModal] = useState<any>(false);

  const [lang, setLang] = useState<any>("es");
  const [tmzone, settmzone] = useState<any>();
  const [langLocal, setLangLocal] = useState("es-ES");
  const [langLoc, setLangLoc] = useState("");
  const [empId, setEmpId] = useState<any>();
  const [emailId, setEmailId] = useState<any>();
  const [langList, setLangList] = useState<any>();
  const [tmzoneList, setTmzoneList] = useState<any>();
  const [langLocalList, setLangLocalList] = useState<any>();
  const [callTypeList, setCallTypeList] = useState<any>();
  const [empCallType, setEmpCallType] = useState<any>();
  const [msgnotificationSelect, setMsgNotificationSelect] = useState<any>(true);
  const [emailnotificationSelect, setEmailNotificationSelect] =
    useState<any>(true);
  const [pushnotificationSelect, setPushNotificationSelect] =
    useState<any>(true);

  const [transData, setTransData]: any = useState([]);
  const [profileSidebarHeight, setProfileSidebarHeight]: any = useState(0);
  const [profileSidebarWidth, setProfileSidebarWidth]: any = useState(0);
  const [profileSidebarTop, setProfileSidebarTop]: any = useState(0);
  const [profileSidebarLeft, setProfileSidebarLeft]: any = useState(0);
  const [mobileStyle, setMobileStyle]: any = useState(false);
  const [showEmailModal, setShowEmailModal] = useState<any>(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [changingAuto, setChangingAuto] = useState<any>(false);
  // const [outOfOfficeStatus, setOutOfOfficeStatus] = useState<any>(null);
  const token = useSelector((state: any) => state.login.token);
  const dispLang = useSelector((state: any) => state.personalisation.language);
  const dispLangLoc = useSelector((state: any) => state.personalisation.locale);
  const dispTmZn = useSelector((state: any) => state.personalisation.timeZone);
  const dispEmpId = useSelector(
    (state: any) => state.personalisation.personalisationId
  );
  const dispEmpCallType = useSelector(
    (state: any) => state.personalisation.callType
  );
  const dispNotificationType = useSelector(
    (state: any) => state.personalisation.notificationType
  );

  let transLocData = {};

  const getHeightWidth = () => {
    let doc: any = document;
    let div: any = document.querySelector("#main");
    let divpaddingTop: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-top")
    );
    let divpaddingbottom: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-bottom")
    );
    let divpaddingleft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingright: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      setProfileSidebarTop(divpaddingTop);
      setProfileSidebarLeft(divpaddingleft);
      if (
        document.getElementsByClassName("mobile-profile-sidebar-container")[0]
      ) {
        setProfileSidebarHeight(
          window.innerHeight - (divpaddingTop + divpaddingbottom)
        );
        setProfileSidebarWidth(
          window.innerWidth - (divpaddingleft + divpaddingright)
        );
      }
    }
  };

  useEffect(() => {
    getHeightWidth();
  }, [
    mobileStyle,
    profileSidebarTop,
    profileSidebarLeft,
    profileSidebarHeight,
    profileSidebarWidth,
  ]);

  useEffect(() => {
    setLangLoc(window.navigator.language.toLowerCase());
    if (langLocal === "" || langLocal === "en-us" || langLocal === "es-ES") {
      setupLanguage();
    }
  }, [langLocal]);
  useEffect(() => {
    console.log(userName);
  }, [userName]);
  useEffect(() => {
    setLangList(datalist["language"]);
    setTmzoneList(datalist["timezone"]);
    setLangLocalList(datalist["langLocal"]);
    setCallTypeList(datalist["callType"]);

    setEmpId(dispEmpId);
    setLang(dispLang);
    setLangLocal(dispLangLoc);
    settmzone(dispTmZn);
    setEmpCallType(dispEmpCallType);
    getNotificationData(dispNotificationType ? dispNotificationType : "");
  }, []);

  const updateEmailDetails = () => {
    getEmailDetails((res) => {
      if (res && res.data && res.data.email) {
        setEmailId(res.data.email);
      } else {
        setEmailId("");
      }
    });
  };
  const handleSave = () => {
    let emptyString = "";
    if (pushnotificationSelect && msgnotificationSelect) {
      emptyString = "push,sms";
    } else if (pushnotificationSelect) {
      emptyString = "push";
    } else if (msgnotificationSelect) {
      emptyString = "sms";
    }
    setEditProfile(false);
    let persPayload = {
      language: lang,
      locale: langLocal,
      timeZone: tmzone,
      personalisationId: empId,
      callType: empPersonalisationCallType.callType,
      notificationType: emptyString,
      EmployeePersonalizationBottomNav_Flg:
        config.REACT_APP_MOBILE_BOTTOM_NAV_FLAG,
    };
    dispatch(
      setUserPersonalisationDeatils({
        callType: empCallType,
        language: lang,
        locale: langLocal,
        personalisationId: empId,
        timeZone: tmzone,
        emailId: emailId,
      })
    );
    updatePersonalizationData(persPayload, (res: any) => {
      if (res) {
        setupLanguage();
      } else {
      }
    });
  };
  const handleSetLang = (e) => {
    const lang = e.target.value;
    setLang(e.target.value);
  };
  const setupLanguage = () => {
    console.log(
      "window.navigator.language.toLowerCase()",
      window.navigator.language.toLowerCase()
    );
    setLangLoc(window.navigator.language.toLowerCase());
    if (!tmzone) {
      let dt = new Date();
      let dtstr = dt.toString();

      settmzone(dtstr.substring(25, 33));
    }
    if (!langLocal) {
      setLangLocal(window.navigator.language.toLowerCase());
    }

    if (transData <= 0) {
      getlangTransData(
        langLocal ? langLocal : window.navigator.language.toLowerCase(),
        (res) => {
          if (res && res.data) {
            //formatting the data of lang translation
            let langjson: any = {};
            let transjson: any = {};
            transjson["translation"] = res.data["translatedValues"][0];
            langjson[langLocal] = transjson;

            if (
              langLocal === "en-us" ||
              langLocal === "en-usa" ||
              langLocal === "en-USA" ||
              langLocal === "" ||
              langLocal === "es-es" ||
              langLocal === "es-ES"
            ) {
              langjson["en"] = transjson;
            } else {
              langjson[langLocal] = transjson;
            }

            setTransData(langjson);
            transLocData = langjson;
            console.log(
              "lang before trans function",
              transData,
              "lang loc",
              langLocal
            );

            i18n.use(initReactI18next).init({
              resources: langjson,

              lng: langLocal,

              interpolation: {
                escapeValue: false,
              },
            });
          }
        },
        token
      );
    }
  };

  const emailHandleSelect = () => {
    setEmailNotificationSelect(!emailnotificationSelect);
  };
  const pushHandleSelect = () => {
    if (editProfile) setPushNotificationSelect(!pushnotificationSelect);
  };
  const msghandleSelect = () => {
    if (editProfile) setMsgNotificationSelect(!msgnotificationSelect);
  };
  const getNotificationData = (e) => {
    if (e.indexOf("push") >= 0) {
      setPushNotificationSelect(true);
    } else {
      setPushNotificationSelect(false);
    }

    if (e.indexOf("sms") >= 0) {
      setMsgNotificationSelect(true);
    } else {
      setMsgNotificationSelect(false);
    }
  };

  const handleSetcalltype = (e) => {
    const lang = e.target.value;
    setEmpCallType(e.target.value);
    dispatch(
      setUserPersonalisationDeatils({
        callType: e.target.value,
      })
    );
  };
  const handleSetLangLocal = (e) => {
    const langLocal = e.target.value;
    setLangLocal(e.target.value);
  };
  const handleSetTmZone = (e) => {
    const TmZone = e.target.value;
    settmzone(e.target.value);
  };

  const handleQR = () => {
    setModalShow(true);
  };

  const download = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a: any = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.log(error));
  };
  /**
   * This function is used to set the out of office status
   * @param e event object
   */
  const onChangeOfOutOfOfficeStatus = (e) => {
    const outOfOfficeStatus = e.target.checked;
    setOOOStatus({ outOfOfficeStatus: e.target.checked }, (res) => {
      setChangingAuto(false);
      if (res?.data?.code === 200) {
        // setOutOfOfficeStatus(outOfOfficeStatus);
        dispatch(setOooValue(outOfOfficeStatus));
      }
    });
  };
  const getOOOStatus = () => {
    getOutOfOffice((res) => {
      dispatch(setOooValue(res?.data[0]?.Status === "Y"));
    });
  };

  useEffect(() => {
    getOOOStatus();
  }, []);
  return (
    <div>
      <div
        className="mobile-profile-sidebar-container"
        style={
          mobileStyle
            ? {
                height: profileSidebarHeight,
                width: profileSidebarWidth,
                top: profileSidebarTop,
                left: profileSidebarLeft,
              }
            : {}
        }
      >
        <div
          className="profile-header-left"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <span
            onClick={() => {
              dispatch(setMobileProfileSidebarView(false));
              // getOOOStatus();
            }}
          >
            ×
          </span>
          <div className="profile-avatar-right mobile-sidebar-avatar">
            <Avatar {...stringProfileSidebarAvatar(userName)} />
            {(config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" ||
              config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y") &&
            config.REACT_APP_SHOW_PROFILE_EDIT === "Y" ? (
              <div className="editsave">
                {editProfile ? (
                  <Tooltip title={t("Save")} arrow>
                    <div
                      className="editicon"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      <i className="fa fa-solid fa-check"></i>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("Edit")} arrow>
                    <div
                      className="editicon"
                      onClick={() => {
                        setEditProfile(true);
                      }}
                    >
                      <Edit />
                    </div>
                  </Tooltip>
                )}
              </div>
            ) : null}
          </div>
        </div>

        <div>
          <div className="profile-detials-left">
            <label className="user-bold-font">{userName}</label>
            <label>{getFormatedPhoneNumber(userPhno)}</label>
            <label>{userEmail}</label>
          </div>
          {config.REACT_APP_PROFILE_CUSTOMIZATION === "Y" ? (
            <>
              {!editProfile ? (
                <div className="settingsDisplay">
                  <div className="singlerow">
                    <span className="icon">
                      <LangIcon />
                    </span>{" "}
                    <span className="dataSpan">{lang}</span>
                  </div>
                  <div className="singlerow">
                    <span className="icon">
                      <LangLocIcon />
                    </span>{" "}
                    <span className="dataSpan">{langLocal}</span>
                  </div>
                  <div className="singlerow">
                    <span className="icon">
                      <TimeZoneIcon />
                    </span>{" "}
                    <span className="dataSpan">{tmzone}</span>
                  </div>
                  {empShowCallType === true &&
                  config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL === "Y" ? (
                    <div className="singlerow">
                      <span className="icon calltype">
                        <i className="fas fa-phone-alt i-2p5 callIcon"></i>
                      </span>{" "}
                      <span>{t(empPersonalisationCallType.callType)}</span>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="settings">
                  <div className="settrow">
                    <div className="dropdownsicon">
                      <LangIcon />
                    </div>
                    <div>
                      <select
                        value={lang}
                        className="dropdowns"
                        onChange={handleSetLang}
                      >
                        {langList.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="settrow">
                    <div className="dropdownsicon">
                      <LangLocIcon />
                    </div>
                    <div>
                      <select
                        value={langLocal}
                        className="dropdowns"
                        onChange={handleSetLangLocal}
                      >
                        {langLocalList.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="settrow">
                    <div className="dropdownsicon">
                      <TimeZoneIcon />
                    </div>
                    <div>
                      <select
                        value={tmzone}
                        className="dropdowns"
                        onChange={handleSetTmZone}
                      >
                        {tmzoneList.map((item) => (
                          <option key={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {empShowCallType === true &&
                  config.REACT_APP_SHOW_BRIDGECALL_CALLMODAL === "Y" ? (
                    <div className="settrow">
                      <div className="dropdownsicon dropdownCalltype">
                        <i className="fas fa-phone-alt i-2p5 callIcon"></i>
                      </div>
                      <div>
                        <select
                          value={empPersonalisationCallType.callType}
                          className="dropdowns"
                          onChange={handleSetcalltype}
                        >
                          {callTypeList.map((item) => (
                            <option key={item}>{t(item)}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </>
          ) : null}
          {config.REACT_APP_SHOW_NOTIFICATION_ICON === "Y" ? (
            <>
              <div className="singlerow align-notification-iocn">
                <span className="icon">
                  <CircleNotificationsIcon />
                </span>
                <span
                  className={
                    pushnotificationSelect === true
                      ? "select_icon"
                      : "unselect_icon"
                  }
                  onClick={pushHandleSelect}
                >
                  <AdUnitsIcon className="unselect_icon" />
                </span>
                <span
                  className={
                    msgnotificationSelect === true
                      ? "select_icon"
                      : "unselect_icon"
                  }
                  onClick={msghandleSelect}
                >
                  <MessageIcon />
                </span>
              </div>
            </>
          ) : null}
          <>
            {config.REACT_APP_SHOW_ADD_EMAIL == "Y" && (
              <div className="showEmailButton">
                <button
                  onClick={() => {
                    setShowEmailModal(true);
                  }}
                >
                  {emailId == "" ? "Add Email Details" : "Email Details"}
                </button>
              </div>
            )}
          </>
          {config.REACT_APP_OOO_TYPE === "TIMESLOT" ? (
            <>
              <div className="showEmailButton mt-2">
                <button
                  onClick={() => {
                    setShowOutOfOfficeModal(true);
                  }}
                >
                  Set Out Of Office
                </button>
              </div>
            </>
          ) : null}
          {config.REACT_APP_OOO_TYPE === "DEFAULT" && (
            <div className="toggle-ooo">
              <label className="autopilot-label">{t("Out Of Office")}</label>
              <label className="autopilot-switch font-sm">
                <input
                  disabled={changingAuto}
                  checked={oooValue}
                  onClick={() => {
                    setChangingAuto(true);
                  }}
                  onChange={onChangeOfOutOfOfficeStatus}
                  type="checkbox"
                  className="font-sm"
                  id="switchChechedId"
                />

                <Tooltip
                  title={
                    oooValue === true
                      ? t("Click to toggle off")
                      : t("Click to toggle on")
                  }
                  arrow
                >
                  <span className="b-slider b-round"></span>
                </Tooltip>
              </label>
            </div>
          )}

          {config.REACT_APP_PROFILE_QR === "Y" ? (
            <>
              <div className="profile-qrcode-right">
                <img src={userQR} className="qr-code-img" />
              </div>
              <div className="profile-btns-right">
                <button onClick={handleQR}>
                  <i
                    className="fa fa-share icons-sizing"
                    aria-hidden="true"
                  ></i>
                </button>
                <button onClick={() => download(userQR, "qr-code.png")}>
                  {" "}
                  <Tooltip title={t("Download")} arrow>
                    <i
                      className="fa fa-download icons-sizing"
                      aria-hidden="true"
                    ></i>
                  </Tooltip>
                </button>{" "}
              </div>
              {modalShow && (
                <QRModal
                  show={modalShow}
                  handleCloseParent={(e) => {
                    setModalShow(false);
                  }}
                />
              )}
            </>
          ) : null}
          <div className="profile-footer-right mobile-profile-footer">
            <img src={poweredByP360} className="poweredBy-img"></img>
          </div>
        </div>
      </div>

      {showEmailModal ? (
        <EmailDetailsModal
          email={emailId}
          show={showEmailModal}
          handleClose={(email) => {
            setEmailId(email);
            updateEmailDetails();
            setShowEmailModal(false);
          }}
        />
      ) : null}
      {showOutOfOfficeModal && (
        <OutOfOfficeMobile
          show={showOutOfOfficeModal}
          handleClose={() => {
            setShowOutOfOfficeModal(false);
          }}
        />
      )}
    </div>
  );
}
