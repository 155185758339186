import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";
const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultipleSelectPlaceholder(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const [value, setValue] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    
    if(props.keepPlaceHolderAtStart){
      setValue({})
      setDisabled((props.data.length==0 && !props.notdisabledAfter))
    }
    else
    { 
      if(props.activeValue){
        setValue(props.activeValue?props.activeValue:{})
      }
      else
      setValue(props.data.length>0 ?props.data[0]:{})
      
      setDisabled((props.data.length==0 && !props.notdisabledAfter))
    }
  
  }, [props.data])
  
  useEffect(()=>{
    
    if(props.activeValue)
    setValue(props.activeValue)
    else{
      if(props.keepPlaceHolderAtStart){
        setValue({})
        setDisabled((props.data.length==0 && !props.notdisabledAfter))
      }
      else
      { 
        if(props.activeValue){
          setValue(props.activeValue?props.activeValue:{})
        }
        else
        setValue(props.data.length>0 ?props.data[0]:{})
        
        setDisabled((props.data.length==0 && !props.notdisabledAfter))
      }
    }
  },[props.activeValue])
  useEffect(() => {
    if (props.activeValue) setValue(props.activeValue);
    else {
      if (props.keepPlaceHolderAtStart) {
        setValue({});
        setDisabled(props.data.length == 0 && !props.notdisabledAfter);
      } else {
        if (props.activeValue) {
          setValue(props.activeValue ? props.activeValue : {});
        } else setValue(props.data.length > 0 ? props.data[0] : {});

        setDisabled(props.data.length == 0 && !props.notdisabledAfter);
      }
    }
  }, [props.activeValue]);
  useEffect(() => {
    if (props.disableColored === true) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [props.disableColored]);

  return (
    <div className="genericDropdownDiv">
      <InputLabel className="genericDropdown_label">
        {t(props.label)}
      </InputLabel>
      <Select
        onFocus={() => {
          if (props.data.length == 0) props.openTemplates();
        }}
        className={
          props.disableColored ||
          disabled ||
          (props.data.length == 0 && !props.notdisabledAfter)
            ? "genericDropdown_select disabledColor"
            : "genericDropdown_select "
        }
        placeholder="Cordastin"
        value={value}
        defaultValue=""
        displayEmpty={props.keepPlaceHolderAtStart}
        disabled={
          disabled ||
          (props.data.length == 0 && !props.notdisabledAfter) ||
          props.disableColored
        }
        onChange={(e: any) => {
          console.log(e.target.value);
          setValue(e.target.value);
          props.func(e.target.value);
        }}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (props.keepPlaceHolderAtStart && !selected[props.name]) {
            return props.placeHolder;
          } else return selected[props.name];
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        {props.data.map((name) => (
          <MenuItem
            style={{ whiteSpace: "break-spaces" }}
            value={name}
            className="brand_name_dropdown"
          >
            {name[props.name]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
