import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import "./FreeTimeModal.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import config from "../../env.json";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../../utils/deviceTypeUtil";

export const Popup = (props) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  console.log(props);
  const [endDate, setEndDate] = React.useState();
  const [startDate, setstartDate] = React.useState();
  const [startTime, setstartTime] = useState(moment());
  const [endTime, setendTime] = useState(moment());
  const [error, setDateError] = useState(false);
  const [timeerror, setTimeError] = useState(false);
  const [greaterError, setGreaterError] = useState(false);
  const [greaterErrorVal, setGreaterErrorVal] = useState(false);
  const [greatertimeError, setGreaterTimeError] = useState(false);
  const [freeTime, setFreeTime] = useState<any>(0);
  const [modalLeftPadding, setModalLeftPadding] = useState<any>(0);
  const [mobileStyle, setMobileStyle] = useState(false);
  const [lessStarttimeError, setLessStarttimeError] = useState(false);
  const [lessEndtimeError, setLessEndtimeError] = useState(false);
  const [startLessThan, setStartLessThan] = useState(false);
  const [endLessThan, setEndLessThan] = useState(false);

  useEffect(() => {
    setstartDate(props.dateval);
    setEndDate(props.dateval);
    setShow(props.show);
    setDateError(false);
    setTimeError(false);
    setGreaterError(false);
    setStartLessThan(false);
    setGreaterTimeError(false);
    setLessEndtimeError(false);
    setEndLessThan(false);
    return () => {
      setGreaterErrorVal(false);
      setstartTime(moment());
      setendTime(moment());
    };
  }, [props.dateval]);

  let modalSize = "lg";
  const handleStartDateChange = (newValue) => {
    setstartDate(newValue);
    setGreaterError(false);
    setDateError(false);
    setStartLessThan(false);
    setLessEndtimeError(false);
    setEndLessThan(false);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
    setGreaterError(false);
    setStartLessThan(false);
    setDateError(false);
    setLessEndtimeError(false);
    setEndLessThan(false);
  };
  const handleStartTimeChange = (newValue) => {
    const s_time: any = newValue;
    setstartTime(s_time);
    setTimeError(false);
    setLessStarttimeError(false);
    setStartLessThan(false);
    setLessEndtimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setEndLessThan(false);
    console.log(startTime);
  };
  const handleEndTimeChange = (newValue) => {
    const e_time: any = newValue;
    setendTime(e_time);
    console.log(endTime);
    setTimeError(false);
    setLessEndtimeError(false);
    setStartLessThan(false);
    setGreaterError(false);
    setLessEndtimeError(false);
    setGreaterTimeError(false);
    setEndLessThan(false);
  };

  useEffect(() => {
    return () => {
      setGreaterErrorVal(false);
      setstartTime(moment());
      setendTime(moment());
    };
  }, []);
  const handleClose = () => {
    setstartDate(props.dateval);
    setEndDate(props.dateval);
    setDateError(false);
    setTimeError(false);
    setGreaterError(false);
    setGreaterTimeError(false);
    setLessEndtimeError(false);
    setStartLessThan(false);
    setLessStarttimeError(false);
    setEndLessThan(false);

    props.onHide(true);
    return () => {
      setGreaterErrorVal(false);
      setstartTime(moment());
      setendTime(moment());
    };
  };
  useEffect(() => {
    let viewportWidth = window.innerWidth;
    let div: any = document.getElementById("main");
    let divpaddingLeft: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-left")
    );
    let divpaddingRight: any = parseInt(
      window.getComputedStyle(div, "").getPropertyValue("padding-right")
    );
    setModalLeftPadding(divpaddingLeft);
    let doc: any = document;

    let device = getDeviceType();
    if (
      device == "mobile" ||
      device == "tablet" ||
      config.REACT_APP_IS_CROSS_PLATFORM
    ) {
      setMobileStyle(true);
      if (doc.getElementsByClassName("free-time-modal")[0]) {
        setFreeTime(viewportWidth - (divpaddingLeft + divpaddingRight));
      }
    }
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(startTime);
    console.log(endTime);
    var today = new Date();
    let currentTime = moment(today).format("HH:mm");
    let s_date = moment(startDate).format("YYYY-MM-DD");
    let e_date = moment(endDate).format("YYYY-MM-DD");
    let s_time = moment(startTime).format("HH:mm");
    let e_time = moment(endTime).format("HH:mm");
    console.log(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      startTime,
      endTime
    );
    const avail_start = moment(
      moment(startDate).format("YYYY-MM-DD") + " " + s_time
    );
    const newavailstrt = moment(avail_start).toISOString();
    const avail_end = moment(
      moment(endDate).format("YYYY-MM-DD") + " " + e_time
    );
    const newavailend = moment(avail_end).toISOString();
    console.log(newavailstrt, newavailend);
    console.log(s_time, avail_start);
    console.log(s_time, moment().format("HH:mm").toString());
    if (s_date == "Invalid date" || e_date == "Invalid date") {
      setDateError(true);
    } else if (s_date > e_date) {
      setGreaterError(true);
    } else if (
      s_time < currentTime &&
      s_date == moment().format("YYYY-MM-DD") &&
      e_date == moment().format("YYYY-MM-DD")
    ) {
      setStartLessThan(true);
    } else if (avail_start.isBefore(moment())) {
      setLessStarttimeError(true);
    } else if (avail_end.isBefore(avail_start)) {
      setLessEndtimeError(true);
    } else if (s_time == null || e_time == null) {
      setTimeError(true);
    } else if (
      s_time >= e_time &&
      s_date == moment().format("YYYY-MM-DD") &&
      e_date == moment().format("YYYY-MM-DD")
    ) {
      setGreaterTimeError(true);
    } else if (s_time >= e_time && s_date == e_date) {
      setGreaterTimeError(true);
    } else if (newavailstrt < newavailend) {
      setShow(false);
      props.onHide(true);
      axios
        .post(
          config.REACT_APP_CALENDAR_API_BASE + "/freetime",
          {
            Description_Val: "Free time description",
            Subject_Val: "Free time",
            AvailabilityEnd_DtTm: newavailend,
            AvailabilityStart_DtTm: newavailstrt,
            OutlookEvent_Id: "",
            SyncedFromEvent_Id: "",
            SyncedFrom_Typ: "app",
          },
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("login"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          setShow(false);
          handleClose();
          props.start(props.start);
        })
        .catch((err) => {
          props.onHide(false);
          console.log("Freetime Error:" + err);
        });
    } else {
      setGreaterErrorVal(true);
    }
  };

  return (
    <>
      {
        <Modal
          className="free-time-modal"
          style={mobileStyle ? { width: freeTime, left: modalLeftPadding } : {}}
          show={show}
          backdrop="static"
          closeAfterTransition
          {...props}
        >
          <Modal.Header>
            <h5 className="modal-title" id="exampleModalLabel">
              {t("Mark Free Time")}
            </h5>
            <span
              onClick={() => {
                handleClose();
              }}
              className="close"
            >
              ×
            </span>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form onSubmit={handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <div className="StartDateDiv">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Select StartDate")}
                      </InputLabel>
                      <DesktopDatePicker
                        className="StartDate"
                        inputFormat="MM/dd/yyyy"
                        value={startDate}
                        disablePast={true}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>

                    <div className="form-group form-group--third floating-form">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Select StartTime")}
                      </InputLabel>
                      <TimePicker
                        className="form-control font-md calendar-starttime"
                        value={startTime}
                        showSecond={false}
                        use12Hours={true}
                        minuteStep={15}
                        defaultValue={moment(startTime)}
                        allowEmpty={false}
                        onChange={handleStartTimeChange}
                        inputReadOnly={true}
                      />
                      {timeerror || lessStarttimeError ? (
                        <p className="form-error-message">
                          {t("Start time can not be less than current time")}
                        </p>
                      ) : null}
                      {startLessThan ? (
                        <p className="form-error-message">
                          {t("Start time can not be less than current time")}
                        </p>
                      ) : null}
                    </div>

                    <div className="StartDateDiv">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Select EndDate")}
                      </InputLabel>
                      <DesktopDatePicker
                        className="EndDate"
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        disablePast={true}
                        onChange={handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {error ? (
                        <p className="form-error-message">
                          {t(
                            "End date should always be greater than Start date"
                          )}
                        </p>
                      ) : null}
                      {greaterError ? (
                        <p className="form-error-message">
                          {t(
                            "End date should always be greater than Start date"
                          )}
                        </p>
                      ) : null}
                    </div>
                    <div className="form-group form-group--third floating-form">
                      <InputLabel className="genericCalDropdown_label">
                        {t("Select EndTime")}
                      </InputLabel>
                      <TimePicker
                        className="form-control font-md calendar-starttime"
                        value={endTime}
                        showSecond={false}
                        use12Hours={true}
                        minuteStep={15}
                        defaultValue={moment(endTime)}
                        allowEmpty={false}
                        onChange={handleEndTimeChange}
                        inputReadOnly={true}
                      />
                      {timeerror || lessEndtimeError ? (
                        <p className="form-error-message">
                          {t(
                            "End time should always be greater than Start time"
                          )}
                        </p>
                      ) : null}
                      {greatertimeError ? (
                        <p className="form-error-message">
                          {t(
                            "End time should always be greater than Start time"
                          )}
                        </p>
                      ) : null}
                    </div>
                  </Stack>
                </LocalizationProvider>
                {greaterErrorVal ? (
                  <p className="form-error-message">
                    {t("Enter valid date and time")}
                  </p>
                ) : null}
                <div className="submitform">
                  <button
                    type="submit"
                    className="submit-btn btn rounded-primary-40 mx-auto set-freetime"
                  >
                    <span> {t("Set Free Time")}</span>
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      }{" "}
    </>
  );
};

export default Popup;
